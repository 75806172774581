<template lang="pug">
div
  v-row
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-school-outline
        | {{ $t("tutorial") }}
        v-spacer
        v-switch(inset, v-model='tutorial')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-monitor-eye
        | {{ $t("monitor") }}
        v-spacer
        v-switch(inset, v-model='monitor')
      
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-timer-outline
        | {{ $t("timeLimit") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='timeLimit',
          :items='timeLimits',
          item-text='text',
          item-value='value'
        )
      StreamSettings(ref="streamSettings")
    
</template>

<script>
import { mapGetters } from 'vuex'
import StreamSettings from './common/StreamSettings.vue'
export default {
  data: () => ({
    monitor: true,
    tutorial: true,
    timeLimit: -1,
    timeLimits: [
      { text: '不限', value: -1 },
      { text: '5 分钟', value: 5 },
      { text: '10 分钟', value: 10 },
      { text: '15 分钟', value: 15 },
      { text: '20 分钟', value: 20 },
      { text: '30 分钟', value: 30 }
    ]
  }),

  methods: {
    getSettings() {
      return JSON.stringify({
        tutorial: this.tutorial,
        monitor: this.monitor,
        timeLimit: this.enabledCountdown ? this.timeLimit : -1,
        streamSetting: this.$refs.streamSettings.streamSetting()
      })
    }
  },

  computed: {
    ...mapGetters({
      enabledCountdown: 'setting/enabledCountdown'
    })
  },

  components:{
    StreamSettings
  }
}
</script>
        
<style lang="sass">
.setting-row
  height: 50px
</style>
        
<i18n>
{
  "en": {
    "tutorial": "Tutorial", 
    "monitor": "Monitor",
    "timeLimit": "Time Limit",
    "infinite": "Infinite",
    "15min": "15 Mins",
    "20min": "20 Mins",
    "30min": "30 Mins",
    "mode": "Training Mode",
    "levelSettings": "Settings",
    "numOfLevels": "No of Levels",
    "numOfItems": "No of Items",
    "manualMode": "Manual",
    "adaptiveMode": "Adaptive",
    "classicMode": "Classic",
    "hint": "Hint",
    "textMode": "Text",
    "imageMode": "Image",
    "noise": "Noise Level",
    "noNoise": "None",
    "20decibal": "20 Decibal",
    "40decibal": "40 Decibal",
    "60decibal": "60 Decibal",
    "useHand": "惯用手",
    "leftHand": "左手",
    "rightHand": "右手"
  },

  "zh": {
    "tutorial": "新手教学",
    "monitor": "开启监控",
    "timeLimit": "时间限制",
    "infinite": "无限",
    "10min": "10 分钟",
    "15min": "15 分钟",
    "20min": "20 分钟",
    "mode": "训练模式",
    "manualMode": "经典模式",
    "levelSettings": "关卡配置",
    "numOfLevels": "关卡数量",
    "numOfItems": "物品数量",
    "adaptiveMode": "智能模式",
    "classicMode": "智能模式",
    "hint": "提示模式",
    "textMode": "文字提示",
    "imageMode": "图片提示",
    "noise": "噪音分贝",
    "noNoise": "无噪音",
    "20decibal": "20 分贝",
    "40decibal": "40 分贝",
    "60decibal": "60 分贝",
    "useHand": "惯用手",
    "leftHand": "左手",
    "rightHand": "右手",
    "strength": "力度"
  }
}
</i18n>