<template lang='pug'>
div(v-resize='onResize', @click='visible = false')
  v-toolbar.mb-6(flat, style='margin-left: -15px; height: 60px', v-if='isOpen')
    img(src='@/assets/title_logo.png', width='50', style='margin-right: 10px; margin-top: 25px', v-if='!isOpen')
    v-toolbar-title(v-if='isOpen') {{ $t("devicesTitle") }}
    v-divider.mx-4(inset, vertical, v-if='isOpen')
    v-spacer
    v-tooltip(bottom)
      template(v-slot:activator='{ on, attrs }')
        span(v-bind='attrs', v-on='on', style='position: relative; top: 10px')
          v-btn(v-if='!muted', icon, @click='mute()')
            v-icon mdi-volume-high
          v-btn(v-if='muted', icon, @click='setVolume()')
            v-icon mdi-volume-off
      span {{ $t("muteAll") }}
    v-tooltip(bottom)
      template(v-slot:activator='{ on, attrs }')
        v-btn(icon, @click='shutdown()', v-bind='attrs', v-on='on', style='position: relative; top: 10px')
          v-icon mdi-power
      span {{ $t("shutdownAll") }}
    v-tooltip(bottom)
      template(v-slot:activator='{ on, attrs }')
        v-btn(icon, @click='reboot()', v-bind='attrs', v-on='on', style='position: relative; top: 10px')
          v-icon mdi-restart
      span {{ $t("restartAll") }}
    v-spacer
    span(v-if='isAdmin')
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn(fab, dark, color='orange', @click.stop='addDeviceDialog = true', v-bind='attrs', v-on='on')
            v-icon mdi-plus
        span {{ $t("add") }}
  v-row(style='margin-left: -15px', v-if='isOpen') 
    v-col(cols='12', :sm='isOpen ? 2 : 4', :md='isOpen ? 2 : 4')
      el-input.input-with-select(
        :placeholder="$t('enterDeviceName')",
        v-model='devicesNo',
        clearable,
        @clear='inputClear',
        @click.stop.native='isOpen ? (visible = false) : (visible = true)',
        @focus.stop='keyboardShowClick'
      )
    v-tooltip(bottom)
      template(v-slot:activator='{ on, attrs }')
        v-btn.back(dark, color='orange', @click='searchClick()', v-bind='attrs', v-on='on')
          v-icon.iconfont.icon-31sousuo
      span {{ $t("search") }}
  .topBar(v-if='!isOpen')
    v-toolbar.mb-6(flat, style='margin-left: -15px; height: 60px')
      img(src='@/assets/title_logo.png', width='50', style='margin-right: 10px; margin-top: 25px', v-if='!isOpen', @click='setWidth')
      h3(v-if='!isOpen', style='margin-top: 25px; font-size: 19px') {{ $t("devicesTitle") }}
      v-divider.mx-4(inset, vertical, v-if='isOpen')
      v-spacer
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          span(v-bind='attrs', v-on='on', style='position: relative; top: 10px')
            v-btn(v-if='!muted', icon, @click='mute()')
              v-icon mdi-volume-high
            v-btn(v-if='muted', icon, @click='setVolume()')
              v-icon mdi-volume-off
        span {{ $t("muteAll") }}
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn(icon, @click='shutdown()', v-bind='attrs', v-on='on', style='position: relative; top: 10px')
            v-icon mdi-power
        span {{ $t("shutdownAll") }}
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn(icon, @click='reboot()', v-bind='attrs', v-on='on', style='position: relative; top: 10px')
            v-icon mdi-restart
        span {{ $t("restartAll") }}
      v-spacer
      div(:style='{ position: "relative", top: isOpen ? "0" : "10px", left: "-22px" }')
        span(v-if='isAdmin')
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(fab, dark, color='orange', @click.stop='addDeviceDialog = true', v-bind='attrs', v-on='on')
                v-icon mdi-plus
            span {{ $t("add") }}
    v-row(style='margin-left: -15px') 
      v-col(cols='12', :sm='isOpen ? 2 : 4', :md='isOpen ? 2 : 4')
        el-input.input-with-select(
          :placeholder="$t('enterSoftwareName')",
          v-model='devicesNo',
          clearable,
          @clear='inputClear',
          @click.stop.native='visible = true',
          @focus.stop='keyboardShowClick'
        )
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn.back(dark, color='orange', @click='searchClick()', v-bind='attrs', v-on='on')
            v-icon.iconfont.icon-31sousuo
        span {{ $t("search") }}
  div(ref='resizableDiv', v-if='isOpen')
    v-data-table(
      fixed-header,
      :height='dataTableHeight',
      :headers='getHeaders()',
      :items='devicesList',
      :search='search',
      :items-per-page='itemsPerPage',
      :footer-props='{ itemsPerPageOptions: [itemsPerPage] }'
    )
      template(v-slot:item.avail='{ item }')
        v-btn(v-if='item.avail && item.state == "client_connected"', block, rounded, color='primary', @click='start(item)')
          v-icon(left, style='font-size: 22px') mdi-play-circle
          | {{ $t("startTraining") }}
        v-btn(v-else-if='!item.avail && item.state == "client_connected"', block, rounded, color='warning')
          v-icon(left) mdi-youtube-subscription
          | {{ $t("inTraining") }}
        v-btn(v-else, disabled, rounded, block, color='primary') N/A
      template(v-slot:item.name='{ item }')
        v-edit-dialog(large, @save='saveEdit(item)', @open='openEdit(item)') 
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-icon(left, v-bind='attrs', v-on='on') mdi-pencil-outline
              | {{ item.name }}
            span {{ $t("editName") }}
          template(v-slot:input)
            .mt-4.title {{ $t("editName") }}
            v-text-field(v-model='editName', :rules='editNameRules', labe='Edit', single-line, autofocus)
      template(v-slot:item.sn='{ item }')
        pre {{ item.sn }}
      template(v-slot:item.state='{ item }')
        v-tooltip(bottom)
          template(v-slot:activator='{ on, attrs }')
            span.mr-4(v-bind='attrs', v-on='on')
              v-icon(v-if='item.state == "client_connected"', color='success') mdi-access-point-check
              v-icon(v-if='item.state == "client_disconnected"', color='error') mdi-access-point-remove
          span {{ $t("connectionStatus") }}
        v-tooltip(bottom)
          template(v-slot:activator='{ on, attrs }')
            span(v-if='item.state == "client_connected"', v-bind='attrs', v-on='on')
              v-icon(v-if='item.wifiStrength == "excellent"', color='success') mdi-signal-cellular-3
              v-icon(v-if='item.wifiStrength == "good"', color='success') mdi-signal-cellular-2
              v-icon(v-if='item.wifiStrength == "fair"', color='warning') mdi-signal-cellular-1
              v-icon(v-if='item.wifiStrength == "poor"', color='error') mdi-wifi-signal-cellular-outline
          span {{ $t("signalStrengthStatus") }}
        v-tooltip(bottom)
          template(v-slot:activator='{ on, attrs }')
            span(v-if='item.state == "client_connected"', v-bind='attrs', v-on='on')
              span(v-if='item.chargingState == "is_charging"')
                v-icon(v-if='parseInt(item.batteryPercentage) >= 90', color='success') mdi-battery-charging-100
                v-icon(v-else-if='item.batteryPercentage == "0"', color='success') mdi-battery-charging-outline
                v-icon(v-else, color='success') {{ `mdi-battery-charging-${Math.ceil(parseInt(item.batteryPercentage) / 10) * 10}` }}
              span(v-if='item.chargingState == "not_charging"')
                v-icon(v-if='parseInt(item.batteryPercentage) >= 90', color='success') mdi-battery
                v-icon(v-else-if='item.batteryPercentage == "0"', color='error') mdi-battery-outline
                v-icon(
                  v-else,
                  :color='parseInt(item.batteryPercentage) > 50 ? "success" : parseInt(item.batteryPercentage) > 20 ? "warning" : "error"'
                ) {{ `mdi-battery-${Math.ceil(parseInt(item.batteryPercentage) / 10) * 10}` }}
          span {{ $t("batteryStatus") }}
      template(v-slot:item.eegState='{ item }')
        span(v-if='item.state == "client_connected"')
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              span.mr-4(v-bind='attrs', v-on='on')
                v-icon(v-if='item.eegState == "connected"', color='success') mdi-link-variant
                v-icon(v-if='item.eegState == "disconnected"', color='error') mdi-link-variant-off
            span {{ $t("eegStatus") }}
          v-tooltip(v-if='item.eegState == "connected"', bottom)
            template(v-slot:activator='{ on, attrs }')
              span(v-bind='attrs', v-on='on')
                v-icon(v-if='item.eegContact == "BAD"', color='error') mdi-link
                v-icon(v-if='item.eegContact == "POOR"', color='warning') mdi-link
                v-icon(v-if='item.eegContact == "GOOD"', color='success') mdi-link
            span {{ $t("eegContact") }}
          v-tooltip(v-if='item.eegState == "connected"', bottom)
            template(v-slot:activator='{ on, attrs }')
              span(v-bind='attrs', v-on='on')
                v-icon(v-if='parseInt(item.eegBattery) >= 90', color='success') mdi-battery
                v-icon(v-else-if='item.eegBattery == "0"', color='error') mdi-battery-outline
                v-icon(v-else, :color='parseInt(item.eegBattery) > 50 ? "success" : parseInt(item.eegBattery) > 20 ? "warning" : "error"') {{ `mdi-battery-${Math.ceil(parseInt(item.eegBattery) / 10) * 10}` }}
            span {{ $t("batteryStatus") }}
      template(v-slot:item.actions='{ item }')
        span(v-if='item.state == "client_connected"')
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='locate(item)', v-bind='attrs', v-on='on')
                v-icon mdi-broadcast
            span {{ $t("findAction") }}
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='recenter(item)', v-bind='attrs', v-on='on')
                v-icon mdi-image-filter-center-focus
            span {{ $t("recenterAction") }}
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='downVolume(item)', v-bind='attrs', v-on='on')
                v-icon mdi-volume-minus
            span {{ $t("volumeDownAction") }}
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              span(v-bind='attrs', v-on='on')
                v-btn(icon, v-if='item.volume == "0"', @click='setVolume(item)')
                  v-icon mdi-volume-off
                v-btn(icon, v-if='item.volume != "0"', @click='mute(item)')
                  v-icon mdi-volume-high
            span {{ $t("volumeMuteAction") }}
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='upVolume(item)', v-bind='attrs', v-on='on')
                v-icon mdi-volume-plus
            span {{ $t("volumeUpAction") }}
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='shutdown(item)', v-bind='attrs', v-on='on')
                v-icon mdi-power
            span {{ $t("shutdownAction") }}
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='reboot(item)', v-bind='attrs', v-on='on')
                v-icon mdi-restart
            span {{ $t("restartAction") }}
        span(v-if='isAdmin')
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='remove(item)', v-bind='attrs', v-on='on')
                v-icon mdi-delete
            span {{ $t("removeAction") }}

  div(ref='resizableDiv', v-if='!isOpen')
    v-data-table.elevation-1(
      fixed-header,
      :height='visible && enableTouch ? 750 : dataTableHeight',
      :headers='getHeaders()',
      :items='devicesList',
      :search='search',
      :items-per-page='itemsPerPage',
      :footer-props='{ itemsPerPageOptions: [itemsPerPage] }',
      :expanded='expanded',
      item-key='model.ID',
      @click:row='clicked',
      style='margin-top: 150px'
    )
      template(v-slot:expanded-item='{ headers, item }')
        td(:colspan='headers.length', style='background: #f9f7f7; border-top: none')
          v-row(style='font-size: 12px; display: flex; justify-content: end; align-items: center; width: 100%')
            div(style='position: relative; left: 10px')
              v-tooltip(bottom)
                template(v-slot:activator='{ on, attrs }')
                  v-btn(icon, @click='recenter(item)', v-bind='attrs', v-on='on')
                    v-icon mdi-image-filter-center-focus
                span {{ $t("recenterAction") }}
              v-tooltip(bottom)
                template(v-slot:activator='{ on, attrs }')
                  v-btn(icon, @click='shutdown(item)', v-bind='attrs', v-on='on')
                    v-icon mdi-power
                span {{ $t("shutdownAction") }}
              v-tooltip(bottom)
                template(v-slot:activator='{ on, attrs }')
                  v-btn(icon, @click='reboot(item)', v-bind='attrs', v-on='on')
                    v-icon mdi-restart
                span {{ $t("restartAction") }}
              span(v-if='isAdmin')
                v-tooltip(bottom)
                  template(v-slot:activator='{ on, attrs }')
                    v-btn(icon, @click='remove(item)', v-bind='attrs', v-on='on')
                      v-icon mdi-delete
                  span {{ $t("removeAction") }}
      template(v-slot:item.avail='{ item }')
        v-btn(
          v-if='item.avail && item.state == "client_connected"',
          block,
          rounded,
          color='primary',
          @click='start(item)',
          style='width: 100px; height: 30px'
        )
          v-icon(left, style='font-size: 20px') mdi-play-circle
          | {{ $t("startTraining") }}
        v-btn(
          v-else-if='!item.avail && item.state == "client_connected"',
          block,
          rounded,
          color='warning',
          style='width: 100px; height: 30px'
        )
          v-icon(left) mdi-youtube-subscription
          | {{ $t("inTraining") }}
        v-btn(v-else, disabled, rounded, block, color='primary', style='width: 100px; height: 30px') N/A
      template(v-slot:item.name='{ item }')
        v-edit-dialog(large, @save='saveEdit(item)', @open='openEdit(item)') 
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              <!-- v-icon( left v-bind="attrs" v-on="on" ) mdi-pencil-outline -->
              | {{ item.name }}
            span {{ $t("editName") }}
          template(v-slot:input)
            .mt-4.title {{ $t("editName") }}
            v-text-field(v-model='editName', :rules='editNameRules', labe='Edit', single-line, autofocus)
      template(v-slot:item.sn='{ item }')
        pre {{ item.sn }}
      template(v-slot:item.state='{ item }')
        v-tooltip(bottom)
          template(v-slot:activator='{ on, attrs }')
            span.mr-4(v-bind='attrs', v-on='on')
              v-icon(v-if='item.state == "client_connected"', color='success') mdi-access-point-check
              v-icon(v-if='item.state == "client_disconnected"', color='error') mdi-access-point-remove
          span {{ $t("connectionStatus") }}
        v-tooltip(bottom)
          template(v-slot:activator='{ on, attrs }')
            span(v-if='item.state == "client_connected"', v-bind='attrs', v-on='on')
              v-icon(v-if='item.wifiStrength == "excellent"', color='success', style='position: relative; left: -12px') mdi-signal-cellular-3
              v-icon(v-if='item.wifiStrength == "good"', color='success') mdi-signal-cellular-2
              v-icon(v-if='item.wifiStrength == "fair"', color='warning') mdi-signal-cellular-1
              v-icon(v-if='item.wifiStrength == "poor"', color='error') mdi-wifi-signal-cellular-outline
          span {{ $t("signalStrengthStatus") }}
        v-tooltip(bottom)
          template(v-slot:activator='{ on, attrs }')
            span(v-if='item.state == "client_connected"', v-bind='attrs', v-on='on', style='position: relative; left: -10px')
              span(v-if='item.chargingState == "is_charging"')
                v-icon(v-if='parseInt(item.batteryPercentage) >= 90', color='success') mdi-battery-charging-100
                v-icon(v-else-if='item.batteryPercentage == "0"', color='success') mdi-battery-charging-outline
                v-icon(v-else, color='success') {{ `mdi-battery-charging-${Math.ceil(parseInt(item.batteryPercentage) / 10) * 10}` }}
              span(v-if='item.chargingState == "not_charging"')
                v-icon(v-if='parseInt(item.batteryPercentage) >= 90', color='success') mdi-battery
                v-icon(v-else-if='item.batteryPercentage == "0"', color='error') mdi-battery-outline
                v-icon(
                  v-else,
                  :color='parseInt(item.batteryPercentage) > 50 ? "success" : parseInt(item.batteryPercentage) > 20 ? "warning" : "error"'
                ) {{ `mdi-battery-${Math.ceil(parseInt(item.batteryPercentage) / 10) * 10}` }}
          span {{ $t("batteryStatus") }}
      template(v-slot:item.eegState='{ item }')
        span(v-if='item.state == "client_connected"')
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              span.mr-4(v-bind='attrs', v-on='on')
                v-icon(v-if='item.eegState == "connected"', color='success') mdi-link-variant
                v-icon(v-if='item.eegState == "disconnected"', color='error') mdi-link-variant-off
            span {{ $t("eegStatus") }}
          v-tooltip(v-if='item.eegState == "connected"', bottom)
            template(v-slot:activator='{ on, attrs }')
              span(v-bind='attrs', v-on='on')
                v-icon(v-if='item.eegContact == "BAD"', color='error') mdi-link
                v-icon(v-if='item.eegContact == "POOR"', color='warning') mdi-link
                v-icon(v-if='item.eegContact == "GOOD"', color='success') mdi-link
            span {{ $t("eegContact") }}
          v-tooltip(v-if='item.eegState == "connected"', bottom)
            template(v-slot:activator='{ on, attrs }')
              span(v-bind='attrs', v-on='on')
                v-icon(v-if='parseInt(item.eegBattery) >= 90', color='success') mdi-battery
                v-icon(v-else-if='item.eegBattery == "0"', color='error') mdi-battery-outline
                v-icon(v-else, :color='parseInt(item.eegBattery) > 50 ? "success" : parseInt(item.eegBattery) > 20 ? "warning" : "error"') {{ `mdi-battery-${Math.ceil(parseInt(item.eegBattery) / 10) * 10}` }}
            span {{ $t("batteryStatus") }}
      template(v-slot:item.actions='{ item }')
        span(v-if='item.state == "client_connected"')
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='locate(item)', v-bind='attrs', v-on='on')
                v-icon mdi-broadcast
            span {{ $t("findAction") }}
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='downVolume(item)', v-bind='attrs', v-on='on')
                v-icon mdi-volume-minus
            span {{ $t("volumeDownAction") }}
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              span(v-bind='attrs', v-on='on')
                v-btn(icon, v-if='item.volume == "0"', @click='setVolume(item)')
                  v-icon mdi-volume-off
                v-btn(icon, v-if='item.volume != "0"', @click='mute(item)')
                  v-icon mdi-volume-high
            span {{ $t("volumeMuteAction") }}
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='upVolume(item)', v-bind='attrs', v-on='on')
                v-icon mdi-volume-plus
            span {{ $t("volumeUpAction") }}

  v-dialog(v-model='addDeviceDialog', width='500', @click:outside='visible = false')
    v-card
      v-card-title.primary.headline.mb-12
        h4(style='color: white') {{ $t("dialogAddDevice") }}
      v-card-text
        v-text-field(
          v-model='addDeviceSN',
          solo,
          label='SN',
          prepend-inner-icon='mdi-pound-box-outline',
          required,
          @click.stop.native='isOpen ? (visible = false) : (visible = true)',
          @focus.stop='keyboardShowClick'
        )
        v-text-field(
          v-model='addDeviceName',
          solo,
          prepend-inner-icon='mdi-tag-text-outline',
          :label='$t("dialogDeviceName")',
          @click.stop.native='isOpen ? (visible = false) : (visible = true)',
          @focus.stop='keyboardShowClick'
        )
      v-card-actions
        v-spacer
        v-btn(text, @click='addDeviceDialog = false; visible = false') {{ $t("dialogCancel") }}
        v-btn(text, @click='addDevicesConfirm(); visible = false') {{ $t("dialogConfirm") }}

  v-dialog(v-model='cmdConfirmDialog', @click:outside='cmdCancel()', width='500')
    v-card
      v-card-title.warning.headline.mb-6
        h4(style='color: white') {{ $t("dialogCommand") }}
      v-card-text
        h3 {{ cmdConfirmMsg }}
      v-card-actions
        v-spacer
        v-btn(text, @click='cmdCancel()') {{ $t("dialogCancel") }}
        v-btn(text, @click='cmdConfirm()') {{ $t("dialogConfirm") }}

  v-snackbar(elevation='24', v-model='snackbar', :color='snackbarColor', timeout='3000') {{ notifMsg }}
    template(v-slot:action='{ attrs }')
      v-btn(text, v-bind='attrs', @click='snackbar = false') {{ $t("notifClose") }}

  NewTreatment(ref='newTreatment')
  Keyboard#keyboard(layout='normal', :input='input', v-if='visible && enableTouch', :cancel='cancel')
  .nav
    NavigationDrawer(:class='navShow ? "navShow" : "navHide"', @selectClick='selectClick')
  .mask(v-show='navShow && !isOpen', @click='outClick')
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { device } from '../api'
import NewTreatment from './NewTreatment'
import Keyboard from 'vue-keyboard-zh'
import { NavigationDrawer } from '../components'

export default {
  data: vm => ({
    muted: false,

    addDeviceDialog: false,
    addDeviceSN: undefined,
    addDeviceName: undefined,

    dataTableHeight: 0,
    itemsPerPage: 0,
    search: '',
    headers: [
      { text: vm.$t('thAvail'), value: 'avail', align: 'center' },
      { text: vm.$t('name'), value: 'name', sortable: false },
      { text: vm.$t('thSn'), value: 'sn', sortable: false },
      { text: vm.$t('thStatus'), value: 'state', sortable: false },
      { text: vm.$t('thActions'), value: 'actions', align: 'end', sortable: false }
    ],
    headersWithEEG: [
      { text: vm.$t('thAvail'), value: 'avail', align: 'center' },
      { text: vm.$t('name'), value: 'name', sortable: false, align: 'center' },
      { text: vm.$t('thSn'), value: 'sn', sortable: false, align: 'center' },
      { text: vm.$t('thStatus'), value: 'state', sortable: false, align: 'center' },
      { text: vm.$t('thEegStatus'), value: 'eegState', sortable: false, align: 'center' },
      { text: vm.$t('thActions'), value: 'actions', align: 'center', sortable: false }
    ],
    editName: '',
    editNameRules: [v => !!v || 'Name is required', v => v.length <= 10 || 'Input too long!'],

    cmdConfirmDialog: false,
    cmdConfirmMsg: '',
    cmdAction: '',
    cmdCallerSn: '',
    cmdCallerId: '',
    snackbar: false,
    snackbarColor: '',
    notifMsg: '',
    devicesNo: '',
    devicesList: [],
    userName: '',
    isOpen: false,
    expanded: [],
    input: '',
    visible: false,
    navShow: false
  }),

  async mounted() {
    window.addEventListener('resize', this.resize, false)
    this.searchClick()
    this.resize()
  },

  methods: {
    setWidth() {
      this.navShow = true
    },
    selectClick(val) {
      if (!this.isOpen) {
        this.navShow = val
      } else {
        this.navShow = true
      }
    },
    outClick() {
      if (this.isOpen) {
        this.navShow = true
      } else {
        this.navShow = false
      }
    },
    keyboardShowClick(e) {
      if (this.isOpen) {
        this.input = ''
        this.visible = false
      } else {
        this.input = e.target
        if (!this.visible) this.visible = true
      }
    },
    cancel() {
      this.visible = false
    },
    clicked(value) {
      const index = this.expanded.indexOf(value)
      if (index === -1 && this.expanded.length === 0) {
        this.expanded.push(value)
      } else if (index == 0) {
        this.expanded.splice(index, 1)
      } else if (this.expanded.length !== 0) {
        this.expanded.splice(index, 1)
        
        this.expanded.push(value)
      }
    },
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    getLocalStorage() {
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      const obj1 = {
        adminId: role == 'admin' ? adminId : 1,
        userId: userId,
        role: role
      }
      return obj1
    },
    inputClear() {
      this.devicesNo = ''
      this.searchClick()
    },
    async searchClick() {
      if (this.devicesWA.length > 0) {
        const logToken = JSON.parse(window.localStorage.getItem('vuex'))
        const adminId = logToken.users.userId
        const role = logToken.users.role
        const userId = parseInt(
          role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
        )
        const relust = await device.search(this.devicesNo, userId)
        this.devicesList = relust.data.map(x => {
          const device = this.devicesWA.find(y => y.model.ID === x.model.ID)
          return device
        })
      }
    },
    onResize() {
      const height = window.innerHeight - this.$refs.resizableDiv.getBoundingClientRect().y - 12 - 37
      this.dataTableHeight = height
      this.itemsPerPage = parseInt((height - 48) / 48)
    },

    start(item) {
      this.$refs.newTreatment.preDeviceId = item?.model.ID
      this.$refs.newTreatment.dialog = true
    },

    getHeaders() {
      if (!this.enabledEEG) return this.headers
      if (this.enabledEEG) return this.headersWithEEG
    },

    addDevicesConfirm() {
      this.add({ sn: this.addDeviceSN, name: this.addDeviceName })
    },

    async add({ sn, name }) {
      try {
        const result = await device.addTooltip({ SN: sn })
        if (result.data.userId == 0) {
          this.addDeviceDialog = false
          await device.add({ sn, name, ...this.getLocalStorage() })
          this.snackbarColor = 'success'
          this.notifMsg = `${this.$t('notifSuccess')}`
        } else {
          this.userList.forEach(x => {
            if (x.model.ID == result.data.userId) {
              this.userName = x.username
            }
          })
          this.snackbarColor = 'warning'
          this.notifMsg = `${this.$t('thisDeviceAlready')}${this.userName}${this.$t('accountAddition')}!`
        }
      } catch (err) {
        this.snackbarColor = 'error'
        this.notifMsg = `${this.$t('notifFailure')}${err.message}!`
      }
      this.snackbar = true
    },

    locate(item) {
      device.locate(item?.sn)
    },

    recenter(item) {
      device.recenter(item?.sn)
    },

    mute(item) {
      if (item != undefined) {
        this.muted = true
        device.mute(item?.sn)
      } else {
        this.devicesList.forEach(x => {
          this.muted = true
          device.mute(x?.sn)
        })
      }
    },

    setVolume(item) {
      if (item != undefined) {
        this.muted = false
        device.setVolume(item?.sn)
      } else {
        this.devicesList.forEach(x => {
          this.muted = false
          device.setVolume(x?.sn)
        })
      }
    },

    upVolume(item) {
      device.upVolume(item?.sn)
    },

    downVolume(item) {
      device.downVolume(item?.sn)
    },

    shutdown(item) {
      this.cmdConfirmDialog = true
      this.cmdConfirmMsg = `${this.$t('dialogActionShutdownConfirmMsg')}${item?.name || this.$t('dialogActionAllDevice')}?`
      this.cmdAction = 'shutdown'
      this.cmdCallerSn = item?.sn
    },

    reboot(item) {
      this.cmdConfirmDialog = true
      this.cmdConfirmMsg = `${this.$t('dialogActionRestartConfirmMsg')}${item?.name || this.$t('dialogActionAllDevice')}?`
      this.cmdAction = 'reboot'
      this.cmdCallerSn = item?.sn
    },

    remove(item) {
      this.cmdConfirmDialog = true
      this.cmdConfirmMsg = `${this.$t('dialogActionRemoveConfirmMsg')}${item?.name || this.$t('dialogActionAllDevice')}?`
      this.cmdAction = 'remove'
      this.cmdCallerId = item?.model.ID
    },

    async saveEdit(item) {
      const request = {
        id: item.model.ID,
        name: this.editName
      }
      try {
        await device.edit(request)
        this.snackbarColor = 'success'
        this.notifMsg = `${this.$t('notifSuccess')}`
      } catch (err) {
        this.snackbarColor = 'error'
        this.notifMsg = `${this.$t('notifFailure')}${err.message}!`
      }
      this.snackbar = true
    },

    openEdit(item) {
      this.editName = item.name
    },

    cmdCancel() {
      this.cmdConfirmDialog = false
      this.cmdConfirmMsg = ''
      this.cmdAction = ''
      this.cmdCallerSn = ''
    },

    async cmdConfirm() {
      switch (this.cmdAction) {
        case 'shutdown':
          try {
            if (this.cmdCallerSn != undefined) {
              await device.shutdown(this.cmdCallerSn)
            } else {
              this.devicesList.forEach(async x => {
                await device.shutdown(x.sn)
              })
            }
            this.snackbarColor = 'success'
            this.notifMsg = `${this.$t('notifSuccess')}`
          } catch (err) {
            this.snackbarColor = 'error'
            this.notifMsg = `${this.$t('notifFailure')}${err.message}!`
          }
          break
        case 'reboot':
          try {
            if (this.cmdCallerSn != undefined) {
              await device.reboot(this.cmdCallerSn)
            } else {
              this.devicesList.forEach(async x => {
                await device.reboot(x.sn)
              })
            }
            this.snackbarColor = 'success'
            this.notifMsg = `${this.$t('notifSuccess')}`
          } catch (err) {
            this.snackbarColor = 'error'
            this.notifMsg = `${this.$t('notifFailure')}${err.message}!`
          }
          break
        case 'remove':
          try {
            await device.remove(this.cmdCallerId)
            this.snackbarColor = 'success'
            this.notifMsg = `${this.$t('notifSuccess')}`
          } catch (err) {
            this.snackbarColor = 'error'
            this.notifMsg = `${this.$t('notifFailure')}${err.message}!`
          }
          break
      }
      this.snackbar = true
      this.cmdCancel()
    },

    clearSnackbar() {
      this.notifMsg = ''
      this.snackbarColor = ''
    }
  },

  components: {
    NewTreatment,
    Keyboard,
    NavigationDrawer
  },

  computed: {
    ...mapGetters({
      isAdmin: 'users/isAdmin',
      devicesWA: 'devices/devicesWA',
      enabledEEG: 'setting/enabledEEG',
      enableTouch: 'setting/enableTouch'
    }),
    ...mapState({
      userList: state => state.users.userList
    })
  },
  watch: {
    devicesWA(val) {
      this.devicesList = val
    }
  }
}
</script>

<style lang="scss" scoped>
.back {
  margin-top: 11px;
}
::v-deep .v-btn:not(.v-btn--round).v-size--default {
  padding: 0;
  min-width: 45px;
  height: 40px;
}

::v-deep .el-select .el-input {
  width: 100px;
}
::v-deep .el-select > .el-input {
  border-top: 1px solid #9e9e9e;
  border-left: 1px solid #9e9e9e;
  border-bottom: 1px solid #9e9e9e;
  height: 39.5px;
}
::v-deep .el-input .el-input__inner:focus {
  border-color: none !important;
  border: 2px solid #1976d2 !important;
}
::v-deep .input-with-select .el-input-group__prepend {
  background-color: #fff;
}
::v-deep .el-input__inner {
  border: 1px solid #9e9e9e;
}
.elevation-1 {
  width: 100%;
}
::v-deep .elevation-1 > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 8px !important;
}
::v-deep .elevation-1 > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0 8px !important;
}
::v-deep .elevation-1 > .v-data-table__wrapper > table > thead > tr > th > .v-data-table-header__icon {
  font-size: 8px !important;
  position: relative;
  top: 1px;
}
.topBar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
  margin-left: -255;
  background: #fff;
}

#keyboard {
  position: fixed;
  top: auto !important;
  bottom: 0;
  z-index: 999;
  padding: 14px 21px;
}
::v-deep .elevation-1 > .v-data-table__wrapper tbody tr.v-data-table__expanded {
  background: #f9f7f7 !important;
}
::v-deep .theme--light.elevation-1 > .v-data-table__wrapper > table > tbody > tr.v-data-table__expanded > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}
::v-deep .elevation-1 {
  box-shadow: none !important;
}
.navShow {
  animation: expanded 0.5s ease;
}
.navHide {
  display: none;
}
.mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  z-index: 998;
}
</style>

