<template lang="pug">
div(@click='outInputClick()', :style='{ height: isOpen ? "100%" : "1326px" }')
  v-toolbar.mb-6.topBar(flat, style='height: 70px', @click='setWidth')
    img(src='@/assets/title_logo.png', width='50', style='margin-right: 10px; margin-top: 25px; margin-left: -15px', v-if='!isOpen')
    v-toolbar-title(v-if='isOpen', style='margin-left: -15px') {{ $t("settingTitle") }}
    h3(v-if='!isOpen', style='margin-top: 25px; font-size: 19px') {{ $t("settingTitle") }}
    v-divider.mx-4(inset, vertical, v-if='isOpen')
  v-row(style='margin-top: 70px')
    v-col(cols='12')
      h4 {{ $t("functionSettings") }}
      v-row.setting-row(align='center', no-gutters)
        span.iconfont.icon-lianjie
        | {{ $t("goToScaleSystem") }}
        v-spacer
        v-switch(inset, v-model='enabledJumpScale', @click.native.capture.stop='toggleJumpScale()')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-head-snowflake-outline
        | {{ $t("enableEEG") }}
        v-spacer
        v-switch(inset, v-model='enabledEEG', @click.native.capture.stop='toggleEEG()')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-account-outline
        | {{ $t("userName") }}
        v-spacer
        v-radio-group(v-model='typeUsername', row, @change='userNameChange')
          v-radio(:label="$t('user')", value='1')
          v-radio(:label="$t('patient')", value='2')
      v-row.setting-row(align='center', no-gutters)
        span.iconfont.icon-bianhao
        span {{ $t("codeName") }}
        v-spacer
        .input-text
          v-text-field(
            v-if='!enableTouch',
            reverse,
            :placeholder="$t('enterNumberName')",
            :value='numberName',
            @change='v => updateNumberName(v)'
          )
          v-text-field(
            v-else,
            reverse,
            :placeholder="$t('enterNumberName')",
            :value='numberName',
            @click.stop='visible = true; type = "userName"',
            @focus.stop='keyboardShowClick'
          )
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-lock-pattern
        | {{ $t("sectionDepartmentName") }}
        v-spacer
        v-switch(inset, v-model='partitionSwitch', @click.native.capture.stop='togglePartition()')
      v-row.setting-row(align='center', no-gutters, v-if='partition !== "false"')
        v-spacer
        .input-text1(style='width: 280px')
          v-text-field(
            v-if='!enableTouch',
            reverse,
            :placeholder="$t('enterClassificationName')",
            :value='partition == "true" ? "" : partition',
            @change='v => updatePartition(v)'
          )
          v-text-field(
            v-else,
            reverse,
            :placeholder="$t('enterClassificationName')",
            :value='partition == "true" ? "" : partition',
            @focus.stop='mupdatePartition',
            @click.stop='visible = true'
          )
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-bed-outline
        | {{ $t("patientNumberName") }}
        v-spacer
        v-switch(inset, v-model='bedNumberSwitch', @click.native.capture.stop='toggleBedNumber()')
      v-row.setting-row(align='center', no-gutters, v-if='bedNumberName !== "false"')
        v-spacer
        .input-text1(style='width: 280px')
          //-  :value="institution" @change="v => updateInstitution(v)" 
          v-text-field(
            v-if='!enableTouch',
            reverse,
            :placeholder="$t('enterBedNumber')",
            :value='bedNumberName == "true" ? "" : bedNumberName',
            @change='v => updateBedNumber(v)'
          )
          v-text-field(
            v-else,
            reverse,
            :placeholder="$t('enterBedNumber')",
            :value='bedNumberName == "true" ? "" : bedNumberName',
            @blur.stop='mupdateBedNumber',
            @click.stop='visible = true'
          )
      v-row.setting-row(align='center', no-gutters)
        img(src='@/assets/keyboard.png', width='20', style='margin-right: 10px')
        | {{ $t("touchScreenKeyboard") }}
        v-spacer
        v-switch(inset, v-model='enableTouch', @click.native.capture.stop='toggleTouch()')

      h4.mt-8 {{ $t("customReportSettings") }}
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-hospital-building
        | {{ $t("institution") }}
        v-spacer
        .input-text
          v-text-field(v-if='!enableTouch', reverse, :value='institution', @change='v => updateInstitution(v)')
          v-text-field(
            v-else,
            reverse,
            :value='institution',
            @click.stop='visible = true; type = "institution"',
            @focus.stop='keyboardShowClick'
          )
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-file-outline
        | {{ $t("reportName") }}
        v-spacer
        .input-text
          v-text-field(v-if='!enableTouch', reverse, :value='reportName', @change='v => updateReportName(v)')
          v-text-field(
            v-else,
            reverse,
            :value='reportName',
            @click.stop='visible = true; type = "reportName"',
            @focus.stop='keyboardShowClick'
          )

      v-row.setting-row(align='center', no-gutters, style='height: 80px')
        span.iconfont.icon-yiyuan
        span {{ $t("hospitalLogo") }}
        v-spacer
        el-upload(style='margin-top: 30px', action='#', :on-change='handlePreview', :file-list='fileList', :before-remove='beforeRemove')
          template
            el-button(size='small', type='primary', style='margin-left: 200px') {{ $t("clickToUpload") }}
      v-row.setting-row(align='center', no-gutters)
        img(src='@/assets/score.png', width='24', style='margin-right: 5px')
        | {{ $t("scoreOverwrite") }}
        v-spacer
        v-switch(inset, v-model='enableScoreOverwrite', @click.native.capture.stop='toggleScoreOverwrite()')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-pencil-outline
        | {{ $t("timeModification") }}
        v-spacer
        v-switch(inset, v-model='enabledTime', @click.native.capture.stop='toggleEnabledTime()')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-calendar
        | {{ $t("trainingDate") }}
        v-spacer
        v-switch(inset, v-model='displayTime', @click.native.capture.stop='toggleDisplayTime()')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-clock-outline
        | {{ $t("treatmentDuration") }}
        v-spacer
        v-switch(inset, v-model='treatmentTime', @click.native.capture.stop='toggleTreatmentTime()')

      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-clock-outline
        | {{ $t("trainingFinishedTime") }}
        v-spacer
        v-switch(inset, v-model='enableEndTime', @click.native.capture.stop='toggleEndTime()')

      v-row.setting-row(align='center', no-gutters)
        span.iconfont.icon-nianling
        | {{ $t("patientAge") }}
        v-spacer
        v-switch(inset, v-model='enableAge', @click.native.capture.stop='toggleAge()')
      v-row.setting-row(align='center', no-gutters)
        span.iconfont.icon-a-ziyuan606
        | {{ $t("countdown") }}
        v-spacer
        v-switch(inset, v-model='enabledCountdown', @click.native.capture.stop='toggleCountdown()')

      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-calendar-plus
        | {{ $t("treatmentRecords") }}
        v-spacer
        .input-limit
          v-text-field(v-if='!enableTouch', type='number', reverse, :value='total', @change='v => updatetreatmentRecords(v)')
          v-text-field(
            v-else,
            type='number',
            reverse,
            :value='total',
            @blur.stop='mupdatetreatmentRecords',
            @click.stop='visible = true',
            @focus.stop='keyboardShowClick'
          )
  Keyboard#keyboard(layout='normal', :input='input', v-if='visible && enableTouch', :cancel='cancel')
  .nav
    NavigationDrawer(:class='navShow ? "navShow" : "navHide"', @selectClick='selectClick')
  .mask(v-show='navShow && !isOpen', @click='outClick')
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { setting } from '../api'
import { VUE_APP_SERVER_ENDPOINT } from '@/app.config'
import Keyboard from 'vue-keyboard-zh'
import { NavigationDrawer } from '../components'

export default {
  data() {
    return {
      fileList: [],
      imgName: '',
      isOpen: false,
      input: '',
      visible: false,
      type: '',
      target: undefined,
      navShow: false
    }
  },
  components: {
    Keyboard,
    NavigationDrawer
  },
  mounted() {
    window.addEventListener('resize', this.resize, false)

    this.resize()
    if (this.logo) {
      this.fileList.push({ name: this.logo, url: `${VUE_APP_SERVER_ENDPOINT}/assets/${this.logo}` })
    } else {
      this.fileList = []
    }
  },

  watch: {
    logo: function (val) {
      this.imgName = val
      if (val) {
        this.fileList.push({ name: val, url: `${VUE_APP_SERVER_ENDPOINT}/assets/${val}` })
      } else {
        this.fileList = []
      }
    }
  },
  methods: {
    setWidth() {
      this.navShow = true
    },
    selectClick(val) {
      if (!this.isOpen) {
        this.navShow = val
      } else {
        this.navShow = true
      }
    },
    outClick() {
      if (this.isOpen) {
        this.navShow = true
      } else {
        this.navShow = false
      }
    },
    outInputClick() {
      var s = {}
      if (this.type == 'partition') {
        s = { partition: this.target, bedNumberName: this.bedNumberName, logo: this.logo, userId: this.userId(), total: this.total }
        setting.update(s)
      } else if (this.type == 'bedNumberName') {
        s = { partition: this.partition, bedNumberName: this.target, logo: this.logo, userId: this.userId(), total: this.total }
        setting.update(s)
      }
      this.visible = false
    },
    keyboardShowClick(e) {
      this.input = e.target
      if (!this.visible) this.visible = true
      if (this.type == 'userName') {
        const s = {
          numberName: e.target.value,
          bedNumberName: this.bedNumberName,
          partition: this.partition,
          logo: this.logo,
          userId: this.userId(),
          total: this.total
        }
        setting.update(s)
      } else if (this.type == 'institution') {
        const s = {
          institution: e.target.value,
          bedNumberName: this.bedNumberName,
          partition: this.partition,
          logo: this.logo,
          userId: this.userId(),
          total: this.total
        }
        setting.update(s)
      } else if (this.type == 'reportName') {
        const s = {
          reportName: e.target.value,
          bedNumberName: this.bedNumberName,
          partition: this.partition,
          logo: this.logo,
          userId: this.userId(),
          total: this.total
        }
        setting.update(s)
      }
    },
    cancel() {
      this.visible = false
    },
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    async handlePreview(file) {
      const typeArr = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg']
      const isJPG = typeArr.indexOf(file.raw.type) !== -1
      const isLt3M = file.size / 1024 / 1024 < 3
      if (!isJPG) {
        this.$message.error(this.$t('enterBedNumber')) 
        this.$refs.upload.clearFiles()
        this.files = null
        return
      }
      if (!isLt3M) {
        this.$message.error(this.$t('uploadImageSize'))
        this.$refs.upload.clearFiles()
        this.files = null
        return
      }
      this.files = file.raw
      // FormData 对象
      var formData = new FormData()
      // 文件对象
      formData.append('img', this.files)
      const result = await setting.upload(formData)
      if (result.data == 'success') {
        const obj = { name: file.name, url: `${VUE_APP_SERVER_ENDPOINT}/assets/${file.name}` }
        const s = {
          logo: file.name,
          bedNumberName: this.bedNumberName,
          partition: this.partition,
          userId: this.userId(),
          total: this.total
        }
        setting.update(s)
        this.fileList.push(obj)
        this.fileList = []
      } else {
        this.$message.error("$t('reuploadImage')!")
      }
    },
    beforeRemove() {
      const s = { logo: '', bedNumberName: this.bedNumberName, partition: this.partition, userId: this.userId(), total: this.total }
      setting.update(s)
      this.fileList.splice(0, 1)
    },

    toggleJumpScale() {
      const s = {
        jumpScale: !this.enabledJumpScale,
        bedNumberName: this.bedNumberName,
        partition: this.partition,
        logo: this.logo,
        userId: this.userId(),
        total: this.total
      }
      setting.update(s)
    },

    toggleEEG() {
      const s = {
        enableEEG: !this.enabledEEG,
        bedNumberName: this.bedNumberName,
        partition: this.partition,
        logo: this.logo,
        userId: this.userId(),
        total: this.total
      }
      setting.update(s)
    },

    toggleTouch() {
      const s = {
        touchSwitch: !this.enableTouch,
        bedNumberName: this.bedNumberName,
        partition: this.partition,
        logo: this.logo,
        userId: this.userId(),
        total: this.total
      }
      setting.update(s)
    },

    userId() {
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      return userId
    },

    toggleCategory() {
      const s = {
        enableCategory: !this.enabledCategory,
        bedNumberName: this.bedNumberName,
        partition: this.partition,
        logo: this.logo,
        userId: this.userId(),
        total: this.total
      }
      setting.update(s)
    },

    togglePartition() {
      const s = {
        partition: this.partition == 'false' ? 'true' : 'false',
        bedNumberName: this.bedNumberName,
        logo: this.logo,
        userId: this.userId(),
        total: this.total
      }
      setting.update(s)
    },

    toggleBedNumber() {
      const s = {
        bedNumberName: this.bedNumberName == 'false' ? 'true' : 'false',
        partition: this.partition,
        logo: this.logo,
        userId: this.userId(),
        total: this.total
      }
      setting.update(s)
    },

    toggleScoreOverwrite() {
      const s = {
        enableScoreOverwrite: !this.enableScoreOverwrite,
        bedNumberName: this.bedNumberName,
        partition: this.partition,
        logo: this.logo,
        userId: this.userId(),
        total: this.total
      }
      setting.update(s)
    },

    toggleEnabledTime() {
      const s = {
        enableTime: !this.enabledTime,
        bedNumberName: this.bedNumberName,
        partition: this.partition,
        logo: this.logo,
        userId: this.userId(),
        total: this.total
      }
      setting.update(s)
    },

    toggleDisplayTime() {
      const s = {
        displayTime: !this.displayTime,
        bedNumberName: this.bedNumberName,
        partition: this.partition,
        logo: this.logo,
        userId: this.userId(),
        total: this.total
      }
      setting.update(s)
    },

    toggleTreatmentTime() {
      const s = {
        treatmentTime: !this.treatmentTime,
        bedNumberName: this.bedNumberName,
        partition: this.partition,
        logo: this.logo,
        userId: this.userId(),
        total: this.total
      }
      setting.update(s)
    },

    toggleEndTime() {
      const s = {
        enableEndTime: !this.enableEndTime,
        bedNumberName: this.bedNumberName,
        partition: this.partition,
        logo: this.logo,
        userId: this.userId(),
        total: this.total
      }
      setting.update(s)
    },

    toggleAge() {
      const s = {
        enableAge: !this.enableAge,
        bedNumberName: this.bedNumberName,
        partition: this.partition,
        logo: this.logo,
        userId: this.userId(),
        total: this.total
      }
      setting.update(s)
    },

    toggleCountdown() {
      const s = {
        countDown: !this.enabledCountdown,
        bedNumberName: this.bedNumberName,
        partition: this.partition,
        logo: this.logo,
        userId: this.userId(),
        total: this.total
      }
      setting.update(s)
    },

    updateNumberName(v) {
      const s = {
        numberName: v,
        bedNumberName: this.bedNumberName,
        partition: this.partition,
        logo: this.logo,
        userId: this.userId(),
        total: this.total
      }
      setting.update(s)
    },

    mupdatePartition(e) {
      this.target = e.target.value
      this.type = 'partition'
      this.input = e.target
      if (!this.visible) this.visible = true
    },

    updatePartition(v) {
      const s = { partition: v, bedNumberName: this.bedNumberName, logo: this.logo, userId: this.userId(), total: this.total }
      setting.update(s)
    },

    updateBedNumber(v) {
      const s = { bedNumberName: v, partition: this.partition, logo: this.logo, userId: this.userId(), total: this.total }
      setting.update(s)
    },

    mupdateBedNumber(e) {
      this.target = e.target.value
      this.type = 'bedNumberName'
      this.input = e.target
      if (!this.visible) this.visible = true
    },

    updateInstitution(v) {
      const s = {
        institution: v,
        bedNumberName: this.bedNumberName,
        partition: this.partition,
        logo: this.logo,
        userId: this.userId(),
        total: this.total
      }
      setting.update(s)
    },
    updateScaleTitle(v) {
      const s = {
        scaleTitle: v,
        bedNumberName: this.bedNumberName,
        partition: this.partition,
        logo: this.logo,
        userId: this.userId(),
        total: this.total
      }
      setting.update(s)
    },
    updateReportName(v) {
      const s = {
        reportName: v,
        bedNumberName: this.bedNumberName,
        partition: this.partition,
        logo: this.logo,
        userId: this.userId(),
        total: this.total
      }
      setting.update(s)
    },
    updatetreatmentRecords(v) {
      const s = { total: Number(v), bedNumberName: this.bedNumberName, partition: this.partition, logo: this.logo, userId: this.userId() }
      setting.update(s)
    },

    mupdatetreatmentRecords(e) {
      this.input = e.target
      if (!this.visible) this.visible = true
      const s = {
        total: Number(e.target.value),
        bedNumberName: this.bedNumberName,
        partition: this.partition,
        logo: this.logo,
        userId: this.userId()
      }
      setting.update(s)
    },

    userNameChange(v) {
      const s = {
        typeUsername: v,
        bedNumberName: this.bedNumberName,
        partition: this.partition,
        logo: this.logo,
        userId: this.userId(),
        total: this.total
      }
      setting.update(s)
    }
  },

  computed: {
    ...mapGetters({
      enabledEEG: 'setting/enabledEEG',
      enabledCategory: 'setting/enabledCategory',
      partition: 'setting/enabledPartition',
      institution: 'setting/institution',
      scaleTitle: 'setting/scaleTitle',
      reportName: 'setting/reportName',
      typeUsername: 'setting/userName',
      total: 'setting/total',
      displayTime: 'setting/displayTime',
      enableScoreOverwrite: 'setting/enableScoreOverwrite',
      numberName: 'setting/numberName',
      bedNumberName: 'setting/bedNumberName',
      treatmentTime: 'setting/treatmentTime',
      logo: 'setting/logo',
      enabledTime: 'setting/enabledTime',
      enableEndTime: 'setting/enableEndTime',
      enableTouch: 'setting/enableTouch',
      enableAge: 'setting/enableAge',
      enabledJumpScale: 'setting/enabledJumpScale',
      enabledCountdown: 'setting/enabledCountdown'
    }),

    ...mapState({
      userType: state => state.setting.userType
    }),

    partitionSwitch() {
      return this.partition == 'false' ? false : true
    },

    bedNumberSwitch() {
      return this.bedNumberName == 'false' ? false : true
    }
  }
}
</script>

<style lang="sass" scoped>
.input-text
  width: 250px
.input-limit
  width: 100px
.iconfont
  font-size: 26px
  margin-right: 8px
  color: #757575
::v-deep .el-upload-list
  position: relative
  top: -37px
  padding-left: 0
::v-deep .el-upload-list__item
  width: 60%
.topBar
  width: 100%
  position: fixed
  top: 0
  z-index: 2
  margin-left: -255
  background: #fff
#keyboard
  position: fixed
  top: auto !important
  bottom: 0
  z-index: 999
  padding: 14px 21px
::v-deep input
  height: 62px
  outline: none
.navShow
  animation: expanded 0.5s ease
.navHide
  display: none
.mask
  width: 100%
  height: 100%
  background: rgba(0,0,0,0.3)
  position: fixed
  top: 0
  z-index: 998
.icon-a-ziyuan606
  font-size: 20px
  font-weight: bold
</style>

