<template lang="pug">
div
  v-toolbar.mb-6(flat)
    v-btn.ma-2(large, color='orange', dark, @click='goBack()')
      v-icon(dark, left) mdi-arrow-left
      | {{ $t("preTitle") }}
    v-divider.mx-4(inset, vertical)
    v-icon.mr-1 mdi-file-chart
    v-toolbar-title {{ $t("conditionManagement") }}
    v-spacer
    div
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn.mr-2(fab, v-bind='attrs', color='orange', v-on='on', @click='printDetail()', style='color: white')
            span.iconfont.icon-chakan
        span {{ $t("viewreport") }}
  v-row(v-if='list.name !== undefined')
    v-col(xl='12', lg='12', md='12')
      v-card.mx-auto(hover)
        .patient_detail
          .patient-mesg
            v-icon(style='margin-top: 19px') mdi-account
            span(style='position: relative; top: 3px; left: 2px') {{ $t("name") }}：
            span(:class='isOpen ? "" : "patient-name"') {{ list.name }}
          .patient-mesg
            v-icon(style='margin-top: 19px') mdi-clipboard-account
            span(style='position: relative; top: 3px; left: 2px') {{ numberName ? numberName : $t("number") }}：
            span(:class='isOpen ? "" : "patient-name"') {{ list.hospitalNumber }}
          .patient-mesg(v-if='partition !== "false"')
            v-icon(style='margin-top: 19px') mdi-lock-pattern
            span(style='position: relative; top: 3px; left: 2px') {{ partition === "true" || partition == "" ? $t("subregion") : partition }}：
            span(:class='isOpen ? "" : "patient-name"') {{ list.bedNumber }}
          .patient-mesg(v-if='bedNumberName !== "false"')
            v-icon(style='margin-top: 19px') mdi-bed
            span(style='position: relative; top: 3px; left: 2px') {{ bedNumberName === "true" || bedNumberName == "" ? (typeUsername == 1 ? $t("userNameNumber") :  $t("patientNumber")) : bedNumberName }}：
            span(:class='isOpen ? "" : "patient-name"') {{ list.patientNumber }}
          div(style='width: 25%')
            v-icon mdi-gender-male-female
            span(style='position: relative; top: 2px; left: 2px') {{ $t("gender")}}：{{ list.gender }}
          div(style='width: 25%', v-if='enableAge')
            v-icon mdi-calendar
            span(style='position: relative; top: 1px; left: 2px') {{ $t("age")}}：{{ Math.floor((new Date() - new Date(list.birthday).getTime()) / 3.15576e10) >= 0 ? Math.floor((new Date() - new Date(list.birthday).getTime()) / 3.15576e10) : "" }}
          div(style='width: 25%')
            v-icon mdi-human-male-female
            span(style='position: relative; top: 1px; left: 2px') {{ $t("maritalStatus") }}：{{ list.maritalStatus }}
          div(style='width: 25%')
            v-icon mdi-map-marker
            span(style='position: relative; top: 1px; left: 2px') {{ $t("patientEthnicity") }}：{{ list.ethnicity }}
          div(style='width: 25%')
            v-icon mdi-school
            span(style='position: relative; top: 3px; left: 2px') {{ $t("patientEducation") }}：{{ list.education }}
          .patient-mesg
            v-icon(style='margin-top: 19px') mdi-pencil-box
            span(style='position: relative; top: 3px; left: 2px') {{ $t("patientRemarks") }}：
            span(:class='isOpen ? "" : "patient-name"') {{ list.remarks }}
          .patient-mesg(:style='{ width: enableAge ? "50%" : "25%" }')
            v-icon(style='margin-top: 19px') mdi-identifier
            span(style='position: relative; top: 3px; left: 2px') {{ $t("IDnumber") }}：
            span.patient-ID {{ list.idNumber }}
  v-row
    v-col(xl='12', lg='12', md='12')
      v-card.mx-auto(hover)
        v-card-title {{ $t("doctorDiagnosis") }}
        v-card-text
          v-row(style='margin-left: 0px; padding: 20px')
            span(style='font-size: 18px; color: #000; position: relative; top: 8px') {{ $t("diagnosising") }}：
            el-select.detail-select(
              v-model='diagnosis',
              filterable,
              :filter-method='diagnosisFilter',
              :placeholder="$t('selectPlease')",
              clearable,
              @clear='diagnosisClear',
              @change='diagnosisChange',
              :style='{ width: isOpen ? "52%" : "92%" }'
            )
              el-option(v-for='item in restaurants', :key='item.model.ID', :label='item.name', :value='item.name', style='font-size: 18px')
  v-row
    v-col(xl='12', lg='12', md='12')
      v-card.mx-auto(hover)
        v-card-title {{ $t("evaluationResult") }}
        v-card-text
          .row1(v-if='assessmentIdData[1] != null')
            span.time {{ assessmentIdData[1].model.CreatedAt.slice(0, 10).replace(/-/g, ".") }}
            span.remark_score {{ $t("mmseScale") }}：{{ assessmentIdData[1].score }}{{ $t("minute") }}
          .row1(v-if='assessmentIdData[0] != null')
            span.time {{ assessmentIdData[0].model.CreatedAt.slice(0, 10).replace(/-/g, ".") }}
            span.remark_score {{ $t("moCAScale") }}：{{ getScore(assessmentIdData[0]) }}{{ $t("minute") }}
          .row1
            v-row(style='margin: 10px 0')
              .gaugeTestBtn(
                @click='gaugeStart',
                v-if='(assessmentIdData[1] === null || assessmentIdData[2] === null) && hospital == ""',
                style='margin-right: 20px'
              ) {{ $t("startScaleAssessment") }}
              .gaugeTestBtn(@click='start', v-if='testResult === null && hospital == ""') {{ $t("beginCognitiveFunctionAssessment") }}
              div(v-if='hospital == "discharge"') {{ $t("noEvaluationResults") }}
          .row1(style='margin-top: 10px', v-if='testResult !== null')
            span.time {{ this.cognitiveTime }}
            span.remark_score {{ $t("cognitiveFunctionAssessment") }}：{{ $t("execution") }}—{{ dimensionData.execution }} {{ $t("orientation") }}—{{ dimensionData.orientation }} {{ $t("attention") }}—{{ dimensionData.attention }} {{ $t("memory") }}—{{ dimensionData.memory }} {{ $t("calculation") }}—{{ dimensionData.calculate }}
  v-row
    v-col(xl='12', lg='12', md='12') 
      v-card(hover)
        v-card-title {{ $t("treatmentPrograms") }}
          v-card-text.recommonContent
            v-card(
              style='width: 300px; margin-bottom: 20px; margin-right: 15px',
              v-for='(item, index) in treatmentPlan',
              :key='index',
              v-if='treatmentPlan.length > 0'
            )
              v-img(:src='getCoverSrc(item)')
              v-card-title(style='font-size: 16px') {{ item.name }}
                v-spacer
                v-btn(color='primary', @click='startTreatment(item)')
                  v-icon(left) mdi-launch
                  |
                  | {{ $t("startTraining") }}
            div(v-if='treatmentPlan.length === 0 && testResult === null', style='margin-left: -16px; font-size: 16px') {{ $t("pleaseEvaluationTestFirst") }}

  NewTreatment(ref='newTreatment')
  NewGauge(ref='newGauge')
  v-row
    v-col(:style='{ display: isOpen ? "flex" : "", justifyContent: "space-between" }')
      v-col(:style='{ width: isOpen ? "100%" : "100%", padding: isOpen ? "0 12px 12px 0" : "0 0 12px 0" }')
        v-card.mx-auto(hover, height='525')
          v-card-title {{ $t("treatmentResults") }}
          v-card-text#pdfDom
            div(style='display: flex; justify-content: center', v-if='testResult !== null')
              apexchart.radarLeft(ref='chart', type='radar', :options='chartOptions', :series='series', width='695')
            span(v-if='testResult === null', style='font-size: 16px') {{ $t("noAnyTraining") }}
      v-col(:style='{ width: isOpen ? "100%" : "100%", padding: isOpen ? "0 0 12px 12px" : "0 0 12px 0" }')
        v-card.mx-auto(hover, height='525') 
          v-card-title {{ $t("treatmentRecordsTitle") }}
          v-card-text
            v-data-table.elevation-1(
              :height='400',
              :headers='headers',
              :items='treatmentsData',
              :items-per-page='7',
              :footer-props='{ itemsPerPageOptions: [7] }',
              @click:row='treatmentDetail'
            )
              template(v-slot:item.model.CreatedAt='{ item }') {{ treatmentDate(item.applicationStartTime.slice(0, 4) == "0001" ? item.model.CreatedAt : item.applicationStartTime, displayTime) }}
              template(v-slot:item.applicationStartTime='{ item }') {{ calcTreatmentTime(item.applicationEndTime, item.applicationStartTime.slice(0, 4) == "0001" ? item.model.CreatedAt : item.applicationStartTime) }} min
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import { patient, record, app } from '../../api'
import NewTreatment from './../NewTreatment'
import NewGauge from './../Gauge/NewGauge'
import { VUE_APP_FILE_SERVER_ENDPOINT } from '@/app.config'

export default {
  data: (vm) => ({
    list: {},
    testResult: [],
    assessmentIdData: [],
    value: '1',
    treatmentRecord: [],
    dimensionData: {},
    gaugeData: [],
    dimension: [1, 2, 4, 3, 5],
    type: '',
    treatmentPlan: [],
    dimensionArr: [],
    dimensionResult: [],
    progressData: [],
    isOpen: false,
    printDom: '',
    chartOptions: {
      chart: {
        height: 300,
        type: 'radar',
        stacked: false,
        toolbar: false,
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1
        }
      },
      stroke: {
        width: 2
      },
      fill: {
        opacity: 0.1
      },
      markers: {
        size: 3,
        hover: {
          size: 5
        }
      },
      xaxis: {
        categories: [vm.$t('attention'), vm.$t('execution'), vm.$t('calculation'), vm.$t('memory'), vm.$t('orientation')]
      },
      yaxis: [
        {
          min: 0,
          max: 100,
          tickAmount: 5,
          show: true,
          opposite: true
        }
      ]
    },
    printTreatment: {},
    treatmentComment: [],
    cognitiveTime: '',
    dimensionTime: '',
    restaurants: [],
    diagnosis: '',
    state: '',
    dataTableHeight: 0,
    itemsPerPage: 0,
    headers: [
      {
        text: vm.$t('date'),
        align: 'center',
        value: 'model.CreatedAt',
        sortable: false
      },
      { text: vm.$t('software'), value: 'app.name', align: 'center', sortable: false },
      {
        text: vm.$t('duration'),
        value: 'applicationStartTime',
        align: 'center',
        sortable: false
      },
      { text: vm.$t('score'), value: 'scoreOverwrite', align: 'center', sortable: false }
    ],
    dimensionCrease: [],
    hospital: '',
    patientsList: [],
    patientsData: [],
    treatmentsData: [],
    getData: [],
    appsData: [],
    categoryType: '0,1,2,3,4,5,6,7,8'
  }),

  watch: {
    patientsWA: function (newVal) {
      if (this.hospital == '') {
        this.list = newVal.find(x => {
          return x.model.ID == parseInt(this.$route.params.id)
        })
        this.diagnosis = this.list.diagnosis
      }
    },
    gaugeList: function (val) {
      this.gaugeData = val
      this.getGaugeTest()
    },
    diagnosis: function (val) {
      this.diagnosis = val
    }
  },

  async mounted() {
    this.resize()
    window.addEventListener('resize', this.resize, false)
    this.printDom = document.querySelector('#pdfDom')
    this.hospital = this.$route.query.hospital
    // 病人信息
    if (this.hospital == '') {
      this.list = this.patientsWA.find(x => {
        return x.model.ID == parseInt(this.$route.params.id)
      })
    }

    // 医生诊断
    this.getDiagnosisRes()
    if (this.list !== undefined && this.hospital == '') {
      this.diagnosis = this.list.diagnosis
    }

    // 量表评估结果
    this.getGaugeTest()

    // 认知功能评估得分
    const result = await patient.getGaugeTesting(this.$route.params.id)
    if (result.status === 200) {
      this.testResult = result.data
      this.cognitiveTime = this.testResult !== null ? this.testResult.model.CreatedAt.slice(0, 10).replace(/-/g, '.') : ''
      // 治疗方案
      if (this.testResult !== null) {
        this.dimensionData = JSON.parse(this.testResult.dimension).dimensionAverage
        this.getTreatmentPlan()
      }
    }

    // 治疗结果
    this.dimensionResult = []
    const res = await patient.getTreatmentResult(this.$route.params.id)
    Object.keys(res.data.count).length > 0
      ? Object.keys(res.data.count).forEach(key => {
        Object.keys(res.data.score).forEach(i => {
          if (key === i) {
            let avg = res.data.score[i] / res.data.count[key]
            this.dimensionResult.push({ [key]: avg })
          }
        })
      })
      : (this.dimensionResult = [{ 0: 0 }, { 1: 0 }, { 2: 0 }, { 3: 0 }, { 4: 0 }])
    this.dimensionArr.forEach((x, i) => {
      var a = this.dimensionResult.filter(item => {
        return i + 1 === parseInt(Object.keys(item)[0])
      })
      if (a.length > 0) {
        this.dimensionCrease.push(x + parseInt(Object.values(a[0])[0]))
      } else {
        this.dimensionCrease.push(x)
      }
    })
    // 认知训练时间
    const timeRes = await patient.getTreatmentTime(this.$route.params.id)
    this.dimensionTime = timeRes.data !== null ? timeRes.data.slice(0, 10).replace(/-/g, '.') : this.$t('NoTreatment')

    // 出院病人病情管理
    if (this.hospital == 'discharge') {
      const dischargeRes = await patient.discharge('', '', this.getUserId)
      this.patientsList = dischargeRes.data
      this.list = this.patientsList.find(x => {
        return x.model.ID == parseInt(this.$route.params.id)
      })
      this.diagnosis = this.list.diagnosis
    }

    // 治疗记录
    const appResult = await app.index(this.getLocalStorage1(this.categoryType, 1))
    this.appsData = appResult.data
    if (this.hospital == '') {
      this.patientsData = this.patients
    } else {
      this.patientsData = this.patientsList
    }
    const year = new Date().getFullYear()
    const month = new Date().getMonth() + 1
    const day = new Date().getDate()
    const currentDate = year + '-' + (month > 9 ? month : '0' + month) + '-' + day

    const result1 = await patient.printTreatment({
      patientId: this.list.model.ID,
      startTime: '1900-01-01 00:00:00',
      endTime: currentDate + ' ' + '23:59:59'
    })
    if (this.apps.length > 0 || this.patientsData.length > 0 || this.devices.length > 0) {
      this.getData = result1.data.map(x => {
        const app = this.appsData.find(y => y.model.ID == x.appId)
        if (app) x.app = app
        const patient = this.patientsData.find(y => y.model.ID == x.patientId)
        if (patient) x.patient = patient
        const device = this.devices.find(y => y.model.ID == x.deviceId)
        if (device) x.device = device
        if (x.deviceId === 0) {
          this.deviceObject = { sn: 'PA7L10PGF7150095W' }
        } else {
          this.deviceObject = { sn: x.device.sn }
        }
        x.device = this.deviceObject
        return x
      })
      this.treatmentsData = []
      this.getData.forEach(x => {
        if (x != undefined) {
          if (x.app != undefined && x.patient != undefined) {
            this.treatmentsData.push(x)
          }
        }
      })

      this.treatmentsData.sort((a, b) => {
        return (
          new Date(a.applicationStartTime.slice(0, 4) == '0001' ? a.model.CreatedAt : a.applicationStartTime) -
          new Date(b.applicationStartTime.slice(0, 4) == '0001' ? b.model.CreatedAt : b.applicationStartTime)
        )
      })
    }
  },

  components: {
    apexchart: VueApexCharts,
    NewTreatment,
    NewGauge
  },

  methods: {
    getLocalStorage1(query, show) {
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      const obj1 = {
        adminId: role == 'admin' ? adminId : 1,
        userId: userId,
        role: role,
        isShow: show,
        type: String(query)
      }
      return obj1
    },
    onResize() {
      const height = window.innerHeight - this.$refs.resizableDiv.getBoundingClientRect().y - 12 - 37
      this.dataTableHeight = height
      this.itemsPerPage = parseInt((height - 48) / 48)
    },
    async getDiagnosisRes() {
      const res = await patient.getDiagnosis(this.diagnosis)
      this.restaurants = res.data
    },

    async diagnosisFilter(val) {
      const res = await patient.getDiagnosis(val)
      this.restaurants = res.data
    },

    diagnosisClear() {
      this.diagnosis = ''
      this.getDiagnosisRes()
    },

    async diagnosisChange(val) {
      const item = this.restaurants.find(x => x.name === val)
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      const year = new Date().getFullYear() - Math.floor((new Date() - new Date(this.list.birthday).getTime()) / 3.15576e10)
      const birthday = year + '-' + '01' + '-' + '01'
      const p = {
        model: this.list.model,
        diagnosis: item.name,
        name: this.list.name,
        gender: this.list.gender,
        birthday: birthday + 'T00:00:00Z',
        hospitalNumber: this.list.hospitalNumber,
        userId: userId
      }
      this.diagnosis = val
      await patient.edit(p)
    },

    treatmentDetail(item) {
      this.$router.push({
        path: `/treatments/${item?.model.ID}`,
        query: { appId: item.appId, patientId: item.patientId, treatmentId: item.model.ID }
      })
    },
    getScore(item) {
      const education = item.data !== '' ? JSON.parse(item.data)[JSON.parse(item.data).length - 1] : "$t('university')"
      if (education == "$t('university')" || education == "$t('master')" || education == "$t('PhD')") {
        return item.score
      } else {
        return item.score < 30 ? item.score + 1 : item.score
      }
    },
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    printDetail() {
      this.$router.push({
        path: `/patients/${this.$route.params.id}`,
        query: { type: 'detailPrint', hospital: this.hospital }
      })
    },
    start() {
      this.$refs.newTreatment.prePatientDetailId = this.$route.params.id
      this.$refs.newTreatment.dialog = true
    },
    gaugeStart() {
      this.$refs.newGauge.prePatientId = this.$route.params.id
      this.$refs.newGauge.gaugeDialog = true
    },
    getGaugeTest() {
      this.gaugeData = this.gaugeList
      this.gaugeData.forEach(async x => {
        const params = {
          patient_id: this.$route.params.id,
          assessment_id: x.model.ID
        }
        const conRes = await patient.getCognitiveTesting(params.patient_id, params.assessment_id)
        this.assessmentIdData.push(conRes.data)
      })
    },
    async getTreatmentPlan() {
      var cognitiveArr = []
      Object.keys(this.dimensionData).forEach(key => {
        this.dimensionArr.push(this.dimensionData[key])
      })
      this.dimensionArr.forEach((item, index) => {
        if (item < 60) {
          cognitiveArr.push(this.dimension[index])
        }
      })

      if (cognitiveArr.length > 0) {
        this.type = cognitiveArr.join(',')
      } else {
        this.type = '0'
      }
      let res = await record.dimension({ dimension: this.type, treatmentId: this.testResult.model.ID })
      this.treatmentPlan = res.data
    },
    getCoverSrc(item) {
      return `${VUE_APP_FILE_SERVER_ENDPOINT}/${item.cover}`
    },
    startTreatment(item) {
      this.$refs.newTreatment.preAppId = item?.model.ID
      this.$refs.newTreatment.dialog = true
    },
    treatmentDate(dateString, displayTime = true) {
      const date = new Date(dateString)
      if (displayTime) {
        return (
          date.toLocaleDateString('zh-CN', { hour12: false }) +
          ' ' +
          date.toLocaleTimeString('zh-CN', {
            hour: '2-digit',
            minute: '2-digit'
          })
        )
      } else {
        return date.toLocaleDateString('zh-CN', { hour12: false })
      }
    },
    calcTreatmentTime(endTime, startTime) {
      var minutes = 0
      if (new Date(startTime).getTime() > new Date(endTime).getTime()) {
        minutes = 0
      } else {
        const diff = Math.abs(new Date(endTime) - new Date(startTime))
        minutes = Math.round(diff / 1000 / 60)
      }

      return minutes
    }
  },

  computed: {
    ...mapGetters({
      patientById: 'patients/patientById',
      patientsWA: 'patients/patientsWA',
      institution: 'setting/institution',
      reportName: 'setting/reportName',
      treatmentById: 'treatments/treatmentById',
      partition: 'setting/enabledPartition',
      typeUsername: 'setting/userName',
      treatments: 'treatments/treatmentsWADP',
      enableScoreOverwrite: 'setting/enableScoreOverwrite',
      displayTime: 'setting/displayTime',
      treatmentTime: 'setting/treatmentTime',
      logo: 'setting/logo',
      bedNumberName: 'setting/bedNumberName',
      numberName: 'setting/numberName',
      enableEndTime: 'setting/enableEndTime',
      enableTouch: 'setting/enableTouch',
      enableAge: 'setting/enableAge'
    }),

    ...mapState({
      gaugeList: state => state.gauge.items,
      appList: state => state.apps.items,
      patients: state => state.patients.items,
      devices: state => state.devices.items,
      apps: state => state.apps.items
    }),

    treatment() {
      return this.treatmentById(this.$route.params.id)
    },

    patient() {
      return this.patientById(this.$route.params.id)
    },

    series() {
      if (this.dimensionArr.length < 0) return []
      return [
        {
          name: this.cognitiveTime,
          data: this.dimensionArr
        },
        {
          name: this.dimensionTime,
          data: this.dimensionCrease
        }
      ]
    },

    getUserId() {
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      return userId
    }
  }
}
</script>

<style scoped lang="scss">
.icon-chakan {
  font-size: 22px;
}

.recommonContent {
  display: flex;
  flex-wrap: wrap;
  padding-right: 0;
}

.durationBox {
  position: absolute;
  bottom: 8px;
  right: 16px;
  padding: 2px 12px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.85);
}

.row1 {
  color: #000000de;
  font-size: 16px;
}

.time {
  margin-right: 20px;
}

.patient_detail {
  line-height: 55px;
  padding: 0 20px;
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  align-content: flex-start;
}

.gaugeTestBtn {
  width: 180px;
  height: 40px;
  background: #ff9800;
  border-radius: 20px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.record-hover:hover {
  background: #eeeeee;
  outline: 2px solid #eee !important;
  outline: none !important;
}

.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}

.patient-name {
  display: inline-block;
  width: 50px;
  height: 52px;
  margin-top: 3px;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}

.patient-ID {
  display: inline-block;
  width: 50%;
  height: 52px;
  margin-top: 3px;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}

.patient-mesg {
  width: 25%;
  display: flex;
  align-items: flex-start;
}
</style>
