<template lang="pug">
div
  v-row
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-monitor-eye
        | {{ $t("monitor") }}
        v-spacer
        v-switch(inset, v-model='monitor')
      v-row.setting-row(align='center', no-gutters)
        img(src='@/assets/hand.png', width='20', style='margin-right: 5px')
        | {{ $t("useHand") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='useHand',
          :items='useHands',
          item-text='text',
          item-value='value'
        )
</template>

<script>
export default {
  data: vm => ({
    monitor: true,
    useHand: 1,
    useHands: [
      { text: vm.$t('leftHand'), value: 0 },
      { text: vm.$t('rightHand'), value: 1 }
    ]
  }),

  methods: {
    getSettings() {
      return JSON.stringify({
        monitor: this.monitor,
        timeLimit: this.enabledCountdown ? this.timeLimit : -1,
        useHand: this.useHand
      })
    }
  }
}
</script>

<style lang="sass">
.setting-row
  height: 50px
</style>

