import axios from 'axios'
import { VUE_APP_SERVER_ENDPOINT } from '@/app.config'

async function index(searchType, userName, userId) {
  return await axios.get(
    `${VUE_APP_SERVER_ENDPOINT}/patient?user_id=${userId}${searchType == '' || userName == '' ? '' : `&${searchType}=${userName}`}`
  )
}

async function discharge(searchType, userName, userId) {
  return await axios.get(
    `${VUE_APP_SERVER_ENDPOINT}/patient/discharge?user_id=${userId}${
      searchType == '' || userName == '' ? '' : `&${searchType}=${userName}`
    }`
  )
}

async function printTreatment(treatment) {
  const json = JSON.stringify(treatment)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/patient/treatment`, json)
}

async function add(patient) {
  const json = JSON.stringify(patient)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/patient`, json)
}

async function batchAdd(patient) {
  const json = JSON.stringify(patient)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/batch/patient`, json)
}

async function edit(patient) {
  const json = JSON.stringify(patient)
  return await axios.put(`${VUE_APP_SERVER_ENDPOINT}/patient`, json)
}

async function delDischarge(id) {
  return await axios.delete(`${VUE_APP_SERVER_ENDPOINT}/patient/${id}`)
}

async function remove(id) {
  return await axios.delete(`${VUE_APP_SERVER_ENDPOINT}/patient/thorough/${id}`)
}

async function getGaugeTesting(id) {
  return await axios.get(`${VUE_APP_SERVER_ENDPOINT}/patient/treatment/${id}`)
}

async function getCognitiveTesting(patientId, assessmentId) {
  return await axios.get(`${VUE_APP_SERVER_ENDPOINT}/patient/examinations?patient_id=${patientId}&assessment_id=${assessmentId}`)
}

async function getTreatmentResult(id) {
  return await axios.get(`${VUE_APP_SERVER_ENDPOINT}/patient/progress/${id}`)
}

async function getTreatmentTime(id) {
  return await axios.get(`${VUE_APP_SERVER_ENDPOINT}/treatment/time/${id}`)
}

async function getDiagnosis(name) {
  return await axios.get(`${VUE_APP_SERVER_ENDPOINT}/illness${name !== '' ? `?name=${name}` : ''}`)
}

export default {
  index,
  add,
  edit,
  remove,
  delDischarge,
  batchAdd,
  printTreatment,
  getCognitiveTesting,
  getGaugeTesting,
  getTreatmentResult,
  getTreatmentTime,
  getDiagnosis,
  discharge
}
