<template lang='pug'>
div(v-resize='onResize', @click='visible = false')
  v-toolbar.mb-6(flat)
    v-btn.ma-2(large, color='orange', dark, @click='goBack()')
      v-icon(dark, left) mdi-arrow-left
      | {{ $t("return")}}
    v-divider.mx-4(inset, vertical)
    v-icon.mr-1 mdi-file-chart
    v-toolbar-title {{ $t("dischargedPatientList") }}
    v-spacer
    .batchDelete(@click='batchDeleteClick()', v-if='isAdmin') {{ $t("batchDeletion") }}
  div(style='padding: 0 16px')
    v-row(style='margin-left: -15px; height: 40px; margin-bottom: 30px; width: 400px')
      v-col(cols='12', sm='8', md='8')
        el-input.input-with-select(
          :placeholder="$t('pleaseEnterContent')",
          v-model='userName',
          clearable,
          @clear='inputClear',
          @click.stop.native='isOpen ? (visible = false) : (visible = true)',
          @focus.stop='keyboardShowClick'
        )
          el-select(v-model='select', slot='prepend', :placeholder="$t('selectPlease')", @change='searchType', clearable, @clear='selectClear')
            el-option(:label="$t('name')", value='name')
            el-option(:label='numberName ? numberName : $t("number")', value='hospital_number')
            el-option(
              :label='bedNumberName === "true" || bedNumberName == "" ? (typeUsername == 1 ? $t("userNameNumber") : $t("patientNumber")) : bedNumberName',
              value='patient_number',
              v-if='bedNumberName !== "false"'
            )
            el-option(
              :label='partition === "true" || partition == "" ? $t("subregion") : partition',
              value='bed_number',
              v-if='partition !== "false"'
            )
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn.back(dark, color='orange', @click='searchClick()', v-bind='attrs', v-on='on')
            v-icon.iconfont.icon-31sousuo
        span {{ $t("search") }}
    div(ref='resizableDiv', v-if='isOpen')
      v-data-table(
        fixed-header,
        :height='dataTableHeight',
        :headers='headers',
        :items='patientsList',
        :search='search',
        :items-per-page='itemsPerPage',
        :footer-props='{ itemsPerPageOptions: [itemsPerPage] }',
        item-key='model.ID',
        v-model='selectedItems',
        :show-select='isAdmin ? true : false'
      )
        template(v-slot:item.hospitalNumber='{ item }')
          v-row.mx-0(align='center')
            v-icon.mr-2 mdi-clipboard-account
            pre.body-1 {{ item.hospitalNumber }}
        template(v-slot:item.bedNumber='{ item }', v-if='partition !== "false"')
          v-row.mx-0(align='center')
            v-icon.mr-2 mdi-lock-pattern
            pre.body-1 {{ item.bedNumber }}
        template(v-slot:item.patientNumber='{ item }')
          v-row.mx-0(align='center')
            v-icon.mr-2 mdi-bed
            pre.body-1 {{ item.patientNumber }}
        template(v-slot:item.birthday='{ item }', v-if='enableAge') {{ Math.floor((new Date() - new Date(item.birthday).getTime()) / 3.15576e10) >= 0 ? Math.floor((new Date() - new Date(item.birthday).getTime()) / 3.15576e10) : "" }}
        template(v-slot:item.model.UpdatedAt='{ item }') {{ treatmentDate(item.model.UpdatedAt, displayTime) }}
        template(v-slot:item.actions='{ item }')
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='goToDetail(item)', v-bind='attrs', v-on='on')
                img.bingli(src='@/assets/bingli_icon.png')
            span {{ $t("conditionManagement") }}
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='print(item)', v-bind='attrs', v-on='on')
                span.iconfont.icon-chakan
            span {{ $t("viewreport") }}

    div(ref='resizableDiv', v-if='!isOpen')
      v-data-table.elevation-1.elevation-1(
        fixed-header,
        :height='visible && enableTouch ? 750 : dataTableHeight',
        :headers='headers',
        :items='patientsList',
        :search='search',
        :items-per-page='itemsPerPage',
        :footer-props='{ itemsPerPageOptions: [itemsPerPage] }',
        :expanded='expanded',
        item-key='model.ID',
        @click:row='clicked',
        v-model='selectedItems',
        :show-select='isAdmin ? true : false'
      )
        template(v-slot:expanded-item='{ headers, item }')
          td(:colspan='headers.length', style='background: #f9f7f7; border-top: none')
            v-row(style='font-size: 12px; display: flex; justify-content: space-between; align-items: center; width: 100%')
              div
                span(style='position: relative; left: 20px; display: inline-block; width: 80px') {{ $t("marriage") }}：{{ item.maritalStatus }}
                span(style='position: relative; left: 80px; width: 80px') {{ $t("patientEthnicity") }}：{{ item.ethnicity }}
              div
                span(v-if='!isAdmin') {{ $t("createTime") }}：
                span(:style='{ marginLeft: isAdmin ? "380px" : "0px", display: "inline-block", width: "105px" }') {{ treatmentDate(item.model.UpdatedAt, displayTime) }}
              div(style='width: 10px; display: flex; flex-direction: column; position: relative; right: 28px')
                span(:style='{ position: "relative", top: isAdmin ? "-5px" : "-10px" }', v-if='isAdmin')
                  v-tooltip(bottom)
                    template(v-slot:activator='{ on, attrs }')
                      v-btn(icon, @click='goToDetail(item)', v-bind='attrs', v-on='on')
                        img.bingli(src='@/assets/bingli_icon.png', style='width: 20px')
                    span {{ $t("conditionManagement") }}
                span(:style='{ position: "relative", top: isAdmin ? "-13px" : "-10px" }', v-if='isAdmin')
                  v-tooltip(bottom)
                    template(v-slot:activator='{ on, attrs }')
                      v-btn(icon, @click='print(item)', v-bind='attrs', v-on='on')
                        span.iconfont.icon-chakan
                    span {{ $t("viewreport") }}

        template(v-slot:item.avail='{ item }')
          v-btn(v-if='item.avail', block, rounded, color='primary', @click='start(item)', style='width: 100px; height: 30px')
            v-icon(left, style='font-size: 20px') mdi-play-circle
            | {{ $t("startTraining") }}
          v-btn(v-else-if='!item.avail', block, rounded, color='warning')
            v-icon(left) mdi-youtube-subscription
            | {{ $t("inTraining") }}
          v-btn(v-else, disabled, rounded, block, color='primary') N/A
        template(v-slot:item.birthday='{ item }') {{ Math.floor((new Date() - new Date(item.birthday).getTime()) / 3.15576e10) > 0 ? Math.floor((new Date() - new Date(item.birthday).getTime()) / 3.15576e10) : "" }}
        template(v-slot:item.model.UpdatedAt='{ item }', v-if='isAdmin') {{ treatmentDatePickUp(item.model.UpdatedAt) }}
        template(v-slot:item.actions='{ item }')
          span(v-if='!isAdmin')
            v-tooltip(bottom)
              template(v-slot:activator='{ on, attrs }')
                v-btn(icon, @click='goToDetail(item)', v-bind='attrs', v-on='on')
                  img.bingli(src='@/assets/bingli_icon.png', style='width: 20px')
              span {{ $t("conditionManagement") }}
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='print(item)', v-bind='attrs', v-on='on')
                v-icon mdi-printer
            span {{ $t("actionPrintTooltip") }}
          span(v-if='!isAdmin')
            v-tooltip(bottom)
              template(v-slot:activator='{ on, attrs }')
                v-btn(icon, @click='edit(item)', v-bind='attrs', v-on='on')
                  v-icon mdi-account-edit
              span {{ $t("actionEditTooltip") }}

    v-dialog(v-model='confirmDialog', @click:outside='confirmCancel()', width='500')
      v-card
        v-card-title.warning.headline.mb-6
          h4(style='color: white') {{ $t("drTitle") }}
        v-card-text
          h3 {{ $t("sureDeleteThisUser") }}？
        v-card-actions
          v-spacer
          v-btn(text, @click='confirmCancel()') {{ $t("drCancel") }}
          v-btn(text, @click='confirmConfirm()') {{ $t("drConfirm") }}

    v-snackbar(elevation='24', v-model='snackbar', :color='snackbarColor', timeout='3000') {{ notifMsg }}
      template(v-slot:action='{ attrs }')
        v-btn(text, v-bind='attrs', @click='snackbar = false') {{ $t("notifClose") }}

    PrintDialog(:printDialog='printDialog', :printId='printId', @cancelClick='cancelClick', @printClick='printHandleClick')
    Keyboard#keyboard(layout='normal', :input='input', v-if='visible && enableTouch', :cancel='cancel')
    .nav
      NavigationDrawer(:class='navShow ? "navShow" : "navHide"', @selectClick='selectClick')
    .mask(v-show='navShow && !isOpen', @click='outClick')
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { patient, app } from '../../api'
import PrintDialog from './PrintDialog.vue'
import Keyboard from 'vue-keyboard-zh'
import { NavigationDrawer } from '../../components'

export default {
  data: () => ({
    dialog: false,
    printDialog: false,
    newItem: {},
    valid: true,
    selectedItems: [],

    dataTableHeight: 0,
    itemsPerPage: 0,
    search: '',
    snackbar: false,
    snackbarColor: '',
    notifMsg: '',
    printContentList: {},
    printContent: [],
    userName: '',
    patientsList: [],
    treatmentsData: [],
    scoreStatus: '',
    deviceObject: {},
    select: 'name',
    removeApp: {},
    getData: [],
    appsData: [],
    categoryType: '0,1,2,3,4,5,6,7,8',
    isOpen: false,
    expanded: [],
    input: '',
    visible: false,
    navShow: false,
    printId: 0,
    confirmDialog: false,
    delIds: []
  }),

  watch: {
    birthdayMenu(val) {
      val && setTimeout(() => (this.$refs.birthdayPicker.activePicker = 'YEAR'))
    }
  },
  async mounted() {
    this.resize()
    window.addEventListener('resize', this.resize, false)
    const appResult = await app.index(this.getLocalStorage1(this.categoryType, 1))
    this.appsData = appResult.data
    this.index()
  },

  methods: {
    async goToDetail(item) {
      this.$router.push({
        path: `/patients/${item?.model.ID}`,
        query: { type: 'desease', hospital: 'discharge' }
      })
    },

    async index() {
      const res = await patient.discharge('', '', this.getUserId)
      this.patientsList = res.data == null ? [] : res.data
    },

    goBack() {
      this.$router.go(-1)
    },

    async batchDeleteClick() {
      var getId = this.selectedItems.map(item => {
        return item?.model.ID
      })
      this.delIds = getId.join(',')
      this.confirmDialog = true
    },
    confirmCancel() {
      this.confirmDialog = false
    },
    async confirmConfirm() {
      try {
        await patient.delDischarge(this.delIds)
        this.snackbarColor = 'success'
        this.notifMsg = `${this.$t('notifSuccess')}`
        this.index()
        this.selectedItems = []
      } catch (err) {
        this.snackbarColor = 'error'
        this.notifMsg = `${this.$t('notifFailure')}${err.message}!`
      }
      this.snackbar = true
      this.confirmCancel()
    },
    clearSnackbar() {
      this.notifMsg = ''
      this.snackbarColor = ''
    },

    setWidth() {
      this.navShow = true
    },

    selectClick(val) {
      if (!this.isOpen) {
        this.navShow = val
      } else {
        this.navShow = true
      }
    },

    outClick() {
      if (this.isOpen) {
        this.navShow = true
      } else {
        this.navShow = false
      }
    },

    clicked(value) {
      const index = this.expanded.indexOf(value)
      if (index === -1 && this.expanded.length === 0) {
        this.expanded.push(value)
      } else if (index == 0) {
        this.expanded.splice(index, 1)
      } else if (this.expanded.length !== 0) {
        this.expanded.splice(index, 1)
        //打开点击的行
        this.expanded.push(value)
      }
    },

    keyboardShowClick(e) {
      if (this.isOpen) {
        this.input = ''
        this.visible = false
      } else {
        this.input = e.target
        if (!this.visible) this.visible = true
      }
    },

    cancel() {
      this.visible = false
    },

    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },

    treatmentDate(dateString, displayTime = true) {
      const date = new Date(dateString)
      if (displayTime) {
        return (
          date.toLocaleDateString('zh-CN', { hour12: false }) +
          ' ' +
          date.toLocaleTimeString('zh-CN', {
            hour: '2-digit',
            minute: '2-digit'
          })
        )
      } else {
        return date.toLocaleDateString('zh-CN', { hour12: false })
      }
    },

    treatmentDatePickUp(dateString) {
      const date = new Date(dateString)
      const month = date.getMonth() + 1
      const days = date.getDate()
      return month + '/' + days
    },

    getLocalStorage1(query, show) {
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      const obj1 = {
        adminId: role == 'admin' ? adminId : 1,
        userId: userId,
        role: role,
        isShow: show,
        type: String(query)
      }
      return obj1
    },

    getLocalStorage() {
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      const obj1 = {
        adminId: role == 'admin' ? adminId : 1,
        userId: userId,
        role: role
      }
      return obj1
    },

    inputClear() {
      this.userName = ''
      this.searchClick()
    },

    selectClear() {
      this.select = ''
      this.searchClick()
    },

    searchType(value) {
      this.select = value
    },

    async searchClick() {
      const patientList = await patient.discharge(this.select, this.userName, this.getUserId)
      this.patientsList = patientList.data == null ? [] : patientList.data
    },

    onResize() {
      const height = window.innerHeight - this.$refs.resizableDiv.getBoundingClientRect().y - 12 - 37
      this.dataTableHeight = height
      this.itemsPerPage = parseInt((height - 48) / 48)
    },

    clear() {
      this.$refs.form.reset()
      this.newItem = {}
    },

    cancelClick() {
      this.printDialog = false
    },

    saveBirthday(date) {
      this.$refs.birthdayMenu.save(date)
    },

    // 查看报告
    async print(item) {
      this.printId = item?.model.ID
      this.printContentList = item
      this.printDialog = true
      this.printContent = []
    },

    async printHandleClick(rangeVal, printId) {
      this.$router.push({
        path: `/patients/${printId}`,
        query: { type: 'print', rangeVal: JSON.stringify(rangeVal), hospital: 'discharge' }
      })
    }
  },

  components: {
    PrintDialog,
    Keyboard,
    NavigationDrawer
  },

  computed: {
    ...mapGetters({
      isAdmin: 'users/isAdmin',
      patientsWA: 'patients/patientsWA',
      institution: 'setting/institution',
      reportName: 'setting/reportName',
      treatmentsByPatientId: 'treatments/treatmentsByPatientId',
      partition: 'setting/enabledPartition',
      typeUsername: 'setting/userName',
      treatments: 'treatments/treatmentsWADP',
      enableScoreOverwrite: 'setting/enableScoreOverwrite',
      displayTime: 'setting/displayTime',
      treatmentTime: 'setting/treatmentTime',
      logo: 'setting/logo',
      bedNumberName: 'setting/bedNumberName',
      numberName: 'setting/numberName',
      enableEndTime: 'setting/enableEndTime',
      enableTouch: 'setting/enableTouch',
      enableAge: 'setting/enableAge'
    }),

    ...mapState({
      patients: state => state.patients.items,
      devices: state => state.devices.items,
      apps: state => state.apps.items
    }),

    headers() {
      const headers = []
      if (this.partition !== 'false') {
        headers.push(
          {
            text: this.$t('name'),
            value: 'name',
            sortable: false,
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.numberName ? this.numberName : this.$t('number'),
            value: 'hospitalNumber',
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.partition === 'true' || this.partition == '' ? this.$t('subregion') : this.partition,
            value: 'bedNumber',
            align: this.isOpen ? 'start' : 'center'
          },
          this.bedNumberName != 'false'
            ? {
                text:
                  this.bedNumberName === 'true' || this.bedNumberName == ''
                    ? this.typeUsername == '1'
                      ? this.$t('thUserNameNumber')
                      : this.$t('thPatientNumber')
                    : this.bedNumberName,
                value: 'patientNumber',
                align: this.isOpen ? 'start' : 'center'
              }
            : '',
          {
            text: this.$t('thGender'),
            value: 'gender',
            align: this.isOpen ? 'start' : 'center'
          },
          this.enableAge
            ? {
                text: this.$t('thAge'),
                value: 'birthday',
                align: this.isOpen ? 'start' : 'center'
              }
            : '',
          {
            text: this.$t('thCreated'),
            value: 'model.UpdatedAt',
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.$t('thActions'),
            value: 'actions',
            sortable: false,
            align: this.isOpen ? 'end' : 'center'
          }
        )
      } else {
        headers.push(
          {
            text: this.$t('name'),
            value: 'name',
            sortable: false,
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.numberName ? this.numberName : this.$t('number'),
            value: 'hospitalNumber',
            align: this.isOpen ? 'start' : 'center'
          },
          this.bedNumberName != 'false'
            ? {
                text:
                  this.bedNumberName === 'true' || this.bedNumberName == ''
                    ? this.typeUsername == '1'
                      ? this.$t('thUserNameNumber')
                      : this.$t('thPatientNumber')
                    : this.bedNumberName,
                value: 'patientNumber',
                align: this.isOpen ? 'start' : 'center'
              }
            : '',
          {
            text: this.$t('thGender'),
            value: 'gender',
            align: this.isOpen ? 'start' : 'center'
          },
          this.enableAge
            ? {
                text: this.$t('thAge'),
                value: 'birthday',
                align: this.isOpen ? 'start' : 'center'
              }
            : '',
          {
            text: this.$t('thMarital'),
            value: 'maritalStatus',
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.$t('thEthnicity'),
            value: 'ethnicity',
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.$t('thCreated'),
            value: 'model.UpdatedAt',
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.$t('thActions'),
            value: 'actions',
            sortable: false,
            align: this.isOpen ? 'end' : 'center'
          }
        )
      }
      return headers
    },

    getUserId() {
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      return userId
    }
  }
}
</script>

<style scoped lang="scss">
.batchDelete {
  width: 100px;
  height: 40px;
  background: orange;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  color: white;
  cursor: pointer;
}
.batchAdd {
  position: relative;
}
.batchBth {
  background: orange !important;
}
.icon-piliangtianjia {
  color: #fff;
}
.addBtn {
  width: 35px;
  position: absolute;
  right: 6px;
}
.back {
  margin-top: 11px;
}
::v-deep .v-btn:not(.v-btn--round).v-size--default {
  padding: 0;
  min-width: 45px;
  height: 40px;
}

::v-deep .el-select .el-input {
  width: 100px;
}
::v-deep .el-select > .el-input {
  border-top: 1px solid #9e9e9e;
  border-left: 1px solid #9e9e9e;
  border-bottom: 1px solid #9e9e9e;
  height: 39.5px;
}
::v-deep .el-input .el-input__inner:focus {
  border-color: none !important;
  border: 2px solid #1976d2 !important;
}
::v-deep .input-with-select .el-input-group__prepend {
  background-color: #fff;
}
::v-deep .el-input__inner {
  border: 1px solid #9e9e9e;
}

.icon-fenquguanli {
  font-size: 26px;
  margin-right: 8px;
  color: #757575;
}

.bingli {
  width: 25px;
}
.elevation-1 {
  width: 100%;
}
::v-deep .elevation-1 > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 8px !important;
}
::v-deep .elevation-1 > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0 8px !important;
}

::v-deep .elevation-1 > .v-data-table__wrapper > table > thead > tr > th > .v-data-table-header__icon {
  font-size: 8px !important;
  position: relative;
  top: 1px;
}
#keyboard {
  position: absolute;
  top: auto !important;
  bottom: 0;
  z-index: 999;
  padding: 14px 21px;
}

::v-deep .v-data-table__wrapper::-webkit-scrollbar {
  width: 0px;
}

::v-deep .mDialog {
  max-height: 300px;
  overflow-y: auto;
}

::v-deep .cDialog {
  max-height: 100%;
}
::v-deep .elevation-1 > .v-data-table__wrapper tbody tr.v-data-table__expanded {
  background: #f9f7f7 !important;
}
::v-deep .theme--light.elevation-1 > .v-data-table__wrapper > table > tbody > tr.v-data-table__expanded > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}
::v-deep .elevation-1 {
  box-shadow: none !important;
}
.navShow {
  animation: expanded 0.5s ease;
}
.navHide {
  display: none;
}
.mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  z-index: 998;
}
.icon-chakan {
  font-weight: bold;
  font-size: 22px;
  color: #757575;
}
</style>

