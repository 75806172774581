import axios from 'axios'
import { VUE_APP_SERVER_ENDPOINT } from '@/app.config'
async function index() {
  let url = ''
  if (localStorage.getItem('lang') == null) {
    url = `${VUE_APP_SERVER_ENDPOINT}/assessment`
  }
  else url = `${VUE_APP_SERVER_ENDPOINT}/${localStorage.getItem('lang')}/assessment`
  return await axios.get(url)
}

async function examination(assessment) {
  const json = JSON.stringify(assessment)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/examination`, json)
}

async function submit(assessment) {
  const json = JSON.stringify(assessment)
  return await axios.put(`${VUE_APP_SERVER_ENDPOINT}/examination`, json)
}

async function getResult(id) {
  return await axios.get(`${VUE_APP_SERVER_ENDPOINT}/examination/${id}`)
}

async function record(searchType, username, start_time, end_time, userId) {
  return await axios.get(`${VUE_APP_SERVER_ENDPOINT}/examination?user_id=${userId}${((searchType == '' || username == '') ? '' : `&${searchType}=${username}`)}${(start_time == '' ? '' : `&start_time=${start_time}`)}${(end_time == undefined || end_time == '' ? '' : `&end_time=${end_time}`)}`)
}

async function remove(id) {
  return await axios.delete(`${VUE_APP_SERVER_ENDPOINT}/examination/${id}`)
}

export default {
  index,
  examination,
  submit,
  getResult,
  record,
  remove
}