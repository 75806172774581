<template lang="pug">
div(v-if='deviceId')
  v-row(align='center')
    div
      pre {{ deviceById(deviceId).name }}
    v-divider.mx-4(vertical)
    v-tooltip(bottom)
      template(v-slot:activator='{ on, attrs }')
        v-btn.mr-2(fab, x-small, @click.stop='locate()', v-bind='attrs', v-on='on')
          v-icon mdi-broadcast
      span {{ $t("findDevice") }}
    v-tooltip(bottom)
      template(v-slot:activator='{ on, attrs }')
        v-btn.mr-4(fab, x-small, @click.stop='recenter()', v-bind='attrs', v-on='on')
          v-icon mdi-image-filter-center-focus
      span {{ $t("recenter") }}
    //- v-spacer
    v-tooltip(bottom)
      template(v-slot:activator='{ on, attrs }')
        span(v-bind='attrs', v-on='on')
          v-icon(v-if='deviceById(deviceId).state == "client_connected"', color='success') mdi-access-point-check
          v-icon(v-if='deviceById(deviceId).state == "client_disconnected"', color='error') mdi-access-point-remove
      span {{ $t("conncetionStatus") }}
    v-tooltip(bottom)
      template(v-slot:activator='{ on, attrs }')
        span.ml-2(v-if='deviceById(deviceId).state == "client_connected"', v-bind='attrs', v-on='on')
          v-icon(v-if='deviceById(deviceId).wifiStrength == "excellent"', color='success') mdi-signal-cellular-3
          v-icon(v-if='deviceById(deviceId).wifiStrength == "good"', color='success') mdi-wifi-signal-cellular-2
          v-icon(v-if='deviceById(deviceId).wifiStrength == "fair"', color='warning') mdi-wifi-signal-cellular-1
          v-icon(v-if='deviceById(deviceId).wifiStrength == "poor"', color='error') mdi-wifi-signal-cellular-outline
      span {{ $t("signalStrength") }}
    v-tooltip(bottom)
      template(v-slot:activator='{ on, attrs }')
        span.ml-2(v-if='deviceById(deviceId).state == "client_connected"', v-bind='attrs', v-on='on')
          span(v-if='deviceById(deviceId).chargingState == "is_charging"')
            v-icon(v-if='parseInt(deviceById(deviceId).batteryPercentage) >= 90', color='success') mdi-battery-charging-100
            v-icon(v-else-if='deviceById(deviceId).batteryPercentage == "0"', color='success') mdi-battery-charging-outline
            v-icon(v-else, color='success') {{ `mdi-battery-charging-${Math.ceil(parseInt(deviceById(deviceId).batteryPercentage) / 10) * 10}` }}
          span(v-if='deviceById(deviceId).chargingState == "not_charging"')
            v-icon(v-if='parseInt(deviceById(deviceId).batteryPercentage) >= 90', color='success') mdi-battery
            v-icon(v-else-if='deviceById(deviceId).batteryPercentage == "0"', color='error') mdi-battery-outline
            v-icon(
              v-else,
              :color='parseInt(deviceById(deviceId).batteryPercentage) > 50 ? "success" : parseInt(deviceById(deviceId).batteryPercentage) > 20 ? "warning" : "error"'
            ) {{ `mdi-battery-${Math.ceil(parseInt(deviceById(deviceId).batteryPercentage) / 10) * 10}` }}
      span {{ $t("batteryStatus") }}
</template>

<script>
import { mapGetters } from 'vuex'
import { device } from '../api'

export default {
  props: ['deviceId'],

  methods: {
    locate() {
      device.locate(this.deviceById(this.deviceId).sn)
    },

    recenter() {
      device.recenter(this.deviceById(this.deviceId).sn)
    }
  },

  computed: {
    ...mapGetters({
      deviceById: 'devices/deviceById'
    })
  }
}
</script>

