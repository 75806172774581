<template lang='pug'>
div(style='height: 50vh')
  v-toolbar.mb-6(flat, style='margin-left: -15px; height: 60px')
    img(src='@/assets/title_logo.png', width='50', style='margin-right: 10px; margin-top: 25px', v-if='!isOpen', @click='setWidth')
    v-toolbar-title(v-if='isOpen') {{ $t("monitoringTitle") }}
    h3(v-if='!isOpen', style='margin-top: 25px; font-size: 19px') {{ $t("monitoringTitle") }}
    v-divider.mx-4(inset, vertical, v-if='isOpen')
    v-spacer
    div(:style='{ position: "relative", top: isOpen ? "0" : "10px" }')
      v-tooltip(bottom, v-if='treatments.length > 0')
        template(v-slot:activator='{ on, attrs }')
          v-btn(fab, dark, color='orange', @click.stop='batchTerminate', v-bind='attrs', v-on='on')
            v-icon(large) mdi-stop-circle-outline
        span {{ $t("barchEnd") }}
    div(style='width: 20px')
    div(:style='{ position: "relative", top: isOpen ? "0" : "10px" }')
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn(fab, dark, color='orange', @click='add()', v-bind='attrs', v-on='on')
            v-icon mdi-plus
        span {{ $t("addTreatment") }}
  v-row(v-if='treatments.length > 0')
    v-col(v-for='(item, i) in treatments', :key='i', xl='6', lg='6', md='12')
      v-card.mx-auto(hover, @click='goToDetail(item)')
        v-img.monitor-img(
          :src='streamImgSrc && streamImgSrc != "initiating" && mouseoverTreatmentId == item.model.ID ? streamImgSrc : getCoverSrc(item.app.model.ID)',
          @mouseover='startStreaming(item.model.ID)',
          @mouseleave='stopStreaming()',
          height='500px'
        )
        .d-flex.flex-no-wrap.justify-space-between
          div
            v-card-title {{ item.app.name }}
            v-card-subtitle
              v-chip.ma-1(v-for='(item, i) in getTags(item.app.tags)', :key='i') {{ item }}
          div
            v-card-title
              v-tooltip(bottom)
                template(v-slot:activator='{ on, attrs }')
                  v-btn(fab, color='warning', @click.stop='terminate(item)', v-bind='attrs', v-on='on')
                    v-icon(large) mdi-stop-circle-outline
                span {{ $t("actionTerminateTooltip") }}
          .countdown(v-if='isApp(item) && enabledCountdown') {{ countdownRes }}
        v-divider
        v-card-text
          v-row(align='center')
            PatientMini.ml-8.my-8(:patientId='item.patient.model.ID')
            v-spacer
            DeviceMini.mr-8.my-8(:deviceId='item.device.model.ID')
          .mt-2
          div(v-if='item.app.packageName == "com.xzs.citstorerelease"')
            CITStoreMeta.mx-4.mt-4.mb-2(:treatment='item')
          div(v-if='item.app.packageName == "com.xzs.dragonbeat"')
            DragonBeatMeta.mx-4.mt-4.mb-2(:treatment='item')
          div(v-if='item.app.packageName == "com.xzs.WheelMaze"')
            WheelMazeMeta.mx-4.mt-4.mb-2(:treatment='item')
          div(v-if='item.app.packageName == "com.xzs.shadow"')
            ShadowMeta.mx-4.mt-4.mb-2(:treatment='item')
          div(v-if='item.app.packageName == "com.xzs.pingpongsimulator"')
            PingPongMeta.mx-4.mt-4.mb-2(:treatment='item')
          div(v-if='item.app.packageName == "com.xzs.shootinggallery"')
            ShootingGalleryMeta.mx-4.mt-4.mb-2(:treatment='item')
          div(v-if='item.app.packageName == "com.xzs.breathe"')
            BreatheMeta.mx-4.mt-4.mb-2(:treatment='item')
          div(v-if='item.app.packageName == "com.xzs.taichi"')
            TaichiMeta.mx-4.mt-4.mb-2(:treatment='item')
          div(v-if='item.app.packageName == "com.xzs.grow"')
            GrowMeta.mx-4.mt-4.mb-2(:treatment='item')

          div(v-if='getPackageName(item)')
            CommonMeta.mx-4.mt-4.mb-2(:treatment='item')
  v-container(v-else, fill-height)
    v-row
      v-col(align='center')
        v-btn(x-large, disabled) {{ $t("noTreatment") }}
  NewTreatment(ref='newTreatment', :monitor='monitor')
  .nav
    NavigationDrawer(:class='navShow ? "navShow" : "navHide"', @selectClick='selectClick')
  .mask(v-show='navShow && !isOpen', @click='outClick')
</template>

<script>
import { VUE_APP_FILE_SERVER_ENDPOINT } from '@/app.config'
import { mapGetters, mapState } from 'vuex'
import { connectStreamer } from '../lib/fmetp'
import { treatment } from '../api'
import PatientMini from './PatientMini'
import DeviceMini from './DeviceMini'
import AppMini from './AppMini'
import NewTreatment from './NewTreatment'
import CITStoreMeta from './CITStoreMeta'
import DragonBeatMeta from './DragonBeatMeta'
import WheelMazeMeta from './WheelMazeMeta'
import ShadowMeta from './ShadowMeta'
import PingPongMeta from './PingPongMeta'
import ShootingGalleryMeta from './ShootingGalleryMeta'
import BreatheMeta from './BreatheMeta'
import TaichiMeta from './TaichiMeta'
import GrowMeta from './GrowMeta'
import ScaleMeta from './ScaleMeta'
import CommonMeta from './CommonMeta.vue'
import { NavigationDrawer } from '../components'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

export default {
  data: () => ({
    streamImgSrc: undefined,
    socket: undefined,
    mouseoverTreatmentId: undefined,
    monitor: '',
    isOpen: false,
    navShow: false,
    countdown: 0,
    timer: undefined,
    nowTimer: undefined,
    startTime: '',
    now: moment(),
    countdownRes: '',
    applicationStartTime: '',
    timestamp: 0,
    isCountdown: false,
    treatmentId: undefined,
    packageName: ''
  }),
  beforeDestroy() {
    clearInterval(this.nowTimer)
  },

  mounted() {
    window.addEventListener('resize', this.resize, false)
    this.resize()
    if (this.treatments.length !== 0) {
      this.treatments.forEach(item => {
        if (item.app.packageName == 'com.xzs.golfclub' && JSON.parse(item.settings).modeName == 'time') {
          this.countdown = JSON.parse(item.settings).levelLimit
        } else {
          this.countdown = JSON.parse(item.settings).timeLimit
          this.packageName = item.app.packageName
        }
        this.applicationStartTime = item.applicationStartTime
        this.treatmentId = item?.model.ID
      })
    } else {
      clearInterval(this.nowTimer)
    }
  },

  watch: {
    treatments(val) {
      if (val.length == 0) {
        clearInterval(this.nowTimer)
      } else {
        val.forEach(item => {
          if (item.app.packageName == 'com.xzs.golfclub' && JSON.parse(item.settings).modeName == 'time') {
            this.countdown = JSON.parse(item.settings).levelLimit
          } else {
            this.countdown = JSON.parse(item.settings).timeLimit
            this.packageName = item.app.packageName
          }
          this.applicationStartTime = item.applicationStartTime
          this.treatmentId = item?.model.ID
        })
      }
    },

    timestamp(val) {
      if (val < 0) {
        clearInterval(this.nowTimer)
      }
    },

    isCountdown(val) {
      if (val && this.enabledCountdown && this.treatments.length > 0) {
        momentDurationFormatSetup(moment)
        this.nowTimer = this.$mySetInterval(() =>
          setTimeout(() => {
            this.now = moment()
            this.startTime = this.calculateDuration(this.applicationStartTime)
            this.getTimeGap(this.countdown, this.startTime, this.treatmentId)
          }, 0)
        )
      }
    }
  },

  methods: {
    getPackageName(item) {
      return (
        item.app.packageName == 'com.xzs.videoplayer' ||
        item.app.packageName == 'com.xzs.painrelief' ||
        item.app.packageName == 'com.xzs.fruitparty' ||
        item.app.packageName == 'com.xzs.golfclub' ||
        item.app.packageName == 'com.xzs.puppydollhouse' ||
        item.app.packageName == 'com.xzs.vrtennis' ||
        item.app.packageName == 'com.xzs.sailingadventure' ||
        item.app.packageName == 'com.xzs.forestfriends' ||
        item.app.packageName == 'com.xzs.melodicpitch' ||
        item.app.packageName == 'com.xzs.snowballfight' ||
        item.app.packageName == 'com.xzs.jellyfishswim' ||
        item.app.packageName == 'com.xzs.mathboxing' ||
        item.app.packageName == 'com.xzs.rabbitstore' ||
        item.app.packageName == 'com.xzs.mindratingscalesystem' ||
        item.app.packageName == 'com.xzs.spp' ||
        item.app.packageName == 'com.xzs.hidenseek' ||
        item.app.packageName == 'com.xzs.laundry' ||
        item.app.packageName == 'com.xzs.icejourney'
      )
    },

    isApp(item) {
      if (item.app.packageName !== 'com.xzs.cognitiveassessment' && item.app.packageName !== 'com.xzs.golfclub' && JSON.parse(item.settings).modeName == 'time') {
        this.isCountdown = true
        return this.isCountdown
      } else {
        this.isCountdown = false
        return this.isCountdown
      }
    },

    calculateDuration(then) {
      if (this.now?.diff && moment(then).year() >= 2021) {
        return moment.duration(this.now.diff(then)).format(this.$t('hHourmmMinssSecond'))
      } else {
        return NaN
      }
    },

    async getTimeGap(currentTime, pastTime, treatmentId) {
      let pattern = /\d+/g
      let match = pastTime.match(pattern)
      var minutes = 0
      var seconds = 0
      if (match.length == 1) {
        seconds = parseInt(match[0])
      } else if (match.length == 2) {
        minutes = parseInt(match[0])
        seconds = parseInt(match[1])
      }
      var now = new Date()
      var pastTimestamp = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), minutes, seconds).getTime()
      var currentTimestamp = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), currentTime, 0).getTime()
      this.timestamp = currentTimestamp - pastTimestamp
      if (this.timestamp >= 0) {
        let date = new Date(this.timestamp)
        this.countdownRes = date.getMinutes() + this.$t('minute') + date.getSeconds() + this.$t('second')

        if (date.getMinutes() == 0 && date.getSeconds() == 0 && this.packageName !== 'com.xzs.videoplayer') {
          await treatment.terminateCountdown({ complete: true, id: treatmentId.toString() })
          clearInterval(this.nowTimer)
          //this.socketIds[treatmentId].close()
        }
      } else {
        this.countdownRes = 0 + this.$t('minute') + 0 + this.$t('second')
      }
    },

    setWidth() {
      this.navShow = true
    },
    selectClick(val) {
      if (!this.isOpen) {
        this.navShow = val
      } else {
        this.navShow = true
      }
    },
    outClick() {
      if (this.isOpen) {
        this.navShow = true
      } else {
        this.navShow = false
      }
    },
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    getCoverSrc(appId) {
      return `${VUE_APP_FILE_SERVER_ENDPOINT}/${this.apps.find(x => x.model.ID == appId).cover}`
    },
    getTags(tags) {
      return tags.split(';')
    },
    add() {
      this.$refs.newTreatment.dialog = true
      this.monitor = 'monitor'
    },
    app(appId) {
      return this.apps.find(x => x.model.ID == appId)
    },
    startStreaming(treatmentId) {
      this.mouseoverTreatmentId = treatmentId
      this.streamImgSrc = 'initiating'
      this.socket = connectStreamer(treatmentId, src => (this.streamImgSrc = src))
    },
    stopStreaming() {
      this.streamImgSrc = undefined
      this.socket && this.socket.close()
      this.socket = undefined
    },
    async goToDetail(item) {
      if (
        [
          'com.xzs.citstorerelease',
          'com.xzs.dragonbeat',
          'com.xzs.WheelMaze',
          'com.xzs.shadow',
          'com.xzs.pingpongsimulator',
          'com.xzs.shootinggallery',
          'com.xzs.taichi',
          'com.xzs.grow',
          'com.xzs.bbqflip',
          'com.xzs.memoryroom',
          'com.xzs.sandtableroom',
          'com.xzs.catharsisroom',
          'com.xzs.melodicpitch',
          'com.xzs.cognitiveassessment',
          'com.xzs.jellyfishswim',
          'com.xzs.snowballfight',
          'com.xzs.mathboxing',
          'com.xzs.londontower',
          'com.xzs.acrophobia',
          'com.xzs.painrelief',
          'com.XZS.VREyeTrackingAttentionAssessmentSystem',
          'com.xzs.forestfriends',
          'com.xzs.fruitparty',
          'com.xzs.golfclub',
          'com.xzs.puppydollhouse',
          'com.xzs.vrtennis',
          'com.xzs.breathe',
          'com.xzs.rabbitstore',
          'com.xzs.sailingadventure',
          'com.xzs.mindratingscalesystem',
          'com.xzs.spp',
          'com.xzs.laundry',
          'com.xzs.hidenseek',
          'com.xzs.baskball',
          'com.xzs.icejourney',
          'com.xzs.citstoregesture',
          'com.xzs.shootinggallery2',
          'com.xzs.happypatting',
          JSON.parse(item.settings).breathMonitor == true ? 'com.xzs.videoplayer' : '',
          JSON.parse(item.settings).breathMonitor == true ? 'com.xzs.spp' : ''
        ].includes(item.app.packageName)
      ) {
        this.$router.push({ path: `/treatments/${item?.model.ID}`, query: { appId: item.appId, patientId: item.patientId } })
      }
    },

    data() {
      return {
        ...mapGetters({
          treatments: 'treatments/activeTreatments'
        })
      }
    },
    
    batchTerminate() {
      this.treatments.forEach(async item => {
        const treatmentId = item?.model.ID
        await treatment.terminate(treatmentId)
        clearInterval(this.nowTimer)
        //this.socketIds[treatmentId].close()
      })
    },
    async terminate(item) {
      const treatmentId = item?.model.ID
      await treatment.terminate(treatmentId)
      clearInterval(this.nowTimer)
      //this.socketIds[treatmentId].close()
    }
  },

  components: {
    PatientMini,
    DeviceMini,
    AppMini,
    NewTreatment,
    CITStoreMeta,
    DragonBeatMeta,
    WheelMazeMeta,
    ShadowMeta,
    PingPongMeta,
    ShootingGalleryMeta,
    BreatheMeta,
    TaichiMeta,
    GrowMeta,
    NavigationDrawer,
    ScaleMeta,
    CommonMeta
  },

  destroyed() {
    this.stopStreaming()
  },

  computed: {
    ...mapGetters({
      treatments: 'treatments/activeTreatments',
      enabledCountdown: 'setting/enabledCountdown'
    }),

    ...mapState({
      patients: state => state.patients.items,
      devices: state => state.devices.items,
      apps: state => state.apps.items
    })
  }
}
</script>

<style lang="scss" scoped>
.navShow {
  animation: expanded 0.5s ease;
}

.navHide {
  display: none;
}

.mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  z-index: 998;
}

.monitor-img {
  position: relative;
}

.countdown {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: red;
  font-weight: bold;
}
</style>

