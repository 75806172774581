import axios from 'axios'
import { VUE_APP_SERVER_ENDPOINT } from '@/app.config'

async function index(userId) {
  return await axios.get(`${VUE_APP_SERVER_ENDPOINT}/device?user_id=${userId}`)
}

async function search(deviceId,userId) {
  return await axios.get(`${VUE_APP_SERVER_ENDPOINT}/device?name=${deviceId}&user_id=${userId}`)
}

async function add({ sn, name, userId }) {
  const json = JSON.stringify({ sn, name,userId })
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/device`, json)
}

async function addTooltip({SN}){
  const json = JSON.stringify({SN})
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/device/user`, json)
}

async function edit({id, name}) {
  const json = JSON.stringify({ id, name })
  return await axios.put(`${VUE_APP_SERVER_ENDPOINT}/device`, json)
}

async function remove(id) {
  return await axios.delete(`${VUE_APP_SERVER_ENDPOINT}/device/${id}`)
}

async function locate(sn) {
  const json = JSON.stringify({
    action: 'locate',
    ...(sn) && { sn },
  })
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/cmd`, json)
}

async function recenter(sn) {
  const json = JSON.stringify({
    action: 'recenter',
    ...(sn) && { sn },
  })
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/cmd`, json)
}

async function mute(sn) {
  const json = JSON.stringify({
    action: 'mute',
    ...(sn) && { sn },
  })
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/cmd`, json)
}

async function setVolume(sn) {
  const json = JSON.stringify({
    action: 'set_volume',
    volume: '12',
    ...(sn) && { sn },
  })
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/cmd`, json)
}

async function upVolume(sn) {
  const json = JSON.stringify({
    action: 'up_volume',
    ...(sn) && { sn },
  })
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/cmd`, json)
}

async function downVolume(sn) {
  const json = JSON.stringify({
    action: 'down_volume',
    ...(sn) && { sn },
  })
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/cmd`, json)
}

async function shutdown(sn) {
  const json = JSON.stringify({
    action: 'shutdown',
    ...(sn) && { sn },
  })
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/cmd`, json)
}

async function reboot(sn) {
  const json = JSON.stringify({
    action: 'reboot',
    ...(sn) && { sn },
  })
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/cmd`, json)
}

export default {
  index,
  add,
  edit,
  remove,
  locate,
  recenter,
  mute,
  setVolume,
  upVolume,
  downVolume,
  shutdown,
  reboot,
  search,
  addTooltip
}