<template lang="pug">
div
  v-row
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-school-outline
        | {{ $t("tutorial") }}
        v-spacer
        v-switch(inset, v-model='tutorial')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-monitor-eye
        | {{ $t("monitor") }}
        v-spacer
        v-switch(inset, v-model='monitor')
      v-row.setting-row(align='center', no-gutters)
        img(src='@/assets/hand.png', width='20', style='margin-right: 5px')
        | {{ $t("useHand") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='useHand',
          :items='useHands',
          item-text='text',
          item-value='value'
        )
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-cube-outline
        | {{ $t("mode") }}
        v-spacer
        v-select.outlined.mr-3(reverse, style='max-width: 100px', v-model='mode', :items='modes', item-text='text', item-value='value')
      v-row.setting-row(align='center', no-gutters, v-if='mode === "manual"')
        v-icon(left) mdi-timer-outline
        | {{ $t("targetCount") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='levelcount',
          :items='targetQuantities',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters, v-if='mode === "manual"')
        v-icon(left) mdi-timer-outline
        | {{ $t("reduceCountdownAndSize") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='reducetype',
          :items='reducetypes',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters, v-if='mode === "manual"')
        v-icon(left) mdi-timer-outline
        | {{ $t("interferenceObjectCount") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='subatancenum',
          :items='subatancenumes',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters, v-if='mode === "classic"')
        v-icon(left) mdi-timer-outline
        | {{$t("difficulty")}}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='difficultyselection',
          :items='difficultyselections',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters, v-if='enabledCountdown')
        v-icon(left) mdi-timer-outline
        | {{ $t("timeLimit") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='timeLimit',
          :items='timeLimits',
          item-text='text',
          item-value='value'
        )
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: vm => ({
    monitor: true,
    tutorial: false,
    mode: 'intelligent',
    modes: [
      { text: vm.$t('manualMode'), value: 'manual' },
      { text: vm.$t('adaptiveMode'), value: 'intelligent' },
      { text: vm.$t('classicMode'), value: 'classic' }
    ],
    levelcount: 10,
    targetQuantities: [
      { text: '10', value: 10 },
      { text: '20', value: 20 },
      { text: '30', value: 30 }
    ],
    reducetype: 'keepsize',
    reducetypes: [
      { text: vm.$t('doNotReduce'), value: 'keepsize' },
      { text: vm.$t('countdown'), value: 'countdowndecrease' },
      { text: vm.$t('size'), value: 'sizedecrease' },
      { text: vm.$t('reduceBoth'), value: 'bothdecrease' }
    ],
    subatancenum: 0,
    subatancenumes: [
      { text: '0', value: 0 },
      { text: '1', value: 1 },
      { text: '2', value: 2 },
      { text: '3', value: 3 },
      { text: '4', value: 4 }
    ],
    difficultyselection: 'simple',
    difficultyselections: [
      { text: vm.$t('easy'), value: 'simple' },
      { text: vm.$t('normal'), value: 'medium' },
      { text: vm.$t('hard'), value: 'difficulty' }
    ],
    useHand: 1,
    useHands: [
      { text: vm.$t('leftHand'), value: 0 },
      { text: vm.$t('rightHand'), value: 1 }
    ],
    timeLimit: 15,
    timeLimits: [
      { text: vm.$t('5min'), value: 5 },
      { text: vm.$t('10min'), value: 10 },
      { text: vm.$t('15min'), value: 15 },
      { text: vm.$t('20min'), value: 20 },
      { text: vm.$t('30min'), value: 30 }
    ]
  }),

  methods: {
    getSettings() {
      return JSON.stringify({
        tutorial: this.tutorial,
        monitor: this.monitor,
        mode: this.mode,
        levelcount: this.mode === 'manual' ? this.levelcount : -1,
        reducetype: this.mode === 'manual' ? this.reducetype : '',
        subatancenum: this.mode === 'manual' ? this.subatancenum : -1,
        difficultyselection: this.mode === 'classic' ? this.difficultyselection : '',
        handindex: this.useHand,
        timeLimit: this.enabledCountdown ? this.timeLimit : -1
      })
    }
  },

  computed: {
    ...mapGetters({
      enabledCountdown: 'setting/enabledCountdown'
    })
  }
}
</script>
        
<style lang="sass">
.setting-row
  height: 50px
</style>
        
