<template lang="pug">
v-dialog(v-model='activateDeviceDialog', width='500', @click:outside='visible = false', persistent)
  v-card
    v-card-title.primary.headline.mb-12
      h4(style='color: white') {{$t("productActivation")}}
    v-card-text
      v-form(ref='form', v-model='valid')
        v-text-field(
          v-model='activateDeviceSN',
          solo,
          :label= "$t('enterProductActivationCode')",
          prepend-inner-icon='mdi-key',
          required,
          :rules='rules.nameRules',
          @click.stop.native='isOpen ? (visible = false) : (visible = true)',
          @focus.stop='keyboardShowClick'
        )
    v-card-actions
      v-spacer
      v-btn(text, @click='confirm()') {{$t("yes")}}
  Keyboard#keyboard(layout='normal', :input='input', v-if='visible && enableTouch', :cancel='cancel')
</template>

<script>
import Keyboard from 'vue-keyboard-zh'
import { about } from './../api'
import { mapGetters } from 'vuex'
export default {
  data: (vm) => ({
    activateDeviceDialog: false,
    visible: false,
    activateDeviceSN: '',
    isOpen: false,
    valid: true,
    rules: {
      nameRules: [v => !!v || vm.$t('reEnterProductActivationCode')]
    }
  }),

  components: {
    Keyboard
  },

  computed: {
    ...mapGetters({
      enableTouch: 'setting/enableTouch'
    })
  },

  mounted() {
    this.resize()
    window.addEventListener('resize', this.resize, false)
  },

  methods: {
    keyboardShowClick(e) {
      if (this.isOpen) {
        this.input = ''
        this.visible = false
      } else {
        this.input = e.target
        if (!this.visible) this.visible = true
      }
    },
    cancel() {
      this.visible = false
    },
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    async confirm() {
      this.$refs.form.validate()
      if (this.valid) {
        try {
          await about.activate({ code: this.activateDeviceSN })
          this.visible = false
          this.activateDeviceDialog = false
          this.$emit('snackSuccess')
        } catch (err) {
          this.valid = false
          this.activateDeviceDialog = true
          this.$emit('snackFail', err)
        }

        this.activateDeviceSN = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.topBar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
  margin-left: -255;
  background: #fff;
}

#keyboard {
  position: fixed;
  top: auto !important;
  bottom: 0;
  z-index: 999;
  padding: 14px 21px;
}

::v-deep .elevation-1>.v-data-table__wrapper tbody tr.v-data-table__expanded {
  background: #f9f7f7 !important;
}

::v-deep .theme--light.elevation-1>.v-data-table__wrapper>table>tbody>tr.v-data-table__expanded>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}

::v-deep .elevation-1 {
  box-shadow: none !important;
}

.navShow {
  animation: expanded 0.5s ease;
}

.navHide {
  display: none;
}

.mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  z-index: 998;
}
</style>