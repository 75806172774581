<template lang="pug">
div
  DiseaseManagement(v-if='this.$route.query.type === "desease"')
  PatientsPrint(v-if='this.$route.query.type === "print"')
  PatientsManagePrint(v-if='this.$route.query.type === "detailPrint"')
</template>

<script>
import DiseaseManagement from './patients/DiseaseManagent.vue'
import PatientsPrint from './PrintPage/PatientsPrint.vue'
import PatientsManagePrint from './PrintPage/PatientsManagePrint.vue'
export default {
  components: {
    DiseaseManagement,
    PatientsPrint,
    PatientsManagePrint
  }
}
</script>

<style>
</style>