<template lang="pug">
div
  v-row.mb-2(align='center')
    div
      v-icon.mr-1 mdi-timer
      span {{ `${$t("totalDuration")} ${calculateDuration(treatment.applicationStartTime)}` }}
</template>

<script>
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

export default {
  props: ['treatment'],

  data: () => ({
    now: moment(),
    timerId: undefined
  }),

  methods: {
    calculateDuration(then) {
      if (this.now?.diff && moment(then).year() >= 2021) {
        return moment.duration(this.now.diff(then)).format(this.$t('hHourmmMinssSecond'))
      } else {
        return NaN
      }
    }
  },

  beforeDestroy() {
    clearInterval(this.timerId)
  },

  created() {
    momentDurationFormatSetup(moment)
    this.timerId = this.$mySetInterval(() => {
      setTimeout(() => {
        this.now = moment()
      }, 0)
    })
  }
}
</script>

