import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import enLocale from 'element-ui/lib/locale/lang/en'
import thLocale from 'element-ui/lib/locale/lang/th'

import {
  VUE_APP_I18N_FALLBACK_LOCALE,
} from '@/app.config'
// import { locale } from 'core-js'

Vue.use(VueI18n)


let elLocales = {
  "zh": zhLocale,
  "en": enLocale,
  "th": thLocale
}

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
      Object.assign(messages[locale], elLocales[locale])
    }
  })

  return messages
}

export default new VueI18n({
  locale: localStorage.getItem('lang') || 'zh',
  fallbackLocale: VUE_APP_I18N_FALLBACK_LOCALE || 'zh',
  messages: loadLocaleMessages()
})
