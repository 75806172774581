import axios from 'axios'
import { VUE_APP_SERVER_ENDPOINT } from '@/app.config'

async function index() {
  return await axios.get(`${VUE_APP_SERVER_ENDPOINT}/about`)
}

async function update(about) {
  const json = JSON.stringify(about)
  return await axios.put(`${VUE_APP_SERVER_ENDPOINT}/about`, json)
}

async function activate(code) {
  const json = JSON.stringify(code)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/check`, json)
}

export default {
  index,
  update,
  activate
}
