<template lang="pug">
div(v-resize='onResize')
  v-toolbar.mb-6(flat)
    v-btn.ma-2(large, color='orange', dark, @click='goBack()')
      v-icon(dark, left) mdi-arrow-left
      | {{ $t("return")}}
    v-divider.mx-4(inset, vertical)
    v-icon.mr-1 mdi-file-chart
    v-toolbar-title {{ $t("viewreport") }}
    v-spacer
    div
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn.primary.mr-2(fab, v-bind='attrs', v-on='on', @click='print()')
            v-icon mdi-printer
        span {{ $t("actionPrintTooltip") }}
  v-card.content-page
    div(:class='isOpen ? "page" : "mPage"')
      #divToPrint
        .page-top
          img.img-logo(:src='getSrc()', width='140', v-if='logo')
          .page-title
            .name {{ institution }}
            .subtitle {{ reportName }}
        .page-msg(v-if='patientList.name !== undefined')
          .row1
            .name(style='width: 266px') {{ $t("name") }}：{{ patientList.name }}
            .gender(style='width: 266px; text-align: center') {{ $t("gender")}}：{{ patientList.gender }}
            .hospitalNumber(style='width: 266px; text-align: right') {{ `${numberName ? numberName + "：" : $t("number") + "："}${patientList.hospitalNumber}` }}
          .row2
            .age(v-if='enableAge', style='width: 266px') {{ $t("age")}}：{{ calcAge(patientList.birthday) >= 0 ? calcAge(patientList.birthday) : "" }}
            .bedNumberName(v-if='bedNumberName !== "false"', :style='{ width: "266px", textAlign: enableAge ? "center" : "left" }') {{ (typeUsername == "1" ? $t("userNameNumber") + ":" : $t("patientNumber") + "：") + patientList.patientNumber }}
            .partition(v-if='partition !== "false"', :style='{ width: "266px", textAlign: enableAge ? "right" : "center" }') {{ (partition == "true" ? $t("subregion") + "：" : partition + "：") + patientList.bedNumber }}
        .diagnosis
          .diagnosis-title {{ $t("doctorDiagnosis") }}
          .diagnosis-content {{ diagnosis == "" ? $t("noDiagnosisResult") : diagnosis }}
        .result
          .result-title {{ $t("evaluationResult") }}
          .result-content
            div(v-if='assessData[1] == null && assessData[0] == null && testResult == null') {{ $t("noEvaluationResults") }}
            .mmse-result(v-if='assessData[1] !== null && assessData.length > 0')
              span.time {{ assessData[1].model.CreatedAt.slice(0, 10).replace(/-/g, ".") }}
              span.name {{ $t("mmseScale") + "：" + assessData[1].score }}
            .moca-result(v-if='assessData[0] !== null && assessData.length > 0')
              span.time {{ assessData[0].model.CreatedAt.slice(0, 10).replace(/-/g, ".") }}
              span.name {{ $t("moCAScale") + "：" + assessData[0].score }}
            .cognition(v-if='testResult !== null')
              span.time {{ cognitiveTime }}
              span.name {{ $t("noEvaluationResults") + "："+ $t("execution") + "—" + cognitionScore.execution +  $t("orientation") + "—" + cognitionScore.orientation + $t("attention") + "—" + cognitionScore.attention + $t("memory") + "—" + cognitionScore.memory + $t("calculation") + "—" + cognitionScore.calculate }}
        .treatment-programs
          .treatment-title {{ $t("treatmentPrograms") }}
          .content(:style='{ height: treatmentPlan.length == 0 ? "50px" : "auto" }')
            p(v-for='(item, index) in treatmentPlan', :key='index')
              span {{ item.name + " — " }}
              span {{ item.target }}
            div(v-if='treatmentPlan.length == 0') {{ $t("NoTreatmentPlan") }}
        .treatment-result
          .treatment-title {{ $t("treatmentResults") }}
          .content
            div(v-if='testResult !== null')
              apexchart.radarLeft(ref='chart', type='radar', :options='chartOptions', :series='series', width='500')
            span(v-if='testResult === null', style='font-size: 16px; margin-left: 10px') {{ $t("noAnyTraining") }}
            table(width='100%', style='font-size: 12px; border-color: #d7d7d7', cellspacing='0', border='1', v-if='testResult !== null')
              thead
                tr(style='background: #f5f5f5; height: 80px')
                  td(align='center') {{ $t("date") }}/{{ $t("classification") }}
                  td(align='center') {{ $t("attention") }}
                  td(align='center') {{ $t("orientation") }}
                  td(align='center') {{ $t("execution") }}
                  td(align='center') {{ $t("calculation") }}
                  td(align='center') {{ $t("memory") }}
              tbody
                tr(height='30', style='background: #f5f5f5')
                  td(align='center') {{ cognitiveTime }}
                  td(align='center') {{ cognitionScore.attention }}
                  td(align='center') {{ cognitionScore.orientation }}
                  td(align='center') {{ cognitionScore.execution }}
                  td(align='center') {{ cognitionScore.calculate }}
                  td(align='center') {{ cognitionScore.memory }}
                tr(height='30', style='background: #f5f5f5')
                  td(align='center') {{ dimensionTime }}
                  td(align='center') {{ dimensionCrease[0] === 0 ? "/" : dimensionCrease[0] }}
                  td(align='center') {{ dimensionCrease[4] === 0 ? "/" : dimensionCrease[4] }}
                  td(align='center') {{ dimensionCrease[1] === 0 ? "/" : dimensionCrease[1] }}
                  td(align='center') {{ dimensionCrease[2] === 0 ? "/" : dimensionCrease[2] }}
                  td(align='center') {{ dimensionCrease[3] === 0 ? "/" : dimensionCrease[3] }}
        .table
          .table-title {{ $t("treatmentRecordsTitle") }}
          table(width='100%', cellspacing='0')
            thead
              tr(style='background: #f5f5f5; height: 42px')
                td(align='left', style='padding-left: 10px') {{ $t("date") }}
                td(align='left') {{ $t("software") }}
                td(align='left') {{ $t("duration") }}(min)
                td(align='center') {{ $t("score") }}
            tbody
              tr(v-for='(item, index) in treatmentsData', :key='index', height='42', style='background: #f5f5f5')
                td(align='left', style='padding-left: 10px') {{ treatmentDate(item.applicationStartTime.slice(0, 4) == "0001" ? item.model.CreatedAt : item.applicationStartTime, displayTime) }}
                td(align='left') {{ item.app.name }}
                td(align='left') {{ calcTreatmentTime(item.applicationEndTime, item.applicationStartTime.slice(0, 4) == "0001" ? item.model.CreatedAt : item.applicationStartTime) }}
                td(align='center') {{ item.scoreOverwrite }}
      #bottomFont.page-bottom
        .left
          span {{ $t("printTime") }}：{{ calcDateTime(new Date()) }}
          span *{{ $t("thisReportClinicalReferenceOnly") }}
        .right
          span {{ $t("signature") }}：
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { VUE_APP_SERVER_ENDPOINT } from '@/app.config'
import { patient, record, app } from '../../api'
import VueApexCharts from 'vue-apexcharts'
import { printPDF } from '../../lib/printImg'
export default {
  data() {
    return {
      dataTableHeight: 0,
      patientList: {},
      diagnosis: '',
      gaugeData: [],
      assessmentIdData: [],
      assessData: [],
      testResult: [],
      cognitionScore: {},
      cognitiveTime: '',
      dimensionArr: [],
      treatmentPlan: [],
      dimension: [1, 2, 4, 3, 5],
      type: '',
      dimensionTime: '',
      dimensionCrease: [],
      treatmentResult: [],
      dimensionResult: [],
      treatmentRecord: [],
      chartOptions: {
        chart: {
          height: 300,
          type: 'radar',
          stacked: false,
          toolbar: false,
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1
          }
        },
        stroke: {
          width: 2
        },
        fill: {
          opacity: 0.1
        },
        markers: {
          size: 3,
          hover: {
            size: 5
          }
        },
        xaxis: {
          categories: [this.$t('attention'), this.$t('execution'), this.$t('calculation'), this.$t('memory'), this.$t('orientation')]
        },
        yaxis: [
          {
            min: 0,
            max: 100,
            tickAmount: 5,
            show: true,
            opposite: true
          }
        ]
      },
      hospital: '',
      patientsList: [],
      patientsData: [],
      treatmentsData: [],
      getData: [],
      appsData: [],
      categoryType: '0,1,2,3,4,5,6,7,8',
      isOpen: true,
      admissionPatients: [],
      admissionList: []
    }
  },
  watch: {
    patientsWA: function (newVal) {
      if (this.hospital == '') {
        this.patientList = newVal.find(x => {
          return x.model.ID == parseInt(this.$route.params.id)
        })
        this.diagnosis = this.patientList.diagnosis
      }
    },
    gaugeList: function (val) {
      this.gaugeData = val
      this.getGaugeTest()
    },
    diagnosis: function (val) {
      this.diagnosis = val
    }
  },
  async mounted() {
    this.hospital = this.$route.query.hospital
    this.resize()
    window.addEventListener('resize', this.resize, false)
    this.getPageData()
  },
  methods: {
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    ...mapActions({
      patientsListWA: 'patients/index'
    }),
    async getPageData() {
      // 病人信息
      if (this.patientsWA.length > 0 && this.hospital == '') {
        this.patientList = this.patientsWA.find(x => {
          return x.model.ID == parseInt(this.$route.params.id)
        })
      }
      if (this.patientList !== undefined && this.hospital == '') {
        this.diagnosis = this.patientList.diagnosis
      }
      // 量表评估结果
      this.getGaugeTest()
      this.getCongition()
      // 认知训练时间
      const timeRes = await patient.getTreatmentTime(this.$route.params.id)
      this.dimensionTime = timeRes.data !== null ? timeRes.data.slice(0, 10).replace(/-/g, '.') : this.$t('NoTreatment')
      this.getTreatmentResult()

      // 出院病人病情管理
      if (this.hospital == 'discharge') {
        const dischargeRes = await patient.discharge('', '', this.getUserId)
        this.patientsList = dischargeRes.data
        this.patientList = dischargeRes.data.find(x => {
          return x.model.ID == parseInt(this.$route.params.id)
        })
        this.diagnosis = this.patientList.diagnosis
      } else {
        const res = await patient.index('', '', this.getUserId)
        this.admissionPatients = res.data
      }

      // 治疗记录
      const appResult = await app.index(this.getLocalStorage1(this.categoryType, 1))
      this.appsData = appResult.data
      if (this.hospital == '') {
        this.patientsData = this.admissionPatients
      } else {
        this.patientsData = this.patientsList
      }
      this.admissionList = this.patientsData.find(x => {
        return x.model.ID == parseInt(this.$route.params.id)
      })
      const year = new Date().getFullYear()
      const month = new Date().getMonth() + 1
      const day = new Date().getDate()
      const currentDate = year + '-' + (month > 9 ? month : '0' + month) + '-' + day
      const result1 = await patient.printTreatment({
        patientId: this.admissionList.model.ID,
        startTime: '1900-01-01 00:00:00',
        endTime: currentDate + ' ' + '23:59:59'
      })
      if (this.apps.length > 0 || this.patientsData.length > 0 || this.devices.length > 0) {
        this.getData = result1.data.map(x => {
          const app = this.appsData.find(y => y.model.ID == x.appId)
          if (app) x.app = app
          const patient = this.patientsData.find(y => y.model.ID == x.patientId)
          if (patient) x.patient = patient
          const device = this.devices.find(y => y.model.ID == x.deviceId)
          if (device) x.device = device
          if (x.deviceId === 0) {
            this.deviceObject = { sn: 'PA7L10PGF7150095W' }
          } else {
            this.deviceObject = { sn: x.device.sn }
          }
          x.device = this.deviceObject
          return x
        })
        this.treatmentsData = []
        this.getData.forEach(x => {
          if (x != undefined) {
            if (x.app != undefined && x.patient != undefined) {
              this.treatmentsData.push(x)
            }
          }
        })

        this.treatmentsData.sort((a, b) => {
          return (
            new Date(a.applicationStartTime.slice(0, 4) == '0001' ? a.model.CreatedAt : a.applicationStartTime) -
            new Date(b.applicationStartTime.slice(0, 4) == '0001' ? b.model.CreatedAt : b.applicationStartTime)
          )
        })
      }
    },
    getLocalStorage1(query, show) {
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      const obj1 = {
        adminId: role == 'admin' ? adminId : 1,
        userId: userId,
        role: role,
        isShow: show,
        type: String(query)
      }
      return obj1
    },
    print() {
      const printDom = document.querySelector('#divToPrint')
      const bottomFontDom = document.querySelector('#bottomFont')
      const content = {
        printDom: printDom,
        bottomFontDom: bottomFontDom,
        type: 'PM',
        isOpen: this.isOpen
      }
      printPDF(content)
    },
    onResize() {
      const height = window.innerHeight - 600
      this.dataTableHeight = height
    },
    goBack() {
      this.$router.go(-1)
    },
    getSrc() {
      return `${VUE_APP_SERVER_ENDPOINT}/assets/${this.logo}`
    },
    calcAge(dateString) {
      var birthday = +new Date(dateString)
      return ~~((Date.now() - birthday) / 31557600000)
    },
    calcDateTime(dateString, displayTime = true) {
      const date = new Date(dateString)
      if (displayTime) {
        return (
          date.toLocaleDateString('zh-CN', { hour12: false }) +
          ' ' +
          date.toLocaleTimeString('zh-CN', { hour: '2-digit', minute: '2-digit' })
        )
      } else {
        return date.toLocaleDateString('zh-CN', { hour12: false })
      }
    },
    async getGaugeTest() {
      this.gaugeData = this.gaugeList
      let promises = this.gaugeData.map(async x => {
        const params = {
          patient_id: this.$route.params.id,
          assessment_id: x.model.ID
        }
        const conRes = await patient.getCognitiveTesting(params.patient_id, params.assessment_id)
        this.assessmentIdData.push(conRes.data)
      })

      for (const promise of promises) {
        await promise
      }
      if (this.assessmentIdData.length > 0) {
        this.assessData = this.assessmentIdData
      }
    },
    async getCongition() {
      // 认知功能评估得分
      const result = await patient.getGaugeTesting(this.$route.params.id)
      if (result.status === 200) {
        this.testResult = result.data
        this.cognitiveTime = this.testResult !== null ? this.testResult.model.CreatedAt.slice(0, 10).replace(/-/g, '.') : ''
        // 治疗方案
        if (this.testResult !== null) {
          this.cognitionScore = JSON.parse(this.testResult.dimension).dimensionAverage
          this.getTreatmentPlan()
        }
      }
    },
    async getTreatmentPlan() {
      var cognitiveArr = []
      Object.keys(this.cognitionScore).forEach(key => {
        this.dimensionArr.push(this.cognitionScore[key])
      })
      this.dimensionArr.forEach((item, index) => {
        if (item < 60) {
          cognitiveArr.push(this.dimension[index])
        }
      })

      if (cognitiveArr.length > 0) {
        this.type = cognitiveArr.join(',')
      } else {
        this.type = '0'
      }
      let res = await record.dimension({ dimension: this.type, treatmentId: this.testResult.model.ID })
      this.treatmentPlan = res.data
    },
    async getTreatmentResult() {
      // 治疗结果
      this.dimensionResult = []
      const res = await patient.getTreatmentResult(this.$route.params.id)
      Object.keys(res.data.count).length > 0
        ? Object.keys(res.data.count).forEach(key => {
          Object.keys(res.data.score).forEach(i => {
            if (key === i) {
              let avg = res.data.score[i] / res.data.count[key]
              this.dimensionResult.push({ [key]: avg })
            }
          })
        })
        : (this.dimensionResult = [{ 0: 0 }, { 1: 0 }, { 2: 0 }, { 3: 0 }, { 4: 0 }])
      this.dimensionCrease = []
      this.dimensionArr.forEach((x, i) => {
        var a = this.dimensionResult.filter(item => {
          return i + 1 === parseInt(Object.keys(item)[0])
        })
        if (a.length > 0) {
          this.dimensionCrease.push(x + parseInt(Object.values(a[0])[0]))
        } else {
          this.dimensionCrease.push(x)
        }
      })
    },
    treatmentDate(dateString, displayTime = true) {
      const date = new Date(dateString)
      if (displayTime) {
        return (
          date.toLocaleDateString('zh-CN', { hour12: false }) +
          ' ' +
          date.toLocaleTimeString('zh-CN', {
            hour: '2-digit',
            minute: '2-digit'
          })
        )
      } else {
        return date.toLocaleDateString('zh-CN', { hour12: false })
      }
    },
    calcTreatmentTime(endTime, startTime) {
      var minutes = 0
      if (new Date(startTime).getTime() > new Date(endTime).getTime()) {
        minutes = 0
      } else {
        const diff = Math.abs(new Date(endTime) - new Date(startTime))
        minutes = Math.round(diff / 1000 / 60)
      }

      return minutes
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'users/isAdmin',
      patientsWA: 'patients/patientsWA',
      institution: 'setting/institution',
      reportName: 'setting/reportName',
      treatmentsByPatientId: 'treatments/treatmentsByPatientId',
      partition: 'setting/enabledPartition',
      typeUsername: 'setting/userName',
      treatments: 'treatments/treatmentsWADP',
      enableScoreOverwrite: 'setting/enableScoreOverwrite',
      displayTime: 'setting/displayTime',
      treatmentTime: 'setting/treatmentTime',
      logo: 'setting/logo',
      bedNumberName: 'setting/bedNumberName',
      numberName: 'setting/numberName',
      enableEndTime: 'setting/enableEndTime',
      enableTouch: 'setting/enableTouch',
      enableAge: 'setting/enableAge'
    }),
    ...mapState({
      gaugeList: state => state.gauge.items,
      patients: state => state.patients.items,
      devices: state => state.devices.items,
      apps: state => state.apps.items
    }),
    series() {
      if (this.dimensionArr.length < 0) return []
      return [
        {
          name: this.cognitiveTime,
          data: this.dimensionArr
        },
        {
          name: this.dimensionTime,
          data: this.dimensionCrease
        }
      ]
    },
    getUserId() {
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      return userId
    }
  },
  components: {
    apexchart: VueApexCharts
  }
}
</script>

<style lang='scss' scoped>
.content-page {
  width: 880px;
  padding: 20px 0 20px 0;
  margin: 0 auto;
}

.mPage {
  width: 100%;
  padding: 0 20px;
}

.page {
  width: 800px;
  margin: 0 auto;
  font-size: 14px;
}

#divToPrint {
  overflow: auto;
}

.page-top {
  position: relative;

  .img-logo {
    position: absolute;
    left: 0;
    top: -30px;
  }

  .page-title {
    width: 100%;
    text-align: center;

    .name {
      font-size: 32px;
      margin-top: 30px;
      // font-weight: bold;
    }

    .subtitle {
      font-size: 24px;
      margin-top: 20px;
    }
  }
}

.page-msg {
  width: 100%;
  height: 72px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin-top: 50px;
  padding-top: 12px;
  font-size: 16px;

  .row1 {
    display: flex;
  }

  .row2 {
    display: flex;
  }
}

.diagnosis {
  margin-top: 20px;

  .diagnosis-title,
  .diagnosis-content {
    height: 30px;
    background: #2980ba;
    color: #fff;
    line-height: 30px;
    padding-left: 10px;
  }

  .diagnosis-content {
    height: 50px;
    background: #f5f5f5;
    color: #000;
  }
}

.result {
  .result-title {
    height: 30px;
    background: #2980ba;
    color: #fff;
    line-height: 30px;
    padding-left: 10px;
  }

  .result-content {
    height: 120px;
    background: #f5f5f5;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.treatment-programs {
  .treatment-title {
    height: 30px;
    background: #2980ba;
    color: #fff;
    line-height: 30px;
    padding-left: 10px;
  }

  .content {
    background: #f5f5f5;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.treatment-result {
  .treatment-title {
    height: 30px;
    background: #2980ba;
    color: #fff;
    line-height: 30px;
    padding-left: 10px;
  }

  .content {
    display: flex;
  }
}

.table {
  .table-title {
    height: 30px;
    background: #2980ba;
    color: #fff;
    line-height: 30px;
    padding-left: 10px;
  }
}

.page-bottom {
  margin-top: 50px;
  display: flex;

  .left {
    font-size: 12px;
    display: flex;
    flex-direction: column;
  }

  .right {
    margin-left: 300px;
  }
}
</style>