<template lang='pug'>
v-app
  div(v-if='!authorized && $route.name !== "PublicScale" && $route.name !== "GaugeTest" && $route.name !== "GaugeResult"')
    v-main
      v-container(fill-height, fluid, style='height: 100vh')
        Login
  div(v-else, style='position: relative')
    v-overlay(:value='loading')
      v-progress-circular(indeterminate, size='64')
    .nav(v-show='!authorized && $route.name !== \'PublicScale\' && $route.name !== \'GaugeTest\' && $route.name !== \'GaugeResult\'')
      NavigationDrawer(:class='navShow ? "navShow" : "navHide"', @selectClick='selectClick')
    .nav(v-show='authorized')
      NavigationDrawer(:class='navShow ? "navShow" : "navHide"', @selectClick='selectClick')
    .openBtn(v-show='$route.name !== \'PublicScale\' && $route.name !== \'GaugeTest\' && $route.name !== \'GaugeResult\'')
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn(
            fab,
            dark,
            color='#4A9BAD',
            v-bind='attrs',
            v-on='on',
            :class='isOpen ? "hideBtn" : "showBtn"',
            v-show='arrowRightShow',
            @click='setWidth',
            style='margin-left: 0'
          )
            v-icon mdi-menu
    .refreshBtn(v-show='$route.name !== \'PublicScale\' && $route.name !== \'GaugeTest\' && $route.name !== \'GaugeResult\'')
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn(
            fab,
            dark,
            color='#4A9BAD',
            v-bind='attrs',
            v-on='on',
            :class='isOpen ? "hideBtn" : "showBtn"',
            v-show='arrowRightShow',
            @click='refreshClick',
            style='margin-left: 0'
          )
            v-icon mdi-refresh
    .mask(v-show='navShow && !isOpen', @click='outClick')
    div(v-if='!isOpen')
      v-main(style='padding: 0 0 0 0')
        v-container(fluid)
          router-view
    div(v-if='isOpen')
      v-main(style='padding: 0 0 0 256px')
        v-container(fluid)
          router-view
  v-snackbar(top, right, elevation='2', v-model='snackbar', timeout='2000') {{ notifMsg }}
    template(v-slot:action='{ attrs }')
      v-btn(color='blue', text, v-bind='attrs', @click='snackbar = false') Close
  ActivateDialog(ref='activateDialog', @snackSuccess='snackSuccess', @snackFail='snackFail')
</template>


<script>
import { mapActions, mapGetters } from 'vuex'

import { Login, AppBar, NavigationDrawer } from './components'
import PublicScale from './components/Gauge/PublicScale.vue'
import ActivateDialog from './components/ActivateDialog.vue'

export default {
  data: () => {
    return {
      isOpen: true,
      arrowRightShow: false,
      navShow: true,
      snackbar: false,
      notifMsg: '',
      isLogin: false
    }
  },
  components: {
    AppBar,
    NavigationDrawer,
    Login,
    ActivateDialog,
    PublicScale
  },

  created() {
    this.init()
    this.resize()
    window.addEventListener('resize', this.resize, true)
    const logToken = JSON.parse(window.localStorage.getItem('vuex'))
    const role = logToken.users.role
    if (role !== undefined) {
      this.isLogin = true
    } else {
      this.isLogin = false
    }
  },

  watch: {
    warranty_date: function (val) {
      if (val == '' || val.slice(0, 4) == '0001') {
        if (this.isLogin) {
          this.$refs.activateDialog.activateDeviceDialog = true
        } else {
          this.$refs.activateDialog.activateDeviceDialog = false
        }
      }
    }
  },

  methods: {
    refreshClick() {
      this.$router.go(0)
    },
    selectClick(val) {
      if (!this.isOpen) {
        this.navShow = val
        this.arrowRightShow = true
      } else {
        this.navShow = true
        this.arrowRightShow = false
      }
    },
    setWidth() {
      this.navShow = true
      this.arrowRightShow = false
    },
    outClick() {
      if (this.isOpen) {
        this.navShow = true
      } else {
        this.navShow = false
        this.arrowRightShow = true
      }
    },
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
        this.arrowRightShow = true
        this.navShow = false
      } else {
        this.isOpen = true
        this.arrowRightShow = false
        this.navShow = true
      }
    },
    snackSuccess() {
      this.snackbar = true
      this.notifMsg = this.$t('notifSuccess')
    },

    snackFail(err) {
      this.snackbar = true
      this.notifMsg = `this.$t('notifFailure')：${err.message}!`
    },
    ...mapActions({
      init: 'init'
    })
  },

  computed: {
    ...mapGetters({
      loading: 'loading',
      warranty_date: 'about/warranty_date'
    }),

    ...mapGetters({
      authorized: 'users/authorized'
    })
  }
}
</script>

<style lang="scss">
* {
  ::-webkit-scrollbar {
    width: 0 !important;
  }
}

.openBtn {
  width: 50px;
  position: fixed;
  z-index: 222;
  bottom: 15px;
  left: 20px;
}

.refreshBtn {
  width: 50px;
  position: fixed;
  z-index: 222;
  bottom: 80px;
  left: 20px;
}

.showBtn {
  display: block;
}

.hideBtn {
  display: none;
}

.navShow {
  animation: expanded 0.5s ease;
}

.navHide {
  display: none;
}

@keyframes expanded {
  0% {
    display: none;
    width: 0;
  }

  100% {
    width: 255;
    display: block;
  }
}

.mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 998;
}

.nav {
  position: fixed;
  z-index: 999;
}

::v-deep #app .v-navigation-drawer--clipped:not(.v-navigation-drawer--temporary):not(.v-navigation-drawer--is-mobile) {
  z-index: 999 !important;
}

.report-legend {
  font-size: 18px;
}
</style>    