import { app } from '../../api'

export default {
  namespaced: true,

  state: {
    items: [],
    appsAllData: []
  },

  getters: {
    appById: state => id => state.items[state.items.findIndex(x => x.model.ID == id)]
  },

  actions: {
    async index({ commit }) {
      var userId = 0
      var obj = {}
      if (JSON.parse(window.localStorage.getItem('vuex')).users.role != undefined) {
        const logToken = JSON.parse(window.localStorage.getItem('vuex'))
        const adminId = logToken.users.userId
        const role = logToken.users.role
        userId = parseInt(role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId)
        obj = {
          adminId: role == 'admin' ? adminId : 1,
          userId: userId,
          role: role,
          isShow: 1,
          type: '0,1,2,3,4,5,6,7,8'
        }
      } else {
        obj = {
          adminId: 1,
          userId: 1,
          role: 'admin',
          isShow: 1,
          type: '0,1,2,3,4,5,6,7,8'
        }
      }

      const response = await app.index(obj)
      commit('index', response.data)
    },
    async appsAll({ commit }) {
      const response = await app.appAll()
      commit('appsAll', response.data)
    }
  },

  mutations: {
    index(state, devices) {
      state.items = devices
    },
    appsAll(state, payload) {
      state.appsAllData = payload
    }
  }
}
