import axios from 'axios'
import { VUE_APP_SERVER_ENDPOINT } from '@/app.config'

async function index(searchType, username, start_time, end_time,userId) {
  return await axios.get(`${VUE_APP_SERVER_ENDPOINT}/examination?user_id=${userId}${((searchType == '' || username == '') ? '' : `&${searchType}=${username}`)}${(start_time == '' ? '' : `&start_time=${start_time}`)}${(end_time == undefined || end_time == '' ? '' : `&end_time=${end_time}`)}`)
}

async function remove(id) {
  return await axios.delete(`${VUE_APP_SERVER_ENDPOINT}/examination/${id}`)
}

export default {
    index,
    remove
  }