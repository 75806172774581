<template lang='pug'>
v-navigation-drawer(app, clipped, dark, permanent)
  v-img#logo(:src='logoImage')
  #subtitle {{ $t("cognitiveSystem") }}YKVR-1

  v-list(nav)
    div(style='font-size: 18px; opacity: 0.6; margin-bottom: 20px; margin-left: 10px') {{ $t("scaleAssessment") }}
    router-link(style='text-decoration: none; color: inherit', to='/gauge')
      v-list-item(link, :class='isSelected("/gauge")', @click='isSelect("/gauge")')
        v-list-item-icon
          v-icon mdi-clipboard-edit
        v-list-item-content
          v-list-item-title.listTitle(style="height:25px") {{ $t("gauge") }}
    router-link(style='text-decoration: none; color: inherit', to='/record')
      v-list-item(link, :class='isSelected("/record")', @click='isSelect("/record")')
        v-list-item-icon
          v-icon mdi-clipboard-multiple
        v-list-item-content
          v-list-item-title.listTitle {{ $t("record") }}
    div(style='font-size: 18px; opacity: 0.6; margin-bottom: 20px; margin-top: 40px; margin-left: 10px') {{ $t("recoveryTreatment") }}
    router-link(style='text-decoration: none; color: inherit', to='/monitors')
      v-list-item(link, :class='isSelected("/monitors")', @click='isSelect("/monitors")')
        v-list-item-icon
          v-icon mdi-youtube-subscription
        v-list-item-content
          v-list-item-title.listTitle {{ $t("monitors") }}
    router-link(style='text-decoration: none; color: inherit', to='/apps')
      v-list-item(link, :class='isSelected("/apps")', @click='isSelect("/apps")')
        v-list-item-icon
          v-icon mdi-apps
        v-list-item-content
          v-list-item-title.listTitle {{ $t("apps") }}
    router-link(style='text-decoration: none; color: inherit', to='/treatments')
      v-list-item(link, :class='isSelected("/treatments")', @click='isSelect("/treatments")')
        v-list-item-icon
          v-icon mdi-calendar-plus
        v-list-item-content
          v-list-item-title.listTitle {{ $t("treatments") }}
    div(style='height: 40px')
    router-link(style='text-decoration: none; color: inherit', to='/patients')
      v-list-item(link, :class='isSelected("/patients")', @click='isSelect("/patients")')
        v-list-item-icon
          v-icon mdi-account-group
        v-list-item-content
          v-list-item-title.listTitle {{ typeUsername == "1" ? $t("userNameList") : $t("patients") }}
    router-link(style='text-decoration: none; color: inherit', to='/devices')
      v-list-item(link, :class='isSelected("/devices")', @click='isSelect("/devices")')
        v-list-item-icon
          v-icon mdi-virtual-reality
        v-list-item-content
          v-list-item-title.listTitle {{ $t("devices") }}
    router-link(v-if='isAdmin', style='text-decoration: none; color: inherit', to='/setting')
      v-list-item(link, :class='isSelected("/setting")', @click='isSelect("/setting")')
        v-list-item-icon
          v-icon mdi-cog-outline
        v-list-item-content
          v-list-item-title.listTitle {{ $t("setting") }}
    router-link(style='text-decoration: none; color: inherit', to='/about')
      v-list-item(link, :class='isSelected("/about")', @click='isSelect("/about")')
        v-list-item-icon
          v-icon.iconfont.icon-guanyu
        v-list-item-content
          v-list-item-title.listTitle {{ $t("regarding") }}
  template(v-slot:append)
    el-popover(placement='right', width='400', style='height: 500px', v-model='showPopover', v-if='isAdmin')
      div(style='display: flex; margin-bottom: 30px')
        el-button(
          icon='el-icon-plus',
          circle,
          style='margin-right: 20px; background: #ff9800; color: #ffffff',
          @click.stop='addUserDialog'
        )
        el-input.input-with-select(:placeholder="$t('pleaseSelectUser')", v-model='userNameSearch', clearable, @clear='inputClear')
        .search(@click='searchClick')
          span.iconfont.icon-31sousuo
      ul.list
        li(v-for='(item, i) in userData', :key='item.model.ID', @click='userSelectClick(i, item)', :class='i == curIndex ? "liBack" : ""') {{ item.username }}
        //- {{options[curIndex].label}}
      el-button(slot='reference', style='width: 238px; margin-left: 10px; color: #606266', @click='user()') {{ selectedUser }}

      v-dialog(v-model='addUser', width='500')
        v-card
          v-card-title.primary.headline.mb-12
            h4(style='color: white') {{$t("addUser")}}
          v-card-text
            v-form(v-model='valid', ref='form')
              v-text-field(
                v-model='username',
                :rules='rules.username',
                solo,
                :label="$t('pleaseEnterUsername')",
                prepend-inner-icon='mdi-pound-box-outline',
                required
              )
              v-text-field(
                v-model='password',
                :rules='rules.password',
                type='password',
                solo,
                prepend-inner-icon='mdi-tag-text-outline',
                :label="$t('enterEnterPassword')",
              )
          v-card-actions
            v-spacer
            v-btn(text, @click='addUser = false') {{ $t("dialogCancel") }}
            v-btn(text, @click='confirm()') {{ $t("dialogConfirm") }}

        v-snackbar(top, right, elevation='2', v-model='snackbar', timeout='2000') {{ notifMsg }}
          template(v-slot:action='{ attrs }')
            v-btn(color='blue', text, v-bind='attrs', @click='snackbar = false') Close
    .pa-2.mb-2(style='color: #fff; margin-left: 10px', v-if='!isAdmin')
      v-icon(left) mdi-account
      span.name {{ userNameBar }}
    .pa-2.mb-2
      v-btn(large, block, light, @click='logout()')
        v-icon(left) mdi-logout
        | {{ $t("logout") }}
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { user } from './../api'

export default {
  data: vm => ({
    logoImage: require('@/assets/logo.png'),
    subtitleImage: require('@/assets/subtitle.png'),
    value: '',
    curIndex: -1,
    selectedUser: vm.$t('pleaseSelectUser'),
    addUser: false,
    showPopover: false,
    userNameSearch: '',

    username: '',
    password: '',
    valid: false,
    rules: {
      username: [v => !!v || vm.$t('usernameRule')],
      password: [v => !!v || vm.$t('passwordRule')]
    },
    snackbar: false,
    notifMsg: '',
    userData: [],
    userNameBar: '',
    isOpen: true
  }),

  watch: {
    userList: function (val) {
      this.userData = val
    }
  },

  mounted() {
    this.userData = this.userList
    const number = this.userList.findIndex(x => {
      return x.model.ID == window.localStorage.getItem('userId')
    })
    this.curIndex = number
    if (this.curIndex !== -1) {
      this.selectedUser = this.userList[number].username
    }
    const logToken = JSON.parse(window.localStorage.getItem('vuex'))
    this.userNameBar = logToken.users.username
    if (!this.isAdmin && this.$route.path == '/setting') {
      this.$router.push('/apps')
    }

  },

  methods: {
    isSelected(path) {
      if (this.$route.query.type == 1) {
        this.$route.path = '/gauge'
      } else if (this.$route.query.type == 2) {
        this.$route.path = '/record'
      } else if (this.$route.query.type == 3) {
        this.$route.path = '/gauge'
      } else if (this.$route.query.type == 4) {
        this.$route.path = '/record'
      }

      if (this.$route.path.includes(path)) {
        return 'selected'
      }
    },

    isSelect() {
      this.$emit('selectClick', this.$route.meta.navShow, this.$route.path)
    },

    ...mapActions({
      logout: 'users/logout',
      user: 'users/user'
    }),

    async searchClick() {
      const result = await user.user(this.userNameSearch)
      this.userData = result.data
    },

    async inputClear() {
      this.userNameSearch = ''
      this.searchClick()
    },

    userSelectClick(idx, item) {
      if (this.curIndex !== idx) {
        this.curIndex = idx
        this.selectedUser = this.userList[this.curIndex].username
        window.localStorage['userId'] = item.model.ID
        window.localStorage['appsId'] = item.appsId
        this.$router.go(0)
      } else {
        this.curIndex = -1
        window.localStorage['userId'] = ''
        this.$router.go(0)
      }
    },

    addUserDialog() {
      this.addUser = true
      this.showPopover = false
    },

    clear() {
      this.$refs.form.reset()
    },

    async confirm() {
      if (this.$refs.form.validate()) {
        const request = {
          username: this.username,
          password: this.password,
          role: 'user',
        }


        try {
          await user.addUser(request)
          this.addUser = false
        } catch (err) {
          this.snackbar = true
          this.notifMsg = 'Login failed, check your username and password combination!'
          this.clear()
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      isAdmin: 'users/isAdmin',
      typeUsername: 'setting/userName'
    }),

    ...mapState({
      userList: state => state.users.userList
    })
  }
}
</script>

<style lang="sass" scoped>
.isShow
  display: block
.hide
  display: none
.theme--dark.v-navigation-drawer
  background: linear-gradient(90deg, rgba(6,101,126,1) 0%, rgba(71,147,163,1) 100%)
  box-shadow: 1px 0px 12px grey
::v-deep .v-list-item
  max-height: 44px
::v-deep .theme--dark.v-icon
  margin-top: -5px

#logo
  margin: 50px auto 30px auto
  max-width: 150px
#subtitle
  margin: 0 auto 50px auto
  max-width: 300px
  color: #fff
  font-family: '微软雅黑'
  text-align: center
.selected
  background: orange
::v-deep el-icon-plus
  color: #fff
.search
  width: 50px
  height: 40px
  background: #ff9800
  border-radius: 5px
  color: #fff
  line-height: 40px
  text-align: center
  margin-left: 10px
.name
  display: inline-block
  height: 23px
  border-bottom: 1px solid white
.list
  height: 400px
  overflow-y: auto
  li
    list-style: none
    height: 50px
    line-height: 50px
    cursor: pointer
    font-size: 18px
    padding-left: 10px
  .liBack
    background: #E4EFFA
    color: #1976D2
.listTitle
  min-height: 22px
.icon-guanyu
  font-size: 23px
</style>
