<template lang="pug">
div
  v-toolbar.mb-6.topBar(flat, style='height: 70px', @click='setWidth')
    img(src='@/assets/title_logo.png', width='50', style='margin-right: 10px; margin-top: 25px; margin-left: -15px', v-if='!isOpen')
    v-toolbar-title(v-if='isOpen', style='margin-left: -15px') {{ $t("regarding") }}
    h3(v-if='!isOpen', style='margin-top: 25px; font-size: 19px') {{ $t("regarding") }}
    v-divider.mx-4(inset, vertical, v-if='isOpen')
  v-row
    v-col(cols='12')
      v-row.setting-row(align='center', no-gutters)
        v-icon.iconfont.icon-name(left)
        | {{ $t("softwareName") }}
        v-spacer
        .input-text(style='width: 150px')
          v-text-field(reverse, :value='name', disabled)
      v-row.setting-row(align='center', no-gutters) 
        v-icon.iconfont.icon-xinghao.icon(left)
        | {{ $t("softwareModel") }}
        v-spacer
        .input-text(style='width: 70px')
          v-text-field(reverse, :value='number', disabled)
      v-row.setting-row(align='center', no-gutters) 
        v-icon.iconfont.icon-guanfangbanben(left)
        | {{ $t("softwareReleaseVersion") }}
        v-spacer
        .input-text(style='width: 50px')
          v-text-field(reverse, :value='release_message', disabled)
      v-row.setting-row(align='center', no-gutters) 
        v-icon.iconfont.icon-banben(left, style='font-weight: normal')
        | {{ $t("completedSoftwareVersion") }}
        v-spacer
        .input-text(style='width: 60px')
          v-text-field(reverse, :value='complete_message', disabled)
      v-row.setting-row(align='center', no-gutters)
        v-icon.iconfont.icon-baoxiuqi(left)
        | {{ $t("warrantyExpires") }}
        v-spacer
        .input-text(style='width: 100px')
          v-text-field(v-if='!isAdmin', reverse, :value='updateDate', disabled)
          v-text-field(v-if='isAdmin', reverse, :value='updateDate', @change='v => updateWarrantyDate(v)', disabled)
      v-row.setting-row(align='center', no-gutters) 
        v-icon.iconfont.icon-scsxx(left)
        | {{ $t("manuFacturer") }}
        v-spacer
        .input-text(style='width: 200px')
          v-text-field(reverse, :value='company', disabled)
      v-row.setting-row(align='center', no-gutters) 
        v-icon.iconfont.icon-shouhuodizhi(left)
        | {{ $t("productionAddress") }}
        v-spacer
        .input-text(style='width: 400px')
          v-text-field(reverse, :value='address', disabled)
      v-row.setting-row(align='center', no-gutters) 
        v-icon.iconfont.icon-dianhualianxifangshi(left)
        | {{ $t("contactInformation") }}
        v-spacer
        .input-text(style='width: 130px')
          v-text-field(reverse, :value='telephone', disabled)

  Keyboard#keyboard(layout='normal', :input='input', v-if='visible && enableTouch', :cancel='cancel')
  .nav
    NavigationDrawer(:class='navShow ? "navShow" : "navHide"', @selectClick='selectClick')
  .mask(v-show='navShow && !isOpen', @click='outClick')
</template>

<script>
import Keyboard from 'vue-keyboard-zh'
import { NavigationDrawer } from '../components'
import { mapGetters } from 'vuex'
import { about } from '../api'
export default {
  data() {
    return {
      isOpen: false,
      input: '',
      visible: false,
      type: '',
      target: undefined,
      navShow: false
    }
  },
  components: {
    Keyboard,
    NavigationDrawer
  },
  mounted() {
    window.addEventListener('resize', this.resize, false)
    this.resize()
  },
  methods: {
    setWidth() {
      this.navShow = true
    },
    selectClick(val) {
      if (!this.isOpen) {
        this.navShow = val
      } else {
        this.navShow = true
      }
    },
    outClick() {
      if (this.isOpen) {
        this.navShow = true
      } else {
        this.navShow = false
      }
    },
    cancel() {
      this.visible = false
    },
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    keyboardShowClick(e) {
      this.input = e.target
      if (!this.visible) this.visible = true
      const s = {
        institution: e.target.value,
        bedNumberName: this.bedNumberName,
        partition: this.partition,
        logo: this.logo,
        userId: this.userId(),
        total: this.total
      }
      about.update(s)
    },
    updateWarrantyDate(v) {
      const s = {
        warranty_date: v + 'T00:00:00Z'
      }
      about.update(s)
    }
  },

  computed: {
    ...mapGetters({
      name: 'about/name',
      number: 'about/number',
      release_message: 'about/release_message',
      complete_message: 'about/complete_message',
      warranty_date: 'about/warranty_date',
      company: 'about/company',
      address: 'about/address',
      telephone: 'about/telephone',
      year: 'about/year',
      isAdmin: 'users/isAdmin'
    }),
    updateDate() {
      const year = (this.warranty_date !== undefined ? parseInt(this.warranty_date.slice(0, 4)) : 0) + parseInt(this.year)
      const month = new Date(this.warranty_date).getMonth() + 1
      const day = new Date(this.warranty_date).getDate()
      return (year < 4 ? '000' + year : year) + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .iconfont {
  font-size: 24px;
  font-weight: bold;
  color: #757575;
}
</style>