<template lang="pug">
div
  v-row
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-monitor-eye
        | {{ $t("monitor") }}
        v-spacer
        v-switch(inset, v-model='monitor')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-circle-multiple-outline
        | {{ $t("mindfulness") }}
        v-spacer
        v-switch(inset, v-model='mindfulness')
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-timer-outline
        | {{ $t("difficulty") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='difficulty',
          :items='difficulties',
          item-text='text',
          item-value='value',
          @change='getDifficultyVal'
        )
      v-row.setting-row(align='center', no-gutters, v-if='enabledCountdown')
        v-icon(left) mdi-timer-outline
        | {{ $t("timeLimit") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='timeLimit',
          :items='timeLimits',
          item-text='text',
          item-value='value'
        )
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: vm => ({
    monitor: true,
    mindfulness: true,
    difficulty: 1,
    difficulties: [
      { text: vm.$t('easy'), value: 0 },
      { text: vm.$t('normal'), value: 1 },
      { text: vm.$t('hard'), value: 2 }
    ],
    timeLimit: 15,
    timeLimits: [
      { text: vm.$t('5min'), value: 5 },
      { text: vm.$t('10min'), value: 10 },
      { text: vm.$t('15min'), value: 15 },
      { text: vm.$t('20min'), value: 20 },
      { text: vm.$t('30min'), value: 30 }
    ]
  }),

  methods: {
    getDifficultyVal(value) {
      this.difficulty = value
    },
    getSettings() {
      return JSON.stringify({
        monitor: this.monitor,
        mindfulness: this.mindfulness,
        timeLimit: this.enabledCountdown ? this.timeLimit : -1,
        difficuty: this.difficulty
      })
    }
  },

  computed: {
    ...mapGetters({
      enabledCountdown: 'setting/enabledCountdown'
    })
  }
}
</script>

<style lang="sass">
.setting-row
  height: 50px
</style>

