import 'jspdf-autotable'
import html2canvas from 'html2canvas'

export const printPDF = content => {
    return new Promise(resolve => {
        import('jspdf').then(({ jsPDF }) => {
            const doc = new jsPDF('', 'pt', 'a4')
            const height = doc.internal.pageSize.getHeight()
            // 获取页面内容的高度
            const contentHeight = content.printDom.offsetHeight

            if (content.isOpen) {
                const scaleHeight = 841.89 / (841.89 - 200 - 28)
                const treatmentHeight = 841.89 / (841.89 - 180 - 28)
                const patientsHeight = 841.89 / (841.89 - 119 - 28)
                const PMHeight = 841.89 / (841.89 - 108 - 28)
                var pageHeight = 0
                if (content.type == 'scale') {
                    pageHeight = 841.89 * scaleHeight
                } else if (content.type == 'treatment') {
                    pageHeight = 841.89 * treatmentHeight
                } else if (content.type == 'patients') {
                    pageHeight = 841.89 * patientsHeight
                } else if (content.type == 'PM') {
                    pageHeight = 841.89 * PMHeight
                }
            } else {
                const scaleHeight = 841.89 / (841.89 - 250 - 28)
                const treatmentHeight = 841.89 / (841.89 - 615 - 28)
                const patientsHeight = 841.89 / (841.89 - 100 - 28)
                const PMHeight = 841.89 / (841.89 - 108 - 28)
                var mPageHeight = 0
                if (content.type == 'scale') {
                    mPageHeight = 841.89 * scaleHeight
                } else if (content.type == 'treatment') {
                    mPageHeight = 841.89 * treatmentHeight
                } else if (content.type == 'patients') {
                    mPageHeight = 841.89 * patientsHeight
                } else if (content.type == 'PM') {
                    mPageHeight = 841.89 * PMHeight
                }
            }

            const a4PageSize = {
                width: 595.28,
                height: content.isOpen ? pageHeight : mPageHeight
            }

            // 计算需要分成多少页
            const totalPages = Math.ceil(contentHeight / a4PageSize.height)

            // 创建一个Promise数组，用于保存每一页的Canvas对象
            const promises = []
            // 使用html2canvas和jspdf进行分页处理
            for (let i = 0; i < totalPages; i++) {
                promises.push(
                    new Promise(resolve => {
                        // 创建一个虚拟的Canvas元素，用于绘制当前页的内容
                        const virtualCanvas = document.createElement('canvas')
                        virtualCanvas.width = a4PageSize.width + (content.isOpen ? 205 : 810)
                        virtualCanvas.height = a4PageSize.height
                        // 获取当前页需要绘制的内容的起始位置
                        const startY = a4PageSize.height * i
                        // 将页面内容绘制到虚拟Canvas上
                        html2canvas(content.printDom, {
                            canvas: virtualCanvas,
                            y: startY,
                            allowTaint: true,
                            taintTest: false,
                            // scale: 4,
                            dpi: 3000,
                            useCORS: true
                        }).then(canvas => {
                            resolve(canvas)
                        })
                    })
                )
            }
            // 等待所有Promise完成后，将Canvas对象转换为PDF并保存
            Promise.all(promises).then(canvases => {
                canvases.forEach((canvas, index) => {
                    if (index > 0) {
                        // 添加新的页面
                        doc.addPage()
                    }
                    if (content.isOpen) {
                        if (content.type == 'scale') {
                            doc.addImage(canvas.toDataURL('image/png'), 'PNG', 20, 28, a4PageSize.width - 40, a4PageSize.height - 340)
                        } else if (content.type == 'treatment') {
                            doc.addImage(canvas.toDataURL('image/png'), 'PNG', 20, 28, a4PageSize.width - 40, a4PageSize.height - 280)
                        } else if (content.type == 'patients') {
                            doc.addImage(canvas.toDataURL('image/png'), 'PNG', 20, 28, a4PageSize.width - 40, a4PageSize.height - 260)
                        } else if (content.type == 'PM') {
                            doc.addImage(canvas.toDataURL('image/png'), 'PNG', 20, 28, a4PageSize.width - 40, a4PageSize.height - 260)
                        }
                    } else {
                        if (content.type == 'scale') {
                            doc.addImage(canvas.toDataURL('image/png'), 'PNG', 20, 28, a4PageSize.width - 40, a4PageSize.height - 440)
                        } else if (content.type == 'treatment') {
                            doc.addImage(canvas.toDataURL('image/png'), 'PNG', 20, 28, a4PageSize.width - 40, a4PageSize.height - 2500)
                        } else if (content.type == 'patients') {
                            doc.addImage(canvas.toDataURL('image/png'), 'PNG', 20, 28, a4PageSize.width - 40, a4PageSize.height - 600)
                        } else if (content.type == 'PM') {
                            doc.addImage(canvas.toDataURL('image/png'), 'PNG', 20, 28, a4PageSize.width - 40, a4PageSize.height - 260)
                        }
                    }
                })

                html2canvas(content.bottomFontDom, {
                    crossorigin: 'anonymous',
                    allowTaint: true,
                    taintTest: false,
                    useCORS: true,
                    y: 0,
                    dpi: window.devicePixelRatio * 4,
                    scale: 4,
                    padding: { left: 10, right: 10 }
                })
                    .then(function (canvas) {
                        let contentWidth = canvas.width
                        let contentHeight = canvas.height
                        let pageHeight = (contentWidth / 592.28) * 841.89
                        let leftHeight = contentHeight
                        let position = 28
                        var imgWidth = 595.28
                        var imgHeight = (595.28 / contentWidth) * contentHeight
                        let pageData = canvas.toDataURL('image/jpeg', 1.0)
                        if (leftHeight < pageHeight) {
                            doc.addImage(pageData, 'JPEG', 20, height - 50, imgWidth - 40, imgHeight)
                        } else {
                            while (leftHeight > 0) {
                                doc.addImage(pageData, 'JPEG', 20, position, imgWidth - 40, imgHeight - 65)
                                leftHeight -= pageHeight
                                position -= 841.89
                                if (leftHeight > 0) {
                                    doc.addPage()
                                }
                            }
                        }
                        window.open(doc.output('bloburl'), '_blank')
                        resolve()
                    })
                    .catch(err => console.log(err))
            })
        })
    })
}