<template lang="pug">
.mask
  v-progress-circular(indeterminate, color='primary')
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.mask {
  width: 100%;
  height: 100%;
  background: rgba(153, 153, 153, 0.3);
  position: absolute;
  top: 0;
  z-index: 100000;
}
.v-progress-circular {
  margin: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>