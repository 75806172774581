import { render, staticRenderFns } from "./NewTreatment.vue?vue&type=template&id=e5ff08ce&scoped=true&lang=pug"
import script from "./NewTreatment.vue?vue&type=script&lang=js"
export * from "./NewTreatment.vue?vue&type=script&lang=js"
import style0 from "./NewTreatment.vue?vue&type=style&index=0&id=e5ff08ce&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5ff08ce",
  null
  
)

export default component.exports