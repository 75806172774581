// TODO: make less api usage by direct manupilating
// vuex state, or make api call on specific IDs

// const tables = [
//   'devices',
//   'apps',
//   'patients',
//   'treatments',
// ]

// function index(dispatch) {
//   tables.forEach( table => { dispatcher(dispatch, table) })
// }

function dispatcher({ dispatch, payload }) {
  switch (payload.table) {
    case 'settings':
      dispatch('setting/pgevent', payload)
      break
    case 'devices':
      dispatch('devices/pgevent', payload)
      break
    case 'patients':
      dispatch('patients/pgevent', payload)
      break
    case 'treatments':
      dispatch('treatments/pgevent', payload)
      break
    case 'records':
      dispatch('records/pgevent', payload)
      break
    default:
      break
  }
}

export default {
  state: {
    connected: false
  },
  
  getters: {
    connected: (state) => state.connected,
  },
  
  actions: {
    // SOCKET_connect ({ dispatch }) {
    //   index(dispatch)
    // },

    SOCKET_pgevent ({ dispatch }, payload) { 
      dispatcher({ dispatch, payload })
    },
  },

  mutations: {
    SOCKET_connect (state) {
      state.connected = true
    },

    SOCKET_disconnect (state) {
      state.connected = false
    },
  },
}