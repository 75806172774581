export const VUE_APP_I18N_LOCALE = process.env.VUE_APP_I18N_LOCALE
export const VUE_APP_I18N_FALLBACK_LOCALE = process.env.VUE_APP_I18N_FALLBACK_LOCALE

export const VUE_APP_SERVER_ENDPOINT = process.env.VUE_APP_SERVER_ENDPOINT
export const VUE_APP_SOCKET_ENDPOINT = process.env.VUE_APP_SOCKET_ENDPOINT
export const VUE_APP_FILE_SERVER_ENDPOINT = process.env.VUE_APP_FILE_SERVER_ENDPOINT
export const VUE_APP_FILE_SERVER_IMGRESULT = process.env.VUE_APP_FILE_SERVER_IMGRESULT
export const VUE_APP_STREAMER_ENDPOINT = process.env.VUE_APP_STREAMER_ENDPOINT
export const VUE_APP_EEG_SOCKET_ENDPOINT = process.env.VUE_APP_EEG_SOCKET_ENDPOINT

export default {
  VUE_APP_I18N_LOCALE,
  VUE_APP_I18N_FALLBACK_LOCALE,
  VUE_APP_SERVER_ENDPOINT,
  VUE_APP_SOCKET_ENDPOINT,
  VUE_APP_FILE_SERVER_ENDPOINT,
  VUE_APP_FILE_SERVER_IMGRESULT,
  VUE_APP_STREAMER_ENDPOINT,
  VUE_APP_EEG_SOCKET_ENDPOINT,
}