<template lang="pug">
div
    v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-numeric-3-box-multiple-outline
        | {{ $t("monitorQuality") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='clarity',
          :items='clarityList',
          item-text='text',
          item-value='value'
        )
    div(v-if='clarity == 4')
        v-row.setting-row(align='center', no-gutters)
          v-icon(left) mdi-message-outline
          | {{ $t("resolution") }}
          v-spacer
          v-select.outlined.mr-3(reverse, style='max-width: 100px', v-model='resolution', :items='resolutionList', item-text='text', item-value='value')
        v-row.setting-row(align='center', no-gutters)
          v-icon(left) mdi-numeric-3-box-multiple-outline
          | {{ $t("fps") }}
          v-spacer
          v-select.outlined.mr-3(
            reverse,
            style='max-width: 100px',
            v-model='fps',
            :items='fpsList',
            item-text='text',
            item-value='value'
          )
        v-row.setting-row(align='center', no-gutters)
          v-icon(left) mdi-numeric-6-circle-outline
          | {{ $t("quality") }}
          v-spacer
          v-select.outlined.mr-3(
            reverse,
            style='max-width: 100px',
            v-model='quality',
            :items='qualityList',
            item-text='text',
            item-value='value'
          )
        v-row.setting-row(align='center', no-gutters)
          v-icon(left) mdi-numeric-6-circle-outline
          | {{ $t("chromaSubsampling") }}
          v-spacer
          v-select.outlined.mr-3(
            reverse,
            style='max-width: 100px',
            v-model='chromaSubsampling',
            :items='chromaSubsamplingList',
            item-text='text',
            item-value='value'
          )
        v-row.setting-row(align='center', no-gutters)
          v-icon(left) mdi-numeric-6-circle-outline
          | {{ $t("resize") }}
          v-spacer
          v-select.outlined.mr-3(
            reverse,
            style='max-width: 100px',
            v-model='resize',
            :items='resizeList',
            item-text='text',
            item-value='value'
          )
</template>

<script>
export default {
  props: ['appCodename'],
  data: vm => ({
    //clarity
    clarity:1,
    clarityList:[
      { text: vm.$t("Smooth"), value: 0 },
      { text: vm.$t("SD"), value: 1 },
      { text: vm.$t("HD"), value: 2 },
      { text: vm.$t("Blu-ray"), value: 3 },
      { text: vm.$t("custom"), value: 4 }
    ],
    resolution: 512,
    resolutionList: [
      { text: vm.$t("low"), value: 512 },
      { text: vm.$t("high"), value: 1024 },
      { text: vm.$t("superHigh"), value: 2048 }
    ],
    fps:24,
    fpsList:[
      { text: vm.$t("low"), value: 24 },
      { text: vm.$t("high"), value: 30 },
      { text: vm.$t("superHigh"), value: 60 }
    ],
    quality: 20,
    qualityList:[
      { text: vm.$t("low"), value: 20 },
      { text: vm.$t("middle"), value: 40 },
      { text: vm.$t("high"), value: 60 }
    ],
    chromaSubsampling: 0,
    chromaSubsamplingList:[
      { text: vm.$t("superHigh"), value: 0 },
      { text: vm.$t("high"), value: 1 },
      { text: vm.$t("low"), value: 2 },
      { text: vm.$t("grayScale"), value: 3 }
    ],
    resize:0,
    resizeList:[
      { text: '100%', value: 0 },
      { text: '50%', value: 1 },
      { text: '25%', value: 2 },
      { text: '12.5%', value: 3 }
    ]
  }),

  methods: {
    streamSetting() {
        // switch(this.clarity){
        //     case 1:
        //         this.resolution = ""
        //         this.fps = ""
        //         this.quality = ""
        //         this.chromaSubsampling = ""
        //         this.resize = ""
        //         break
        //     case 2:
        //         this.resolution = ""
        //         this.fps = ""
        //         this.quality = ""
        //         this.chromaSubsampling = ""
        //         this.resize = ""
        //         break
        //     case 3:
        //         this.resolution = ""
        //         this.fps = ""
        //         this.quality = ""
        //         this.chromaSubsampling = ""
        //         this.resize = ""
        //         break
            
        // }
      let options = {
        resolution: this.resolution,
        fps: this.fps,
        quality: this.quality,
        chromaSubsampling: this.chromaSubsampling,
        resize: this.resize
      }
      return JSON.stringify({ level: this.clarity, options: options })
    }
  }
}
</script>

<style lang="sass">
.setting-row
  height: 50px
</style>

