<template lang='pug'>
div 
  v-dialog(
    v-model='dialog',
    persistent,
    width='1200px',
    @click:outside='visible = false',
    :content-class='!visible ? "cDialog" : "mDialog"'
  )
    v-card
      v-card-title
      v-card-text
        v-row
          v-col(cols='12', :sm='isOpen ? 4 : 6', :md='isOpen ? 4 : 6')
            v-card
              v-toolbar(dense, dark, color='primary')
                v-toolbar-title {{ typeUsername == "1" ? $t("userNameTitle") : $t("titlePatient") }}
                v-spacer
                v-text-field(
                  v-if='!prePatientId',
                  style='max-width: 200px',
                  rounded,
                  dense,
                  PatientMini,
                  outlined,
                  single-line,
                  hide-details,
                  v-model='searchPatients',
                  append-icon='mdi-magnify',
                  :label='$t("search")',
                  @click.stop.native='isOpen ? (visible = false) : (visible = true)',
                  @focus.stop='keyboardShowClick'
                )
              v-card-text
                v-list.overflow-y-auto(style='height: 300px')
                  v-list-item-group
                    ul
                      li(v-for='(item, i) in patients(searchPatients)', :key='i', :class='userColor')
                        PatientMini(:patientId='prePatientId', :ref='`patients/${i}`', :userType='typeUsername', :isOpen='isOpen')
          v-col(cols='12', :sm='isOpen ? 4 : 6', :md='isOpen ? 4 : 6')
            v-card
              v-toolbar(dense, dark, color='primary')
                v-toolbar-title {{ $t("devicesTitle") }}
                v-spacer
                v-text-field(
                  v-if='prePatientId',
                  style='max-width: 200px',
                  rounded,
                  dense,
                  outlined,
                  single-line,
                  hide-details,
                  v-model='searchDevices',
                  append-icon='mdi-magnify',
                  :label='$t("search")',
                  @click.stop.native='isOpen ? (visible = false) : (visible = true)',
                  @focus.stop='keyboardShowClick'
                )
              v-card-text
                v-list.overflow-y-auto(style='height: 300px')
                  v-list-item-group
                    ul
                      li(
                        v-for='(item, i) in devices(searchDevices)',
                        :key='i',
                        @click='devicesClick(item, i)',
                        :class='devicesId === item.model.ID ? deviceColor : ""'
                      )
                        DeviceMini(:deviceId='item.model.ID', :ref='`devices/${i}`')
          v-col(cols='12', sm='4', md='4', v-if='isOpen')
            v-card
              v-toolbar(dense, dark, color='primary') 
                v-toolbar-title {{ $t("appsTitle") }}
                v-spacer
                v-text-field(
                  v-if='prePatientId',
                  style='max-width: 200px',
                  rounded,
                  dense,
                  outlined,
                  single-line,
                  hide-details,
                  v-model='searchApps',
                  append-icon='mdi-magnify',
                  :label='$t("search")'
                )
              v-card-text
                ul
                  li(v-for='(item, i) in apps(searchApps)', :key='i', @click='appsClick(item, i)', :class='appIndex == i ? appColor : ""')
                    AppMini(:appId='item.model.ID', :ref='`apps/${i}`')
          v-col(cols='12', sm='12', md='12', v-if='!isOpen')
            v-card
              v-toolbar(dense, dark, color='primary') 
                v-toolbar-title {{ $t("appsTitle") }}
                v-spacer
                v-text-field(
                  v-if='prePatientId',
                  style='max-width: 200px',
                  rounded,
                  dense,
                  outlined,
                  single-line,
                  hide-details,
                  v-model='searchApps',
                  append-icon='mdi-magnify',
                  :label='$t("search")'
                )
              v-card-text
                ul
                  li(v-for='(item, i) in apps(searchApps)', :key='i', @click='appsClick(item, i)', :class='appIndex == i ? appColor : ""')
                    AppMini(:appId='item.model.ID', :ref='`apps/${i}`')
          v-col
            v-card
              v-card-title {{ $t("pairingList") }}
              v-card-text
                v-row
                  v-col(cols='12', sm='6', md='4', v-for='(item, index) in pairingList', :key='index')
                    .pairingBtn
                      v-icon.mr-2(style='color: #1976d2') mdi-account
                      span {{ item.patients.name }}
                      span(style='margin: 0 10px; color: rgba(0, 0, 0, 0.12); font-size: 28px') |
                      v-icon.mr-2(style='color: #1976d2') mdi-virtual-reality
                      span {{ item.devices.name }}
                      span(style='margin: 0 10px; color: rgba(0, 0, 0, 0.12); font-size: 28px') |
                      v-icon.mr-2(style='color: #1976d2') mdi-virtual-reality
                      span {{ item.apps.name }}
                      span.iconfont.icon-close(@click='pairingClose(item, index)')

          v-col(v-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.citstorerelease"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                CITStoreSettings(ref='citstoresettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.dragonbeat"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                DragonBeatSettings(ref='dragonbeatsettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.WheelMaze"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                WheelMazeSettings(ref='wheelmazesettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.shadow"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                ShadowSettings(ref='shadowsettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.pingpongsimulator"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                PingPongSettings(ref='pingpongsettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.shootinggallery"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                ShootingGallerySettings(ref='shootinggallerysettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.breathe"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                BreatheSettings(ref='breathesettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.taichi"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                TaichiSettings(ref='taichisettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.grow"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                GrowSettings(ref='growsettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.bbqflip"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                bbqFlip(ref='bbqFlipSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.memoryroom"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                MemoryRoom(ref='MemoryRoomSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.sandtableroom"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                SandTableRoom(ref='SandTableRoomSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.catharsisroom"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                CatharsisRoom(ref='CatharsisRoomSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.laundry"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                LaundrySettings(ref='laundrySettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.Basketball"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                BasketballSettings(ref='basketballSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.icejourney"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                IceJourneySettings(ref='iceJourneySettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.cognitiveassessment"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                CognitiveAssessment(ref='CognitiveAssessmentSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.melodicpitch"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                MelodicPitch(ref='MelodicPitchSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.jellyfishswim"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                Jellyfish(ref='JellyfishSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.snowballfight"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                Snowballfight(ref='SnowballfightSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.mathboxing"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                mathBoxing(ref='mathBoxingSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.londontower"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                TowerOfLondonTest(ref='TowerOfLondonTestSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.acrophobia"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                AcrophobiaSettings(ref='AcrophobiaSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.painrelief"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                PainreliefSettings(ref='PainreliefSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.XZS.VREyeTrackingAttentionAssessmentSystem"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                EyeMovementSettings(ref='EyeMovementSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.forestfriends"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                ForestfriendsSettings(ref='ForestfriendsSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.fruitparty"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                FruitpartySettings(ref='FruitpartySettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.golfclub"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                GolfclubSettings(ref='GolfclubSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.puppydollhouse"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                PuppydollhouseSettings(ref='puppydollhouseSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.vrtennis"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                TennisSettings(ref='tennisSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.rabbitstore"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                RabbitStoreSettings(ref='rabbitStoreSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.brushteeth"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                BrushteethSettings(ref='brushteethSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.sailingadventure"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                SailingadventureSettings(ref='sailingadventureSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.hidenseek"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                HideNSeekSettings(
                  ref='hideNSeekSettings',
                  :packageName='getAppPackageName(appIndex, searchApps)',
                  :appCodename='getAppCodename(appIndex, searchApps)',
                )
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.mindratingscalesystem"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                ScaleSettings(
                  ref='scaleSettings',
                  :packageName='getAppPackageName(appIndex, searchApps)',
                  :appCodename='getAppCodename(appIndex, searchApps)',
                  :url='getScaleUrl(appIndex, searchApps)',
                )
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.spp"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                SppSettings(
                  ref='sppSettings',
                  :packageName='getAppPackageName(appIndex, searchApps)',
                  :appCodename='getAppCodename(appIndex, searchApps)',
                  :bgm='getAppBGMType(appIndex, searchApps)',
                  :voice='getAppVoiceType(appIndex, searchApps)',
                  :video='getAppVideoType(appIndex, searchApps)',
                  :type='getAppType(appIndex, searchApps)'
                )
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.shootinggallery2"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                ShootingGallery2Settings(
                  ref='shootinggallery2Settings',
                  :appCodename='getAppCodename(appIndex, searchApps)',
                )
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.adlsupermarket"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                ADLSupermarketSettings(ref='ADLSupermarketSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.fruittrain"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                FruittrainSettings(ref='fruittrainSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.musicbeat"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                MusicbeatSettings(ref='musicbeatSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.ComaArousal"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                ComaArousalSettings(ref='ComaArousalSettings')

          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.happypatting"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                HappypattingSettings(ref='happypattingSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.simulatedtown"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                SimulatedtownSettings(ref='SimulatedtownSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.mathboxing2024"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                MathBoxing2024Settings(ref='mathBoxing2024Settings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.postalsearch"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                PostalSearchSettings(ref='postalSearchSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.postalbliz"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                PostalBlitzSettings(ref='postalBlitzSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.postcard_mission"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                PostcardMissionSettings(ref='postcardMissionSettings')
          v-col(v-else-if='appIndex >= 0', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                CommonSettings(
                  ref='commonsettings',
                  :packageName='getAppPackageName(appIndex, searchApps)',
                  :appCodename='getAppCodename(appIndex, searchApps)',
                  :voice='getAppVoiceType(appIndex, searchApps)',
                  :video='getAppVideoType(appIndex, searchApps)',
                  :type='getAppType(appIndex, searchApps)'
                ) 

      v-card-actions
        v-spacer
        v-btn(text, @click='clear(); dialog = false; visible = false') {{ $t("dialogCancel") }}
        v-btn(color='primary', @click='joinQueue(); visible = false')
          | {{ $t("joinQueue") }}
        v-btn(color='primary', @click='start(); visible = false')
          v-icon(left, style='font-size: 22px') mdi-play-circle
          | {{ $t("dialogStart") }}
      v-snackbar(elevation='24', v-model='snackbar', :color='snackbarColor', timeout='3000') {{ notifMsg }}
        template(v-slot:action='{ attrs }')
          v-btn(text, v-bind='attrs', @click='snackbar = false') {{ $t("notifClose") }}
  Keyboard#keyboard(layout='normal', :input='input', v-if='visible && enableTouch', :cancel='cancel')
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { treatment, app } from '../../api'
import PatientMini from './../PatientMini'
import DeviceMini from './../DeviceMini'
import AppMini from './../AppMini'
import CommonSettings from './../CommonSettings'
import CITStoreSettings from './../CITStoreSettings'
import DragonBeatSettings from './../DragonBeatSettings'
import WheelMazeSettings from './../WheelMazeSettings'
import ShadowSettings from './../ShadowSettings'
import PingPongSettings from './../PingPongSettings'
import ShootingGallerySettings from './../ShootingGallerySettings'
import BreatheSettings from './../BreatheSettings'
import TaichiSettings from './../TaichiSettings'
import GrowSettings from './../GrowSettings'
import bbqFlip from './../bbqFlipSettings'
import MemoryRoom from './../MemoryRoomSettings'
import SandTableRoom from './../SandTableRoomSettings.vue'
import SppSettings from './../SppSettings.vue'
import CatharsisRoom from './../CatharsisRoomSettings.vue'
import CognitiveAssessment from './../CognitiveAssessmentSettings.vue'
import MelodicPitch from './../MelodicPitchSettings.vue'
import Jellyfish from './../JellyfishSettings.vue'
import Snowballfight from './../SnowballfightSettings.vue'
import mathBoxing from './../mathBoxingSettings.vue'
import TowerOfLondonTest from './../TowerOfLondonSettings.vue'
import AcrophobiaSettings from './../AcrophobiaSettings.vue'
import PainreliefSettings from './../PainreliefSettings.vue'
import EyeMovementSettings from './../EyeMovementSettings.vue'
import ForestfriendsSettings from './../ForestfriendsSettings.vue'
import FruitpartySettings from './../FruitpartySettings.vue'
import GolfclubSettings from './../GolfclubSettings.vue'
import PuppydollhouseSettings from './../PuppydollhouseSettings.vue'
import TennisSettings from './../TennisSettings.vue'
import RabbitStoreSettings from './../RabbitStoreSettings.vue'
import SailingadventureSettings from './../SailingadventureSettings.vue'
import HideNSeekSettings from './../HideNSeekSettings.vue'
import BrushteethSettings from './../BrushteethSettings.vue'
import ScaleSettings from './../ScaleSettings.vue'
import BasketballSettings from './../BasketballSettings.vue'
import IceJourneySettings from './../IceJourneySettings.vue'
import LaundrySettings from './../LaundrySettings.vue'
import ShootingGallery2Settings from '../ShootingGallery2Settings.vue'
import ADLSupermarketSettings from '../ADLSupermarketSettings.vue'
import FruittrainSettings from '../FruittrainSettings.vue'
import MathBoxing2024Settings from '../MathBoxing2024Settings.vue'
import MusicbeatSettings from '../MusicbeatSettings.vue'
import HappypattingSettings from '../HappypattingSettings.vue'
import Keyboard from 'vue-keyboard-zh'
import ComaArousalSettings from '../ComaArousalSettings.vue'
import SimulatedtownSettings from '../SimulatedtownSettings.vue'


export default {
  props: ['monitor'],
  data: () => ({
    dialog: false,

    searchPatients: undefined,
    searchDevices: undefined,
    searchApps: undefined,

    patientIndex: undefined,
    deviceIndex: undefined,
    appIndex: undefined,

    prePatientId: undefined,

    newTreatment: {
      active: false,
      start: true
    },

    devicesId: '',
    appsId: '',

    devicesItem: {},
    appsItem: {},

    snackbarColor: '',
    notifMsg: '',
    snackbar: false,

    pairingList: [],
    pairingRes: [],
    groupAppsId: [],

    userColor: 'listColor',
    deviceColor: '',
    appColor: '',

    hideApps: [],

    data: 0,

    isOpen: false,
    input: '',
    visible: false,

    eyePatientId: undefined,
    golfclubPatientId: undefined,
    golfclubAppId: undefined,
    tennisPatientId: undefined
  }),

  async mounted() {
    this.resize()
    window.addEventListener('resize', this.resize, false)

    const logToken = JSON.parse(window.localStorage.getItem('vuex'))
    const adminId = logToken.users.userId
    const role = logToken.users.role
    const userId = parseInt(role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId)
    const obj1 = {
      adminId: role == 'admin' ? adminId : 1,
      userId: userId,
      role: role,
      isShow: 1,
      type: '0,1,2,3,4,5,6,7,8'
    }
    const result = await app.index(obj1)
    this.hideApps = result.data
  },

  watch: {
    patientIndex: function (val) {
      if (this.$refs[`patients/${val}`]) {
        const el = this.$refs[`patients/${val}`][0].$el
        el.scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth'
        })
      }
    },

    deviceIndex: function (val) {
      if (this.$refs[`devices/${val}`]) {
        const el = this.$refs[`devices/${val}`][0].$el
        el.scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth'
        })
      }
    },

    appIndex: function (val) {
      if (this.$refs[`apps/${val}`]) {
        const el = this.$refs[`apps/${val}`][0].$el
        el.scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth'
        })
      }
    }
  },

  methods: {
    getScaleUrl(appIndex, searchApps) {
      if (!(appIndex >= 0)) return null
      return "http://47.110.41.40:8001/user/evaluations/scales/" + this.apps(searchApps)[appIndex].codename.split("/")[1]
    },
    getAppBGMType(appIndex, searchApps) {
      if (!(appIndex >= 0)) return null
      return this.apps(searchApps)[appIndex].bgm
    },
    keyboardShowClick(e) {
      if (!this.isOpen) {
        this.input = e.target
        if (!this.visible) this.visible = true
      } else {
        this.visible = false
        this.input = ''
      }
    },
    cancel() {
      this.visible = false
    },
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    devicesClick(item, index) {
      if (this.pairingList.length == 0) {
        this.devicesItem = item
        this.deviceIndex = index
        this.devicesId = item.model.ID
        this.deviceColor = 'listColor'
      } else {
        this.notifMsg = this.$t('aSetOnlyTheSameDevice')
        this.snackbarColor = 'warning'
        this.snackbar = true
      }
    },
    appsClick(item, index) {
      if (this.prePatientId) {
        this.appIndex = index
        this.appsItem = item
        this.appColor = 'listColor'
      }
    },
    joinPublic() {
      this.pairingList.push({ patients: this.patients()[0], devices: this.devicesItem, apps: this.appsItem })
      this.appColor = ''
      switch (this.appsItem.packageName) {
        case 'com.xzs.citstorerelease':
          this.newTreatment.settings = this.$refs.citstoresettings.getSettings()
          break
        case 'com.xzs.dragonbeat':
          this.newTreatment.settings = this.$refs.dragonbeatsettings.getSettings()
          break
        case 'com.xzs.WheelMaze':
          this.newTreatment.settings = this.$refs.wheelmazesettings.getSettings()
          break
        case 'com.xzs.shadow':
          this.newTreatment.settings = this.$refs.shadowsettings.getSettings()
          break
        case 'com.xzs.pingpongsimulator':
          this.newTreatment.settings = this.$refs.pingpongsettings.getSettings()
          break
        case 'com.xzs.shootinggallery':
          this.newTreatment.settings = this.$refs.shootinggallerysettings.getSettings()
          break
        case 'com.xzs.breathe':
          this.newTreatment.settings = this.$refs.breathesettings.getSettings()
          break
        case 'com.xzs.taichi':
          this.newTreatment.settings = this.$refs.taichisettings.getSettings()
          break
        case 'com.xzs.grow':
          this.newTreatment.settings = this.$refs.growsettings.getSettings()
          break
        case 'com.xzs.bbqflip':
          this.newTreatment.settings = this.$refs.bbqFlipSettings.getSettings()
          break
        case 'com.xzs.memoryroom':
          this.newTreatment.settings = this.$refs.MemoryRoomSettings.getSettings()
          break
        case 'com.xzs.sandtableroom':
          this.newTreatment.settings = this.$refs.SandTableRoomSettings.getSettings()
          break
        case 'com.xzs.catharsisroom':
          this.newTreatment.settings = this.$refs.CatharsisRoomSettings.getSettings()
          break
        case 'com.xzs.cognitiveassessment':
          this.newTreatment.settings = this.$refs.CognitiveAssessmentSettings.getSettings()
          break
        case 'com.xzs.melodicpitch':
          this.newTreatment.settings = this.$refs.MelodicPitchSettings.getSettings()
          break
        case 'com.xzs.jellyfishswim':
          this.newTreatment.settings = this.$refs.JellyfishSettings.getSettings()
          break
        case 'com.xzs.snowballfight':
          this.newTreatment.settings = this.$refs.SnowballfightSettings.getSettings()
          break
        case 'com.xzs.mathboxing':
          this.newTreatment.settings = this.$refs.mathBoxingSettings.getSettings()
          break
        case 'com.xzs.londontower':
          this.newTreatment.settings = this.$refs.TowerOfLondonTestSettings.getSettings()
          break
        case 'com.xzs.acrophobia':
          this.newTreatment.settings = this.$refs.AcrophobiaSettings.getSettings()
          break
        case 'com.xzs.painrelief':
          this.newTreatment.settings = this.$refs.PainreliefSettings.getSettings()
          break
        case 'com.XZS.VREyeTrackingAttentionAssessmentSystem':
          this.$refs.EyeMovementSettings.patientId = this.prePatientId
          this.newTreatment.settings = this.$refs.EyeMovementSettings.getSettings()
          break
        case 'com.xzs.forestfriends':
          this.newTreatment.settings = this.$refs.ForestfriendsSettings.getSettings()
          break
        case 'com.xzs.fruitparty':
          this.newTreatment.settings = this.$refs.FruitpartySettings.getSettings()
          break
        case 'com.xzs.golfclub':
          this.$refs.GolfclubSettings.patientId = this.prePatientId
          this.$refs.GolfclubSettings.appId = this.appsItem.model.ID
          this.newTreatment.settings = this.$refs.GolfclubSettings.getSettings()
          break
        case 'com.xzs.puppydollhouse':
          this.newTreatment.settings = this.$refs.puppydollhouseSettings.getSettings()
          break
        case 'com.xzs.vrtennis':
          this.$refs.tennisSettings.patientId = this.prePatientId
          this.$refs.tennisSettings.appId = this.appsItem.model.ID
          this.newTreatment.settings = this.$refs.tennisSettings.getSettings()
          break
        case 'com.xzs.rabbitstore':
          this.newTreatment.settings = this.$refs.rabbitStoreSettings.getSettings()
          break
        case 'com.xzs.sailingadventure':
          this.newTreatment.settings = this.$refs.sailingadventureSettings.getSettings()
          break
        case 'com.xzs.spp':
          this.newTreatment.settings = this.$refs.sppSettings.getSettings()
          break
        case 'com.xzs.hidenseek':
          this.newTreatment.settings = this.$refs.hideNSeekSettings.getSettings()
          break
        case 'com.xzs.brushteeth':
          this.newTreatment.settings = this.$refs.brushteethSettings.getSettings()
          break
        case 'com.xzs.mindratingscalesystem':
          this.newTreatment.settings = this.$refs.scaleSettings.getSettings()
          break
        case 'com.xzs.laundry':
          this.newTreatment.settings = this.$refs.laundrySettings.getSettings()
          break
        case 'com.xzs.Basketball':
          this.newTreatment.settings = this.$refs.basketballSettings.getSettings()
          break
        case 'com.xzs.shootinggallery2':
          this.newTreatment.settings = this.$refs.shootinggallery2Settings.getSettings()
          break
        case 'com.xzs.adlsupermarket':
          this.newTreatment.settings = this.$refs.ADLSupermarketSettings.getSettings()
          break
        case 'com.xzs.fruittrain':
          this.newTreatment.settings = this.$refs.fruittrainSettings.getSettings()
          break
        case 'com.xzs.musicbeat':
          this.newTreatment.settings = this.$refs.musicbeatSettings.getSettings()
          break
        case 'com.xzs.ComaArousal':
          this.newTreatment.settings = this.$refs.ComaArousalSettings.getSettings()
          break
        case 'com.xzs.happypatting':
          this.newTreatment.settings = this.$refs.happypattingSettings.getSettings()
          break
        case 'com.xzs.simulatedtown':
          this.newTreatment.settings = this.$refs.SimulatedtownSettings.getSettings()
          break
        case 'com.xzs.mathboxing2024':
          this.this.newTreatment.settings = this.$refs.mathBoxing2024Settings.getSettings()
          break
        default:
          this.newTreatment.settings = this.$refs.commonsettings.getSettings()
          break
      }
      this.groupAppsId.push(this.appsItem.model.ID)
      this.pairingRes.push(JSON.parse(this.newTreatment.settings))
      this.apps(this.searchApps).splice(this.appIndex, 1)
    },
    joinQueue() {
      if (this.devicesItem.name == undefined || this.appsItem.name == undefined) {
        this.notifMsg = this.$t('pleaseSelectDeviceAndSoftware')
        this.snackbarColor = 'warning'
        this.snackbar = true
      } else {
        if (this.pairingList.length == 0) {
          this.joinPublic()
        } else {
          var isSame = false
          this.pairingList.forEach(x => {
            if (x.apps.model.ID == this.appsItem.model.ID) {
              this.notifMsg = this.$t('cannotStartSameModuleTraining')
              this.snackbarColor = 'warning'
              this.snackbar = true
              isSame = true
            } else {
              isSame = false
            }
          })
          if (!isSame) {
            this.joinPublic()
          }
        }
      }
    },
    pairingClose(item, index) {
      this.pairingList.splice(index, 1)
      this.apps(this.searchApps).unshift(item.apps)
      this.groupAppsId.splice(index, 1)
    },
    clear() {
      this.prePatientId = undefined
      this.appIndex = undefined
      this.deviceColor = ''
      this.appColor = ''
      this.pairingList.forEach(x => {
        this.apps(this.searchApps).unshift(x.apps)
      })
      this.pairingList = []
    },

    async start() {
      const date = Date.now()
      const timeStamp = date - 8 * 60 * 60 * 1000
      const time = new Date(timeStamp)
      const year = time.getFullYear()
      const month = time.getMonth() + 1
      const day = time.getDate()
      const hour = time.getHours()
      const minutes = time.getMinutes()
      const seconds = time.getSeconds()
      this.newTreatment.applicationStartTime =
        year +
        '-' +
        (month < 10 ? '0' + month : month) +
        '-' +
        (day < 10 ? '0' + day : day) +
        'T' +
        (hour < 10 ? '0' + hour : hour) +
        ':' +
        (minutes < 10 ? '0' + minutes : minutes) +
        ':' +
        (seconds < 10 ? '0' + seconds : seconds) +
        'Z'
      if (this.pairingList.length > 0) {
        const logToken = JSON.parse(window.localStorage.getItem('vuex'))
        const adminId = logToken.users.userId
        const role = logToken.users.role
        const userId = parseInt(
          role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
        )
        var obj = {
          active: this.newTreatment.active,
          patientId: this.prePatientId,
          deviceId: this.devicesId,
          appId: this.groupAppsId.join(','),
          userId: userId,
          applicationStartTime: this.newTreatment.applicationStartTime,
          start: this.newTreatment.start,
          settings: JSON.stringify(this.pairingRes)
        }
        await treatment.groupStart(obj)
        this.$router.push('/monitors')
      } else {
        this.notifMsg = this.$t('cannotStartSameModuleTraining')
        this.snackbarColor = 'warning'
        this.snackbar = true
      }
    },

    patients(query) {
      if (this.prePatientId || this.prePatientDetailId) {
        const index = this.patientsAvail.findIndex(x => x.model.ID == this.prePatientId || this.prePatientDetailId)
        this.patientIndex = this.prePatientId
        return [this.patientsAvail[index]]
      }
      if (!query) return this.patientsAvail
      else {
        const filtered = this.filterByValue(this.patientsAvail, query)
        return filtered
      }
    },

    devices(query) {
      if (!query) return this.devicesAvail
      else {
        const filtered = this.filterByValue(this.devicesAvail, query)
        return filtered
      }
    },

    apps(query) {
      const treatmentList = this.hideApps
      if (!query) return treatmentList
      else {
        const filtered = this.filterByValue(treatmentList, query)
        return filtered
      }
    },

    filterByValue(arr, term) {
      return arr.filter(obj => {
        return Object.keys(obj).reduce((acc, curr) => {
          return acc || String(obj[curr]).toLowerCase().includes(term)
        }, false)
      })
    },

    getAppPackageName(appIndex, searchApps) {
      if (!(appIndex >= 0)) return null
      return this.apps(searchApps)[appIndex].packageName
    },

    getAppVoiceType(appIndex, searchApps) {
      if (!(appIndex >= 0)) return null
      return this.apps(searchApps)[appIndex].voiceType
    },

    getAppVideoType(appIndex, searchApps) {
      if (!(appIndex >= 0)) return null
      return this.apps(searchApps)[appIndex].videoType
    },

    getAppType(appIndex, searchApps) {
      if (!(appIndex >= 0)) return null
      return this.apps(searchApps)[appIndex].type
    },

    getAppCodename(appIndex, searchApps) {
      if (!(appIndex >= 0)) return null
      return this.apps(searchApps)[appIndex].codename
    }
  },

  components: {
    PatientMini,
    DeviceMini,
    AppMini,
    SimulatedtownSettings,
    MathBoxing2024Settings,
    ComaArousalSettings,
    MusicbeatSettings,
    HappypattingSettings,
    FruittrainSettings,
    ADLSupermarketSettings,
    ShootingGallery2Settings,
    LaundrySettings,
    IceJourneySettings,
    BasketballSettings,
    ScaleSettings,
    HideNSeekSettings,
    BrushteethSettings,
    CommonSettings,
    CITStoreSettings,
    DragonBeatSettings,
    WheelMazeSettings,
    ShadowSettings,
    PingPongSettings,
    ShootingGallerySettings,
    BreatheSettings,
    TaichiSettings,
    GrowSettings,
    bbqFlip,
    MemoryRoom,
    SandTableRoom,
    CatharsisRoom,
    CognitiveAssessment,
    MelodicPitch,
    Jellyfish,
    Snowballfight,
    mathBoxing,
    TowerOfLondonTest,
    AcrophobiaSettings,
    Keyboard,
    PainreliefSettings,
    EyeMovementSettings,
    ForestfriendsSettings,
    FruitpartySettings,
    GolfclubSettings,
    PuppydollhouseSettings,
    TennisSettings,
    RabbitStoreSettings,
    SailingadventureSettings,
    SppSettings
  },

  computed: {
    ...mapGetters({
      devicesAvail: 'devices/devicesAvail',
      patientsAvail: 'patients/patientsAvail',
      typeUsername: 'setting/userName',
      enableTouch: 'setting/enableTouch'
    }),

    ...mapState({
      appsAvail: state => state.apps.items
    })
  }
}
</script>

<style lang="sass" scoped>
.scrollable
  overflow-y: auto
.pairingBtn
  width: 347px
  height: 56px
  border-radius: 2px
  line-height: 56px
  display: flex
  justify-content: center
  color: #1976D2
  font-size: 14px
  position: relative
  background: #E4EFFA
.icon-close
  position: absolute
  top: -8px
  right: -9px
  height: 20px
  width: 20px
  text-align: center
  background: #777777
  border-radius: 50%
  color: #fff
  cursor: pointer
.icon-close:before
  position: relative
  top: -18px
.hide
  display: none
.show
  display: block
.listColor
  background: #E4EFFA
  color: #1976D2 !important
.appList
  display: flex
  flex-wrap: wrap

#keyboard
  position: fixed
  top: auto !important
  bottom: 0
  z-index: 999
  padding: 14px 21px

ul
  height: 298px
  overflow-y: scroll
  padding-left: 0
li
  list-style: none
  height: 56px
  box-sizing: border-box
  cursor: pointer
  padding-left: 40px
  padding-top: 20px

::v-deep .apps.v-list-item--active
  background-color: white !important

::v-deep .mDialog.v-dialog:not(.v-dialog--fullscreen)
  max-height: 56%

::v-deep .cDialog.v-dialog:not(.v-dialog--fullscreen)
  max-height: 100%
</style>
