<template lang="pug">
div
  v-toolbar.mb-6(flat)
    v-btn.ma-2(large, color='orange', dark, @click='goBack()')
      v-icon(dark, left) mdi-arrow-left
      | {{ $t("return")}}
    v-divider.mx-4(inset, vertical)
    v-icon.mr-1 mdi-file-chart
    v-toolbar-title {{ $t("report")}}
    v-spacer
    v-tooltip(bottom)
      template(v-slot:activator='{ on, attrs }')
        v-btn.primary.mr-2(fab, v-bind='attrs', v-on='on', @click='printMMSE()')
          v-icon mdi-printer
      span {{ $t("actionPrintTooltipon") }}
  .report-title(style='text-align: center; font-size: 26px; font-family: Microsoft; font-weight: 600') {{ parseInt(this.$route.query.assessmentId) === 1 ? $t("MMSEScaleReport") : $t("montrealCognitiveAssessmentReport") }}
  v-col(xl='12', lg='12', md='12')
    v-card.mx-auto(hover)
      v-card-text
        .patient_detail(style='display: flex; justify-content: space-between; flex-wrap: wrap; align-content: flex-start')
          div
            v-icon mdi-account
            span.name {{ $t("name") }}：{{ patientsList.name }}
          div
            v-icon mdi-clipboard-account
            span.name {{ $t("hospitalNumber") }}：{{ patientsList.hospitalNumber }}
          div
            v-icon mdi-calendar
            span.name {{ $t("age")}}：{{ Math.floor((new Date() - new Date(patientsList.birthday).getTime()) / 3.15576e10) >= 0 ? Math.floor((new Date() - new Date(patientsList.birthday).getTime()) / 3.15576e10) : "" }}
          div
            v-icon mdi-gender-male-female
            span.name {{ $t("gender")}}：{{ patientsList.gender }}
          div
            v-icon mdi-human-male-female
            span.name {{ $t("maritalStatus") }}：{{ patientsList.maritalStatus }}
          div
            v-icon mdi-school
            span.name {{ $t("patientEducation") }}：{{ parseInt(this.$route.query.assessmentId) === 1 ? patientsList.education : education }}
  v-col(xl='12', lg='12', md='12')
    v-card.mx-auto(hover)
      v-card-title {{ $t("recordScoreGraph") }}
      v-card-text
        div(style='display: flex', v-if='parseInt(this.$route.query.assessmentId) === 1')
          .score-left
            .num(:style='{ color: color }') {{ num }}
            .left-name {{ tipText }}
          .score-right
            div(style='margin-bottom: 40px')
              span.green
              span.section {{ $t("27to30points") }}
            div(style='margin-bottom: 40px')
              span.darkBlue 
              span.section {{ $t("21to26points") }}
            div(style='margin-bottom: 40px')
              span.orange
              span.section {{ $t("10to20points") }}
            div(style='margin-bottom: 40px')
              span.red 
              span.section {{ $t("0to9points") }}
              v-col(xl='12', lg='12', md='12')
        div(v-else, style='text-align: center')
          .num(:style='{ color: color }') {{ num }}
          .left-name {{ tipText }}
          .bottom {{ $t("fullScores") }}
  v-col(xl='12', lg='12', md='12')
    v-card.mx-auto(hover)
      v-card-title {{ $t("scoreAnalysis") }}
      v-card-text
        table(
          v-if='parseInt(this.$route.query.assessmentId) == 1',
          style='color: black; margin-left: 20px; border-collapse: collapse',
          width='98%'
        )
          tr(style='background: rgba(215, 237, 253, 0.5); height: 40px')
            td(width='500', style='padding-left: 40px') {{ $t("checkItem") }}
            td(width='800', align='center') {{ $t("scoringItems") }}
            td(width='500', align='center') {{ $t("scoreCorrectly") }}
          tr(v-for='(item, index) in tableData', :key='index', :class='(index + 1) % 2 == 0 ? "blue1" : "white1"')
            td(style='padding-left: 40px') {{ item.name }}
            td(align='center') {{ item.question }}
            td(align='center') {{ item.answer }}
        table(v-else, style='color: black; margin-left: 20px; border-collapse: collapse', width='98%')
          tr(style='background: rgba(215, 237, 253, 0.5); height: 40px')
            td(width='80.3%', style='padding-left: 40px') {{ $t("scoringItems") }}
            td(width='300', align='center') {{ $t("scoreCorrectly") }}
          tr(v-for='(item, index) in tableData', :key='index', :class='(index + 1) % 2 == 0 ? "blue1" : "white1"')
            td(style='padding-left: 40px') {{ item.question[index] }}
            td(align='center') {{ item.answer }}
  LoadingPage(v-if='fullscreenLoading')
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { gauge } from '../../api'
import LoadingPage from '../../components/common/LoadingPage.vue'
export default {
  data: () => ({
    patientsList: {
      name: '',
      hospitalNumber: '',
      birthday: '',
      gender: '',
      maritalStatus: '',
      education: ''
    },
    id: 0,
    num: 0,
    color: '',
    tipText: '',
    tableData: [],
    education: '',
    isOpen: false,
    mmseScore: 0,
    mocaScore: 0,
    fullscreenLoading: false,
    mocaGrade: [
      {
        grade: this.$t('26scores'),
        name: this.$t('normalCognitiveFunction')
      },
      {
        grade: this.$t('scores26'),
        name: this.$t('cognitiveDysfunction')
      }
    ],
    mmseGrade: [
      {
        grade: this.$t('scores2730'),
        name: this.$t('normalCognitiveFunction')
      },
      {
        grade: this.$t('scores2126'),
        name: this.$t('mildCognitiveImpairment')
      },
      {
        grade: this.$t('scores1020'),
        name: this.$t('moderateCognitiveImpairment')
      },
      {
        grade: this.$t('scores09'),
        name: this.$t('severeCognitiveImpairment')
      }
    ]
  }),

  watch: {
    patients: function (val) {
      val.forEach(item => {
        if (item.model.ID === this.id) {
          this.patientsList = item
        }
      })
    }
  },

  components: {
    LoadingPage
  },

  async mounted() {
    this.resize()
    window.addEventListener('resize', this.resize, false)
    const result = await gauge.getResult(parseInt(this.$route.params.id))
    this.id = result.data.patientId
    if (parseInt(this.$route.query.assessmentId) == 1) {
      this.num = result.data.score
      this.tableData = JSON.parse(result.data.data)
      this.mmseScore = this.num
    } else {
      const data = JSON.parse(result.data.data)
      this.tableData = data.slice(0, data.length - 1)
      this.education = data.slice(data.length - 1, data.length)[0]
      if (this.education == this.$t('university') || this.education == this.$t('master') || this.education == this.$t('PhD')) {
        this.num = result.data.score
      } else {
        this.num = result.data.score < 30 ? result.data.score + 1 : result.data.score
      }
      this.mocaScore = this.num
    }
    if (parseInt(this.$route.query.assessmentId) === 1) {
      if (this.num >= 27 && this.num <= 30) {
        this.color = '#4BD549'
        this.tipText = this.$t('normalCognitiveFunction')
      } else if (this.num >= 21 && this.num <= 26) {
        this.color = '#FAD984'
        this.tipText = this.$t('mildCognitiveImpairment')
      } else if (this.num >= 10 && this.num <= 20) {
        this.color = '#FF9800'
        this.tipText = this.$t('moderateCognitiveImpairment')
      } else {
        this.color = '#E02A1F'
        this.tipText = this.$t('severeCognitiveImpairment')
      }
    } else {
      if (this.num >= 26) {
        this.color = '#4BD549'
        this.tipText = this.$t('normalCognitiveFunction')
      } else {
        this.color = '#E02A1F'
        this.tipText = this.$t('cognitiveDysfunction')
      }
    }
    this.patients.forEach(item => {
      if (item.model.ID === this.id) {
        this.patientsList = item
      }
    })
  },

  methods: {
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    goBack() {
      if (parseInt(this.$route.query.type) === 1) {
        this.$router.push('/gauge')
      } else {
        this.$router.push('/record')
      }
    }
  },

  computed: {
    ...mapState({
      patients: state => state.patients.items
    }),
    ...mapGetters({
      isAdmin: 'users/isAdmin',
      patientsWA: 'patients/patientsWA',
      institution: 'setting/institution',
      reportName: 'setting/reportName',
      treatmentsByPatientId: 'treatments/treatmentsByPatientId',
      partition: 'setting/enabledPartition',
      typeUsername: 'setting/userName',
      treatments: 'treatments/treatmentsWADP',
      enableScoreOverwrite: 'setting/enableScoreOverwrite',
      displayTime: 'setting/displayTime',
      treatmentTime: 'setting/treatmentTime',
      logo: 'setting/logo',
      bedNumberName: 'setting/bedNumberName',
      numberName: 'setting/numberName'
    })
  }
}
</script>

<style lang="scss" scoped>
.report-title {
  margin-bottom: 20px;
}
.mb-6 {
  height: 44px !important;
}
.name {
  color: black;
  font-size: 16px;
  margin-left: 5px;
  position: relative;
  top: 1px;
}
.score-left {
  width: 60%;
  height: 240px;
  text-align: center;
  margin-top: 40px;
  .num {
    color: #4bd549;
    font-size: 150px;
  }
  .left-name {
    color: black;
    font-size: 20px;
    margin-top: 110px;
    font-weight: bold;
  }
}

.num {
  color: #4bd549;
  font-size: 150px;
  margin-top: 50px;
}

.left-name {
  color: black;
  font-size: 20px;
  margin-top: 110px;
  font-weight: bold;
}

.bottom {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  margin-top: 20px;
}

.score-right {
  margin-top: 20px;
  .red,
  .orange,
  .darkBlue,
  .blue,
  .green {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: #4bd549;
    border-radius: 50%;
  }

  .orange {
    background: #ed6f54;
  }
  .darkBlue {
    background: #fad984;
  }
  .section {
    font-size: 16px;
    color: black;
    margin-left: 10px;
    font-weight: bold;
  }
}
.blue1 {
  background: rgba(215, 237, 253, 0.5);
  height: 40px;
}
.white1 {
  height: 60px;
}

.patient_detail {
  display: flex;
  flex-wrap: wrap;
}
</style>