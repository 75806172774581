<template lang="pug">
div
  v-row
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-monitor-eye
        | {{ $t("monitor") }}
        v-spacer
        v-switch(inset, v-model='monitor')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-circle-multiple-outline
        | {{ $t("warmUp") }}
        v-spacer
        v-switch(inset, v-model='warmUp')
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-circle-multiple-outline
        | {{ $t("relax") }}
        v-spacer
        v-switch(inset, v-model='relax')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-timer-outline
        | {{ $t("difficulty") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='difficulty',
          :items='difficulties',
          item-text='text',
          item-value='value',
          @change='getDifficultyVal'
        )
</template>

<script>
export default {
  data: vm => ({
    monitor: true,
    relax: true,
    warmUp: true,
    difficulty: 0,
    difficulties: [
      { text: vm.$t('easy'), value: 0 },
      { text: vm.$t('normal'), value: 1 },
      { text: vm.$t('hard'), value: 2 }
    ]
  }),

  methods: {
    getDifficultyVal(value) {
      this.difficulty = value
    },
    getSettings() {
      return JSON.stringify({
        monitor: this.monitor,
        resheng: this.warmUp,
        fangsong: this.relax,
        //timeLimit: -1,
        nandumodel: this.difficulty
      })
    }
  }
}
</script>

<style lang="sass">
.setting-row
  height: 50px
</style>

