import Vue from 'vue'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import dayjs from 'dayjs'
import VueCookies from 'vue-cookies'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import { VUE_APP_SOCKET_ENDPOINT } from '@/app.config'
import './assets/iconfont/iconfont.css'
import VueSmoothScroll from 'vue2-smooth-scroll'
import { mySetInterval } from './lib/intervalUtils'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import VueI18n from 'vue-i18n'
import ElementLocale from 'element-ui/lib/locale'
import printPDF from './lib/treatment-print'
// import patientPDF from './lib/patient-print'


Vue.use(VueVirtualScroller)
Vue.use(VueI18n)


// 将 mySetInterval 注册为 Vue 的原型方法
Vue.prototype.$mySetInterval = mySetInterval

Vue.prototype.VueSmoothScroll = VueSmoothScroll
Vue.prototype.dayjs = dayjs
Vue.config.productionTip = false
Vue.use(printPDF)
// Vue.use(patientPDF)
var io = undefined
if (JSON.parse(window.localStorage.getItem('vuex')) != null) {
  const logToken = JSON.parse(window.localStorage.getItem('vuex'))
  const adminId = logToken.users.userId
  const role = logToken.users.role
  const userId = parseInt(role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId)
  io = SocketIO(VUE_APP_SOCKET_ENDPOINT, { query: `uid=${userId}` })
} else {
  io = SocketIO(VUE_APP_SOCKET_ENDPOINT)
}

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: io,
    vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
    },
    options: { useConnectionNamespace: true }
  })
)

Vue.use(VueCookies)

Vue.use(ElementUI)

new Vue({
  el: '#app',
  store,
  vuetify,
  router,
  i18n,
  render: h => h(App)
})
ElementLocale.i18n((key, value) => i18n.t(key, value))






