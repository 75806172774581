<template lang='pug'>
v-app-bar(app, clipped-left)
  v-toolbar-title XZS Medical Platform
  v-spacer
  v-btn(text) {{ username }}
  v-divider.mx-4(inset, vertical)
  v-btn(icon, @click='logout()')
    v-icon mdi-logout
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data: () => ({
    logoImage: require('@/assets/logo.png')
  }),

  methods: {
    ...mapActions({
      logout: 'users/logout'
    })
  },

  computed: {
    ...mapState({
      username: state => state.users.username
    })
  }
}
</script>

<style lang="sass" scoped>
</style>
