<template lang="pug">
div
  v-row
    v-col(xl='12', lg='12', md='12')
      v-card.mx-auto(hover)
        v-card-title
          v-icon(left) mdi-waveform
          | {{ $t("eegGraph") }}
        v-card-text
          apexchart#rawChart(ref='rawChart', type='line', height='200', :options='rawChartOptions', :series='rawSeries')
  v-row(v-if='!isOpen')
    v-col(xl='12', lg='12', md='12')
      v-card.mx-auto(hover)
        v-card-title
          v-icon(left) mdi-chart-pie
          | {{ $t("eegResultsGraph") }}
        v-card-text
          apexchart#graphChart(ref='graphChart', height='300', type='radialBar', :options='graphChartOptions', :series='graphSeries')
          span {{ `${$t("dataQuality")}: ${dataQuality == 0 ? $t("low") : dataQuality == 1 ? $t("mid") : dataQuality == 2 ? $t("high") : $t("error")}` }}

  v-row(v-if='!isOpen')
    v-col(xl='6', lg='6', md='12')
      v-row
        v-col(xl='3', lg='3', md='12')
          v-card.mx-auto(hover)
            v-card-title
              v-icon(left) mdi-all-inclusive
              | {{ $t("eegPolarization") }}
            v-card-text
              apexchart#polarizationChart(
                ref='polarizationChart',
                height='300',
                type='radialBar',
                :options='polarizationChartOptions',
                :series='polarizationSeries'
              )
    v-col(xl='6', lg='6', md='12')
      v-card.mx-auto(hover)
        v-card-title
          v-icon(left) mdi-graphql
          | {{ $t("eegResult") }}
        v-divider
        v-card-text
          .d-flex.flex-no-wrap.justify-space-between.my-4
            v-icon.mr-1 mdi-all-inclusive
            span {{ $t("eegPolarization") }}
            v-spacer
            span {{ parseInt(this.polarizationProgress) + " %" }}
          .d-flex.flex-no-wrap.justify-space-between.my-4
            v-icon.mr-1 mdi-quality-high
            span {{ $t("dataQuality") }}
            v-spacer
            span {{ this.dataQuality == 0 ? $t("low") : this.dataQuality == 1 ? $t("mid") : this.dataQuality == 2 ? $t("high") : $t("error") }}
          .d-flex.flex-no-wrap.justify-space-between.my-4
            v-icon.mr-1 mdi-atom
            span {{ $t("concentration") }}
            v-spacer
            span {{ parseInt(this.concentration) + " %" }}
          .d-flex.flex-no-wrap.justify-space-between.my-4
            v-icon.mr-1 mdi-guitar-acoustic
            span {{ $t("relax") }}
            v-spacer
            span {{ parseInt(this.relax) + " %" }}

  v-col(xl='12', lg='12', md='12', v-if='isOpen')
    v-row
      v-col(xl='3', lg='3', md='12')
        v-card.mx-auto(hover)
          v-card-title
            v-icon(left) mdi-all-inclusive
            | {{ $t("eegPolarization") }}
          v-card-text
            apexchart#polarizationChart(
              ref='polarizationChart',
              height='300',
              type='radialBar',
              :options='polarizationChartOptions',
              :series='polarizationSeries'
            )

      v-col(xl='6', lg='6', md='12', v-if='isOpen')
        v-card.mx-auto(hover)
          v-card-title
            v-icon(left) mdi-chart-pie
            | {{ $t("eegResultsGraph") }}
          v-card-text
            apexchart#graphChart(ref='graphChart', height='300', type='radialBar', :options='graphChartOptions', :series='graphSeries')
            span {{ `${$t("dataQuality")}: ${dataQuality == 0 ? $t("low") : dataQuality == 1 ? $t("mid") : dataQuality == 2 ? $t("high") : $t("error")}` }}
      v-col(xl='3', lg='3', md='12', v-if='isOpen')
        v-card.mx-auto(hover)
          v-card-title
            v-icon(left) mdi-graphql
            | {{ $t("eegResult") }}
          v-divider
          v-card-text
            .d-flex.flex-no-wrap.justify-space-between.my-4
              v-icon.mr-1 mdi-all-inclusive
              span {{ $t("eegPolarization") }}
              v-spacer
              span {{ parseInt(this.polarizationProgress) + " %" }}
            .d-flex.flex-no-wrap.justify-space-between.my-4
              v-icon.mr-1 mdi-quality-high
              span {{ $t("dataQuality") }}
              v-spacer
              span {{ this.dataQuality == 0 ? $t("low") : this.dataQuality == 1 ? $t("mid") : this.dataQuality == 2 ? $t("high") : $t("error") }}
            .d-flex.flex-no-wrap.justify-space-between.my-4
              v-icon.mr-1 mdi-atom
              span {{ $t("concentration") }}
              v-spacer
              span {{ parseInt(this.concentration) + " %" }}
            .d-flex.flex-no-wrap.justify-space-between.my-4
              v-icon.mr-1 mdi-guitar-acoustic
              span {{ $t("relax") }}
              v-spacer
              span {{ parseInt(this.relax) + " %" }}
</template>

<script>
import { io } from 'socket.io-client'
import { VUE_APP_EEG_SOCKET_ENDPOINT } from '@/app.config'
import VueApexCharts from 'vue-apexcharts'

export default {
  props: {
    sn: {
      type: String,
      required: true
    }
  },

  data: vm => ({
    socket: undefined,
    eegRawDataArray: [],
    dataQuality: -1,
    polarizationProgress: 0,
    concentration: 0,
    relax: 0,
    isOpen: false,

    rawChartOptions: {
      chart: {
        type: 'line',
        toolbar: false
      },
      colors: ['#546E7A'],
      stroke: {
        curve: 'smooth',
        width: 1
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        opacity: 1
      },
      markers: {
        size: 0
      },
      xaxis: {
        labels: {
          show: false
        }
      }
    },

    polarizationChartOptions: {
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ['#ABE5A1'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: 'round'
      },
      labels: [vm.$t('progress')]
    },

    graphChartOptions: {
      labels: [vm.$t('concentration'), vm.$t('relax')],
      legend: {
        show: true
      }
    }
  }),

  async mounted() {
    this.resize()
    window.addEventListener('resize', this.resize, false)

    await this.$nextTick()
    this.socket = io.connect(VUE_APP_EEG_SOCKET_ENDPOINT)

    this.socket.on('connect', () => {
      this.socket.emit('RegViewer', this.sn)
    })

    this.socket.on(this.sn, payload => {
      if (payload.type == 'raw') {
        const typedArray = new Int8Array(payload.data)
        const eegRawData = [...typedArray]
        eegRawData.splice(0, 2)
        this.eegRawDataArray = eegRawData
      }
      if (payload.type == 'attention') {
        this.dataQuality = payload.dataQuality
        this.polarizationProgress = payload.polarizationProgress
        this.concentration = payload.concentration
        this.relax = payload.relax
      }
    })
  },

  beforeDestroy() {
    this.socket.close()
  },

  computed: {
    rawSeries() {
      return [
        {
          data: this.eegRawDataArray
        }
      ]
    },

    polarizationSeries() {
      return [parseInt(this.polarizationProgress)]
    },

    graphSeries() {
      return [parseInt(this.concentration), parseInt(this.relax)]
    }
  },

  components: {
    apexchart: VueApexCharts
  },

  methods: {
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    }
  }
}
</script>

