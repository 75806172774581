<template lang="pug">
div
  v-dialog(v-model='dialog', width='500', persistent)
    v-card
      v-card-title.primary.headline.mb-12
        h4(style='color: white') {{ $t("modificationDate") }}
      v-card-text
        v-row
          v-col(cols='12', sm='6', md='6')
            DateTimePicker(ref='startDatetime', :datetime='(myTreatment && startTime) || ""')
          v-col(cols='12', sm='6', md='6')
            DateTimePicker(ref='endDatetime', :datetime='(myTreatment && endTime) || ""')

      v-card-actions
        v-spacer
        v-btn(text, @click='cancelClick()') {{ $t("daCancel") }}
        v-btn(text, @click='save()') {{ $t("daSave") }}
  v-snackbar(elevation='24', v-model='snackbar', :color='snackbarColor', timeout='3000') {{ notifMsg }}
    template(v-slot:action='{ attrs }')
      v-btn(text, v-bind='attrs', @click='snackbar = false') {{ $t("notifClose") }}
</template>


<script>
import { treatment as api } from '../../api'
import DateTimePicker from './DateTimePicker.vue'

export default {
  props: ['dialog', 'treatment'],

  data: () => ({
    snackbarColor: '',
    notifMsg: '',
    snackbar: false,
    myTreatment: undefined
  }),

  watch: {
    treatment(val) {
      this.myTreatment = val
    }
  },

  computed: {
    startTime() {
      const localTime = new Date(
        this.myTreatment.applicationStartTime.slice(0, 4) == '0001'
          ? this.myTreatment.model.CreatedAt
          : this.myTreatment.applicationStartTime
      )
      const localString = localTime.toLocaleString('zh-CN')
      const splitString = localString.split(' ')
      const dateSplit = splitString[0].split('/')
      if (dateSplit[1].length == 1) {
        dateSplit[1] = '0' + dateSplit[1]
      }
      if (dateSplit[2].length == 1) {
        dateSplit[2] = '0' + dateSplit[2]
      }
      const date = dateSplit.join('-')
      const ret = `${date}T${localString.split(' ')[1].slice(0, 5)}`
      return ret
    },

    endTime() {
      const localTime = new Date(this.myTreatment.applicationEndTime!=="0001-01-01T00:00:00Z"?this.myTreatment.applicationEndTime:this.myTreatment.model.UpdatedAt)
      const localString = localTime.toLocaleString('zh-CN')
      const splitString = localString.split(' ')
      const dateSplit = splitString[0].split('/')
      if (dateSplit[1].length == 1) {
        dateSplit[1] = '0' + dateSplit[1]
      }
      if (dateSplit[2].length == 1) {
        dateSplit[2] = '0' + dateSplit[2]
      }
      const date = dateSplit.join('-')
      const ret = `${date}T${localString.split(' ')[1].slice(0, 5)}`
      return ret
    }
  },

  methods: {
    cancelClick() {
      this.$emit('cancelClick')
    },

    async save() {
      try {
        await api.editTime({
          ID: this.myTreatment.model.ID,
          ApplicationStartTime: this.$refs.startDatetime.output,
          ApplicationEndTime: this.$refs.endDatetime.output
        })
        this.snackbarColor = 'success'
        this.notifMsg = `${this.$t('notifSuccess')}`
      } catch (err) {
        this.snackbarColor = 'error'
        this.notifMsg = `${this.$t('notifFailure')}${err.message}!`
      }
      this.snackbar = true
      this.$emit('saveClick')
    }
  },

  components: {
    DateTimePicker
  }
}
</script>


