<template lang="pug">
div
  v-row
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-monitor-eye
        | {{ $t("monitor") }}
        v-spacer
        v-switch(inset, v-model='monitor')
      v-row.setting-row(align='center', no-gutters, v-if='enabledCountdown')
        v-icon(left) mdi-timer-outline
        | {{ $t("timeLimit") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='timeLimit',
          :items='timeLimits',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters, v-if='!enabledCountdown')
        v-icon(left) mdi-timer-outline
        | {{ $t("timeLimit") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='noCountdownTimeLimit',
          :items='noCountdowntimeLimits',
          item-text='text',
          item-value='value'
        )
      //- v-row.setting-row(align='center', no-gutters)
      //-   v-icon(left) mdi-message-outline
      //-   | {{ $t("hand") }}
      //-   v-spacer
      //-   v-select.outlined.mr-3(reverse, style='max-width: 100px', v-model='hand', :items='hands', item-text='text', item-value='value')
      
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-message-outline
        | {{ $t("difficulty") }}
        v-spacer
        v-select.outlined.mr-3(reverse, style='max-width: 100px', v-model='difficulty', :items='difficultyList', item-text='text', item-value='value')
      
      //- div(v-if='difficulty == 4')
      //-   v-row.setting-row(align='center', no-gutters)
      //-     v-icon(left) mdi-numeric-3-box-multiple-outline
      //-     | {{ $t("missionType") }}
      //-     v-spacer
      //-     v-select.outlined.mr-3(
      //-       reverse,
      //-       style='max-width: 100px',
      //-       v-model='missionType',
      //-       :items='missionTypes',
      //-       item-text='text',
      //-       item-value='value'
      //-     )
      //-   v-row.setting-row(align='center', no-gutters)
      //-     v-icon(left) mdi-message-outline
      //-     | {{ $t("levelTimeLimit") }}
      //-     v-spacer
      //-     v-select.outlined.mr-3(reverse, style='max-width: 100px', v-model='levelTimeLimit', :items='levelTimeLimitList', item-text='text', item-value='value')
      //-   v-row.setting-row(align='center', no-gutters)
      //-     v-icon(left) mdi-numeric-3-box-multiple-outline
      //-     | {{ $t("numOfPerLevel") }}
      //-     v-spacer
      //-     v-select.outlined.mr-3(
      //-       reverse,
      //-       style='max-width: 100px',
      //-       v-model='numOfPerLevel',
      //-       :items='numOfPerLevelList',
      //-       item-text='text',
      //-       item-value='value'
      //-     )
      //-   v-row.setting-row(align='center', no-gutters)
      //-     v-icon(left) mdi-numeric-6-circle-outline
      //-     | {{ $t("numOfLevel") }}
      //-     v-spacer
      //-     v-select.outlined.mr-3(
      //-       reverse,
      //-       style='max-width: 100px',
      //-       v-model='numOfLevel',
      //-       :items='numOfLevelList',
      //-       item-text='text',
      //-       item-value='value'
      //-     )
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-message-outline
        | {{ $t("clarity") }}
        v-spacer
        v-select.outlined.mr-3(reverse, style='max-width: 100px', v-model='clarity', :items='clarityList', item-text='text', item-value='value')
      div(v-if='clarity == 4')
        v-row.setting-row(align='center', no-gutters)
          v-icon(left) mdi-message-outline
          | {{ $t("resolution") }}
          v-spacer
          v-select.outlined.mr-3(reverse, style='max-width: 100px', v-model='resolution', :items='resolutionList', item-text='text', item-value='value')
        v-row.setting-row(align='center', no-gutters)
          v-icon(left) mdi-numeric-3-box-multiple-outline
          | {{ $t("fps") }}
          v-spacer
          v-select.outlined.mr-3(
            reverse,
            style='max-width: 100px',
            v-model='fps',
            :items='fpsList',
            item-text='text',
            item-value='value'
          )
        v-row.setting-row(align='center', no-gutters)
          v-icon(left) mdi-numeric-6-circle-outline
          | {{ $t("quality") }}
          v-spacer
          v-select.outlined.mr-3(
            reverse,
            style='max-width: 100px',
            v-model='quality',
            :items='qualityList',
            item-text='text',
            item-value='value'
          )
        v-row.setting-row(align='center', no-gutters)
          v-icon(left) mdi-numeric-6-circle-outline
          | {{ $t("chromaSubsampling") }}
          v-spacer
          v-select.outlined.mr-3(
            reverse,
            style='max-width: 100px',
            v-model='chromaSubsampling',
            :items='chromaSubsamplingList',
            item-text='text',
            item-value='value'
          )
        v-row.setting-row(align='center', no-gutters)
          v-icon(left) mdi-numeric-6-circle-outline
          | {{ $t("resize") }}
          v-spacer
          v-select.outlined.mr-3(
            reverse,
            style='max-width: 100px',
            v-model='resize',
            :items='resizeList',
            item-text='text',
            item-value='value'
          )
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: vm => ({
    monitor: true,
    timeLimit: 15,
    timeLimits: [
      { text: vm.$t('5min'), value: 5 },
      { text: vm.$t('10min'), value: 10 },
      { text: vm.$t('15min'), value: 15 },
      { text: vm.$t('20min'), value: 20 },
      { text: vm.$t('20min'), value: 30 }
    ],
    noCountdownTimeLimit: -1,
    noCountdowntimeLimits: [
      { text: vm.$t('infinite'), value: -1 },
      { text: vm.$t('5min'), value: 5 },
      { text: vm.$t('10min'), value: 10 },
      { text: vm.$t('15min'), value: 15 },
      { text: vm.$t('20min'), value: 20 },
      { text: vm.$t('20min'), value: 30 }
    ],
    
    
    //difficulty
    difficulty: 1,
    difficultyList: [
      { text: vm.$t('easy'), value: 1 },
      { text: vm.$t('normal'), value: 2 },
      { text: vm.$t('hard'), value: 3 },
      { text: vm.$t('custom'), value: 4 }
    ],
    missionType:2,
    missionTypes:[
      { text: vm.$t('item'), value: 0 },
      { text: vm.$t('type'), value: 1 },
      { text: vm.$t('all'), value: 2 }
    ],
    hand: 0,
    hands:[
      { text: vm.$t('leftHand'), value: 0 },
      { text: vm.$t('rightHand'), value: 1 }
    ],
    levelTimeLimit: 60,
    levelTimeLimitList:[
      { text: vm.$t('60s'), value: 60 },
      { text: vm.$t('45s'), value: 45 },
      { text: vm.$t('30s'), value: 30 },
    ],
    numOfLevel:3,
    numOfLevelList:[
      { text: vm.$t('3levels'), value: 3 },
      { text: vm.$t('6levels'), value: 6 },
      { text: vm.$t('9levels'), value: 9 }
    ],
    numOfPerLevel:2,
    numOfPerLevelList:[
      { text: vm.$t('2numbers'), value: 2 },
      { text: vm.$t('4numbers'), value: 4 },
      { text: vm.$t('6numbers'), value: 6 }
    ],
    //clarity
    clarity:1,
    clarityList:[
      { text: vm.$t('Smooth'), value: 0 },
      { text: vm.$t('SD'), value: 1 },
      { text: vm.$t('HD'), value: 2 },
      { text: vm.$t('Blu-ray'), value: 3 },
      { text: vm.$t('custom'), value: 4 }
    ],
    resolution: 512,
    resolutionList: [
      { text: vm.$t('low'), value: 512 },
      { text: vm.$t('high'), value: 1024 },
      { text: vm.$t('superHigh'), value: 2048 }
    ],
    fps:24,
    fpsList:[
      { text: vm.$t('low'), value: 24 },
      { text: vm.$t('high'), value: 30 },
      { text: vm.$t('superHigh'), value: 60 }
    ],
    quality: 20,
    qualityList:[
      { text: vm.$t('low'), value: 20 },
      { text: vm.$t('mid'), value: 40 },
      { text: vm.$t('high'), value: 60 }
    ],
    chromaSubsampling: 0,
    chromaSubsamplingList:[
      { text: vm.$t('superHigh'), value: 0 },
      { text: vm.$t('high'), value: 1 },
      { text: vm.$t('low'), value: 2 },
      { text: vm.$t('grayScale'), value: 3 }
    ],
    resize:0,
    resizeList:[
      { text: vm.$t('fullFrame'), value: 0 },
      { text: vm.$t('halfFrame'), value: 1 },
      { text: vm.$t('quarterFrame'), value: 2 },
      { text: vm.$t('quarterFrame'), value: 3 }
    ]

  }),

  methods: {
    getSettings() {
      switch (this.difficulty) {
        case 1:
          break
        case 2:
          this.missionType = 1
          this.numOfPerLevel = 4
          this.numOfLevel = 6
          break
        case 3:
          this.missionType = 2
          this.numOfPerLevel = 4
          this.numOfLevel = 9
          break
      }
      let options = {
        resolution: this.resolution,
        fps: this.fps,
        quality: this.quality,
        chromaSubsampling: this.chromaSubsampling,
        resize: this.resize
      }
      return JSON.stringify({
        monitor: this.monitor,
        timeLimit: this.enabledCountdown ? this.timeLimit : this.noCountdownTimeLimit,
        difficulty:this.difficulty,
        // type: this.missionType,
        // numOfPerLevel: this.numOfPerLevel,
        // numOfLevel: this.numOfLevel,
        // hand: this.hand,
        level_timeLimit: this.levelTimeLimit,
        streamSetting: JSON.stringify({ level: this.clarity, options: options })
      })
    }
  },

  computed: {
    ...mapGetters({
      enabledCountdown: 'setting/enabledCountdown'
    })
  }
}
</script>

<style lang="sass">
.setting-row
  height: 50px
</style>

