import { render, staticRenderFns } from "./SimulatedtownSettings.vue?vue&type=template&id=39e061fe&lang=pug"
import script from "./SimulatedtownSettings.vue?vue&type=script&lang=js"
export * from "./SimulatedtownSettings.vue?vue&type=script&lang=js"
import style0 from "./SimulatedtownSettings.vue?vue&type=style&index=0&id=39e061fe&prod&lang=sass"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./SimulatedtownSettings.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports