<template lang='pug'>
div 
  v-dialog(
    v-model='dialog',
    persistent,
    width='1200px',
    @click:outside='visible = false',
    :content-class='!visible ? "cDialog" : "mDialog"'
  )
    v-card
      v-card-title
      v-card-text
        v-row
          v-col(cols='12', :sm='isOpen ? 4 : 6', :md='isOpen ? 4 : 6')
            v-card
              v-toolbar(dense, dark, color='primary')
                v-toolbar-title {{ typeUsername == "1" ? $t("userNameTitle") : $t("titlePatient") }}
                v-spacer
                v-text-field(
                  v-if='!prePatientId || !prePatientDetailId',
                  style='max-width: 200px',
                  rounded,
                  dense,
                  outlined,
                  single-line,
                  hide-details,
                  v-model='searchPatients',
                  append-icon='mdi-magnify',
                  :label='$t("search")',
                  @click.stop.native='isOpen ? (visible = false) : (visible = true)',
                  @focus.stop='keyboardShowClick'
                )
              v-card-text
                v-list-item-group(v-model='patientIndex')
                  DynamicScroller(
                    style='height:300px',
                    :items="patients(searchPatients)",
                    :min-item-size="20",
                    key-field="name",
                  )
                    template(v-slot="{ item, index, active }")
                      DynamicScrollerItem(:item="item", :index="index", :active="active") 
                        v-list-item(@click='userClick(item, index)',
                          :class='userIndex === index ? listColor : ""',
                          :style='{ flex: isOpen ? "1 1 100%" : "none", padding: isOpen ? "0 16px" : "0" }'
                        )
                          PatientMini(:patientId='item.model.ID', :ref='`patients/${index}`', :userType='typeUsername', :isOpen='isOpen')
                //- v-list.overflow-y-auto(style='height: 300px')
                //- v-list-item-group(v-model='patientIndex')
                //- v-list-item(
                //- v-for='(item, i) in patients(searchPatients)',
                //- :key='i',
                //- @click='userClick(item, i)',
                //- :class='userIndex === i ? listColor : ""',
                //- :style='{ flex: isOpen ? "1 1 100%" : "none", padding: isOpen ? "0 16px" : "0" }'
                //- )
                //- v-list-item-content.px-6.my-4
                //- PatientMini(:patientId='item.model.ID', :ref='`patients/${i}`', :userType='typeUsername', :isOpen='isOpen')

          v-col(cols='12', :sm='isOpen ? 4 : 6', :md='isOpen ? 4 : 6')
            v-card
              v-toolbar(dense, dark, color='primary')
                v-toolbar-title {{ $t("devicesTitle") }}
                v-spacer
                v-text-field(
                  v-if='!preDeviceId',
                  style='max-width: 200px',
                  rounded,
                  dense,
                  outlined,
                  single-line,
                  hide-details,
                  v-model='searchDevices',
                  append-icon='mdi-magnify',
                  :label='$t("search")',
                  @click.stop.native='isOpen ? (visible = false) : (visible = true)',
                  @focus.stop='keyboardShowClick'
                )
              v-card-text
                v-list.overflow-y-auto(style='height: 300px')
                  v-list-item-group(v-model='deviceIndex')
                    v-list-item(
                      v-for='(item, i) in devices(searchDevices)',
                      :key='i',
                      @click='devicesClick(item, i)',
                      :class='devicesIndex === i ? listColor : ""',
                      :style='{ flex: isOpen ? "1 1 100%" : "none", padding: isOpen ? "0 16px" : "0" }'
                    )
                      v-list-item-content.pa-6
                        DeviceMini(:deviceId='item.model.ID', :ref='`devices/${i}`')
          v-col(cols='12', sm='4', md='4', v-if='isOpen && prePatientDetailId == undefined')
            v-card
              v-toolbar(dense, dark, color='primary') 
                v-toolbar-title {{ $t("appsTitle") }}
                v-spacer
                v-text-field(
                  v-if='!preAppId',
                  style='max-width: 200px',
                  rounded,
                  dense,
                  outlined,
                  single-line,
                  hide-details,
                  v-model='searchApps',
                  append-icon='mdi-magnify',
                  :label='$t("search")'
                )
              v-card-text
                v-list.overflow-y-auto(style='height: 300px')
                  v-list-item-group(v-model='appIndex', color='primary')
                    v-list-item(v-for='(item, i) in apps(searchApps)', :key='i')
                      v-list-item-content.px-6.my-2
                        AppMini(:appId='item.model.ID', :ref='`apps/${i}`')
          v-col(cols='12', sm='4', md='4', v-if='isOpen && prePatientDetailId !== undefined')
            v-card
              v-toolbar(dense, dark, color='primary') 
                v-toolbar-title {{ $t("appsTitle") }}
                v-spacer
                v-text-field(
                  v-if='!preAppId',
                  style='max-width: 200px',
                  rounded,
                  dense,
                  outlined,
                  single-line,
                  hide-details,
                  v-model='searchApps',
                  append-icon='mdi-magnify',
                  :label='$t("search")'
                )
              v-card-text
                v-list.overflow-y-auto(style='height: 300px')
                  v-list-item-group(v-model='appIndex', color='primary')
                    v-list-item(v-for='(item, i) in apps(searchApps)', :key='i')
                      v-list-item-content.px-6.my-2
                        AppMini(:appId='item.model.ID', :ref='`apps/${i}`')

          v-col(cols='12', sm='12', md='12', v-if='!isOpen')
            v-card
              v-toolbar(dense, dark, color='primary') 
                v-toolbar-title {{ $t("appsTitle") }}
                v-spacer
                v-text-field(
                  v-if='!preAppId',
                  style='max-width: 200px',
                  rounded,
                  dense,
                  outlined,
                  single-line,
                  hide-details,
                  v-model='searchApps',
                  append-icon='mdi-magnify',
                  :label='$t("search")',
                  @click.stop.native='visible = true',
                  @focus.stop='keyboardShowClick'
                )
              v-card-text
                v-list.overflow-y-auto(style='height: 300px')
                  v-list-item-group(v-model='appIndex', color='primary', :class='preAppId ? "" : "appList"')
                    v-list-item(v-for='(item, i) in apps(searchApps)', :key='i', :style='{ flex: preAppId ? "1 1 100%" : "1 1 50%" }')
                      v-list-item-content.px-6.my-2
                        AppMini(:appId='item.model.ID', :ref='`apps/${i}`')
                        v-row(v-if='preAppId', style='margin-top: 30px; color: #757575; line-height: 20px') {{ item.description }}
          v-col(v-show='pairingListShow')
            v-card
              v-card-title {{ $t("pairingList") }}
              v-card-text
                v-row
                  v-col(cols='12', sm='6', md='4', v-for='(item, index) in pairingList', :key='index')
                    .pairingBtn
                      v-icon.mr-2(style='color: #1976d2') mdi-account
                      span {{ item.userName }}
                      span(style='margin: 0 10px; color: rgba(0, 0, 0, 0.12); font-size: 28px') |
                      v-icon.mr-2(style='color: #1976d2') mdi-virtual-reality
                      span {{ item.name }}
                      span.iconfont.icon-close(@click='pairingClose(item, index)')

          v-col(v-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.citstorerelease"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                CITStoreSettings(ref='citstoresettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.dragonbeat"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                DragonBeatSettings(ref='dragonbeatsettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.WheelMaze"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                WheelMazeSettings(ref='wheelmazesettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.shadow"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                ShadowSettings(ref='shadowsettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.pingpongsimulator"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                PingPongSettings(ref='pingpongsettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.shootinggallery"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                ShootingGallerySettings(ref='shootinggallerysettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.breathe"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                BreatheSettings(ref='breathesettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.taichi"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                TaichiSettings(ref='taichisettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.grow"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                GrowSettings(ref='growsettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.bbqflip"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                bbqFlip(ref='bbqFlipSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.memoryroom"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                MemoryRoom(ref='MemoryRoomSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.sandtableroom"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                SandTableRoom(ref='SandTableRoomSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.catharsisroom"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                CatharsisRoom(ref='CatharsisRoomSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.laundry"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                LaundrySettings(ref='laundrySettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.Basketball"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                BasketballSettings(ref='basketballSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.icejourney"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                IceJourneySettings(ref='iceJourneySettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.cognitiveassessment"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                CognitiveAssessment(ref='CognitiveAssessmentSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.melodicpitch"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                MelodicPitch(ref='MelodicPitchSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.jellyfishswim"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                Jellyfish(ref='JellyfishSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.snowballfight"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                Snowballfight(ref='SnowballfightSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.mathboxing"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                mathBoxing(ref='mathBoxingSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.londontower"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                TowerOfLondonTest(ref='TowerOfLondonTestSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.acrophobia"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                AcrophobiaSettings(ref='AcrophobiaSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.painrelief"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                PainreliefSettings(ref='PainreliefSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.XZS.VREyeTrackingAttentionAssessmentSystem"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                EyeMovementSettings(ref='EyeMovementSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.forestfriends"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                ForestfriendsSettings(ref='ForestfriendsSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.fruitparty"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                FruitpartySettings(ref='FruitpartySettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.golfclub"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                GolfclubSettings(ref='GolfclubSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.puppydollhouse"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                PuppydollhouseSettings(ref='puppydollhouseSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.vrtennis"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                TennisSettings(ref='tennisSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.rabbitstore"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                RabbitStoreSettings(ref='rabbitStoreSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.brushteeth"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                BrushteethSettings(ref='brushteethSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.sailingadventure"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                SailingadventureSettings(ref='sailingadventureSettings')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.hidenseek"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                HideNSeekSettings(
                  ref='hideNSeekSettings',
                  :packageName='getAppPackageName(appIndex, searchApps)',
                  :appCodename='getAppCodename(appIndex, searchApps)',
                )
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.mindratingscalesystem"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                ScaleSettings(
                  ref='scaleSettings',
                  :packageName='getAppPackageName(appIndex, searchApps)',
                  :appCodename='getAppCodename(appIndex, searchApps)',
                  :url='getScaleUrl(appIndex, searchApps)',
                )
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.spp"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                SppSettings(
                  ref='sppSettings',
                  :packageName='getAppPackageName(appIndex, searchApps)',
                  :appCodename='getAppCodename(appIndex, searchApps)',
                  :bgm='getAppBGMType(appIndex, searchApps)',
                  :voice='getAppVoiceType(appIndex, searchApps)',
                  :video='getAppVideoType(appIndex, searchApps)',
                  :type='getAppType(appIndex, searchApps)'
                )
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.citstoregesture"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                CITStoreGestureSettings(ref='citStoreGestureSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.mathboxing2024"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                MathBoxing2024Settings(ref='mathBoxing2024Settings' :appCodename='getAppCodename(appIndex, searchApps)')
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.shootinggallery2"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                ShootingGallery2Settings(
                  ref='shootinggallery2Settings',
                  :appCodename='getAppCodename(appIndex, searchApps)',
                )
          v-col(
            v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.adlsupermarket"',
            cols='12',
            sm='12',
            md='12'
          )
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                ADLSupermarketSettings(ref='ADLSupermarketSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.fruittrain"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                FruittrainSettings(ref='fruittrainSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.musicbeat"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                MusicbeatSettings(ref='musicbeatSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.ComaArousal"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                ComaArousalSettings(ref='ComaArousalSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.simulatedtown"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                SimulatedtownSettings(ref='SimulatedtownSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.happypatting"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                HappypattingSettings(ref='happypattingSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.postalsearch"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                PostalSearchSettings(ref='postalSearchSettings')
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.postalblitz"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                PostalBlitzSettings(ref='postalBlitzSettings')
          
          v-col(v-else-if='appIndex >= 0 && apps(searchApps)[appIndex].packageName == "com.xzs.postcard_mission"', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                PostcardMissionSettings(ref='postcardMissionSettings')
          v-col(v-else-if='appIndex >= 0', cols='12', sm='12', md='12')
            v-card
              v-card-title {{ $t("appSettings") }}
              v-card-text
                CommonSettings(
                  ref='commonsettings',
                  :packageName='getAppPackageName(appIndex, searchApps)',
                  :appCodename='getAppCodename(appIndex, searchApps)',
                  :voice='getAppVoiceType(appIndex, searchApps)',
                  :video='getAppVideoType(appIndex, searchApps)',
                  :type='getAppType(appIndex, searchApps)'
                ) 

      v-card-actions
        v-spacer
        v-btn(text, @click='clear(); dialog = false; visible = false') {{ $t("dialogCancel") }}
        v-btn(color='primary', @click='start(); visible = false')
          v-icon(left, style='font-size: 22px') mdi-play-circle
          | {{ $t("dialogStart") }}
      v-snackbar(elevation='24', v-model='snackbar', :color='snackbarColor', timeout='3000') {{ notifMsg }}
        template(v-slot:action='{ attrs }')
          v-btn(text, v-bind='attrs', @click='snackbar = false') {{ $t("notifClose") }}
  Keyboard#keyboard(layout='normal', :input='input', v-if='visible && enableTouch', :cancel='cancel')
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { treatment, app } from '../api'
import PatientMini from './PatientMini'
import DeviceMini from './DeviceMini'
import AppMini from './AppMini'
import CommonSettings from './CommonSettings'
import CITStoreSettings from './CITStoreSettings'
import DragonBeatSettings from './DragonBeatSettings'
import WheelMazeSettings from './WheelMazeSettings'
import ShadowSettings from './ShadowSettings'
import PingPongSettings from './PingPongSettings'
import ShootingGallerySettings from './ShootingGallerySettings'
import BreatheSettings from './BreatheSettings'
import TaichiSettings from './TaichiSettings'
import GrowSettings from './GrowSettings'
import bbqFlip from './bbqFlipSettings'
import MemoryRoom from './MemoryRoomSettings'
import SandTableRoom from './SandTableRoomSettings.vue'
import SppSettings from './SppSettings.vue'
import CatharsisRoom from './CatharsisRoomSettings.vue'
import CognitiveAssessment from './CognitiveAssessmentSettings.vue'
import MelodicPitch from './MelodicPitchSettings.vue'
import Jellyfish from './JellyfishSettings.vue'
import Snowballfight from './SnowballfightSettings.vue'
import mathBoxing from './mathBoxingSettings.vue'
import TowerOfLondonTest from './TowerOfLondonSettings.vue'
import AcrophobiaSettings from './AcrophobiaSettings.vue'
import PainreliefSettings from './PainreliefSettings.vue'
import EyeMovementSettings from './EyeMovementSettings.vue'
import ForestfriendsSettings from './ForestfriendsSettings.vue'
import FruitpartySettings from './FruitpartySettings.vue'
import GolfclubSettings from './GolfclubSettings.vue'
import PuppydollhouseSettings from './PuppydollhouseSettings.vue'
import TennisSettings from './TennisSettings.vue'
import RabbitStoreSettings from './RabbitStoreSettings.vue'
import SailingadventureSettings from './SailingadventureSettings.vue'
import HideNSeekSettings from './HideNSeekSettings.vue'
import BrushteethSettings from './BrushteethSettings.vue'
import debounce from 'lodash/debounce'
import ScaleSettings from './ScaleSettings.vue'
import BasketballSettings from './BasketballSettings.vue'
import IceJourneySettings from './IceJourneySettings.vue'
import LaundrySettings from './LaundrySettings.vue'
import CITStoreGestureSettings from './CITStoreGestureSettings.vue'
import MathBoxing2024Settings from './MathBoxing2024Settings.vue'
import ShootingGallery2Settings from './ShootingGallery2Settings.vue'
import ADLSupermarketSettings from './ADLSupermarketSettings.vue'
import FruittrainSettings from './FruittrainSettings.vue'
import MusicbeatSettings from './MusicbeatSettings.vue'
import HappypattingSettings from './HappypattingSettings.vue'
import { startTreatmentTime } from '../lib/start-treatment-time'
import Keyboard from 'vue-keyboard-zh'
import ComaArousalSettings from './ComaArousalSettings.vue'
import SimulatedtownSettings from './SimulatedtownSettings.vue'
import PostalSearchSettings from './PostalSearchSettings.vue'
import PostalBlitzSettings from './PostalBlitzSettings.vue'
import PostcardMissionSettings from './PostalCardMissionSettings.vue'

function filterByValue(arr, term) {
  return arr.filter(obj => {
    return Object.keys(obj).reduce((acc, curr) => {
      return acc || String(obj[curr]).toLowerCase().includes(term)
    }, false)
  })
}

export default {
  props: ['monitor'],
  data: () => ({
    dialog: false,

    searchPatients: undefined,
    searchDevices: undefined,
    searchApps: undefined,

    patientIndex: undefined,
    deviceIndex: undefined,
    appIndex: undefined,

    prePatientId: undefined,
    prePatientDetailId: undefined,
    preDeviceId: undefined,
    preAppId: undefined,

    newTreatment: {
      active: false,
      patientId: undefined,
      deviceId: undefined,
      appId: undefined,
      start: true,
      userId: 1
    },
    pairingText: '',
    pairingDevices: '',
    userId: '',
    devicesId: '',
    userIndex: '',
    devicesIndex: '',
    userItem: {},
    devicesItem: {},
    snackbarColor: '',
    notifMsg: '',
    snackbar: false,
    pairingList: [],
    listColor: '',
    devicesList: [],
    trainingState: '',
    pairingListShow: true,
    hideApps: [],
    appsdata: [],
    data: 0,
    isOpen: false,
    input: '',
    visible: false,
    patientDetail: [],
    detailIndex: undefined,
    eyePatientId: undefined,
    golfclubPatientId: undefined,
    golfclubAppId: undefined,
    tennisPatientId: undefined,
    tennisAppId: undefined
  }),

  async mounted() {
    this.resize()
    window.addEventListener('resize', this.resize, false)

    const logToken = JSON.parse(window.localStorage.getItem('vuex'))
    const adminId = logToken.users.userId
    const role = logToken.users.role
    const userId = parseInt(role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId)
    const obj1 = {
      adminId: role == 'admin' ? adminId : 1,
      userId: userId,
      role: role,
      isShow: 1,
      type: '0,1,2,3,4,5,6,7,8'
    }
    const result = await app.index(obj1)
    this.hideApps = result.data
    this.appsdata = this.apps()
  },

  watch: {
    patientIndex: function (val) {
      if (this.$refs[`patients/${val}`]) {
        const el = this.$refs[`patients/${val}`].$el
        el.scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth'
        })
      }
    },

    deviceIndex: function (val) {
      if (this.$refs[`devices/${val}`]) {
        const el = this.$refs[`devices/${val}`][0].$el
        el.scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth'
        })
      }
    },

    appIndex: function (val) {
      if (this.$refs[`apps/${val}`]) {
        const el = this.$refs[`apps/${val}`][0].$el
        el.scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth'
        })
      }
    },

    devicesAvail: function (val) {
      this.pairingList.forEach(value => {
        val.forEach((x, i) => {
          if (value.model.ID == x.model.ID) {
            this.devices(this.searchDevices).splice(i, 1)
          }
        })
      })
    },
    patientsAvail: function (val) {
      this.pairingList.forEach(value => {
        val.forEach((x, i) => {
          if (value.userID == x.model.ID) {
            this.patients(this.searchPatients).splice(i, 1)
          }
        })
      })
    },
    preDeviceId: function (val) {
      if (val) {
        this.devicesIndex = 0
        this.listColor = 'listColor'
        this.pairingListShow = false
      }
    },
    prePatientDetailId: function (val) {
      if (val) {
        this.userIndex = 0
        this.listColor = 'listColor'
        this.pairingListShow = false
      }
    },
    prePatientId: function (val) {
      if (val) {
        this.userIndex = 0
        this.listColor = 'listColor'
        this.pairingListShow = false
      }
    },
    monitor: function (val) {
      if (val == 'monitor') {
        this.pairingListShow = false
      }
    }
  },

  methods: {
    getScaleUrl(appIndex, searchApps) {
      if (!(appIndex >= 0)) return null
      return "http://47.110.41.40:8001/user/evaluations/scales/" + this.apps(searchApps)[appIndex].codename.split("/")[1]
    },
    keyboardShowClick(e) {
      if (!this.isOpen) {
        this.input = e.target
        if (!this.visible) this.visible = true
      } else {
        this.visible = false
        this.input = ''
      }
    },
    cancel() {
      this.visible = false
    },
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    pairingSuccessful() {
      if (this.pairingDevices !== '' && this.pairingText !== '') {
        let obj2 = {
          ...this.userItem,
          ...this.devicesItem,
          userName: this.pairingText,
          userID: this.userId
        }
        this.pairingList.push(obj2)
        this.userItem = ''
        this.devicesItem = ''
        this.notifMsg = this.$t('paireSuccess')
        this.snackbarColor = 'success'
        this.snackbar = true
        this.patients(this.searchPatients).splice(this.userIndex, 1)
        this.devices(this.searchDevices).splice(this.devicesIndex, 1)
        this.listColor = ''
        this.pairingText = ''
        this.pairingDevices = ''
        this.userIndex = -1
        this.devicesIndex = -1
      }
    },
    userClick: debounce(function (item, index) {
      if (this.preAppId) {
        this.pairingText = item.name
        this.userItem = item
        this.userId = item.model.ID
        this.userIndex = index
        this.listColor = 'listColor'
        this.pairingSuccessful()
        if (this.pairingDevices !== '' && this.pairingText !== '') {
          this.pairingDevices = ''
        }
      } else {
        this.userIndex = index
        this.listColor = 'listColor'
      }
    }, 300),
    devicesClick: debounce(function (item, index) {
      if (this.preAppId) {
        this.pairingDevices = item.name
        this.devicesItem = item
        this.devicesId = item.model.ID
        this.devicesIndex = index
        this.listColor = 'listColor'
        this.pairingSuccessful()
        if (this.pairingDevices !== '' && this.pairingText !== '') {
          this.pairingDevices = ''
        }
      } else {
        this.devicesIndex = index
        this.listColor = 'listColor'
      }
    }, 300),
    pairingClose(item, index) {
      const patientsObj = {
        avail: item.avail,
        name: item.userName,
        patientNumber: item.patientNumber,
        bedNumber: item.bedNumber,
        model: { ID: item.userID }
      }
      const devicesObj = {
        batteryPercentage: item.batteryPercentage,
        chargingState: item.chargingState,
        deviceType: item.deviceType,
        eegBattery: item.eegBattery,
        eegContact: item.eegContact,
        eegState: item.eegState,
        model: { ID: item.model.ID },
        name: item.name,
        puiVersion: item.puiVersion,
        sn: item.sn,
        state: item.state,
        volume: item.volume,
        wifiIpAddress: item.wifiIpAddress,
        wifiStrength: item.wifiStrength
      }
      this.patients(this.searchPatients).unshift(patientsObj)
      this.devices(this.searchDevices).unshift(devicesObj)
      this.pairingList.splice(index, 1)
    },
    clear() {
      this.patientIndex = undefined
      this.deviceIndex = undefined
      this.appIndex = undefined
      this.prePatientId = undefined
      this.prePatientDetailId = undefined
      this.preDeviceId = undefined
      this.preAppId = undefined
      this.pairingList.forEach(item => {
        const patientsObj = {
          avail: item.avail,
          name: item.userName,
          patientNumber: item.patientNumber,
          bedNumber: item.bedNumber,
          model: { ID: item.userID }
        }
        const devicesObj = {
          batteryPercentage: item.batteryPercentage,
          chargingState: item.chargingState,
          deviceType: item.deviceType,
          eegBattery: item.eegBattery,
          eegContact: item.eegContact,
          eegState: item.eegState,
          model: { ID: item.model.ID },
          name: item.name,
          puiVersion: item.puiVersion,
          sn: item.sn,
          state: item.state,
          volume: item.volume,
          wifiIpAddress: item.wifiIpAddress,
          wifiStrength: item.wifiStrength
        }
        this.patients(this.searchPatients).unshift(patientsObj)
        this.devices(this.searchDevices).unshift(devicesObj)
      })
      this.pairingList = []
    },

    async start() {
      if (this.appIndex >= 0) {
        this.newTreatment.appId =
          this.preAppId || this.apps(this.searchApps)[this.appIndex].model.ID || this.appsAvail[this.appIndex]?.model.ID
        const date = Date.now()
        const timeStamp = date - 8 * 60 * 60 * 1000
        const time = new Date(timeStamp)
        const year = time.getFullYear()
        const month = time.getMonth() + 1
        const day = time.getDate()
        const hour = time.getHours()
        const minutes = time.getMinutes()
        const seconds = time.getSeconds()

        this.newTreatment.applicationStartTime =
          year +
          '-' +
          (month < 10 ? '0' + month : month) +
          '-' +
          (day < 10 ? '0' + day : day) +
          'T' +
          (hour < 10 ? '0' + hour : hour) +
          ':' +
          (minutes < 10 ? '0' + minutes : minutes) +
          ':' +
          (seconds < 10 ? '0' + seconds : seconds) +
          'Z'
        if (this.preAppId && this.pairingList.length > 0) {
          this.newTreatment.patientId = this.prePatientId || this.prePatientDetailId || this.userId
          this.eyePatientId = this.newTreatment.patientId
          this.golfclubPatientId = this.newTreatment.patientId
          this.tennisPatientId = this.newTreatment.patientId
        } else if (this.preDeviceId || this.prePatientId || this.prePatientDetailId || this.monitor == 'monitor') {
          this.newTreatment.patientId = parseInt(
            this.prePatientId ||
            this.prePatientDetailId ||
            this.patients(this.searchPatients)[this.patientIndex].model.ID ||
            this.patientsAvail[this.patientIndex]?.model.ID
          )
          this.eyePatientId = this.newTreatment.patientId
          this.golfclubPatientId = this.newTreatment.patientId
          this.tennisPatientId = this.newTreatment.patientId
        }
        switch (this.apps(this.searchApps)[this.appIndex].packageName) {
          case 'com.xzs.citstorerelease':
            this.newTreatment.settings = this.$refs.citstoresettings.getSettings()
            break
          case 'com.xzs.dragonbeat':
            this.newTreatment.settings = this.$refs.dragonbeatsettings.getSettings()
            break
          case 'com.xzs.WheelMaze':
            this.newTreatment.settings = this.$refs.wheelmazesettings.getSettings()
            break
          case 'com.xzs.shadow':
            this.newTreatment.settings = this.$refs.shadowsettings.getSettings()
            break
          case 'com.xzs.pingpongsimulator':
            this.newTreatment.settings = this.$refs.pingpongsettings.getSettings()
            break
          case 'com.xzs.shootinggallery':
            this.newTreatment.settings = this.$refs.shootinggallerysettings.getSettings()
            break
          case 'com.xzs.breathe':
            this.newTreatment.settings = this.$refs.breathesettings.getSettings()
            break
          case 'com.xzs.taichi':
            this.newTreatment.settings = this.$refs.taichisettings.getSettings()
            break
          case 'com.xzs.grow':
            this.newTreatment.settings = this.$refs.growsettings.getSettings()
            break
          case 'com.xzs.bbqflip':
            this.newTreatment.settings = this.$refs.bbqFlipSettings.getSettings()
            break
          case 'com.xzs.memoryroom':
            this.newTreatment.settings = this.$refs.MemoryRoomSettings.getSettings()
            break
          case 'com.xzs.sandtableroom':
            this.newTreatment.settings = this.$refs.SandTableRoomSettings.getSettings()
            break
          case 'com.xzs.catharsisroom':
            this.newTreatment.settings = this.$refs.CatharsisRoomSettings.getSettings()
            break
          case 'com.xzs.cognitiveassessment':
            this.newTreatment.settings = this.$refs.CognitiveAssessmentSettings.getSettings()
            break
          case 'com.xzs.melodicpitch':
            this.newTreatment.settings = this.$refs.MelodicPitchSettings.getSettings()
            break
          case 'com.xzs.jellyfishswim':
            this.newTreatment.settings = this.$refs.JellyfishSettings.getSettings()
            break
          case 'com.xzs.snowballfight':
            this.newTreatment.settings = this.$refs.SnowballfightSettings.getSettings()
            break
          case 'com.xzs.mathboxing':
            this.newTreatment.settings = this.$refs.mathBoxingSettings.getSettings()
            break
          case 'com.xzs.londontower':
            this.newTreatment.settings = this.$refs.TowerOfLondonTestSettings.getSettings()
            break
          case 'com.xzs.acrophobia':
            this.newTreatment.settings = this.$refs.AcrophobiaSettings.getSettings()
            break
          case 'com.xzs.painrelief':
            this.newTreatment.settings = this.$refs.PainreliefSettings.getSettings()
            break
          case 'com.XZS.VREyeTrackingAttentionAssessmentSystem':
            this.$refs.EyeMovementSettings.patientId = this.eyePatientId
            this.newTreatment.settings = this.$refs.EyeMovementSettings.getSettings()
            break
          case 'com.xzs.forestfriends':
            this.newTreatment.settings = this.$refs.ForestfriendsSettings.getSettings()
            break
          case 'com.xzs.fruitparty':
            this.newTreatment.settings = this.$refs.FruitpartySettings.getSettings()
            break
          case 'com.xzs.golfclub':
            this.$refs.GolfclubSettings.patientId = this.golfclubPatientId
            this.$refs.GolfclubSettings.appId = this.newTreatment.appId
            this.newTreatment.settings = this.$refs.GolfclubSettings.getSettings()
            break
          case 'com.xzs.puppydollhouse':
            this.newTreatment.settings = this.$refs.puppydollhouseSettings.getSettings()
            break
          case 'com.xzs.vrtennis':
            this.$refs.tennisSettings.patientId = this.tennisPatientId
            this.$refs.tennisSettings.appId = this.newTreatment.appId
            this.newTreatment.settings = this.$refs.tennisSettings.getSettings()
            break
          case 'com.xzs.rabbitstore':
            this.newTreatment.settings = this.$refs.rabbitStoreSettings.getSettings()
            break
          case 'com.xzs.sailingadventure':
            this.newTreatment.settings = this.$refs.sailingadventureSettings.getSettings()
            break
          case 'com.xzs.spp':
            this.newTreatment.settings = this.$refs.sppSettings.getSettings()
            break
          case 'com.xzs.hidenseek':
            this.newTreatment.settings = this.$refs.hideNSeekSettings.getSettings()
            break
          case 'com.xzs.brushteeth':
            this.newTreatment.settings = this.$refs.brushteethSettings.getSettings()
            break
          case 'com.xzs.mindratingscalesystem':
            this.newTreatment.settings = this.$refs.scaleSettings.getSettings()
            break
          case 'com.xzs.laundry':
            this.newTreatment.settings = this.$refs.laundrySettings.getSettings()
            break
          case 'com.xzs.Basketball':
            this.newTreatment.settings = this.$refs.basketballSettings.getSettings()
            break
          case 'com.xzs.icejourney':
            this.newTreatment.settings = this.$refs.iceJourneySettings.getSettings()
            break
          case 'com.xzs.citstoregesture':
            this.newTreatment.settings = this.$refs.citStoreGestureSettings.getSettings()
            break
          case 'com.xzs.mathboxing2024':
            this.newTreatment.settings = this.$refs.mathBoxing2024Settings.getSettings()
            break
          case 'com.xzs.shootinggallery2':
            this.newTreatment.settings = this.$refs.shootinggallery2Settings.getSettings()
            break
          case 'com.xzs.adlsupermarket':
            this.newTreatment.settings = this.$refs.ADLSupermarketSettings.getSettings()
            break
          case 'com.xzs.fruittrain':
            this.newTreatment.settings = this.$refs.fruittrainSettings.getSettings()
            break
          case 'com.xzs.musicbeat':
            this.newTreatment.settings = this.$refs.musicbeatSettings.getSettings()
            break
          case 'com.xzs.ComaArousal':
            this.newTreatment.settings = this.$refs.ComaArousalSettings.getSettings()
            break
          case 'com.xzs.happypatting':
            this.newTreatment.settings = this.$refs.happypattingSettings.getSettings()
            break
          case 'com.xzs.simulatedtown':
            this.newTreatment.settings = this.$refs.SimulatedtownSettings.getSettings()
            break
          case 'com.xzs.postcard_mission':
            this.newTreatment.settings = this.$refs.postcardMissionSettings.getSettings()
            break
          case 'com.xzs.postalsearch':
            this.newTreatment.settings = this.$refs.postalSearchSettings.getSettings()
            break
          case 'com.xzs.postalblitz':
            this.newTreatment.settings = this.$refs.postalBlitzSettings.getSettings()
            break
          default:
            this.newTreatment.settings = this.$refs.commonsettings.getSettings()
            break
        }
      }

      if (this.pairingList.length === 0 && this.preAppId) {
        this.snackbar = true
        this.notifMsg = this.$t("paireEmptySelectDevice")
        this.snackbarColor = 'success'
      } else if (this.preAppId && this.pairingList.length > 0) {
        this.newTreatment.appId = this.preAppId || this.appsAvail[this.appIndex]?.model.ID
        this.pairingList.forEach(async item => {
          this.newTreatment.patientId = this.prePatientId || this.prePatientDetailId || item.userID
          this.newTreatment.deviceId = this.preDeviceId || item.model.ID
          const logToken = JSON.parse(window.localStorage.getItem('vuex'))
          const adminId = logToken.users.userId
          const role = logToken.users.role
          const userId = parseInt(
            role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
          )
          const result = await treatment.trainingState({
            patientId: this.newTreatment.patientId,
            deviceId: this.newTreatment.deviceId,
            userId: userId
          })
          if (result.data === 'success') {
            this.newTreatment.patientId = this.prePatientId || this.prePatientDetailId || item.userID
            this.newTreatment.deviceId = this.preDeviceId || item.model.ID
            const logToken = JSON.parse(window.localStorage.getItem('vuex'))
            const adminId = logToken.users.userId
            const role = logToken.users.role
            const userId = parseInt(
              role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
            )
            this.newTreatment.userId = userId
            await treatment.start(this.newTreatment)
            startTreatmentTime(new Date())
            this.clear()
            this.dialog = false
            this.$router.push('/monitors')
          } else {
            this.snackbar = true
            this.notifMsg = this.$t('notStartAgainTraining')
            this.snackbarColor = 'success'
          }
        })
      } else if (this.preDeviceId || this.prePatientId || this.prePatientDetailId || this.monitor == 'monitor') {

        this.newTreatment.patientId = parseInt(
          this.prePatientId ||
          this.prePatientDetailId ||
          this.patients(this.searchPatients)[this.userIndex].model.ID ||
          this.patientsAvail[this.userIndex]?.model.ID
        )
        this.newTreatment.deviceId =
          this.preDeviceId || this.devices(this.searchDevices)[this.devicesIndex].model.ID || this.devicesAvail[this.deviceIndex]?.model.ID
        this.newTreatment.appId =
          this.preAppId || this.apps(this.searchApps)[this.appIndex].model.ID || this.apps()[this.appIndex]?.model.ID
        const logToken = JSON.parse(window.localStorage.getItem('vuex'))
        const adminId = logToken.users.userId
        const role = logToken.users.role
        const userId = parseInt(
          role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
        )
        this.newTreatment.userId = userId
        const result = await treatment.trainingState({
          patientId: this.newTreatment.patientId,
          deviceId: this.newTreatment.deviceId,
          userId: userId
        })
        if (result.data === 'fail') {
          this.snackbar = true
          this.notifMsg = this.$t('notStartAgainTraining')
          this.snackbarColor = 'success'
        } else {
          await treatment.start(this.newTreatment)
          startTreatmentTime(new Date())
          this.clear()
          this.dialog = false
          this.$router.push('/monitors')
        }
      }
    },

    patients(query) {
      if (this.prePatientId || this.prePatientDetailId) {
        const index = this.patientsAvail.findIndex(x => x.model.ID == this.prePatientId || this.prePatientDetailId)
        this.patientIndex = 0
        return [this.patientsAvail[index]]
      }
      if (!query) return this.patientsAvail
      else {
        const filtered = filterByValue(this.patientsAvail, query)
        return filtered
      }
    },

    devices(query) {
      if (this.preDeviceId) {
        const index = this.devicesAvail.findIndex(x => x.model.ID == this.preDeviceId)
        this.deviceIndex = 0
        return [this.devicesAvail[index]]
      }
      if (!query) return this.devicesAvail
      else {
        const filtered = filterByValue(this.devicesAvail, query)
        return filtered
      }
    },

    apps(query) {
      const treatmentList = this.hideApps
      if (this.preAppId) {
        const index = treatmentList.findIndex(x => x.model.ID == this.preAppId)
        this.appIndex = 0
        return [treatmentList[index]]
      }
      if (this.prePatientDetailId) {
        const i = treatmentList.findIndex(x => x.packageName == 'com.xzs.cognitiveassessment')
        this.appIndex = 0
        return [treatmentList[i]]
      }
      if (!query) return treatmentList
      else {
        const filtered = filterByValue(treatmentList, query)
        return filtered
      }
    },

    getAppPackageName(appIndex, searchApps) {
      if (!(appIndex >= 0)) return null
      return this.apps(searchApps)[appIndex].packageName
    },

    getAppVoiceType(appIndex, searchApps) {
      if (!(appIndex >= 0)) return null
      return this.apps(searchApps)[appIndex].voiceType
    },

    getAppBGMType(appIndex, searchApps) {
      if (!(appIndex >= 0)) return null
      return this.apps(searchApps)[appIndex].bgm
    },

    getAppVideoType(appIndex, searchApps) {
      if (!(appIndex >= 0)) return null
      return this.apps(searchApps)[appIndex].videoType
    },

    getAppType(appIndex, searchApps) {
      if (!(appIndex >= 0)) return null
      return this.apps(searchApps)[appIndex].type
    },

    getAppCodename(appIndex, searchApps) {
      if (!(appIndex >= 0)) return null
      return this.apps(searchApps)[appIndex].codename
    }
  },

  components: {
    PatientMini,
    DeviceMini,
    AppMini,
    PostalSearchSettings,
    PostalBlitzSettings,
    PostcardMissionSettings,
    SimulatedtownSettings,
    CommonSettings,
    HappypattingSettings,
    FruittrainSettings,
    ADLSupermarketSettings,
    ShootingGallery2Settings,
    CITStoreGestureSettings,
    SppSettings,
    CITStoreSettings,
    DragonBeatSettings,
    WheelMazeSettings,
    ShadowSettings,
    PingPongSettings,
    ShootingGallerySettings,
    BreatheSettings,
    TaichiSettings,
    GrowSettings,
    bbqFlip,
    MemoryRoom,
    BrushteethSettings,
    HideNSeekSettings,
    SandTableRoom,
    CatharsisRoom,
    CognitiveAssessment,
    BasketballSettings,
    IceJourneySettings,
    MelodicPitch,
    Jellyfish,
    Snowballfight,
    mathBoxing,
    TowerOfLondonTest,
    AcrophobiaSettings,
    Keyboard,
    PainreliefSettings,
    EyeMovementSettings,
    ForestfriendsSettings,
    FruitpartySettings,
    GolfclubSettings,
    PuppydollhouseSettings,
    TennisSettings,
    RabbitStoreSettings,
    LaundrySettings,
    SailingadventureSettings,
    MathBoxing2024Settings,
    ScaleSettings,
    MusicbeatSettings,
    ComaArousalSettings
  },

  computed: {
    ...mapGetters({
      devicesAvail: 'devices/devicesAvail',
      patientsAvail: 'patients/patientsAvail',
      typeUsername: 'setting/userName',
      enableTouch: 'setting/enableTouch'
    }),

    ...mapState({
      appsAvail: state => state.apps.items
    })
  }
}
</script>

<style lang="sass" scoped>
.scrollable
  overflow-y: auto
.pairingBtn
  width: 347px
  height: 56px
  border-radius: 2px
  line-height: 56px
  display: flex
  justify-content: center
  color: #1976D2
  font-size: 14px
  position: relative
  background: #E4EFFA
.icon-close
  position: absolute
  top: -8px
  right: -9px
  height: 20px
  width: 20px
  text-align: center
  background: #777777
  border-radius: 50%
  color: #fff
  cursor: pointer
.icon-close:before
  position: relative
  top: -18px
.hide
  display: none
.show
  display: block
.listColor
  background: #E4EFFA
  color: #1976D2
.appList
  display: flex
  flex-wrap: wrap

#keyboard
  position: fixed
  top: auto !important
  bottom: 0
  z-index: 999
  padding: 14px 21px

::v-deep .mDialog.v-dialog:not(.v-dialog--fullscreen)
  max-height: 56%

::v-deep .cDialog.v-dialog:not(.v-dialog--fullscreen)
  max-height: 100%
</style>
