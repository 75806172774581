import { record } from '../../api'

const mapRecord = data => ({
  model: {
    ID: data.id,
    CreatedAt: data.created_at,
    UpdatedAt: data.updated_at,
    DeletedAt: data.deleted_at
  },
  treatmentId: data.treatment_id,
  data: JSON.parse(data.data)
})

export default {
  namespaced: true,

  state: {
    items: []
  },

  getters: {
    recordsByTreatmentId: state => treatmentId => {
      return state.items.filter(x => x.treatmentId == treatmentId)
    }
  },

  actions: {
    async index({ commit }) {
      const response = await record.index()
      response.data.map(x => (x.data = JSON.parse(x.data)))
      commit('index', response.data)
    },

    async indexByTreatment({ commit }, treatmentId) {
      const response = await record.indexByTreatment(treatmentId)
      response.data.map(x => (x.data = JSON.parse(x.data)))
      commit('indexByTreatment', response.data)
    },

    async pgevent({ commit }, payload) {
      switch (payload.action) {
        case 'INSERT':
          commit('add', mapRecord(payload.data))
          break
        case 'UPDATE':
          commit('edit', mapRecord(payload.data))
          break
        case 'DELETE':
          commit('remove', payload.data.id)
          break
        default:
          break
      }
    }
  },

  mutations: {
    index(state, records) {
      state.items = records
    },

    indexByTreatment(state, records) {
      state.items = records
    },

    add(state, payload) {
      state.items.push(payload)
    },

    edit(state, payload) {
      state.items.splice(
        state.items.findIndex(x => x.model.ID == payload.model.ID),
        1,
        payload
      )
    },

    remove(state, payload) {
      state.items.splice(
        state.items.findIndex(x => x.model.ID == payload),
        1
      )
    }
  }
}
