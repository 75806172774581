import 'jspdf-autotable'
// import { VUE_APP_SERVER_ENDPOINT } from '@/app.config'
import './Yahei-normal'
import html2Canvas from 'html2canvas'
import { jsPDF } from 'jspdf'

export default {
    install(Vue) {
        Vue.prototype.getPdf = function (content) {
            const doc = new jsPDF()
            doc.setFont('Yahei')
            const width = doc.internal.pageSize.getWidth()
            const height = doc.internal.pageSize.getHeight()
            const middle = width / 2 - 20
            const titleMiddle = width / 2
            const left = { align: 'left' }
            const center = { align: 'center' }
            // if (content.logo) {
            //     const img = new Image()
            //     img.src = `${VUE_APP_SERVER_ENDPOINT}/assets/${content.logo}`
            //     doc.addImage(encodeURIComponent(img.src), 10, 11, 22, 22, center)
            // }
            doc.setFontSize(16)
            doc.text(content.institution, titleMiddle, 20, center)
            doc.setFontSize(14)
            doc.text(content.reportName, titleMiddle, 30, center)
            doc.line(10, 38, width - 10, 38)
            doc.setFontSize(12)
            doc.text(`姓名：${content.patientsInfo.patient.name}`, 10, 45, left)
            doc.text(`性别：${content.patientsInfo.patient.gender}`, middle, 45, left)
            // console.log(content.patientsInfo.patient)

            if (content.enableAge) {
                doc.text(`年龄：${calcAge(content.patientsInfo.patient.birthday)}`, width - 30, 45, left)

            }

            doc.text(`${content.numberName ? content.numberName + '：' : '编号：'}${content.patientsInfo.patient.hospitalNumber}`, 10, 52, left)
            doc.text(
                `${content.bedNumberName != 'false'
                    ? content.bedNumberName == 'true'
                        ? content.typeUsername == '1'
                            ? '用户号：'
                            : '病床号：'
                        : content.bedNumberName + '：'
                    : ''
                }${content.bedNumberName !== 'false' ? content.patientsInfo.patient.patientNumber : ''}`,
                middle,
                52,
                left
            )
            doc.text(
                `${content.partition !== 'false' ? (content.partition == 'true' ? '分区：' : content.partition + '：') : ''}${content.partition !== 'false' ? content.patientsInfo.patient.bedNumber : ''
                }`,
                width - 30,
                52,
                left
            )
            doc.text(`治疗项目：${content.patientsInfo.app.name}`, 10, 59, left)

            doc.text(`治疗日期：${calcEndDateTime(content.patientsInfo.applicationStartTime, content.displayTime)}`, middle, 59, left)
            doc.text(`得分：${content.patientsInfo.scoreOverwrite}`, width - 30, 59, left)
            doc.line(10, 64, width - 10, 64)
            //表格列头
            var columns1 = ['治疗目标']
            //表格数据
            var rows1 = [
                [
                    content.patientsInfo.target == undefined || content.patientsInfo.target == ''
                        ? content.patientsInfo.app.target
                        : content.patientsInfo.target
                ]
            ]
            var columns2 = ['治疗内容']
            var rows2 = [
                [
                    content.patientsInfo.content == undefined || content.patientsInfo.content == ''
                        ? content.patientsInfo.app.content
                        : content.patientsInfo.content
                ]
            ]
            var columns3 = ['治疗效果']
            var rows3 = [[content.patientsInfo.effect]]
            var columns4 = ['下次治疗计划']
            var rows4 = [[content.patientsInfo.plan]]
            // console.log(rows1)
            doc.autoTable({
                theme: 'plain',
                styles: { font: 'Yahei', fontSize: 10 },
                bodyStyles: { fillColor: '#F5F5F5', minCellHeight: 30, textColor: '#000000' },
                margin: { left: 10, right: 10 },
                startY: 70,
                head: [columns1],
                body: rows1
            })

            doc.autoTable({
                theme: 'plain',
                styles: { font: 'Yahei', fontSize: 10 },
                bodyStyles: { fillColor: '#F5F5F5', minCellHeight: 30, textColor: '#000000' },
                margin: { left: 10, right: 10 },
                startY: 100,
                head: [columns2],
                body: rows2
            })

            doc.autoTable({
                theme: 'plain',
                styles: { font: 'Yahei', fontSize: 10 },
                bodyStyles: { fillColor: '#F5F5F5', minCellHeight: 30, textColor: '#000000' },
                margin: { left: 10, right: 10 },
                startY: 135,
                head: [columns3],
                body: rows3
            })
            doc.setFontSize(8)
            content.printDom ? doc.text('得分情况', 10, height - 120, left) : ''
            content.printDom
                ? html2Canvas(content.printDom, {
                    allowTaint: true,
                    taintTest: false,
                    useCORS: true,
                    // y: 72,
                    dpi: window.devicePixelRatio * 4,
                    scale: 4,
                    padding: { left: 10, right: 10 }
                }).then(function (canvas) {
                    let contentWidth = canvas.width
                    let contentHeight = canvas.height
                    let pageHeight = (contentWidth / 592.28) * 841.89
                    let leftHeight = contentHeight
                    let position = 30
                    let pageData = canvas.toDataURL('image/jpeg', 1.0)
                    if (leftHeight < pageHeight) {
                        doc.addImage(pageData, 'JPEG', 10, height - 115, content.pdfDomGraph ? 90 : 200, 50)
                    } else {
                        while (leftHeight > 0) {
                            doc.addImage(pageData, 'JPEG', 0, position, 90, 60)
                            leftHeight -= pageHeight
                            position -= 841.89
                            if (leftHeight > 0) {
                                doc.addPage()
                            }
                        }
                    }
                })
                : ''

            doc.setFontSize(8)
            content.pdfDomGraph ? doc.text('报告图表', content.printDom ? 105 : 10, height - 120, left) : ''
            console.log(content.pdfDomGraph)
            content.pdfDomGraph
                ? html2Canvas(content.pdfDomGraph, {
                    allowTaint: true,
                    taintTest: false,
                    useCORS: true,
                    // y: 72,
                    dpi: window.devicePixelRatio * 4,
                    scale: 4,
                    padding: { left: 10, right: 10 }
                }).then(function (canvas) {
                    let contentWidth = canvas.width
                    let contentHeight = canvas.height
                    let pageHeight = (contentWidth / 592.28) * 841.89
                    let leftHeight = contentHeight
                    let position = 30
                    let pageData = canvas.toDataURL('image/jpeg', 1.0)
                    if (leftHeight < pageHeight) {
                        doc.addImage(pageData, 'JPEG', content.printDom ? 100 : 5, height - 115, content.printDom ? 100 : 200, 50)
                    } else {
                        while (leftHeight > 0) {
                            doc.addImage(pageData, 'JPEG', 0, position, 90, 60)
                            leftHeight -= pageHeight
                            position -= 841.89
                            if (leftHeight > 0) {
                                doc.addPage()
                            }
                        }
                    }
                })
                : ''

            doc.autoTable({
                theme: 'plain',
                styles: { font: 'Yahei', fontSize: 10 },
                bodyStyles: { fillColor: '#F5F5F5', minCellHeight: 30, textColor: '#000000' },
                margin: { left: 10, right: 10 },
                startY: content.pdfDomGraph || content.printDom ? 235 : 172,
                head: [columns4],
                body: rows4
            })
            doc.setFontSize(8)
            doc.text(`打印时间：${calcDateTime(new Date())}`, 10, height - 15, left)
            doc.text('*此报告仅供临床参考', 10, height - 10, left)
            doc.setFontSize(12)
            doc.text('签字：', width / 2 + 20, height - 12, left)
            setTimeout(() => {
                window.open(doc.output('bloburl'), '_blank')
            }, 2000)
        }
    }
}

function calcAge(dateString) {
    var birthday = +new Date(dateString)
    return ~~((Date.now() - birthday) / 31557600000)
}

function calcDateTime(dateString, displayTime = true) {
    const date = new Date(dateString)
    if (displayTime) {
        return (
            date.toLocaleDateString('zh-CN', { hour12: false }) + ' ' + date.toLocaleTimeString('zh-CN', { hour: '2-digit', minute: '2-digit' })
        )
    } else {
        return date.toLocaleDateString('zh-CN', { hour12: false })
    }
}

function calcEndDateTime(dateString, displayTime = true) {
    const date = new Date(dateString)
    if (displayTime) {
        return (
            date.toLocaleDateString('zh-CN', { hour12: false }) + ' ' + date.toLocaleTimeString('zh-CN', { hour: '2-digit', minute: '2-digit' })
        )
    } else {
        return date.toLocaleDateString('zh-CN', { hour12: false })
    }
}
