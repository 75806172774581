import axios from 'axios'
import { VUE_APP_SERVER_ENDPOINT } from '@/app.config'

async function index(id) {
  return await axios.get(`${VUE_APP_SERVER_ENDPOINT}/setting/${id}`)
}

async function update(setting) {
  const json = JSON.stringify(setting)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/setting`, json)
}

async function upload(img) {
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/upload`, img, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export default {
  index,
  update,
  upload
}
