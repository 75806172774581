<template>
  <div>
    <v-dialog ref="dialog" v-model="dateModal" :return-value.sync="date" persistent width="290px">
      <template v-slot:activator="{ on }">
        <v-text-field v-model="date" :suffix="time" prepend-icon="event" readonly v-on="on"></v-text-field>
      </template>
      <v-date-picker v-model="date" value="hahaha" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dateModal = false">Cancel</v-btn>
        <v-btn text color="primary" @click="timeModal = true">OK</v-btn>
      </v-date-picker>
    </v-dialog>

    <v-dialog ref="dialog2" v-model="timeModal" :return-value.sync="time" persistent width="290px">
      <v-time-picker v-if="timeModal" v-model="time" format="24">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="timeModal = false">Cancel</v-btn>
        <v-btn text color="primary" @click="set()">OK</v-btn>
      </v-time-picker>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DateTimePicker',

  props: ['datetime'],

  data: vm => ({
    date: (vm.datetime && vm.datetime.split('T')[0]) || '',
    dateModal: false,
    time: (vm.datetime && vm.joinTime(vm.datetime.split('T')[1])) || '',
    timeModal: false
  }),

  watch: {
    datetime: function (val) {
      this.date = val.split('T')[0]
      this.time = this.joinTime(val.split('T')[1])
    }
  },

  computed: {
    output() {
      const timeString = `${this.date}T${this.time}:00+08:00`
      const rawTime = new Date(timeString)
      const rawTimeStamp = rawTime.getTime()
      const timeStamp = rawTimeStamp - 8 * 60 * 60 * 1000
      const time = new Date(timeStamp)
      const year = time.getFullYear()
      const month = time.getMonth() + 1
      const day = time.getDate()
      const hour = time.getHours()
      const minutes = time.getMinutes()
      const seconds = time.getSeconds()
      const outputString =
        year +
        '-' +
        (month < 10 ? '0' + month : month) +
        '-' +
        (day < 10 ? '0' + day : day) +
        'T' +
        (hour < 10 ? '0' + hour : hour) +
        ':' +
        (minutes < 10 ? '0' + minutes : minutes) +
        ':' +
        (seconds < 10 ? '0' + seconds : seconds) +
        'Z'
      return outputString
    }
  },

  methods: {
    set() {
      this.$refs.dialog.save(this.date)
      this.$refs.dialog2.save(this.time)
    },

    joinTime(time) {
      return time.slice(0, 5)
    }
  }
}
</script>