<template lang='pug'>
div(style='height: 50vh')
  v-toolbar.mb-6.topBar(flat, style='margin-left: -15px; height: 80px')
    img(src='@/assets/title_logo.png', width='50', style='margin-right: 10px; margin-top: 25px', v-if='!isOpen', @click='setWidth')
    v-toolbar-title(v-if='isOpen') {{ $t("scaleAssessment") }}
    h3(v-if='!isOpen', style='margin-top: 25px; font-size: 19px') {{ $t("scaleAssessment") }}
    v-divider.mx-4(inset, vertical, v-if='isOpen')
  v-row(xl='4', lg='6', md='12', style='margin-top: 60px')
    v-col(xl='4', lg='6', md='12', v-for='(item, i) in gaugeList', :key='i')
      v-card.mx-auto
        v-img(:src='getThumbnailSrc(item.img)')
        v-card-title {{ item.name }}
        v-card-subtitle
          .mt-4
          div(style='display: flex; flex-direction: column')
            v-btn(@click='printPaper(item)')
              | {{ $t("printEvaluationPaper") }}
            v-btn(color='primary', @click='start(item)', style='margin-top: 10px; margin-bottom: 20px')
              v-icon(left) mdi-launch
              | {{ $t("startAssessment") }}
  v-dialog(v-model='dialog', persistent, @click:outside='visible = false')
    v-card
      v-card-title.primary.headline.mb-12
        h4(style='color: white') {{ $t("revieweeInformation") }}
      v-card-text
        v-form(ref='form', v-model='valid')
          v-col
            v-text-field(
              v-model='newTreatment.name',
              :label="$t('name')",
              prepend-inner-icon='mdi-account',
              maxlength='6',
              counter,
              required,
              @click.stop.native='isOpen ? (visible = false) : (visible = true)',
              @focus.stop='keyboardShowClick'
            )
            p(v-if='nameValidShow', style='color: red') {{ $t("pleaseEnterName") }}
            v-select(:items='genders', v-model='newTreatment.gender', :label="$t('gender')", prepend-inner-icon='mdi-gender-male-female', required)
            p(v-if='genderValidShow', style='color: red') {{ $t("pleaseEnterGender") }}
            v-text-field(
              v-model='newTreatment.birthday',
              :label="$t('age')",
              prepend-inner-icon='mdi-calendar',
              maxlength='3',
              counter,
              type='number',
              :min='1',
              onkeyup='this.value=this.value.replace(/\D|^0/g,\'\')',
              onafterpaste='this.value=this.value.replace(/\D|^0/g,\'\')',
              @click.stop.native='isOpen ? (visible = false) : (visible = true)',
              @focus.stop='keyboardShowClick'
            )
            p(v-if='birthdayValidShow', style='color: red') {{ $t("pleaseEnterAge") }}
            v-text-field(
              v-model='newTreatment.idNumber',
              label='',
              prepend-inner-icon='mdi-identifier',
              maxlength='18',
              counter,
              @click.stop.native='isOpen ? (visible = false) : (visible = true)',
              @focus.stop='keyboardShowClick'
            )
            p(v-if='idValidShow', style='color: red') {{ $t("pleaseEnterIdentificationNumber") }}
            v-text-field(
              v-model='newTreatment.phoneNumber',
              :label="$t('telephoneNumber')",
              type='number',
              prepend-inner-icon='mdi-phone',
              maxlength='11',
              counter,
              @click.stop.native='isOpen ? (visible = false) : (visible = true)',
              @focus.stop='keyboardShowClick'
            )
            p(v-if='phoneValidShow', style='color: red') {{ $t("pleaseEnterPhoneNumber") }}
      v-card-actions
        v-spacer
        v-btn(text, @click='cancel(); dialog = false; visible = false') {{ $t("drCancel") }}
        v-btn(text, @click='save(); visible = false') {{ $t("start") }}
</template>


<script>
import NewGauge from './NewGauge'
import { mapState } from 'vuex'
import { VUE_APP_FILE_SERVER_ENDPOINT } from '@/app.config'
import { NavigationDrawer } from '..'
import { gauge, patient } from '../../api'

export default {
  data: () => ({
    tags: {},
    genders: {},
    isOpen: false,
    navShow: false,
    showFloatingActions: false,
    scaleId: undefined,

    dialog: false,
    visible: false,
    newPatient: {},

    valid: true,
    
    birthdayMenu: false,

    newTreatment: {},

    nameValidShow: false,
    genderValidShow: false,
    birthdayValidShow: false,
    idValidShow: false,
    phoneValidShow: false
  }),

  components: {
    NewGauge,
    NavigationDrawer
  },

  computed: {
    ...mapState({
      gaugeList: state => state.gauge.items
    })
  },

  mounted() {
    window.addEventListener('resize', this.resize, false)
    this.resize()
    this.tags = [this.$t("orientation"), this.$t("memory"), this.$t("attention"), this.$t("calculation"), this.$t("recallAbility"), this.$t("languageAbility")]
    this.genders = [this.$t("male"), this.$t("female")]
  },

  methods: {
    setWidth() {
      this.navShow = true
    },
    selectClick(val) {
      if (!this.isOpen) {
        this.navShow = val
      } else {
        this.navShow = true
      }
    },
    outClick() {
      if (this.isOpen) {
        this.navShow = true
      } else {
        this.navShow = false
      }
    },
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    getTags(item) {
      return item?.tags.split(';')
    },
    start(item) {
      this.scaleId = item?.model.ID
      this.dialog = true
    },
    printPaper(item) {
      const content = {
        id: item.model.ID,
        logo: `${VUE_APP_FILE_SERVER_ENDPOINT}/mmse_close.png`,
        logo1: `${VUE_APP_FILE_SERVER_ENDPOINT}/moca_pic.png`
      }
      import('../../lib/print-mmsePaper').then(({ printPDF }) => {
        printPDF(content)
      })
    },
    getThumbnailSrc(src) {
      return `${VUE_APP_FILE_SERVER_ENDPOINT}/${src}`
    },
    keyboardShowClick(e) {
      if (this.isOpen) {
        this.input = ''
        this.visible = false
      } else {
        this.input = e.target
        if (!this.visible) this.visible = true
      }
    },
    cancel() {
      this.dialog = false
    },

    async addPatient() {
      var birthday = ''
      if (this.newTreatment.birthday !== undefined) {
        const year = new Date().getFullYear() - Number(this.newTreatment.birthday)
        birthday = year + '-' + '01' + '-' + '01'
      } else {
        birthday = '2099-06-01'
      }
      birthday = birthday + 'T00:00:00Z'

      let p = {
        name: this.newTreatment.name,
        hospitalNumber: '0',
        bedNumber: '0',
        gender: this.newTreatment.gender,
        patientNumber: '0',
        idNumber: this.newTreatment.idNumber,
        remarks: this.newTreatment.phoneNumber,
        birthday,
        adminId: 1,
        userId: 1,
        role: 'admin'
      }

      const result = await patient.add(p)
      return result.data
    },

    async save() {
      if (!this.newTreatment.name) {
        this.nameValidShow = true
        return
      }
      if (!this.newTreatment.gender) {
        this.genderValidShow = true
        return
      }
      if (!this.newTreatment.birthday) {
        this.birthdayValidShow = true
        return
      }
      if (!this.newTreatment.idNumber) {
        this.idValidShow = true
        return
      }
      if (!this.newTreatment.phoneNumber) {
        this.phoneValidShow = true
        return
      }

      const patientId = await this.addPatient()

      this.newTreatment = {
        assessmentId: this.scaleId,
        patientId,
        complete: false,
        score: 0,
        data: '',
        userId: 1,
        model: {},
        type: 3
      }

      const result = await gauge.examination(this.newTreatment)
      this.newTreatment.model.ID = result.data
      this.$router.push({
        path: `/gauge/${this.newTreatment.assessmentId}`,
        query: this.newTreatment
      })
    }
  }
}
</script>


<style lang="scss" scoped>
.topBar {
  width: 100%;
  height: 54px;
  position: fixed;
  top: 0;
  z-index: 2;
}
</style>