import axios from 'axios'
import { VUE_APP_SERVER_ENDPOINT } from '@/app.config'

async function index(obj) {
  const json = JSON.stringify(obj)
  let url = ""
  if (localStorage.getItem('lang') == null) {
    url = `${VUE_APP_SERVER_ENDPOINT}/app`
  }
  else url = `${VUE_APP_SERVER_ENDPOINT}/${localStorage.getItem('lang')}/app`
  return await axios.post(url, json)
}

async function hideApps(show) {
  const json = JSON.stringify(show)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/app/show`, json)
}

async function categoryUserIndex(obj) {
  const json = JSON.stringify(obj)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/navigation`, json)
}

async function appAll() {
  let url = ""
  if (localStorage.getItem('lang') == null) {
    url = `${VUE_APP_SERVER_ENDPOINT}/app/all`
  }
  else url = `${VUE_APP_SERVER_ENDPOINT}/${localStorage.getItem('lang')}/app/all`
  return await axios.get(url)
}

export default {
  index,
  hideApps,
  categoryUserIndex,
  appAll
}
