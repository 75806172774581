import axios from 'axios'
import { VUE_APP_SERVER_ENDPOINT } from '@/app.config'

async function login({ username, password }) {
  const json = JSON.stringify({ username, password })
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/login`, json)
}

async function user(username) {
  return await axios.get(`${VUE_APP_SERVER_ENDPOINT}/user${username!==undefined? `?username=${username}` : ''}`)
}

async function addUser(users) {
  const json = JSON.stringify(users)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/user`, json)
}

export default {
  login,
  user,
  addUser
}