const tables = ['setting', 'devices', 'apps', 'patients', 'treatments', 'gauge', 'gaugeRecords', 'about']

async function index(dispatch) {
  for (const table of tables) {
    await dispatcher(dispatch, table)
  }
}

async function dispatcher(dispatch, table) {
  switch (table) {
    case 'setting':
      await dispatch('setting/index')
      break
    case 'devices':
      await dispatch('devices/index')
      break
    case 'apps':
      await dispatch('apps/index')
      await dispatch('apps/appsAll')
      break
    case 'patients':
      await dispatch('patients/index')
      break
    case 'treatments':
      await dispatch('treatments/index')
      break
    case 'records':
      await dispatch('records/index')
      break
    case 'gauge':
      await dispatch('gauge/index')
      break
    case 'gaugeRecords':
      await dispatch('gaugeRecords/index')
      break
    case 'about':
      await dispatch('about/index')
      break
    default:
      break
  }
}

export default {
  state: {
    init: false
  },

  getters: {
    loading: state => !state.init
  },

  actions: {
    async init({ commit, dispatch }) {
      await index(dispatch)
      commit('init', true)
    }
  },

  mutations: {
    init(state, payload) {
      state.init = payload
    }
  }
}
