import { gaugeRecords } from '../../api'

export default {
  namespaced: true,
  state: {
    items: [],
  },

  getters: {
    treatmentsWADP: (state, getter, rootState) => {
      return [...state.items].map(x => {
        const patient = rootState['patients'].items.find(y => y.model.ID == x.patientId)
        if (patient) x.patient = patient
        const gaugeName = rootState['gauge'].items.find(y => y.model.ID == x.assessmentId)
        if (gaugeName) x.gaugeName = gaugeName
        return x
      })
    },
  },

  actions: {
    async index({ commit }) {
      var userId = 0
      if(JSON.parse(window.localStorage.getItem('vuex')).users.role != undefined){
        const logToken = JSON.parse(window.localStorage.getItem('vuex'))
        const adminId = logToken.users.userId
        const role = logToken.users.role
        userId = parseInt(role == 'admin'?(window.localStorage.getItem('userId')? window.localStorage.getItem('userId') : 1):adminId)
      }else{
        userId = 1
      }
      const response = await gaugeRecords.index( '', '', '','',userId)
      commit('index', response.data)
    },
  },

  mutations: {
    index(state, payload) {
      state.items = payload
    },
  }

}
