<template lang='pug'>
v-dialog(v-model='gaugeDialog', persistent, :width='prePatientId == undefined ? 550 : 800', @click:outside='closeKeyboard')
  v-toolbar(dense, dark, color='primary', style='height: 60px')
    v-toolbar-title {{ $t("patient") }}
    el-input.input(
      :placeholder="$t('pleaseEnterContent')",
      suffix-icon='el-icon-search',
      v-model='searchPatients',
      :style='{ marginLeft: prePatientId == undefined ? "160px" : "300px" }',
      @click.stop='isOpen ? (visible = false) : (visible = true)',
      @focus.stop='keyboardShowClick'
    )
  v-card
    v-card-title
    v-card-text
      v-row
        v-col(cols='12', :sm='prePatientId == undefined ? 12 : 6', :md='prePatientId == undefined ? 12 : 6') 
          |
          v-card
            v-card-text
              v-list.overflow-y-auto(style='height: 400px')
                v-list-item-group(v-model='patientIndex')
                  v-list-item(v-for='(item, i) in patients(searchPatients)', :key='i', @click='userClick(item)')
                    v-list-item-content.px-6.my-4
                      PatientMini(:patientId='item.model.ID', :ref='`patients/${i}`')
        v-col(
          cols='12',
          :sm='prePatientId == undefined ? 12 : 6',
          :md='prePatientId == undefined ? 12 : 6',
          v-if='prePatientId !== undefined'
        ) 
          |
          v-card
            v-card-text
              v-list.overflow-y-auto(style='height: 400px')
                v-list-item-group
                  v-list-item(v-for='(item, i) in gaugeList', :key='i', @click='gaugeClick(item)')
                    v-list-item-content.px-6.my-4
                      v-row(align='center')
                        pre.body-1 {{ item.name }}

    v-card-actions
      v-spacer
      //- v-btn( text @click="clear()" ) {{ $t("dialogClear") }}
      v-btn(text, @click='(gaugeDialog = false), (visible = false)') {{ $t("dialogCancel") }}
      v-btn(color='primary', @click='start(), (visible = false)')
        v-icon(left) mdi-launch
        | {{ $t("dialogStart") }}

  v-snackbar(top, right, elevation='2', v-model='snackbar', :color='snackbarColor', timeout='2000') {{ notifMsg }}
    template(v-slot:action='{ attrs }')
      v-btn(color='blue', text, v-bind='attrs', @click='snackbar = false') Close

  Keyboard#keyboard(layout='normal', :input='input', v-if='visible && enableTouch', :cancel='cancel')
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { gauge } from '../../api'
import PatientMini from './../PatientMini'
import Keyboard from 'vue-keyboard-zh'

function filterByValue(arr, term) {
  return arr.filter(obj => {
    return Object.keys(obj).reduce((acc, curr) => {
      return acc || String(obj[curr]).toLowerCase().includes(term)
    }, false)
  })
}

export default {
  props: ['monitor'],
  data: () => ({
    gaugeDialog: false,
    searchPatients: undefined,
    patientIndex: undefined,
    prePatientId: undefined,
    assessmentId: undefined,

    newTreatment: {
      assessmentId: undefined,
      patientId: undefined,
      complete: false,
      score: 0,
      data: '',
      userId: 0,
      model: {
        ID: 0
      },
      type: 3
    },
    pairingText: '',
    pairingDevices: '',
    userId: '',
    devicesId: '',
    userIndex: '',
    devicesIndex: '',
    userItem: {},
    devicesItem: {},
    snackbarColor: '',
    notifMsg: '',
    snackbar: false,
    pairingList: [],
    listColor: '',
    devicesList: [],
    trainingState: '',
    pairingListShow: true,
    hideApps: [],
    appsdata: [],
    data: 0,
    keyboardShow: false,
    inputType: '',
    visible: false,
    input: '',
    isOpen: false,
    patientDetailData: []
  }),

  mounted() {
    window.addEventListener('resize', this.resize, false)
    this.resize()
  },

  watch: {
    patientIndex: function (val) {
      if (this.$refs[`patients/${val}`]) {
        const el = this.$refs[`patients/${val}`][0].$el
        el.scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth'
        })
      }
    },
    patientsAvail: function (val) {
      for (let i = 0; i < val.length; i++) {
        for (let j = 0; j < this.pairingList.length; j++) {
          if (val[i].model.ID == this.pairingList[j].userID) {
            this.patients(this.searchPatients).splice(i, 1)
          }
        }
      }
    },
    prePatientId: function (val) {
      if (val) {
        this.userIndex = 0
        this.listColor = 'listColor'
        this.pairingListShow = false
      }
    },
    gaugeDialog: function (val) {
      if (val) {
        this.searchPatients = ''
      }
    }
  },

  methods: {
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    keyboardShowClick(e) {
      if (this.isOpen) {
        this.input = ''
        this.visible = false
      } else {
        this.input = e.target
        if (!this.visible) this.visible = true
      }
    },
    cancel() {
      this.visible = false
    },

    closeKeyboard() {
      this.visible = false
    },

    userClick(item) {
      this.newTreatment.assessmentId = this.assessmentId
      this.newTreatment.patientId = item.model.ID
    },

    gaugeClick(item) {
      this.newTreatment.patientId = parseInt(this.prePatientId)
      this.newTreatment.assessmentId = item.model.ID
    },

    async start() {
      try {
        const logToken = JSON.parse(window.localStorage.getItem('vuex'))
        const adminId = logToken.users.userId
        const role = logToken.users.role
        const userId = parseInt(
          role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
        )
        this.newTreatment.userId = userId
        const result = await gauge.examination(this.newTreatment)
        this.newTreatment.model.ID = result.data
        this.$router.push({
          path: `/gauge/${this.newTreatment.assessmentId}`,
          query: this.newTreatment
        })
      } catch (err) {
        this.snackbar = true
        this.snackbarColor = 'error'
        this.notifMsg = this.$t('requestErrorSelectUser') 
      }
    },

    patients(query) {
      if (this.prePatientId) {
        const index = this.patientsAvail.findIndex(x => x.model.ID == this.prePatientId)
        this.patientIndex = 0
        return [this.patientsAvail[index]]
      }
      if (!query) return this.patientsAvail
      else {
        const filtered = filterByValue(this.patientsAvail, query)
        return filtered
      }
    }
  },

  components: {
    PatientMini,
    Keyboard
  },

  computed: {
    ...mapGetters({
      patientsAvail: 'patients/patientsAvail',
      enableTouch: 'setting/enableTouch'
    }),

    ...mapState({
      appsAvail: state => state.apps.items,
      gaugeList: state => state.gauge.items
    })
  }
}
</script>

<style lang="sass" scoped>
::v-deep .v-list-item--active
  color: #1976D2 !important
.scrollable
  overflow-y: auto
.pairingBtn
  width: 347px
  height: 56px
  border-radius: 2px
  line-height: 56px
  display: flex
  justify-content: center
  color: #1976D2
  font-size: 14px
  position: relative
  background: #E4EFFA
.icon-close
  position: absolute
  top: -8px
  right: -9px
  height: 20px
  width: 20px
  text-align: center
  background: #777777
  border-radius: 50%
  color: #fff
  cursor: pointer
.icon-close:before
  position: relative
  top: -18px
.hide
  display: none
.show
  display: block
.listColor
  background: #E4EFFA
  color: #1976D2
.select
  overflow: auto

.pairingBtn
  height: 56px
  border-radius: 2px
  line-height: 56px
  display: flex
  justify-content: center
  color: #1976D2
  font-size: 14px
  position: relative
  background: #E4EFFA
.icon-close
  position: absolute
  top: -8px
  right: -9px
  height: 20px
  width: 20px
  text-align: center
  background: #777777
  border-radius: 50%
  color: #fff
  cursor: pointer
.icon-close:before
  position: relative
  top: -18px
  top: -18px
::v-deep .el-input
  width: 120px
  position: relative
  left: 210px
  color: #fff
::v-deep .el-select
  width: 120px
  position: relative
  left: -10px
::v-deep .el-input__inner
  border-radius: 55px
  height: 35px
  background: #1976D2
  color: #fff
  border: 1px solid #3788D8

::v-deep .el-input__icon
  line-height: 35px
  color: #fff
::v-deep .el-select .el-input .el-select__caret
  color: #fff
::v-deep .v-input__slot
  min-height: 35px !important
  line-height: 35px !important
.listColor
  background: #E4EFFA
  color: #1976D2
::v-deep input::-webkit-input-placeholder
  -webkit-text-fill-color: #fff
::v-deep .v-application .primary--text
  color: red !important

#app
  width: 100%
  font-family: "Avenir", Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  position: absolute
  z-index: 999
  left: 50%
  bottom: 0
  transform: translateX(-50%)
::v-deep .simple-keyboard
  max-width: 100%
  max-height: 500px
#keyboard
  position: absolute
  top: auto !important
  bottom: 0
  z-index: 999
  padding: 14px 21px
</style>

