<template lang="pug">
div
  v-dialog(v-model='batchDialog', width='1200', persistent, @click:outside='visible = false')
    v-card
      v-card-title.primary.headline.mb-12
        h4(style='color: white') {{ $t("daTitle") }}
      v-card-text
        v-form(ref='form', v-model='valid', v-if='isOpen')
          v-row(style='margin: 0')
            v-btn.btn(elevation='2', outlined, plain, @click='addHandleClick()') {{ $t("addInBatches") }}
          v-row(v-for='(item, index) in newItem', :key='index')
            v-col(cols='12', sm='5', md='5')
              v-text-field(
                v-model='item.name',
                :label='$t("name")',
                solo,
                prepend-inner-icon='mdi-account',
                :rules='rules.nameRules',
                maxlength='20',
                counter,
                required
              )
            v-col(cols='12', sm='3', md='3')
              v-text-field(
                v-model='item.hospitalNumber',
                :label='numberName ? numberName : $t("number")',
                solo,
                prepend-inner-icon='mdi-clipboard-account',
                :rules='rules.patientNumberRules',
                maxlength='10',
                counter,
                required
              )
            v-col(cols='12', sm='3', md='3')
              v-select(
                :items='genders',
                v-model='item.gender',
                :label='$t("daGender")',
                solo,
                prepend-inner-icon='mdi-gender-male-female',
                :rules='rules.genderRules',
                required
              )
            //- v-col(cols='12', sm='2', md='3')
            //-   v-text-field(
            //-     v-model='item.birthday',
            //-     :label='$t("daAge")',
            //-     solo,
            //-     prepend-inner-icon='mdi-calendar',
            //-     :rules='rules.birthdayRules',
            //-     maxlength='3',
            //-     required,
            //-     type='number',
            //-     :min='1',
            //-     onkeyup='this.value=this.value.replace(/\D|^0/g,\'\')',
            //-     onafterpaste='this.value=this.value.replace(/\D|^0/g,\'\')'
            //-   )
            v-spacer
            v-col(cols='12', sm='1', md='1', style='position: relative; left: -10px; top: -5px')
              v-tooltip(bottom)
                template(v-slot:activator='{ on, attrs }')
                  v-btn(fab, dark, color='orange', @click.stop='deleteBtnClick(item)', v-bind='attrs', v-on='on')
                    v-icon mdi-minus
                span {{ $t("addPatient") }}

        v-form.mDialog(ref='form', v-model='valid', v-if='!isOpen')
          v-row(style='margin: 0')
            v-btn.btn(elevation='2', outlined, plain, @click='addHandleClick()') {{ $t("addInBatches") }}
          v-row(v-for='(item, index) in newItem', :key='index')
            v-col(cols='12', sm='4', md='4')
              v-text-field(
                v-model='item.name',
                :label='$t("name")',
                solo,
                prepend-inner-icon='mdi-account',
                :rules='rules.nameRules',
                maxlength='20',
                counter,
                required,
                @click.stop.native='visible = true',
                @focus.stop='keyboardShowClick'
              )
            v-col(cols='12', sm='3', md='3')
              v-text-field(
                v-model='item.hospitalNumber',
                :label='numberName ? numberName : $t("number")',
                solo,
                prepend-inner-icon='mdi-clipboard-account',
                :rules='rules.patientNumberRules',
                maxlength='15',
                counter,
                required,
                @click.stop.native='visible = true',
                @focus.stop='keyboardShowClick'
              )
            v-col(cols='12', sm='3', md='3')
              v-select(
                :items='genders',
                v-model='item.gender',
                :label='$t("daGender")',
                solo,
                prepend-inner-icon='mdi-gender-male-female',
                :rules='rules.genderRules',
                required
              )
            //- v-col(cols='12', sm='2', md='3')
            //-   v-text-field(
            //-     v-model='item.birthday',
            //-     :label='$t("daAge")',
            //-     solo,
            //-     prepend-inner-icon='mdi-calendar',
            //-     :rules='rules.birthdayRules',
            //-     maxlength='3',
            //-     required,
            //-     type='number',
            //-     :min='1',
            //-     onkeyup='this.value=this.value.replace(/\D|^0/g,\'\')',
            //-     onafterpaste='this.value=this.value.replace(/\D|^0/g,\'\')',
            //-     @click.stop.native='visible = true',
            //-     @focus.stop='keyboardShowClick'
            //-   )
            v-spacer
            v-col(cols='12', sm='1', md='1', style='position: relative; left: -30px; top: -5px')
              v-tooltip(bottom)
                template(v-slot:activator='{ on, attrs }')
                  v-btn(fab, dark, color='orange', @click.stop='deleteBtnClick(item)', v-bind='attrs', v-on='on')
                    v-icon mdi-minus
                span {{ $t("addPatient") }}

        v-card-actions
          v-spacer
          v-btn(text, @click='clearClick; visible = false') {{ $t("daClear") }}
          v-btn(text, @click='cancelClick(); visible = false') {{ $t("daCancel") }}
          v-btn(text, @click='save(); visible = false') {{ $t("daSave") }}
  v-snackbar(elevation='24', v-model='snackbar', :color='snackbarColor', timeout='3000') {{ notifMsg }}
    template(v-slot:action='{ attrs }')
      v-btn(text, v-bind='attrs', @click='snackbar = false') {{ $t("notifClose") }}

  Keyboard#keyboard(layout='normal', :input='input', v-if='visible && enableTouch', :cancel='cancel')
</template>

<script>
import { mapGetters } from 'vuex'
import { patient } from '../../api'
import Keyboard from 'vue-keyboard-zh'
export default {
  props: ['batchDialog', 'typeUsername'],
  data: () => ({
    newItem: [
      {
        name: '',
        hospitalNumber: '',
        gender: '',
        birthday: '2099-01-01T00:00:00Z'
      }
    ],
    valid: true,
    genders: {},
    nowDate: '',
    snackbarColor: '',
    notifMsg: '',
    snackbar: false,
    input: '',
    visible: false,
    isOpen: false
  }),

  components: {
    Keyboard
  },

  computed: {
    ...mapGetters({
      bedNumberName: 'setting/bedNumberName',
      numberName: 'setting/numberName',
      enableTouch: 'setting/enableTouch'
    }),

    rules() {
      let rules = {}
      if (this.typeUsername == '1') {
        rules = {
          nameRules: [v => !!v || this.$t('daNameRule')],
          patientNumberRules: [v => !!v || `${this.numberName ? this.numberName : this.$t('number')}${this.$t("mustRequired")}`],
          bedNumberRules: [],
          genderRules: [v => !!v || this.$t('daGenderRule')],
          birthdayRules: [v => !!v || this.$t('daBirthdayRule'), v => /^([1-9]\d?|1[01]\d|120)$/.test(v) || this.$t('ageMax')]
        }
      } else {
        rules = {
          nameRules: [v => !!v || this.$t('daNameRule')],
          patientNumberRules: [v => !!v || `${this.numberName ? this.numberName : this.$t('number')}${this.$t("mustRequired")}`],
          bedNumberRules: [],
          genderRules: [v => !!v || this.$t('daGenderRule')],
          birthdayRules: [v => !!v || this.$t('daBirthdayRule'), v => /^([1-9]\d?|1[01]\d|120)$/.test(v) || this.$t('ageMax')]
        }
      }
      return rules
    }
  },

  mounted() {
    this.resize()
    this.genders = [this.$t("male"), this.$t("female")]
    window.addEventListener('resize', this.resize, false)
  },

  methods: {
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    keyboardShowClick(e) {
      this.input = e.target
      if (!this.visible) this.visible = true
    },
    cancel() {
      this.visible = false
    },
    getLocalStorage() {
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      const obj1 = {
        adminId: role == 'admin' ? adminId : 1,
        userId: userId,
        role: role
      }
      return obj1
    },
    addHandleClick() {
      this.newItem.push({
        name: '',
        hospitalNumber: '',
        gender: '',
        birthday: '2099-01-01T00:00:00Z'
      })
    },
    deleteBtnClick(item) {
      var index = this.newItem.indexOf(item)
      if (index !== -1) {
        this.newItem.splice(index, 1)
      }
    },
    cancelClick() {
      this.$refs.form.reset()
      this.newItem = [{}]
      this.$emit('cancelClick')
    },
    clearClick() {
      this.$refs.form.reset()
      this.newItem = [{}]
    },
    async save() {
      const newItemList = []
      this.newItem.forEach(item => {
        newItemList.push({
          birthday: item.birthday,
          name: item.name,
          hospitalNumber: item.hospitalNumber,
          gender: item.gender,
          ...this.getLocalStorage(),
          status: true
        })
      })
      this.$refs.form.validate()
      if (this.valid) {
        try {
          await patient.batchAdd({ data: newItemList })
          this.snackbarColor = 'success'
          this.notifMsg = `${this.$t('notifSuccess')}`
        } catch (err) {
          this.snackbarColor = 'error'
          this.notifMsg = `${this.$t('notifFailure')}${err.message}!`
        }
        this.snackbar = true
        this.clearClick()
        this.$emit('closeDialog')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  width: 100px;
  height: 40px;
  border: 1px solid rgb(80, 79, 79);
  border-radius: 8px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.btn:hover {
  border-color: #1976d2;
  color: #1976d2;
}

.btn:focus {
  background-color: #97b0ca !important;
}

#keyboard {
  position: fixed;
  top: auto !important;
  bottom: 0;
  z-index: 999;
  padding: 14px 21px;
}

::v-deep .mDialog {
  max-height: 500px;
  overflow-y: auto;
}

::v-deep .cDialog.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}
</style>

