<template lang='pug'>
div(style='height: 50vh')
  v-toolbar.mb-6.topBar(flat, style='margin-left: -15px; height: 80px')
    img(src='@/assets/title_logo.png', width='50', style='margin-right: 10px; margin-top: 25px', v-if='!isOpen', @click='setWidth')
    v-toolbar-title(v-if='isOpen') {{$t("scaleAssessment")}}
    h3(v-if='!isOpen', style='margin-top: 25px; font-size: 19px') {{$t("scaleAssessment")}}
    v-divider.mx-4(inset, vertical, v-if='isOpen')
  v-row(xl='4', lg='6', md='12', style='margin-top: 60px')
    v-col(xl='4', lg='6', md='12', v-for='(item, i) in gaugeList', :key='i')
      v-card.mx-auto
        v-img(:src='getThumbnailSrc(item.img)')
        v-card-title {{ item.name }}
          v-spacer
          div(style='display: flex; flex-direction: column')
            v-btn(@click='printPaper(item)')
              | {{$t("printEvaluationPaper")}}
            v-btn(color='primary', @click='start(item)', style='margin-top: 10px; margin-bottom: 20px') 
              v-icon(left) mdi-launch
              | {{$t("startAssessment")}}
        v-card-subtitle
          v-chip.ma-1(v-for='(item, i) in getTags(item)', :key='i') {{ item }}
  v-row(style='margin-left: 0px; margin-top: 60px', v-if='enabledJumpScale')
    v-btn(color='primary', @click='toScale()', style='height: 50px; width: 200px') 
      | {{$t("moreScales")}}
  NewGauge(ref='newGauge')
  .nav
    NavigationDrawer(:class='navShow ? "navShow" : "navHide"', @selectClick='selectClick')
  .mask(v-show='navShow && !isOpen', @click='outClick')
</template>

<script>
import NewGauge from './NewGauge'
import { mapGetters, mapState } from 'vuex'
import { VUE_APP_FILE_SERVER_ENDPOINT } from '@/app.config'
import { NavigationDrawer } from '../../components'
export default {
  data: () => ({
    tags: {},
    isOpen: false,
    navShow: false
  }),

  components: {
    NewGauge,
    NavigationDrawer
  },

  computed: {
    ...mapState({
      gaugeList: state => state.gauge.items
    }),

    ...mapGetters({
      enabledJumpScale: 'setting/enabledJumpScale'
    })
  },

  mounted() {
    window.addEventListener('resize', this.resize, false)
    this.resize()
    this.tags = [this.$t("orientation"), this.$t("memory"), this.$t("attention"), this.$t("calculation"), this.$t("recallAbility"), this.$t("languageAbility")]
  },

  methods: {
    toScale() {
      window.open('https://reg.xuzhishi.com/#/c/dashboard/login?redirect=/c/dashboard')
    },
    setWidth() {
      this.navShow = true
    },
    selectClick(val) {
      if (!this.isOpen) {
        this.navShow = val
      } else {
        this.navShow = true
      }
    },
    outClick() {
      if (this.isOpen) {
        this.navShow = true
      } else {
        this.navShow = false
      }
    },
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    getTags(item) {
      return item?.tags.split(';')
    },
    start(item) {
      this.$refs.newGauge.assessmentId = item?.model.ID
      this.$refs.newGauge.gaugeDialog = true
    },
    printPaper(item) {
      const content = {
        id: item.model.ID,
        logo: `${VUE_APP_FILE_SERVER_ENDPOINT}/mmse_close.png`,
        logo1: `${VUE_APP_FILE_SERVER_ENDPOINT}/moca_pic.png`
      }
      import('../../lib/print-mmsePaper').then(({ printPDF }) => {
        printPDF(content)
      })
    },
    getThumbnailSrc(src) {
      return `${VUE_APP_FILE_SERVER_ENDPOINT}/${src}`
    }
  }
}
</script>

<style lang="scss" scoped>
.topBar {
  width: 100%;
  height: 54px;
  position: fixed;
  top: 0;
  z-index: 2;
}
.navShow {
  animation: expanded 0.5s ease;
}
.navHide {
  display: none;
}
.mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  z-index: 998;
}
</style>