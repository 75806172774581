<template lang='pug'>
div(v-resize='onResize', @click='closeKeyboard')
  v-toolbar.mb-6(flat, style='margin-left: -15px; height: 60px')
    img(src='@/assets/title_logo.png', width='50', style='margin-right: 10px; margin-top: 25px', v-if='!isOpen', @click='setWidth')
    v-toolbar-title(v-if='isOpen') {{$t("record")}}
    h3(v-if='!isOpen', style='margin-top: 25px; font-size: 19px') {{$t("record")}}
    v-divider.mx-4(inset, vertical, v-if='isOpen')
  v-row(style='margin-left: -15px')
    v-col(cols='12', :sm='isOpen ? 2 : 5', :md='isOpen ? 2 : 5', style='height: 68px')
      el-input.input-with-select(
        :placeholder="$t('pleaseEnterContent')",
        v-model='userName',
        clearable,
        @clear='inputClear',
        @click.stop.native='isOpen ? (visible = false) : (visible = true)',
        @focus.stop='keyboardShowClick'
      )
        el-select#select(v-model='select', slot='prepend', :placeholder="$t('pleaseEnterContent')", @change='searchType', clearable, @clear='selectClear')
          el-option(:label="$t('scale')", value='assessment')
          el-option(:label='typeUsername == "1" ? $t("thUserName") : $t("thUserName")', value='name')
          el-option(:label='numberName ? numberName : $t("number")', value='hospital_number') 
          el-option(
            :label='bedNumberName === "true" || bedNumberName == "" ? (typeUsername == "1" ? $t("userNameNumber"): $t("patientNumber")) : bedNumberName',
            value='patient_number',
            v-if='bedNumberName !== "false"'
          )
          el-option(:label='partition === "true" || partition == "" ? $t("subregion") : partition', value='bed_number', v-if='partition !== "false"')
    v-col(cols='12', :sm='isOpen ? 3 : 6', :md='isOpen ? 3 : 6', style='margin-left: -80px')
      el-date-picker(
        v-model='dates',
        type='daterange',
        align='right',
        unlink-panels,
        :range-separator="$t('to')",
        :start-placeholder="$t('thStartDate')",
        :end-placeholder="$t('thEndDate')",
        :picker-options='pickerOptions',
        style='width: 80%; margin-left: 20%',
        clearable,
        @change='dataClear'
      )
    v-tooltip(bottom, style='margin-left: 100px')
      template(v-slot:activator='{ on, attrs }')
        v-btn.back(dark, color='orange', @click='searchClick()', v-bind='attrs', v-on='on')
          v-icon.iconfont.icon-31sousuo
      span {{ $t("search") }}

  div(ref='resizableDiv', v-if='isOpen')
    v-data-table(
      fixed-header,
      :height='dataTableHeight',
      :headers='headers',
      :items='recordsData',
      :search='search',
      :items-per-page='itemsPerPage',
      :footer-props='{ itemsPerPageOptions: [itemsPerPage] }'
    )
      template(v-slot:item.gaugeName='{ item }')
        div(v-if='item.gaugeName')
        v-row.mx-0(align='center')
          pre.body-1 {{ item.gaugeName.name }}
      template(v-slot:item.patientName='{ item }')
        v-row.mx-0(align='center')
          v-icon.mr-2 mdi-account(v-if="isOpen")
          pre.body-1(v-if='item.patient') {{ item.patient.name }}
      template(v-slot:item.hospitalNumber='{ item }')
        v-row.mx-0(align='center')
          v-icon.mr-2 mdi-clipboard-account(v-if="isOpen")
          pre.body-1(v-if='item.patient') {{ item.patient.hospitalNumber }}
      template(v-slot:item.patientBedNumber='{ item }', v-if='partition !== "false"')
        v-row.mx-0(align='center')
          v-icon.mr-2 mdi-lock-pattern(v-if="isOpen")
          pre.body-1(v-if='item.patient') {{ item.patient.bedNumber }}
      template(v-slot:item.patientPatientNumber='{ item }')
        v-row.mx-0(align='center')
          v-icon.mr-2 mdi-bed(v-if="isOpen")
          pre.body-1(v-if='item.patient') {{ item.patient.patientNumber }}
      template(v-slot:item.score='{ item }')
        v-row.mx-0(align='center')
          pre.body-1 {{ item.gaugeName.name === $t('MoCAMontrealCognitiveAssessmentScale') ? getScore(item) : item.score }}

      template(v-slot:item.model.CreatedAt='{ item }')
        v-row.mx-0(align='center')
          pre.body-1(v-if='item.model.CreatedAt') {{ changeTimeToUTC8(item.model.CreatedAt) }}
      template(v-slot:item.complete='{ item }')
        v-row.mx-0(align='center')
          img(src='@/assets/complete.png', width='18', style='margin-right: 5px', v-if='item.complete && isOpen') 
          img(src='@/assets/uncomplete.png', width='18', style='margin-right: 5px', v-if='!item.complete && isOpen')
          pre.body-1 {{ item.complete ? $t('completed') : $t('undone') }}

      template(v-slot:item.model.ID='{ item }')
        //- span(v-if='item.complete')  item.model.CreatedAt.split("T")[0]} ${item.model.CreatedAt.split("T")[1].slice(0, 5)
        //-   v-tooltip(bottom)
        //-     template(v-slot:activator='{ on, attrs }')
        //-       v-btn(icon, @click='print(item)', v-bind='attrs', v-on='on')
        //-         v-icon mdi-printer
        //-     span {{ $t("actionPrintTooltip") }}
        span(v-if='item.complete')
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, v-bind='attrs', v-on='on', @click='goToDetail(item)')
                v-icon mdi-finance
            span {{ $t("actionReportTooltip") }}
        span(v-if='!item.complete')
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, v-bind='attrs', v-on='on', @click='continueTest(item)')
                img(src='@/assets/uncompleteDetail.png', width='18', style='margin-right: 5px')
            span {{$t("continueTesting")}}
        span(v-if='isAdmin')
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='remove(item)', v-bind='attrs', v-on='on')
                v-icon mdi-delete
            span {{ $t("actionRemoveTooltip") }}

  div(ref='resizableDiv', v-if='!isOpen', style='margin-top: 20px')
    v-data-table.elevation-1.elevation-1(
      fixed-header,
      :height='visible && enableTouch ? 750 : dataTableHeight',
      :headers='headers',
      :items='recordsData',
      :search='search',
      :items-per-page='itemsPerPage',
      :footer-props='{ itemsPerPageOptions: [itemsPerPage] }',
      :single-expand='singleExpand',
      :expanded='expanded',
      item-key='model.ID',
      @click:row='clicked'
    )
      template(v-slot:expanded-item='{ headers, item }')
        td(:colspan='headers.length', style='background: #f9f7f7; border-top: none')
          v-row(style='font-size: 12px; display: flex; justify-content: space-between; align-items: center; width: 100%; height: 72px')
            div(style='margin-left: 17px; width: 150px')
              span {{ item.gaugeName.name === $t('MoCAMontrealCognitiveAssessmentScale') ? $t('montrealCognitiveAssessmentScale') : $t('miniIntelligentMentalStateExaminationScale') }}
            div(:style='{ marginLeft: isAdmin || item.complete ? "320px" : "270px", width: "105px" }')
              span {{ treatmentDate(item.model.CreatedAt, displayTime) }}
            div(:style='{ width: "50px", marginLeft: isAdmin || item.complete ? "10px" : "-20px" }')
              span {{ item.complete ? $t('completed') : $t('undone') }}
            div(style='height: 100%; display: flex; flex-direction: column; justify-content: center; position: relative; right: -6px')
              //- span(v-if='item.complete', :style='{ marginTop: isAdmin ? "13px" : "0" }')
              //-   v-tooltip(bottom)
              //-     template(v-slot:activator='{ on, attrs }')
              //-       v-btn(icon, @click='print(item)', v-bind='attrs', v-on='on')
              //-         v-icon mdi-printer
              //-     span {{ $t("actionPrintTooltip") }}
              span(v-if='isAdmin', :style='{ position: "relative", bottom: item.complete ? "13px" : "0" }')
                v-tooltip(bottom)
                  template(v-slot:activator='{ on, attrs }')
                    v-btn(icon, @click='remove(item)', v-bind='attrs', v-on='on')
                      v-icon mdi-delete
                  span {{ $t("actionRemoveTooltip") }}
      template(v-slot:item.gaugeName.name='{ item }')
        div(v-if='item.gaugeName.name')
        span
          pre.body-1 {{ updateName(item.gaugeName.name) }}
      template(v-slot:item.patientName='{ item }')
        span
          pre.body-1(v-if='item.patient') {{ item.patient.name }}
      template(v-slot:item.hospitalNumber='{ item }')
        span
          pre.body-1(v-if='item.patient') {{ item.patient.hospitalNumber }}
      template(v-slot:item.patientBedNumber='{ item }', v-if='partition !== "false"')
        span
          pre.body-1(v-if='item.patient') {{ item.patient.bedNumber }}
      template(v-slot:item.patientPatientNumber='{ item }')
        span
          pre.body-1(v-if='item.patient') {{ item.patient.patientNumber }}
      template(v-slot:item.score='{ item }')
        v-row.mx-0(align='center')
          pre.body-1 {{ item.gaugeName.name === $t('MoCAMontrealCognitiveAssessmentScale') ? getScore(item) : item.score }}
      template(v-slot:item.score='{ item }') {{ item.score }}
      template(v-slot:item.model.CreatedAt='{ item }') {{ treatmentDatePickUp(item.model.CreatedAt) }}
      template(v-slot:item.complete='{ item }')
        span
          img(src='@/assets/complete.png', width='18', style='margin-right: 5px', v-if='item.complete') 
          img(src='@/assets/uncomplete.png', width='18', style='margin-right: 5px', v-if='!item.complete')
          <!-- pre.body-1 {{ item.complete ? $t('completed') : $t('undone') }} -->

      template(v-slot:item.model.ID='{ item }')
        <!-- span(v-if="item.complete")
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='print(item)', v-bind='attrs', v-on='on')
                v-icon mdi-printer
            span {{ $t("actionPrintTooltip") }} -->
        span(v-if='item.complete')
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, v-bind='attrs', v-on='on', @click='goToDetail(item)')
                v-icon mdi-finance
            span {{ $t("actionReportTooltip") }}
        span(v-if='!item.complete')
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, v-bind='attrs', v-on='on', @click='continueTest(item)')
                img(src='@/assets/uncompleteDetail.png', width='18')
            span {{$t("continueTesting")}}
        <!-- span(v-if="isAdmin")
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='remove(item)', v-bind='attrs', v-on='on')
                v-icon mdi-delete
            span {{ $t("actionRemoveTooltip") }} -->

  v-dialog(v-model='confirmDialog', @click:outside='confirmCancel()', width='500')
    v-card
      v-card-title.warning.headline.mb-6
        h4(style='color: white') {{ $t("drTitle") }}
      v-card-text
        h3 {{ $t("drMsg") }}
      v-card-actions
        v-spacer
        v-btn(text, @click='confirmCancel()') {{ $t("drCancel") }}
        v-btn(text, @click='confirmConfirm()') {{ $t("drConfirm") }}

  v-snackbar(elevation='24', v-model='snackbar', :color='snackbarColor', timeout='3000') {{ notifMsg }}
    template(v-slot:action='{ attrs }')
      v-btn(text, v-bind='attrs', @click='snackbar = false') {{ $t("notifClose") }}

  NewTreatment(ref='newTreatment')
  UpdateDateDialog(
    :dialog='dialog',
    :treatment='treatmentEditDate',
    @cancelClick='cancelHandleClick',
    @clearClick='clearHandleClick',
    @saveClick='saveHandleClick'
  )
  LoadingPage(v-if='fullscreenLoading')
  Keyboard#keyboard(layout='normal', :input='input', v-if='visible && enableTouch', :cancel='cancel')
  .nav
    NavigationDrawer(:class='navShow ? "navShow" : "navHide"', @selectClick='selectClick')
  .mask(v-show='navShow && !isOpen', @click='outClick')
</template>
  
  <script>
import { mapGetters, mapState } from 'vuex'
import { treatment, gaugeRecords } from '../../api'
import PatientMini from './../PatientMini'
import DeviceMini from './../DeviceMini'
import AppMini from './../AppMini'
import NewTreatment from './../NewTreatment'
import { VUE_APP_FILE_SERVER_ENDPOINT } from '@/app.config'
import UpdateDateDialog from './../Treatments/updateDateDialog.vue'
import Keyboard from 'vue-keyboard-zh'
import { NavigationDrawer } from '../../components'
import LoadingPage from '../../components/common/LoadingPage.vue'
import moment from 'moment-timezone'

export default {
  data: (vm) => ({
    isOpen: false,
    dialog: false,
    dataTableHeight: 0,
    itemsPerPage: 0,
    search: '',
    userName: '',
    ID: '',
    scoreData: [],
    recordsData: [],
    dates: '',
    confirmDialog: false,
    writeScoreDialog: false,
    treatmentIdDel: '',
    snackbar: false,
    snackbarColor: '',
    notifMsg: '',
    pickerOptions: {

    },
    select: 'name',
    startTime: '',
    endTime: '',
    scoreOverwrite: undefined,
    scoreOverwriteTreatmentId: undefined,
    originalScore: undefined,
    treatmentEditDate: undefined,
    getData: [],
    removeApp: {},
    num: 0,
    newTreatment: {
      assessmentId: undefined,
      patientId: undefined,
      complete: false,
      score: 0,
      data: '',
      userId: 0,
      model: {
        ID: 0
      },
      type: 4
    },
    expanded: [],
    visible: false,
    input: '',
    singleExpand: false,
    navShow: false,
    scoreNum: 0,
    education: '',
    mmseScore: 0,
    mocaScore: 0,
    fullscreenLoading: false,
    mocaGrade: [
      {
        grade: vm.$t('26scores'),
        name: vm.$t('normalCognitiveFunction')
      },
      {
        grade: vm.$t('scores26'),
        name: vm.$t('cognitiveDysfunction')
      }
    ],
    mmseGrade: [
      {
        grade: vm.$t('scores2730'),
        name: vm.$t('normalCognitiveFunction')
      },
      {
        grade: vm.$t('scores2126'),
        name: vm.$t('mildCognitiveImpairment')
      },
      {
        grade: vm.$t('scores1020'),
        name: vm.$t('moderateCognitiveImpairment')
      },
      {
        grade: vm.$t('scores09'),
        name: vm.$t('severeCognitiveImpairment')
      }
    ]
  }),

  watch: {
    treatments: function (val) {
      if (this.userName == '' && this.endTime == '') this.recordsData = []
      val.forEach(x => {
        if (x.gaugeName != undefined && x.patient != undefined) {
          this.recordsData.push(x)
        }
      })
    }
  },

  async mounted() {
    window.addEventListener('resize', this.resize, false)
    this.resize()
    
    this.pickerOptions = {
      shortcuts: [
        {
          text: this.$t("lastWeek"),
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: this.$t("lastMonth"),
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: this.$t("lastThreeMonths"),
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }
      ]
    }
    const startDate = this.dates
      ? new Date(this.dates[0]).getFullYear() + '-' + (new Date(this.dates[0]).getMonth() + 1) + '-' + new Date(this.dates[0]).getDate()
      : ''
    const endDate = this.dates
      ? new Date(this.dates[1]).getFullYear() + '-' + (new Date(this.dates[1]).getMonth() + 1) + '-' + new Date(this.dates[1]).getDate()
      : ''
    this.startTime = startDate ? startDate + ' ' + '00:00:00' : ''
    this.endTime = endDate ? endDate + ' ' + '23:59:59' : ''
    const logToken = JSON.parse(window.localStorage.getItem('vuex'))
    const adminId = logToken.users.userId
    const role = logToken.users.role
    const userId = parseInt(role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId)

    const result = await gaugeRecords.index(this.select, this.userName, this.startTime, this.endTime, userId)
    this.recordsData = result.data
    if (this.patients.length > 0 || this.gaugeName.length > 0) {
      this.getData =
        this.recordsData.length > 0
          ? this.recordsData.map(x => {
              const gaugeName = this.gaugeName.find(y => y.model.ID == x.assessmentId)
              if (gaugeName) x.gaugeName = gaugeName
              const patient = this.patients.find(y => y.model.ID == x.patientId)
              if (patient) x.patient = patient
              return x
            })
          : []
      this.recordsData = []
      this.getData.forEach(x => {
        if (x != undefined) {
          if (x.patient != undefined && x.gaugeName != undefined) {
            this.recordsData.push(x)
          }
        }
      })
    } else {
      this.recordsData = []
    }
  },

  methods: {
    getScore(item) {
      if (item.assessmentId === 2 && item.data !== '') {
        let eduGrade1 = JSON.parse(item.data)[JSON.parse(item.data).length - 1]
        if (eduGrade1 == this.$t('university') || eduGrade1 == this.$t('master') || eduGrade1 ==  this.$t('PhD')) {
          return item.score
        } else {
          return item.score < 30 ? item.score + 1 : item.score
        }
      }
    },
    setWidth() {
      this.navShow = true
    },
    selectClick(val) {
      if (!this.isOpen) {
        this.navShow = val
      } else {
        this.navShow = true
      }
    },
    outClick() {
      if (this.isOpen) {
        this.navShow = true
      } else {
        this.navShow = false
      }
    },
    keyboardShowClick(e) {
      if (this.isOpen) {
        this.input = ''
        this.visible = false
      } else {
        this.input = e.target
        if (!this.visible) this.visible = true
      }
    },
    cancel() {
      this.visible = false
    },
    closeKeyboard() {
      this.visible = false
    },
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    updateName(name) {
      return name === this.$t('MoCAMontrealCognitiveAssessmentScale') ? 'MoCA' : 'MMSE'
    },
    treatmentDatePickUp(dateString) {
      const date = new Date(dateString)
      const month = date.getMonth() + 1
      const days = date.getDate()
      return month + '/' + days
    },
    clicked(value) {
      const index = this.expanded.indexOf(value)
      if (index === -1 && this.expanded.length === 0) {
        this.expanded.push(value)
      } else if (index == 0) {
        this.expanded.splice(index, 1)
      } else if (this.expanded.length !== 0) {
        this.expanded.splice(index, 1)
        //打开点击的行
        this.expanded.push(value)
      }
    },
    continueTest(item) {
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      this.newTreatment.userId = userId
      this.newTreatment.model.ID = item.model.ID
      this.newTreatment.assessmentId = item.assessmentId
      this.newTreatment.patientId = item.patientId
      this.newTreatment.complete = item.complete
      this.newTreatment.score = item.score
      this.$router.push({
        path: `/gauge/${this.newTreatment.assessmentId}`,
        query: this.newTreatment
      })
    },

    treatmentDate(dateString, displayTime = true) {
      const date = new Date(dateString)
      if (displayTime) {
        return (
          date.toLocaleDateString('zh-CN') +
          ' ' +
          date.toLocaleTimeString('zh-CN', {
            hour: '2-digit',
            minute: '2-digit'
          })
        )
      } else {
        return date.toLocaleDateString('zh-CN')
      }
    },
    searchType(value) {
      this.select = value
    },
    inputClear() {
      this.userName = ''
      this.searchClick()
    },
    selectClear() {
      this.select = ''
      this.searchClick()
    },
    dataClear(value) {
      if (value == null) {
        this.startTime = ''
        this.endTime = undefined
        this.searchClick()
      }
    },
    async searchClick() {
      const startDate = this.dates
        ? new Date(this.dates[0]).getFullYear() + '-' + (new Date(this.dates[0]).getMonth() + 1) + '-' + new Date(this.dates[0]).getDate()
        : ''
      const endDate = this.dates
        ? new Date(this.dates[1]).getFullYear() + '-' + (new Date(this.dates[1]).getMonth() + 1) + '-' + new Date(this.dates[1]).getDate()
        : ''
      this.startTime = startDate ? startDate + ' ' + '00:00:00' : ''
      this.endTime = endDate ? endDate + ' ' + '23:59:59' : ''
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      const treatmentsList = await gaugeRecords.index(this.select, this.userName, this.startTime, this.endTime, userId)
      if (this.patients.length > 0 || this.gaugeName.length > 0) {
        this.getData =
          treatmentsList.data.length > 0
            ? treatmentsList.data.map(x => {
                const patient = this.patients.find(y => y.model.ID == x.patientId)
                if (patient) x.patient = patient
                const gaugeName = this.gaugeName.find(y => y.model.ID == x.assessmentId)
                if (gaugeName) x.gaugeName = gaugeName
                return x
              })
            : []
      }
      this.recordsData = []
      this.getData.forEach(x => {
        if (x != undefined) {
          if (x.gaugeName != undefined && x.patient != undefined) {
            this.recordsData.push(x)
          }
        }
      })
    },
    // 删除
    remove(item) {
      this.confirmDialog = true
      this.treatmentIdDel = item?.model.ID
    },
    confirmCancel() {
      this.confirmDialog = false
    },
    async confirmConfirm() {
      try {
        await gaugeRecords.remove(this.treatmentIdDel)
        this.snackbarColor = 'success'
        this.notifMsg = `${this.$t('notifSuccess')}`
        this.searchClick()
      } catch (err) {
        this.snackbarColor = 'error'
        this.notifMsg = `${this.$t('notifFailure')}${err.message}!`
      }
      this.snackbar = true
      this.confirmCancel()
    },
    onResize() {
      const height = window.innerHeight - this.$refs.resizableDiv.getBoundingClientRect().y - 12 - 37
      this.dataTableHeight = height
      this.itemsPerPage = parseInt((height - 48) / 48)
    },
    add() {
      this.$refs.newTreatment.dialog = true
    },
    async terminate(item) {
      const treatmentId = item?.model.ID
      await treatment.terminate(treatmentId)
    },
    getThumbnailSrc(src) {
      return `${VUE_APP_FILE_SERVER_ENDPOINT}/${src}`
    },

    async goToDetail(item) {
      const routeData = this.$router.resolve({
        path: `/gaugeResult/${parseInt(item.model.ID)}`,
        query: { assessmentId: item.assessmentId, type: 2 }
      })
      !this.authorized
        ? window.open(routeData.href, '_blank')
        : this.$router.push({
            path: `/gaugeResult/${parseInt(item.model.ID)}`,
            query: { assessmentId: item.assessmentId, type: 2 }
          })
    },
    // 编辑日期
    edit(item) {
      if (!this.enabledTime) return
      this.treatmentEditDate = item
      this.dialog = true
    },
    cancelHandleClick() {
      this.dateList = { CreatedAt: '', ID: this.ID }
      this.dialog = false
    },
    clearHandleClick() {
      this.dateList = { CreatedAt: '', ID: this.ID }
    },
    saveHandleClick() {
      this.dialog = false
    },
    writeScore(item) {
      if (!this.enableScoreOverwrite) return
      this.scoreOverwrite = item.scoreOverwrite
      this.scoreOverwriteTreatmentId = item.model.ID
      this.originalScore = item.score
      this.writeScoreDialog = true
    },
    writeScoreCancel() {
      this.writeScoreDialog = false
    },
    writeScoreReset() {
      const request = {
        treatmentId: this.scoreOverwriteTreatmentId,
        scoreOverwrite: parseFloat(this.originalScore)
      }
      treatment.overwriteScore(request)
      this.writeScoreDialog = false
    },
    writeScoreConfirm() {
      const treatmentFiltered = this.treatmentMounted.find(x => x.model.ID == this.scoreOverwriteTreatmentId)
      if (treatmentFiltered) treatmentFiltered.scoreOverwrite = parseFloat(this.scoreOverwrite)
      const request = {
        treatmentId: this.scoreOverwriteTreatmentId,
        scoreOverwrite: parseFloat(this.scoreOverwrite)
      }
      treatment.overwriteScore(request)
      this.writeScoreDialog = false
    },
    onWrite() {
      if (this.scoreOverwrite < 0) {
        this.$nextTick(() => {
          this.scoreOverwrite = 0
        })
      }
      if (this.scoreOverwrite > 100) {
        this.$nextTick(() => {
          this.scoreOverwrite = 100
        })
      }
    },
    changeTimeToUTC8(time){
      return moment.utc(time).tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm')
    }
  },

  components: {
    PatientMini,
    DeviceMini,
    AppMini,
    NewTreatment,
    UpdateDateDialog,
    Keyboard,
    NavigationDrawer,
    LoadingPage
  },

  computed: {
    ...mapGetters({
      treatments: 'gaugeRecords/treatmentsWADP',
      isAdmin: 'users/isAdmin',
      enableScoreOverwrite: 'setting/enableScoreOverwrite',
      institution: 'setting/institution',
      reportName: 'setting/reportName',
      partition: 'setting/enabledPartition',
      typeUsername: 'setting/userName',
      total: 'setting/total',
      displayTime: 'setting/displayTime',
      bedNumberName: 'setting/bedNumberName',
      numberName: 'setting/numberName',
      enabledTime: 'setting/enabledTime',
      logo: 'setting/logo',
      enableTouch: 'setting/enableTouch',
      authorized: 'users/authorized'
    }),

    ...mapState({
      patients: state => state.patients.items,
      devices: state => state.devices.items,
      apps: state => state.apps.items,
      gaugeName: state => state.gauge.items
    }),

    headers() {
      const headers = []
      if (this.partition != 'false') {
        headers.push(
          {
            text: this.$t('scale'),
            value: 'gaugeName.name',
            sortable: false,
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.typeUsername == '1' ? this.$t('thUserName') : this.$t('thPatientName'),
            value: 'patientName',
            sortable: false,
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.numberName == '' ? this.$t('number') : this.numberName,
            value: 'hospitalNumber',
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.partition === 'true' || this.partition == '' ? this.$t('subregion') : this.partition,
            value: 'patientBedNumber',
            align: this.isOpen ? 'start' : 'center'
          },
          this.bedNumberName != 'false'
            ? {
                text:
                  this.bedNumberName === 'true' || this.bedNumberName == ''
                    ? this.typeUsername == '1'
                      ? this.$t('thUserNameNumber')
                      : this.$t('thPatientPatientNumber')
                    : this.bedNumberName,
                value: 'patientPatientNumber',
                align: this.isOpen ? 'start' : 'center'
              }
            : '',
          {
            text: this.$t('evaluationScore'),
            value: 'score',
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.$t('testDate'),
            value: 'model.CreatedAt',
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.$t('state'),
            value: 'complete',
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.$t('thActions'),
            value: 'model.ID',
            sortable: false,
            align: this.isOpen ? 'end' : 'center'
          }
        )
      } else {
        headers.push(
          {
            text: this.$t('scale'),
            value: 'gaugeName.name',
            sortable: false,
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.typeUsername == '1' ? this.$t('thUserName') : this.$t('thPatientName'),
            value: 'patientName',
            sortable: false,
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.numberName == '' ? this.$t('number') : this.numberName,
            value: 'hospitalNumber',
            align: this.isOpen ? 'start' : 'center'
          },
          this.bedNumberName != 'false'
            ? {
                text:
                  this.bedNumberName === 'true' || this.bedNumberName == ''
                    ? this.typeUsername == '1'
                      ? this.$t('thUserNameNumber')
                      : this.$t('thPatientPatientNumber')
                    : this.bedNumberName,
                value: 'patientPatientNumber',
                align: this.isOpen ? 'start' : 'center'
              }
            : '',
          {
            text: this.$t('evaluationScore'),
            value: 'score',
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.$t('creationDate'),
            value: 'model.CreatedAt',
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.$t('state'),
            value: 'complete',
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.$t('thActions'),
            sortable: false,
            value: 'model.ID',
            align: this.isOpen ? 'end' : 'center'
          }
        )
      }
      return headers
    }
  }
}
</script>
  
<style lang="sass" scoped>
.disable-events
  pointer-events: none
.icon-31sousuo
  color: #fff
::v-deep .v-btn:not(.v-btn--round).v-size--default
  padding: 0
  min-width: 45px
  height: 40px
.back
  margin-top: 11px
  margin-left: -4.8%
::v-deep .el-input__inner
  border: 1px solid #9E9E9E
::v-deep .el-range-editor.is-active, .el-range-editor.is-active:hover, .el-select .el-input.is-focus .el-input__inner
  border: 2px solid #1976D2 !important
::v-deep .el-date-editor .el-range-input
  font-size: 16px
  font-family: 400
  color: #929292
::v-deep .el-select .el-input
  width: 110px
::v-deep .el-select>.el-input
  border-top: 1px solid #9E9E9E
  border-left: 1px solid #9E9E9E
  border-bottom: 1px solid #9E9E9E
  height: 39.5px
::v-deep .el-input .el-input__inner:focus
  border-color: none !important
  border: 2px solid #1976D2 !important
::v-deep .input-with-select .el-input-group__prepend
  background-color: #fff
.icon-fenquguanli
  font-size: 26px
  margin-right: 8px
  color: #757575
::v-deep .v-btn:not(.v-btn--round).v-size--default
  margin-left: 4px
::v-deep .mClass > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th
  padding: 0 !important
::v-deep .pcClass > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th
  padding: 0 16px !important
::v-deep .mClass > .text-
  width: 50px !important
.elevation-1
  width: 100%
::v-deep .elevation-1 > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th
  padding: 0 8px !important

::v-deep .elevation-1>.v-data-table__wrapper tbody tr.v-data-table__expanded
  background: #F9F7F7 !important
::v-deep .theme--light.elevation-1 > .v-data-table__wrapper > table > tbody > tr.v-data-table__expanded > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row)
  border-top: none !important
  border-bottom: none !important
#keyboard
  position: absolute
  top: auto !important
  bottom: 0
  z-index: 999
  padding: 14px 21px
::v-deep .elevation-1
  box-shadow: none !important
.navShow
  animation: expanded 0.5s ease
.navHide
  display: none
.mask
  width: 100%
  height: 100%
  background: rgba(0,0,0,0.3)
  position: fixed
  top: 0
  z-index: 998
::v-deep .elevation-1 > .v-data-table__wrapper > table > thead > tr > th > .v-data-table-header__icon
  font-size: 8px !important
  position: relative
  top: 1px
@keyframes expanded
  0%
    display: none
    width: 0
  100%
    width: 255
    display: block
</style>
  












