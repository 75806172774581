import { setting } from '../../api'
import i18n from '../../i18n'

const mapSetting = data => ({
  enableEEG: data.enable_eeg,
  enableCategory: data.enable_category,
  institution: data.institution,
  reportName: data.report_name,
  scaleTitle: data.scale_title,
  partition: data.partition,
  typeUsername: data.type_username,
  total: data.total,
  enableScoreOverwrite: data.enable_score_overwrite,
  displayTime: data.display_time,
  numberName: data.number_name,
  bedNumberName: data.bed_number_name,
  treatmentTime: data.treatment_time,
  logo: data.logo,
  enableTime: data.enable_time,
  enableEndTime: data.enable_end_time,
  enableAge: data.enable_age,
  enableTouchSwitch: data.touch_switch,
  jumpScale: data.jump_scale,
  isCountdown: data.count_down
})

export default {
  namespaced: true,

  state: {
    enableEEG: undefined,
    enableCategory: undefined,
    institution: undefined,
    scaleTitle: undefined,
    reportName: undefined,
    partition: undefined,
    typeUsername: '1',
    total: undefined,
    userType: localStorage.getItem('userName'),
    enableScoreOverwrite: undefined,
    displayTime: undefined,
    numberName: undefined,
    bedNumberName: undefined,
    treatmentTime: undefined,
    logo: undefined,
    enableTime: undefined,
    enableEndTime: undefined,
    enableTouchSwitch: undefined,
    enableAge: undefined,
    jumpScale: undefined,
    isCountdown: undefined
  },

  getters: {
    enabledEEG: state => {
      return state.enableEEG
    },

    enabledJumpScale: state => {
      return state.jumpScale
    },

    enabledCountdown: state => {
      return state.isCountdown
    },

    enableAge: state => {
      return state.enableAge
    },

    numberName: state => {
      return state.numberName
    },

    bedNumberName: state => {
      return state.bedNumberName === '' ? i18n.t("patientNumber") : state.bedNumberName
    },

    logo: state => {
      return state.logo
    },

    treatmentTime: state => {
      return state.treatmentTime
    },

    enabledTime: state => {
      return state.enableTime
    },

    enableEndTime: state => {
      return state.enableEndTime
    },

    enableTouch: state => {
      return state.enableTouchSwitch
    },

    enabledCategory: state => {
      return state.enableCategory
    },

    enabledPartition: state => {
      return state.partition == '' ? i18n.t("subregion") : state.partition
    },

    userName: state => {
      return state.typeUsername
    },

    total: state => {
      return state.total
    },

    institution: state => {
      return state.institution
    },

    scaleTitle: state => {
      return state.scaleTitle
    },
    reportName: state => {
      return state.reportName
    },

    enableScoreOverwrite: state => {
      return state.enableScoreOverwrite
    },

    displayTime: state => {
      return state.displayTime
    },

    userType: state => {
      if (localStorage.getItem('userName')) {
        state.userType = localStorage.getItem('userName')
      }
      return state.userType
    }
  },

  actions: {
    async index({ commit }) {
      var userId = 0
      if (
        JSON.parse(window.localStorage.getItem('vuex')) != null &&
        JSON.parse(window.localStorage.getItem('vuex')).users.role != undefined
      ) {
        const logToken = JSON.parse(window.localStorage.getItem('vuex'))
        const adminId = logToken.users.userId
        const role = logToken.users.role
        userId = parseInt(role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId)
      } else {
        userId = 1
      }
      const response = await setting.index(userId)
      commit('index', response.data)
    },

    userType({ commit }, data) {
      commit('userType', (window.localStorage['userName'] = data))
    },

    async pgevent({ commit }, payload) {
      switch (payload.action) {
        case 'UPDATE':
          commit('update', mapSetting(payload.data))
          break
        default:
          break
      }
    }
  },

  mutations: {
    index(state, payload) {
      state.enableEEG = payload.enableEEG
      state.enableCategory = payload.enableCategory
      state.numberName = payload.numberName
      state.bedNumberName = payload.bedNumberName
      state.treatmentTime = payload.treatmentTime
      state.logo = payload.logo
      state.institution = payload.institution
      state.scaleTitle = payload.scaleTitle
      state.reportName = payload.reportName
      state.partition = payload.partition
      state.typeUsername = payload.typeUsername
      state.total = payload.total
      state.Logo = payload.Logo
      state.enableScoreOverwrite = payload.enableScoreOverwrite
      state.displayTime = payload.displayTime
      state.enableTime = payload.enableTime
      state.enableEndTime = payload.enableEndTime
      state.enableTouchSwitch = payload.touchSwitch
      state.enableAge = payload.enableAge
      state.jumpScale = payload.jumpScale
      state.isCountdown = payload.countDown
    },

    update(state, payload) {
      state.enableEEG = payload.enableEEG
      state.numberName = payload.numberName
      state.bedNumberName = payload.bedNumberName
      state.treatmentTime = payload.treatmentTime
      state.logo = payload.logo
      state.enableCategory = payload.enableCategory
      state.institution = payload.institution
      state.scaleTitle = payload.scaleTitle
      state.reportName = payload.reportName
      state.partition = payload.partition
      state.typeUsername = payload.typeUsername
      state.total = payload.total
      state.Logo = payload.Logo
      state.enableScoreOverwrite = payload.enableScoreOverwrite
      state.displayTime = payload.displayTime
      state.enableTime = payload.enableTime
      state.enableEndTime = payload.enableEndTime
      state.enableTouchSwitch = payload.enableTouchSwitch
      state.enableAge = payload.enableAge
      state.jumpScale = payload.jumpScale
      state.isCountdown = payload.isCountdown
    },

    userType(state, payload) {
      state.userType = payload
    }
  }
}
