import axios from 'axios'
import { VUE_APP_SERVER_ENDPOINT } from '@/app.config'
async function index(treatmentTotal, searchType, username, start_time, end_time, userId) {
  return await axios.get(
    `${VUE_APP_SERVER_ENDPOINT}/treatment?total=${treatmentTotal}&user_id=${userId}${searchType == '' || username == '' ? '' : `&${searchType}=${username}`
    }${start_time == '' ? '' : `&start_time=${start_time}`}${end_time == undefined || end_time == '' ? '' : `&end_time=${end_time}`}`
  )
}

async function trainingState(state) {
  const json = JSON.stringify(state)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/treatment/state`, json)
}

async function start(treatment) {
  const json = JSON.stringify(treatment)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/treatment`, json)
}

async function groupStart(treatment) {
  const json = JSON.stringify(treatment)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/treatment/group`, json)
}

async function edit(treatment) {
  const json = JSON.stringify(treatment)
  return await axios.put(`${VUE_APP_SERVER_ENDPOINT}/treatment`, json)
}

async function editTime(treatment) {
  const json = JSON.stringify(treatment)
  return await axios.put(`${VUE_APP_SERVER_ENDPOINT}/treatment/time`, json)
}

async function terminate(id) {
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/treatment/terminate/${id}`)
}

async function terminateCountdown(treatment) {
  const json = JSON.stringify(treatment)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/treatment/terminate`, json)
}

async function time() {
  return await axios.get(`${VUE_APP_SERVER_ENDPOINT}/now`)
}

async function updateMeta(meta) {
  const json = JSON.stringify(meta)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/treatment/update/meta`, json)
}

async function getScore(id) {
  const json = JSON.stringify(id)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/treatment/score`, json)
}

async function averageScore(id) {
  const json = JSON.stringify(id)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/average/score`, json)
}

async function remove(id) {
  return await axios.delete(`${VUE_APP_SERVER_ENDPOINT}/treatment/${id}`)
}

async function overwriteScore(request) {
  const json = JSON.stringify(request)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/treatment/scoreow`, json)
}

async function exports(treatment) {
  const json = JSON.stringify(treatment)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/treatments`, json)
}

async function treatmentSheet(treatment) {
  const json = JSON.stringify(treatment)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/treatment/sheet`, json)
}

async function treatmentRankingList(treatment) {
  const json = JSON.stringify(treatment)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/treatment/leaderboard`, json)
}

async function treatmentMedalsList(treatment) {
  const json = JSON.stringify(treatment)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/medals/list`, json)
}

async function getTreatment(id) {
  return await axios.get(`${VUE_APP_SERVER_ENDPOINT}/treatment/${id}`)
}

export default {
  index,
  start,
  terminate,
  updateMeta,
  edit,
  getScore,
  averageScore,
  trainingState,
  remove,
  overwriteScore,
  time,
  editTime,
  exports,
  treatmentSheet,
  treatmentRankingList,
  groupStart,
  terminateCountdown,
  treatmentMedalsList,
  getTreatment
}
