import { user } from '../../api'

export default {
  namespaced: true,

  state: {
    userId: undefined,
    username: undefined,
    role: undefined,
    token: undefined,
    userList: []
  },
  
  getters: {
    authorized: (state) => {
      return state.username != null
    },

    isAdmin: (state) => {
      return state.role == 'admin'
    },
  },
  
  actions: {
    async login ({ commit }, payload) {
      const response = await user.login(payload)
      commit('set', response.data)
    },

    async user ({ commit }, payload) {
      const response = await user.user(payload)
      commit('userType', response.data)
    },

    logout({ commit }) {
      commit('unset')
    }
  },
  
  mutations: {
    set (state, payload) {
      state.userId = payload.userId
      state.username = payload.username
      state.role = payload.role
      state.token = payload.token
    },

    userType(state,payload) {
      state.userList = payload
    },

    unset (state) {
      state.userId = undefined
      state.username = undefined
      state.role = undefined
      state.token = undefined
    },
  },
}