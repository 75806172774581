<template lang="pug">
  div
    v-toolbar.mb-6(flat)
      v-btn.ma-2(large, color='orange', dark, @click='goBack()')
        v-icon(dark, left) mdi-arrow-left
        | 返回
      v-divider.mx-4(inset, vertical)
      v-icon.mr-1 mdi-file-chart
      v-toolbar-title 报告
      v-spacer
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn.primary.mr-2(fab, v-bind='attrs', v-on='on', @click='printMMSE()')
            v-icon mdi-printer
        span 打印
    .report-title(style='text-align: center; font-size: 26px; font-family: Microsoft; font-weight: 600') {{ parseInt(this.$route.query.assessmentId) === 1 ? "MMSE量表报告" : "蒙特利尔认知评估报告" }}
    v-col(xl='12', lg='12', md='12')
      v-card.mx-auto(hover)
        v-card-text
          .patient_detail(style='display: flex; justify-content: space-between; flex-wrap: wrap; align-content: flex-start')
            div
              v-icon mdi-account
              span.name 姓名：{{ patientsList.name }}
            div
              v-icon mdi-clipboard-account
              span.name 住院号：{{ patientsList.hospitalNumber }}
            div
              v-icon mdi-calendar
              span.name 年龄：{{ Math.floor((new Date() - new Date(patientsList.birthday).getTime()) / 3.15576e10) }}
            div
              v-icon mdi-gender-male-female
              span.name 性别：{{ patientsList.gender }}
            div
              v-icon mdi-human-male-female
              span.name 婚姻状况：{{ patientsList.maritalStatus }}
            div
              v-icon mdi-school
              span.name 学历：{{ parseInt(this.$route.query.assessmentId) === 1 ? patientsList.education : education }}
    v-col(xl='12', lg='12', md='12')
      v-card.mx-auto(hover)
        v-card-title 得分情况
        v-card-text
          div(style='display: flex', v-if='parseInt(this.$route.query.assessmentId) === 1')
            .score-left
              .num(:style='{ color: color }') {{ num }}
              .left-name {{ tipText }}
            .score-right
              div(style='margin-bottom: 40px')
                span.green
                span.section 27-30分：认知功能正常
              div(style='margin-bottom: 40px')
                span.darkBlue 
                span.section 21-26分：轻度认知功能障碍
              div(style='margin-bottom: 40px')
                span.orange
                span.section 10-20分：中度认知功能障碍
              div(style='margin-bottom: 40px')
                span.red 
                span.section 0-9分：重度认知功能障碍
                v-col(xl='12', lg='12', md='12')
          div(v-else, style='text-align: center')
            .num(:style='{ color: color }') {{ num }}
            .left-name {{ tipText }}
            .bottom 满分30分，大于等于26分属于正常。
    v-col(xl='12', lg='12', md='12')
      v-card.mx-auto(hover)
        v-card-title 得分分析
        v-card-text
          table(
            v-if='parseInt(this.$route.query.assessmentId) == 1',
            style='color: black; margin-left: 20px; border-collapse: collapse',
            width='98%'
          )
            tr(style='background: rgba(215, 237, 253, 0.5); height: 40px')
              td(width='500', style='padding-left: 40px') 检查项目
              td(width='800', align='center') 评分项目
              td(width='500', align='center') 正确计分
            tr(v-for='(item, index) in tableData', :key='index', :class='(index + 1) % 2 == 0 ? "blue1" : "white1"')
              td(style='padding-left: 40px') {{ item.name }}
              td(align='center') {{ item.question }}
              td(align='center') {{ item.answer }}
          table(v-else, style='color: black; margin-left: 20px; border-collapse: collapse', width='98%')
            tr(style='background: rgba(215, 237, 253, 0.5); height: 40px')
              td(width='80.3%', style='padding-left: 40px') 评分项目
              td(width='300', align='center') 正确计分
            tr(v-for='(item, index) in tableData', :key='index', :class='(index + 1) % 2 == 0 ? "blue1" : "white1"')
              td(style='padding-left: 40px') {{ item.question[index] }}
              td(align='center') {{ item.answer }}
    LoadingPage(v-if='fullscreenLoading')
  </template>

<script>
import { mapState, mapGetters } from 'vuex'
import { gauge } from '../../api'
import LoadingPage from '../../components/common/LoadingPage.vue'
export default {
  data: () => ({
    patientsList: {
      name: '',
      hospitalNumber: '',
      birthday: '',
      gender: '',
      maritalStatus: '',
      education: ''
    },
    id: 0,
    num: 0,
    color: '',
    tipText: '',
    tableData: [],
    education: '',
    isOpen: false,
    mmseScore: 0,
    mocaScore: 0,
    fullscreenLoading: false,
    mocaGrade: [
      {
        grade: '分数≥26',
        name: '认知功能正常'
      },
      {
        grade: '分数<26',
        name: '认知功能障碍'
      }
    ],
    mmseGrade: [
      {
        grade: '27≤分数≤30',
        name: '认知功能正常'
      },
      {
        grade: '21≤分数≤26',
        name: '轻度认知功能障碍'
      },
      {
        grade: '10≤分数≤20',
        name: '中度认知功能障碍'
      },
      {
        grade: '0≤分数≤9',
        name: '重度认知功能障碍'
      }
    ]
  }),

  watch: {
    patients: function (val) {
      val.forEach(item => {
        if (item.model.ID === this.id) {
          this.patientsList = item
        }
      })
    }
  },

  components: {
    LoadingPage
  },

  async mounted() {
    this.resize()
    window.addEventListener('resize', this.resize, false)
    const result = await gauge.getResult(parseInt(this.$route.params.id))
    this.id = result.data.patientId
    if (parseInt(this.$route.query.assessmentId) == 1) {
      this.num = result.data.score
      this.tableData = JSON.parse(result.data.data)
      this.mmseScore = this.num
    } else {
      const data = JSON.parse(result.data.data)
      this.tableData = data.slice(0, data.length - 1)
      this.education = data.slice(data.length - 1, data.length)[0]
      if (this.education == '大学' || this.education == '硕士' || this.education == '博士') {
        this.num = result.data.score
      } else {
        this.num = result.data.score < 30 ? result.data.score + 1 : result.data.score
      }
      this.mocaScore = this.num
    }
    if (parseInt(this.$route.query.assessmentId) === 1) {
      if (this.num >= 27 && this.num <= 30) {
        this.color = '#4BD549'
        this.tipText = '认知功能正常'
      } else if (this.num >= 21 && this.num <= 26) {
        this.color = '#FAD984'
        this.tipText = '轻度认知功能障碍'
      } else if (this.num >= 10 && this.num <= 20) {
        this.color = '#FF9800'
        this.tipText = '中度认知功能障碍'
      } else {
        this.color = '#E02A1F'
        this.tipText = '重度认知功能障碍'
      }
    } else {
      if (this.num >= 26) {
        this.color = '#4BD549'
        this.tipText = '认知功能正常'
      } else {
        this.color = '#E02A1F'
        this.tipText = '认知功能障碍'
      }
    }
    this.patients.forEach(item => {
      if (item.model.ID === this.id) {
        this.patientsList = item
      }
    })
  },

  methods: {
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    goBack() {
      if (parseInt(this.$route.query.type) === 1) {
        this.$router.push('/gauge')
      } else {
        this.$router.push('/record')
      }
    },
    printMMSE() {
      // this.tableData.push({ question: '总分', answer: this.num })
      this.fullscreenLoading = true
      const content = {
        id: this.$route.query.assessmentId,
        institution: this.institution,
        reportName: this.reportName,
        patient: this.patientsList,
        treatments: this.tableData,
        logo: this.logo,
        enableScoreOverwrite: this.enableScoreOverwrite,
        displayTime: this.displayTime,
        treatmentTime: this.treatmentTime,
        partition: this.partition,
        typeUsername: this.typeUsername,
        bedNumberName: this.bedNumberName,
        numberName: this.numberName,
        mmseScore: this.mmseScore,
        mocaScore: this.mocaScore,
        mocaGrade: this.mocaGrade,
        mmseGrade: this.mmseGrade
      }
      import('../../lib/print-MMSE').then(({ printPDF }) => {
        printPDF(content)
      })
      setTimeout(() => {
        this.fullscreenLoading = false
      }, 1000)
    }
  },

  computed: {
    ...mapState({
      patients: state => state.patients.items
    }),
    ...mapGetters({
      isAdmin: 'users/isAdmin',
      patientsWA: 'patients/patientsWA',
      institution: 'setting/institution',
      reportName: 'setting/reportName',
      treatmentsByPatientId: 'treatments/treatmentsByPatientId',
      partition: 'setting/enabledPartition',
      typeUsername: 'setting/userName',
      treatments: 'treatments/treatmentsWADP',
      enableScoreOverwrite: 'setting/enableScoreOverwrite',
      displayTime: 'setting/displayTime',
      treatmentTime: 'setting/treatmentTime',
      logo: 'setting/logo',
      bedNumberName: 'setting/bedNumberName',
      numberName: 'setting/numberName'
    })
  }
}
</script>

<style lang="scss" scoped>
.report-title {
  margin-bottom: 20px;
}

.mb-6 {
  height: 44px !important;
}

.name {
  color: black;
  font-size: 16px;
  margin-left: 5px;
  position: relative;
  top: 1px;
}

.score-left {
  width: 60%;
  height: 240px;
  text-align: center;
  margin-top: 40px;

  .num {
    color: #4bd549;
    font-size: 150px;
  }

  .left-name {
    color: black;
    font-size: 20px;
    margin-top: 110px;
    font-weight: bold;
  }
}

.num {
  color: #4bd549;
  font-size: 150px;
  margin-top: 50px;
}

.left-name {
  color: black;
  font-size: 20px;
  margin-top: 110px;
  font-weight: bold;
}

.bottom {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  margin-top: 20px;
}

.score-right {
  margin-top: 20px;

  .red,
  .orange,
  .darkBlue,
  .blue,
  .green {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: #4bd549;
    border-radius: 50%;
  }

  .orange {
    background: #ed6f54;
  }

  .darkBlue {
    background: #fad984;
  }

  .section {
    font-size: 16px;
    color: black;
    margin-left: 10px;
    font-weight: bold;
  }
}

.blue1 {
  background: rgba(215, 237, 253, 0.5);
  height: 40px;
}

.white1 {
  height: 60px;
}

.patient_detail {
  display: flex;
  flex-wrap: wrap;
}
</style>