let optionsData = {
    score: {},
    report: {}
}
let seriesData = {
    score: [],
    report: []
}

const setData = (type, data) => {
    switch (type) {
        case 'score':
            optionsData.score = data.options
            seriesData.score = data.series
            break;
        case 'report':
            optionsData.report = data.options
            seriesData.report = data.series
            break;
        default:
            break;
    }
}

const options = (type) => {
    switch (type) {
        case 'score':
            return optionsData.score
        case 'report':
            return optionsData.report
        default:
            break;
    }
}

const series = (type) => {
    switch (type) {
        case 'score':
            return seriesData.score
        case 'report':
            return seriesData.report
        default:
            break;
    }
}
export {
    options,
    series,
    setData
}