import Vue from 'vue'
import VueRouter from 'vue-router'

import Gauge from '../components/Gauge/Gauge'
import GaugeRecord from '../components/Gauge/GaugeRecord'
import GaugeTest from '../components/Gauge/GaugeTest'
import ScalePrint from '../components/PrintPage/ScalePrint'
import PatientsDischarge from '../components/patients/PatientsDischarge'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: () => import('../components/Apps.vue'),
    meta: { requiresAuth: false, navShow: false }
  },
  {
    path: '/public-scale',
    name: 'PublicScale',
    component: () => import('../components/Gauge/PublicScale.vue'),
    meta: { requiresAuth: false, navShow: false }
  },
  {
    path: '/gauge',
    name: 'Gauge',
    component: Gauge,
    meta: { requiresAuth: true, navShow: false }
  },
  {
    path: '/gaugeResult/:id',
    name: 'GaugeResult',
    component: ScalePrint,
    meta: { requiresAuth: false, navShow: false }
  },
  {
    path: '/gauge/:id',
    name: 'GaugeTest',
    component: GaugeTest,
    meta: { requiresAuth: false, navShow: false }
  },
  {
    path: '/record',
    name: 'Record',
    component: GaugeRecord,
    meta: { requiresAuth: true, navShow: false }
  },
  {
    path: '/monitors',
    name: 'Monitors',
    component: () => import('../components/Monitors.vue'),
    meta: { requiresAuth: true, navShow: false }
  },
  {
    path: '/apps',
    name: 'Apps',
    component: () => import('../components/Apps.vue'),
    meta: { requiresAuth: true, navShow: false }
  },
  {
    path: '/treatments',
    name: 'Treatments',
    component: () => import('../components/Treatments.vue'),
    meta: { requiresAuth: true, navShow: false }
  },
  {
    path: '/treatments/:id',
    name: 'TreatmentDetail',
    component: () => import('../components/TreatmentDetail.vue'),
    meta: { requiresAuth: true, navShow: false }
  },
  {
    path: '/patients',
    name: 'Patients',
    component: () => import('../components/Patients.vue'),
    meta: { requiresAuth: true, navShow: false }
  },
  {
    path: '/patients/:id',
    name: 'PatientsDetail',
    component: () => import('../components/PatientDetail.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/discharge',
    name: 'PatientsDischarge',
    component: PatientsDischarge,
    meta: { requiresAuth: true }
  },
  {
    path: '/devices',
    name: 'Devices',
    component: () => import('../components/Devices.vue'),
    meta: { requiresAuth: true, navShow: false }
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('../components/Setting.vue'),
    meta: { requiresAuth: true, navShow: false }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../components/AboutPage.vue'),
    meta: { requiresAuth: true, navShow: false }
  }
]

const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['users/authorized']) {
      next()
      return
    }
  } else {
    next()
  }
})

export default router
