<template lang="pug">
#login-page(@click='visible = false')
  v-img#bg(:src='backgroundImage', v-if='isOpen')
  v-row.login_page
    v-col(:lg='isOpen ? 6 : 12', :md='isOpen ? 6 : 12', sm='12', xs='12')
      #login-section
        v-img#logo(:src='logoImage')
        #subtitle {{ $t("cognitiveSystem") }}YKVR-1
        //- span#subtitle(@click='aaa()') 11111111
        v-form.login_form(v-model='valid', ref='form')
          v-text-field(
            solo,
            required,
            maxlength='20',
            counter,
            prepend-inner-icon='mdi-account',
            v-model='username',
            :rules='rules.username',
            :label='$t("username")',
            @click.stop='visible = true',
            @focus.stop='keyboardShowClick'
          )

          v-text-field(
            solo,
            required,
            maxlength='20',
            counter,
            prepend-inner-icon='mdi-form-textbox-password',
            v-model='password',
            :rules='rules.password',
            :label='$t("password")',
            type='password',
            autocomplete='on',
            @click.stop='visible = true',
            @focus.stop='keyboardShowClick'
          )

          v-checkbox(dark, required, v-model='agreed', :rules='rules.agreement', @click.stop='keyboardShow = true')
            template(v-slot:label, color='white')
              span {{ $t("agreementMessage") }}
              a#agreement-link(text, @click='dialog = true') {{ $t("agreementName") }}
          v-btn#login-button(block, large, color='orange', dark, @click='login()') {{ $t("login") }}
        v-img.login_bg(src='@/assets/login_bg.png', width='250', v-if='!isOpen')

  span#locale-group
    v-select.solo.mr-3(reverse, style='max-width: 100px;color: white', v-model='lang', :items='langs', item-text='lan', item-value='abb', @change='changeLocale')
      template(v-slot:selection='{ item }')
        span(style='color: white') {{ currentLang }}
    //- v-btn(text, dark, @click='$root.$i18n.locale = "zh"') 中文
    //- v-btn(text, dark, @click='$root.$i18n.locale = "en"') english

  Keyboard#keyboard(layout='normal', :input='input', v-if='visible && enableTouch', :cancel='cancel')

  v-dialog(v-model='dialog', width='600px')
    v-card
      v-card-title
        span.headline 
      v-card-text
        p#agreement-doc {{ $t("agreement") }}
      v-card-actions
        v-spacer
        v-btn(text, @click='agreed = false; dialog = false') Disagree
        v-btn(text, @click='agreed = true; dialog = false') Agree

  v-snackbar(top, right, elevation='2', v-model='snackbar', timeout='2000') {{ notifMsg }}
    template(v-slot:action='{ attrs }')
      v-btn(color='blue', text, v-bind='attrs', @click='snackbar = false') Close

  ActivateDialog(ref='activateDialog', @snackSuccess='snackSuccess', @snackFail='snackFail')

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Keyboard from 'vue-keyboard-zh'
import ActivateDialog from './ActivateDialog.vue'
import { lang } from '@/api'
export default {
  data: vm => ({
    backgroundImage: require('@/assets/bg.jpg'),
    backgroundMobileImage: require('@/assets/bg.png'),
    logoImage: require('@/assets/logo.png'),
    subtitleImage: require('@/assets/subtitle.png'),

    valid: false,
    rules: {
      username: [v => !!v || vm.$t('usernameRule')],
      password: [v => !!v || vm.$t('passwordRule')],
      agreement: [v => !!v || vm.$t('agreementRule')]
    },
    lang: 'zh',
    langs: [],
    username: '',
    password: '',
    agreed: false,
    currentLang: '',
    dialog: false,
    snackbar: false,
    notifMsg: '',
    isOpen: false,
    visible: false,
    input: '',
    //appsId:
    //  '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112'
  }),

  components: { Keyboard, ActivateDialog },

  computed: {
    ...mapGetters({
      isAdmin: 'users/isAdmin',
      enableTouch: 'setting/enableTouch',
      warranty_date: 'about/warranty_date'
    })
  },

  async mounted() {
    this.resize()
    window.addEventListener('resize', this.resize, false)
    const langs = await lang.index()
    this.langs = langs.data
    this.lang = localStorage.getItem('lang')
    this.currentLang = this.langs.find((item) => item.abb === this.lang).lan || '中文'
  },

  watch: {
    warranty_date: function (val) {
      if (val == '' || val.slice(0, 4) == '0001') {
        this.$refs.activateDialog.activateDeviceDialog = true
      }
    }
  },

  methods: {
    changeLocale() {
      this.$root.$i18n.locale = this.lang
      localStorage.setItem('lang', this.lang)
      this.currentLang = this.langs.find((item) => item.abb === this.lang).lan || '中文'
    },
    keyboardShowClick(e) {
      this.input = e.target
      if (!this.visible) this.visible = true
    },
    cancel() {
      this.visible = false
    },
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    async login() {
      if (this.$refs.form.validate()) {
        const request = {
          username: this.username,
          password: this.password
        }
        try {
          await this.actionLogin(request)
          this.$router.go(0)
          window.localStorage['appsId'] = this.appsId
        } catch (err) {
          this.snackbar = true
          this.notifMsg = 'Login failed, check your username and password combination!'
          this.clear()
        }
      }
    },

    clear() {
      this.$refs.form.reset()
    },

    snackSuccess() {
      this.snackbar = true
      this.notifMsg = this.$t('notifSuccess')
    },

    snackFail(err) {
      this.snackbar = true
      this.notifMsg = `this.$t('notifFailure')：${err.message}!`
    },

    ...mapActions({
      actionLogin: 'users/login'
    })
  }
}
</script>

<style lang="sass" scoped>
@media (min-width: 1081px)
  #bg
    position: absolute
    min-height: 100%
    min-width: 100%
    top: 0
    bottom: 0
    left: 0
    right: 0
  #login-page
    width: 100%
  #login-section
    margin: 0 0 50px 0
    padding: 0 100px
  #logo
    max-width: 250px
    margin: auto auto 30px auto
  #subtitle
    width: 500px
    height: 50px
    text-align: center
    margin: auto auto 50px auto
    color: #ffffff
    font-size: 35px
    position: relative
    z-index: 999
    font-family: '楷体'
    font-weight: bold
  #agreement-doc
    white-space: per-line
  #agreement-link
    color: white
  #login-button
    margin-top: 50px
  #locale-group
    position: fixed
    top: 12px
    right: 6px
@media (max-width: 1080px)

  #login-page
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    height: 100%
    width: 100%
    background: url('../assets/bg.png') 100% 100%
    overflow: hidden
  #login-section
    margin: 100px auto
  #logo
    max-width: 250px
    margin: auto auto 30px auto
  #subtitle
    width: 500px
    height: 50px
    text-align: center
    margin: auto auto 50px auto
    color: #ffffff
    font-size: 35px
    position: relative
    z-index: 999
    font-family: '楷体'
    font-weight: bold
  #agreement-doc
    white-space: per-line
  #agreement-link
    color: white
  #login-button
    margin-top: 50px
  #locale-group
    position: fixed
    top: 12px
    right: 6px
  .login_form
    width: 80%
    margin: 0 auto
  .login_bg
    position: absolute
    right: 0px
    bottom: 0px
  #keyboard
    position: absolute
    top: auto !important
    bottom: 0
    box-sizing: border-box
    padding: 14px 21px
</style>
