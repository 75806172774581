<template lang="pug">
div(v-if='patientId')
  v-row(align='center')
    v-tooltip(bottom)
      template(v-slot:activator='{ on, attrs }')
        span(v-bind='attrs', v-on='on')
          v-icon.mr-2 mdi-account
          span {{ patientById(patientId).name.slice(0, 3) }}
      span {{ $t("name") }}
    v-divider.mx-4(vertical)
    v-tooltip(bottom)
      template(v-slot:activator='{ on, attrs }')
        span(v-bind='attrs', v-on='on')
          v-icon.mr-2 mdi-clipboard-account
          span {{ patientById(patientId).hospitalNumber }}
      span {{ numberName ? numberName : this.$t('number') }}
    
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['patientId', 'userType', 'isOpen'],

  computed: {
    ...mapGetters({
      patientById: 'patients/patientById',
      partition: 'setting/enabledPartition',
      bedNumberName: 'setting/bedNumberName',
      numberName: 'setting/numberName'
    })
  }
}
</script>

