<template lang="pug">
div
  v-row
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-school-outline
        | {{ $t("tutorial") }}
        v-spacer
        v-switch(inset, v-model='tutorial')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-monitor-eye
        | {{ $t("monitor") }}
        v-spacer
        v-switch(inset, v-model='monitor')
      v-row.setting-row(align='center', no-gutters, v-if='enabledCountdown')
        v-icon(left) mdi-timer-outline
        | {{ $t("timeLimit") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='timeLimit',
          :items='timeLimits',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters, v-if='!enabledCountdown')
        v-icon(left) mdi-timer-outline
        | {{ $t("timeLimit") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='noCountdownTimeLimit',
          :items='noCountdowntimeLimits',
          item-text='text',
          item-value='value'
        )
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-cube-outline
        | {{ $t("mode") }}
        v-spacer
        v-select.outlined.mr-3(reverse, style='max-width: 100px', v-model='mode', :items='modes', item-text='text', item-value='value')
      v-row.setting-row(align='center', no-gutters, v-if='mode == "classic"')
        v-icon(left) mdi-timer-outline
        | {{ $t("difficulty") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='missionDifficulty',
          :items='difficulties',
          item-text='text',
          item-value='value',
          @change='getDifficultyVal'
        )
      v-row.song-row(align='center', no-gutters)
        v-icon(left) mdi-music-circle-outline
        | {{ $t("musicSelect") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          multiple,
          style='max-width: 300px',
          v-model='music',
          :items='musicAvail',
          item-text='text',
          item-value='value'
        )
          template(v-slot:selection='{ item, index }')
            span.grey--text.text-caption(v-if='index === 1') + {{  music.length - 1 + ' ' + $t("others")  }}
            v-chip(v-if='index === 0')
              span {{ item.text }}
</template>

<script>
import { VUE_APP_FILE_SERVER_ENDPOINT } from '@/app.config'
import { mapGetters } from 'vuex'

export default {
  data: vm => ({
    tutorial: true,
    monitor: true,
    timeLimit: 15,
    timeLimits: [
      { text: vm.$t('5min'), value: 5 },
      { text: vm.$t('10min'), value: 10 },
      { text: vm.$t('15min'), value: 15 },
      { text: vm.$t('20min'), value: 20 },
      { text: vm.$t('30min'), value: 30 }
    ],
    noCountdownTimeLimit: -1,
    noCountdowntimeLimits: [
      { text: vm.$t('infinite'), value: -1 },
      { text: vm.$t('5min'), value: 5 },
      { text: vm.$t('10min'), value: 10 },
      { text: vm.$t('15min'), value: 15 },
      { text: vm.$t('20min'), value: 20 },
      { text: vm.$t('30min'), value: 30 }
    ],
    mode: 'adaptive',
    modes: [
      { text: vm.$t('adaptiveMode'), value: 'adaptive' },
      { text: vm.$t('classicMode'), value: 'classic' }
    ],
    music: [
      { musicTitle: vm.$t('templeDance'), musicUrl: `${VUE_APP_FILE_SERVER_ENDPOINT}/庙舞.mp3` },
      { musicTitle: vm.$t('artist'), musicUrl: `${VUE_APP_FILE_SERVER_ENDPOINT}/艺术家.mp3` },
      { musicTitle: vm.$t('greatWall'), musicUrl: `${VUE_APP_FILE_SERVER_ENDPOINT}/长城.mp3` }
    ],
    musicAvail: [
      { text: vm.$t('boat'), value: { musicTitle: vm.$t('boat'), musicUrl: `${VUE_APP_FILE_SERVER_ENDPOINT}/小船.mp3` } },
      { text: vm.$t('templeDance'), value: { musicTitle: vm.$t('templeDance'), musicUrl: `${VUE_APP_FILE_SERVER_ENDPOINT}/庙舞.mp3` } },
      { text: vm.$t('riceAndWine'), value: { musicTitle: vm.$t('riceAndWine'), musicUrl: `${VUE_APP_FILE_SERVER_ENDPOINT}/米和酒.mp3` } },
      { text: vm.$t('artist'), value: { musicTitle: vm.$t('artist'), musicUrl: `${VUE_APP_FILE_SERVER_ENDPOINT}/艺术家.mp3` } },
      { text: vm.$t('greatWall'), value: { musicTitle: vm.$t('greatWall'), musicUrl: `${VUE_APP_FILE_SERVER_ENDPOINT}/长城.mp3` } },
      { text: vm.$t('yinAndYang'), value: { musicTitle: vm.$t('yinAndYang'), musicUrl: `${VUE_APP_FILE_SERVER_ENDPOINT}/阴阳.mp3` } },
      { text: vm.$t('recipe'), value: { musicTitle: vm.$t('recipe'), musicUrl: `${VUE_APP_FILE_SERVER_ENDPOINT}/食谱.mp3` } },
      { text: vm.$t('YearOfTheDragon'), value: { musicTitle: vm.$t('YearOfTheDragon'), musicUrl: `${VUE_APP_FILE_SERVER_ENDPOINT}/龙年.mp3` } },
      { text: vm.$t('CanonInDMajor'), value: { musicTitle: vm.$t('CanonInDMajor'), musicUrl: `${VUE_APP_FILE_SERVER_ENDPOINT}/D大调卡农.mp3` } },
      { text: vm.$t('BrandenburgConcerto'), value: { musicTitle: vm.$t('BrandenburgConcerto'), musicUrl: `${VUE_APP_FILE_SERVER_ENDPOINT}/勃兰登堡协奏曲.mp3` } },
      { text: vm.$t('HungarianDance'), value: { musicTitle: vm.$t('HungarianDance'), musicUrl: `${VUE_APP_FILE_SERVER_ENDPOINT}/匈牙利舞曲.mp3` } },
      { text: vm.$t('SymphonyOfDestiny'), value: { musicTitle: vm.$t('SymphonyOfDestiny'), musicUrl: `${VUE_APP_FILE_SERVER_ENDPOINT}/命运交响曲.mp3` } },
      { text: vm.$t('TurkishMarch'), value: { musicTitle: vm.$t('TurkishMarch'), musicUrl: `${VUE_APP_FILE_SERVER_ENDPOINT}/土耳其進行曲.mp3` } },
      { text: vm.$t('StringSerenade'), value: { musicTitle: vm.$t('StringSerenade'), musicUrl: `${VUE_APP_FILE_SERVER_ENDPOINT}/弦乐小夜曲.mp3` } },
      { text: vm.$t('SymphonyNo. 40'), value: { musicTitle: vm.$t('SymphonyNo. 40'), musicUrl: `${VUE_APP_FILE_SERVER_ENDPOINT}/第40号交响曲.mp3` } },
      { text: vm.$t('AllaisGirl'), value: { musicTitle: vm.$t('AllaisGirl'), musicUrl: `${VUE_APP_FILE_SERVER_ENDPOINT}/阿莱城姑娘.mp3` } }
    ],
    missionDifficulty: 1,
    difficulties: [
      { text: vm.$t('easy'), value: 0 },
      { text: vm.$t('normal'), value: 1 },
      { text: vm.$t('hard'), value: 2 }
    ]
  }),

  methods: {
    getDifficultyVal(value) {
      this.missionDifficulty = value
    },
    getSettings() {
      return JSON.stringify({
        tutorial: this.tutorial,
        monitor: this.monitor,
        timeLimit: this.enabledCountdown ? this.timeLimit : this.noCountdownTimeLimit,
        mode: this.mode,
        music: this.music,
        missionDifficulty: this.missionDifficulty
      })
    }
  },

  computed: {
    ...mapGetters({
      enabledCountdown: 'setting/enabledCountdown'
    })
  }
}
</script>

<style lang="sass">
.setting-row
  height: 50px
  .song-row
    height: 80px
</style>

