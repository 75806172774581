import { render, staticRenderFns } from "./SnowballfightSettings.vue?vue&type=template&id=0c51f533&lang=pug"
import script from "./SnowballfightSettings.vue?vue&type=script&lang=js"
export * from "./SnowballfightSettings.vue?vue&type=script&lang=js"
import style0 from "./SnowballfightSettings.vue?vue&type=style&index=0&id=0c51f533&prod&lang=sass"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports