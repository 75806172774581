<template lang="pug">
div(v-resize='onResize')
  v-toolbar.mb-6(flat)
    v-btn.ma-2(large, color='orange', dark, @click='goBack()')
      v-icon(dark, left) mdi-arrow-left
      | {{ $t("return")}}
    v-divider.mx-4(inset, vertical)
    v-icon.mr-1 mdi-file-chart
    v-toolbar-title {{ $t("viewreport") }}
    v-spacer
    div
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn.primary.mr-2(fab, v-bind='attrs', v-on='on', @click='print()')
            v-icon mdi-printer
        span {{ $t("actionPrintTooltip") }}
  v-card.content-page
    div(:class='isOpen ? "page" : "mPage"')
      #divToPrint
        .page-top
          img.img-logo(:src='getSrc()', width='140', v-if='logo') 
          .page-title
            .name {{ institution }}
            .subtitle {{ reportName }}
            //- img.img-logo(:src='getSrc()', width='100', v-if='logo')
        .page-msg(v-if='printContentList.name !== undefined')
          .row1
            .name(style='width: 266px') {{ $t("name") }}：{{ printContentList.name }}
            .gender(style='width: 266px; text-align: center') {{ $t("gender")}}：{{ printContentList.gender }}
            .hospitalNumber(style='width: 266px; text-align: right') {{ `${numberName ? numberName + "：" : $t("number") + "："}${printContentList.hospitalNumber}` }}
          .row2
            .age(v-if='enableAge', style='width: 266px') {{ $t("age")}}：{{ calcAge(printContentList.birthday) >= 0 ? calcAge(printContentList.birthday) : "" }}
            .bedNumberName(v-if='bedNumberName !== "false"', :style='{ width: "266px", textAlign: enableAge ? "center" : "left" }') {{ (typeUsername == "1" ?  $t("userNameNumber") + ":" : $t("patientNumber") + "：") + printContentList.patientNumber }}
            .partition(v-if='partition !== "false"', :style='{ width: "266px", textAlign: enableAge ? "right" : "center" }') {{ (partition == "true" ? $t("subregion") + "：" : partition + "：") + printContentList.bedNumber }}
        table(width='100%', cellspacing='0', style='margin-top: 40px; font-size: 14px', v-if='isPrint === true')
          thead
            tr(style='background: #2980ba; height: 30px; color: white')
              td(align='center') {{ $t("number") }}
              td(align='center') {{ $t("treatmentItems") }}
              td(align='center') {{ $t("trainingGoal") }}
              td(align='center') {{ $t("trainingScore") }}
              td(align='center', v-if='treatmentTime') {{ $t("treatmentDurationMinutes") }}
              td(align='center') {{ $t("trainingStartTime") }}
              td(align='center', v-if='enableEndTime') {{ $t("trainingEndTime") }}
          tbody
            tr(
              v-for='(item, index) in tableData',
              :key='index',
              height='30',
              :style='{ background: index % 2 === 0 ? "#F5F5F5" : "#FFFFFF" }'
            )
              td(align='center') {{ item.no }}
              td(align='center') {{ item.name }}
              td(align='center') {{ item.target }}
              td(align='center') {{ item.score }}
              td(align='center', v-if='treatmentTime') {{ item.treatmentTime }}
              td(align='center') {{ item.applicationStartTime }}
              td(align='center', v-if='enableEndTime') {{ item.applicationEndTime }}
        #table(
          :style='{ height: dataTableHeight + "px", position: "relative", overflowY: "scroll", marginTop: "40px", fontSize: "14px" }',
          v-if='isPrint === false'
        )
          table(width='100%', cellspacing='0')
            thead(style='position: sticky; top: 0; z-index: 1')
              tr(style='background: #2980ba; height: 30px; color: white')
                td(align='center') {{ $t("number") }}
                td(align='center') {{ $t("treatmentItems") }}
                td(align='center') {{ $t("trainingGoal") }}
                td(align='center') {{ $t("trainingScore") }}
                td(align='center', v-if='treatmentTime') {{ $t("treatmentDurationMinutes") }}
                td(align='center') {{ $t("trainingStartTime") }}
                td(align='center', v-if='enableEndTime') {{ $t("trainingEndTime") }}
            tbody
              tr(
                v-for='(item, index) in tableData',
                :key='index',
                height='30',
                :style='{ background: index % 2 === 0 ? "#F5F5F5" : "#FFFFFF" }'
              )
                td(align='center') {{ item.no }}
                td(align='center') {{ item.name }}
                td(align='center') {{ item.target }}
                td(align='center') {{ item.score }}
                td(align='center', v-if='treatmentTime') {{ item.treatmentTime }}
                td(align='center') {{ item.applicationStartTime }}
                td(align='center', v-if='enableEndTime') {{ item.applicationEndTime }}
        .result-msg(v-if="diagnosis!=''||plan!=''") 
          .row1(v-if="diagnosis!=''") 
            .name {{ $t("trainingEvaluation") }}：
            .content {{ diagnosis }}
          .row1(v-if="plan!=''")  
            .name {{ $t("nextTreatmentPlan") }}：
            .content {{ plan }}
      #bottomFont.page-bottom
        .left
          span {{ $t("printTime") }}：{{ calcDateTime(new Date()) }}
          span *{{ $t("thisReportClinicalReferenceOnly") }}
        .right
          span {{ $t("signature") }}：
</template>

<script>
import { VUE_APP_SERVER_ENDPOINT } from '@/app.config'
import { printPDF } from '../../lib/patientPrint'
import { mapGetters, mapState } from 'vuex'
import { patient, app } from '../../api'
// import domtoimage from 'dom-to-image-more'
export default {
  data() {
    return {
      dataTableHeight: 0,
      // 判断有滚动条的表格，打印时展开
      isPrint: false,
      appsData: [],
      printContentList: {},
      printContent: [],
      categoryType: '0,1,2,3,4,5,6,7,8',
      tableData: [],
      hospital: '',
      patientsData: [],
      patientsList: [],
      isOpen: true,
      diagnosis: "",
      plan: ""
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'users/isAdmin',
      patientsWA: 'patients/patientsWA',
      institution: 'setting/institution',
      reportName: 'setting/reportName',
      treatmentsByPatientId: 'treatments/treatmentsByPatientId',
      partition: 'setting/enabledPartition',
      typeUsername: 'setting/userName',
      treatments: 'treatments/treatmentsWADP',
      enableScoreOverwrite: 'setting/enableScoreOverwrite',
      displayTime: 'setting/displayTime',
      treatmentTime: 'setting/treatmentTime',
      logo: 'setting/logo',
      bedNumberName: 'setting/bedNumberName',
      numberName: 'setting/numberName',
      enableEndTime: 'setting/enableEndTime',
      enableTouch: 'setting/enableTouch',
      enableAge: 'setting/enableAge'
    }),
    ...mapState({
      patients: state => state.patients.items,
      devices: state => state.devices.items,
      apps: state => state.apps.items
    }),
    getUserId() {
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      return userId
    }
  },
  watch: {
    patientsWA(val) {
      if (this.hospital == '') {
        this.printContentList = val.find(item => {
          return item.model.ID === parseInt(this.$route.params.id)
        })
        if (this.printContentList.name !== undefined) {
          this.getPageData()
        }
      }
    }
  },
  mounted() {
    this.hospital = this.$route.query.hospital
    this.getPageData()
    this.resize()
    window.addEventListener('resize', this.resize, false)
    this.diagnosis = this.$route.query.diagnosis
    this.plan = this.$route.query.plan
  },
  methods: {
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    onResize() {
      const height = window.innerHeight - 600
      this.dataTableHeight = height
    },
    goBack() {
      this.$router.go(-1)
    },
    print() {
      this.isPrint = true
      const printDom = document.querySelector('#divToPrint')
      const bottomFontDom = document.querySelector('#bottomFont')
      const content = {
        printDom: printDom,
        bottomFontDom: bottomFontDom,
        type: 'patients',
        isOpen: this.isOpen
      }
      printPDF(content).then(() => {
        this.isPrint = false
      })
    },
    // printDetail() {
    //   this.fullscreenLoading = true
    //   this.printTreatment = {
    //     patient: this.patientsList,
    //     Score: this.assessmentIdData,
    //     dimensionData: this.dimensionData,
    //     treatmentRecord: this.treatmentRecord,
    //     dimensionResult: this.dimensionCrease,
    //     treatmentPlan: this.treatmentPlan
    //   }
    //   const content = {
    //     institution: this.institution,
    //     reportName: this.reportName,
    //     patientsInfo: this.printTreatment,
    //     logo: this.logo,
    //     enableScoreOverwrite: this.enableScoreOverwrite,
    //     displayTime: this.displayTime,
    //     treatmentTime: this.treatmentTime,
    //     partition: this.partition,
    //     typeUsername: this.typeUsername,
    //     bedNumberName: this.bedNumberName,
    //     numberName: this.numberName,
    //     enableEndTime: this.enableEndTime,
    //     printDom: this.printDom,
    //     cognitiveTime: this.cognitiveTime,
    //     dimensionTime: this.dimensionTime,
    //     diagnosis: this.diagnosis,
    //     isOpen: this.isOpen,
    //     testResult: this.testResult
    //   }
    //   this.patientPdf(content)
    //   setTimeout(() => {
    //     this.fullscreenLoading = false
    //   }, 1000)
    // },
    getSrc() {
      return `${VUE_APP_SERVER_ENDPOINT}/assets/${this.logo}`
    },
    calcAge(dateString) {
      var birthday = +new Date(dateString)
      return ~~((Date.now() - birthday) / 31557600000)
    },
    calcTreatmentTime(endTime, startTime) {
      var minutes = 0
      if (new Date(startTime).getTime() > new Date(endTime).getTime()) {
        minutes = 0
      } else {
        const diff = Math.abs(new Date(endTime) - new Date(startTime))
        minutes = Math.round(diff / 1000 / 60)
      }

      return minutes
    },

    calcDateTime(dateString, displayTime = true) {
      const date = new Date(dateString)
      if (displayTime) {
        return (
          date.toLocaleDateString('zh-CN', { hour12: false }) +
          ' ' +
          date.toLocaleTimeString('zh-CN', { hour: '2-digit', minute: '2-digit' })
        )
      } else {
        return date.toLocaleDateString('zh-CN', { hour12: false })
      }
    },
    getLocalStorage1(query, show) {
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      const obj1 = {
        adminId: role == 'admin' ? adminId : 1,
        userId: userId,
        role: role,
        isShow: show,
        type: String(query)
      }
      return obj1
    },
    genTableData(content) {
      const _treatments = content
      _treatments.sort((a, b) => {
        return (
          new Date(a.applicationStartTime.slice(0, 4) == '0001' ? a.model.CreatedAt : a.applicationStartTime) -
          new Date(b.applicationStartTime.slice(0, 4) == '0001' ? b.model.CreatedAt : b.applicationStartTime)
        )
      })
      return _treatments.map((c, p) => {
        const score = c.scoreOverwrite
        return {
          no: p + 1,
          name: c.app.name,
          device: c.device.sn,
          score,
          target: c.app.tags,
          treatmentTime: this.treatmentTime
            ? this.calcTreatmentTime(
              c.applicationEndTime,
              c.applicationStartTime.slice(0, 4) == '0001' ? c.model.CreatedAt : c.applicationStartTime
            )
            : '',
          applicationStartTime: this.calcDateTime(
            c.applicationStartTime.slice(0, 4) == '0001' ? c.model.CreatedAt : c.applicationStartTime,
            this.displayTime
          ),
          applicationEndTime: this.enableEndTime ? this.calcEndDateTime(c.applicationEndTime, content.displayTime) : ''
        }
      })
    },
    calcEndDateTime(dateString, displayTime = true) {
      const date = new Date(dateString)
      if (displayTime) {
        return (
          date.toLocaleDateString('zh-CN', { hour12: false }) +
          ' ' +
          date.toLocaleTimeString('zh-CN', { hour: '2-digit', minute: '2-digit' })
        )
      } else {
        return date.toLocaleDateString('zh-CN', { hour12: false })
      }
    },
    async getPageData() {
      const rangeVal = JSON.parse(this.$route.query.rangeVal)
      const year = new Date().getFullYear()
      const month = new Date().getMonth() + 1
      const day = new Date().getDate()
      const currentDate = year + '-' + (month > 9 ? month : '0' + month) + '-' + day
      if (this.patientsWA.length > 0 && this.hospital == '') {
        this.printContentList = this.patientsWA.find(item => {
          return item.model.ID === parseInt(this.$route.params.id)
        })
      }

      // 出院病人病情管理
      if (this.hospital == 'discharge') {
        const dischargeRes = await patient.discharge('', '', this.getUserId)
        this.patientsData = dischargeRes.data
        this.printContentList = dischargeRes.data.find(x => {
          return x.model.ID == parseInt(this.$route.params.id)
        })
        this.diagnosis = this.printContentList.diagnosis
      }

      if (this.hospital == '') {
        this.patientsList = this.patients
      } else {
        this.patientsList = this.patientsData
      }

      const appResult = await app.index(this.getLocalStorage1(this.categoryType, 1))
      this.appsData = appResult.data
      if (this.printContentList.name !== undefined) {
        const result = await patient.printTreatment({
          patientId: this.printContentList.model.ID,
          startTime: rangeVal.startDate == '' ? '1900-01-01 00:00:00' : rangeVal.startDate + ' ' + '00:00:00',
          endTime: rangeVal.endDate == '' ? currentDate + ' ' + '23:59:59' : rangeVal.endDate + ' ' + '23:59:59'
        })
        if (this.apps.length > 0 || this.patientsList.length > 0 || this.devices.length > 0) {
          this.getData = result.data.map(x => {
            const app = this.appsData.find(y => y.model.ID == x.appId)
            if (app) x.app = app
            const patient = this.patientsList.find(y => y.model.ID == x.patientId)
            if (patient) x.patient = patient
            const device = this.devices.find(y => y.model.ID == x.deviceId)
            if (device) x.device = device
            if (x.deviceId === 0) {
              this.deviceObject = { sn: 'PA7L10PGF7150095W' }
            } else {
              this.deviceObject = { sn: x.device.sn }
            }
            x.device = this.deviceObject
            return x
          })
          this.treatmentsData = []
          this.getData.forEach(x => {
            if (x != undefined) {
              if (x.app != undefined && x.patient != undefined) {
                this.treatmentsData.push(x)
              }
            }
          })
        }
        this.printContent = []
        this.treatmentsData.forEach(item => {
          result.data.forEach(i => {
            if (item.model.ID === i.model.ID) {
              this.printContent.push(item)
            }
          })
        })
        this.printContent.sort((a, b) => {
          return Date.parse(a.model.CreatedAt) - Date.parse(b.model.CreatedAt)
        })
        this.tableData = this.genTableData(this.printContent)
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.content-page {
  width: 880px;
  padding: 20px 0 20px 0;
  margin: 0 auto;
}

.mPage {
  width: 100%;
  padding: 0 20px;
}

.page {
  width: 800px;
  margin: 0 auto;
}

#divToPrint {
  overflow: auto;
}

.page-top {
  display: flex;
  position: relative;

  .img-logo {
    position: absolute;
    left: 0;
    top: 0px;
    margin-top: 20px;
  }

  .page-title {
    width: 100%;
    text-align: center;

    .name {
      font-size: 32px;
      margin-top: 30px;
    }

    .subtitle {
      font-size: 24px;
      margin-top: 20px;
    }
  }
}

.page-msg {
  width: 100%;
  height: 72px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin-top: 50px;
  padding-top: 12px;

  .row1 {
    display: flex;
  }

  .row2 {
    display: flex;
  }
}

.result-msg {
  width: 100%;
  height: 96px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin-top: 50px;
  padding-top: 12px;

  .row1 {
    display: flex;

    .name {
      width: 150px;
    }

    .content {
      width: 650px;
      overflow-x: scroll;
    }
  }
}

.page-bottom {
  margin-top: 50px;
  display: flex;

  .left {
    font-size: 12px;
    display: flex;
    flex-direction: column;
  }

  .right {
    margin-left: 300px;
  }
}
</style>