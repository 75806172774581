import { io } from "socket.io-client"
import { Zlib } from './gunzip.min'
import { VUE_APP_STREAMER_ENDPOINT } from '@/app.config'

export function connectStreamer(treatmentId, callback) {
  let dataID_img = 0
  let dataLength_img = 0
  let receivedLength_img = 0
  let dataByte_img = new Uint8Array(0)
  let ReadyToGetFrame_img = true
  let socket = undefined

  if(JSON.parse(window.localStorage.getItem('vuex')) != null){
    const logToken = JSON.parse(window.localStorage.getItem('vuex'))
    const adminId = logToken.users.userId
    const role = logToken.users.role
    const userId = parseInt(role == 'admin'?(window.localStorage.getItem('userId')? window.localStorage.getItem('userId') : 1):adminId)
    socket = io.connect(VUE_APP_STREAMER_ENDPOINT,{query:`uid=${userId}`})
    // io = SocketIO(VUE_APP_SOCKET_ENDPOINT,{query:`uid=${userId}`})
  }else{
    socket = io.connect(VUE_APP_STREAMER_ENDPOINT)
  }

  // const socket = io.connect(VUE_APP_STREAMER_ENDPOINT)

  socket.on("connect", () => {
    socket.emit('RegViewer')
  })

  socket.on(`streaming/${treatmentId}`, data => {
    const version = data.version
    const cut = version == '2.0' ? 18 : 17
    const _byteData = new Uint8Array(data.DataByte)

    const _dataID = ByteToInt32(_byteData, 4)
    if (_dataID != dataID_img) receivedLength_img = 0

    dataID_img = _dataID
    dataLength_img = ByteToInt32(_byteData, 8)

    let _GZipMode = (_byteData[16] == 1) ? true : false

    if (receivedLength_img == 0) dataByte_img = new Uint8Array(0)
    receivedLength_img += _byteData.length - cut

    //----------------add byte----------------
    dataByte_img = CombineInt8Array(dataByte_img, _byteData.slice(cut, _byteData.length))
    //----------------add byte----------------

    if (ReadyToGetFrame_img) {
      if (receivedLength_img == dataLength_img) {
        ReadyToGetFrame_img = false

        let binary = ''

        let bytes = new Uint8Array(dataByte_img)
        if (_GZipMode) {
          let gunzip = new Zlib.Gunzip(bytes)
          bytes = gunzip.decompress()
        }

        //----conver byte[] to Base64 string----
        let len = bytes.byteLength
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i])
        }
        //----conver byte[] to Base64 string----

        const src = 'data:image/jpeg;base64,' + btoa(binary)
        callback(src)
        ReadyToGetFrame_img = true
      }
    }
  })

  function CombineInt8Array(a, b) {
    let c = new Int8Array(a.length + b.length)
    c.set(a)
    c.set(b, a.length)
    return c
  }

  function ByteToInt32(_byte, _offset) {
    return (_byte[_offset] & 255) + ((_byte[_offset + 1] & 255) << 8) + ((_byte[_offset + 2] & 255) << 16) + ((_byte[_offset + 3] & 255) << 24)
  }

  return socket
}