<template lang="pug">
div
  v-row.mb-2(align='center')
    .mr-8
      v-icon.mr-1 mdi-star-box-multiple-outline
      span {{ `${$t("currentMission")} ${treatment.missionId} / ${treatment.numberOfMissions}` }}
    div
      v-icon.mr-1 mdi-timer-outline
      span {{ `${$t("currentMissionDuration")} ${calculateDuration(treatment.missionStartTime)}` }}
    v-spacer
    div
      v-icon.mr-1 mdi-timer
      span {{ `${$t("totalDuration")} ${calculateDuration(treatment.applicationStartTime)}` }}
  v-row(align='center')
    div
      v-icon.mr-1 mdi-bullseye
      span {{ `${$t("missionTargets")} - ${treatment.missionTargets}` }}
</template>

<script>
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

export default {
  props: ['treatment'],

  data: () => ({
    now: moment(),
    timerId: undefined
  }),

  methods: {
    calculateDuration(then) {
      if (this.now?.diff && moment(then).year() >= 2021) {
        return moment.duration(this.now.diff(then)).format(this.$t('hHourmmMinssSecond'))
      } else {
        return NaN
      }
    }
  },

  watch: {
    treatment: function (newVal) {
      if (!newVal.active) {
        clearInterval(this.timerId)
      }
    }
  },

  beforeDestroy() {
    clearInterval(this.timerId)
  },

  created() {
    momentDurationFormatSetup(moment)
    this.timerId = this.$mySetInterval(() => {
      setTimeout(() => {
        this.now = moment()
      }, 0)
    })
  }
}
</script>

