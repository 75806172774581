<template lang="pug">
div
  v-toolbar.mb-6(flat)
    v-btn.ma-2(large, color="orange", dark, @click="goBack()")
      v-icon(dark, left) mdi-arrow-left
      | {{ $t("return") }}
    v-divider.mx-4(inset, vertical)
  div(
    style="width: 97%; margin: 0 auto",
    v-if="parseInt(this.$route.query.assessmentId) === 1"
  )
    .test-title {{ $t("MiniMentalStateExamination") }}
    .test-tip 共30题，每项正确回答得1分，回答错误或者不知道得0分，量表总分范围为0-30分。
    div(v-for="(item, index) in testList", :key="index")
      .title1
        span.circle
        span {{ item.title }}
      div(
        v-for="(i, index) in item.contents",
        :key="index",
        style="position: relative; overflow: hidden"
      )
        .subTitle1 {{ i.subTitle1 }}
        .subTitle {{ i.subTitle }}
        div(
          v-for="(j, index) in i.question",
          :key="index",
          :style="{ marginTop: j.img ? '20px' : 0, display: j.img ? 'block' : 'none', marginLeft: '20px' }"
        )
          img(:src="j.img")
        div(style="height: 10px")
          div(
            style="color: red; margin-left: 20px",
            v-if="i.required === false"
          ) * {{ $t("selectPlease")}}
          div(
            style="width: 98%; display: flex; justify-content: end; background: pink; margin-left: 20px; position: relative"
          )
            div(
              style="width: 70px; display: flex; justify-content: space-between; position: absolute; right: 45px"
            )
              span {{ $t("0score")}}
              span {{ $t("1score")}}
        div(style="height: 20px")
        v-simple-table(style="width: 98%; margin-left: 20px")
          template(v-slot:default)
            tbody
              tr(v-for="(j, index) in i.question", :key="index")
                td {{ j.question }}
                td(align="right")
                  div(
                    style="width: 100px; display: flex; justify-content: space-between"
                  )
                    v-radio-group(
                      v-model="j.answer",
                      row,
                      @change="answerClick()"
                    )
                      v-radio(:value="0")
                      v-radio(:value="1")
    div(style="text-align: center")
      div(v-if="isShow", style="color: red; margin-top: 50px") {{ $t("uncheckedPoints") }}
      v-btn(
        color="primary",
        style="margin-top: 20px; margin-bottom: 50px",
        @click="submit()"
      ) {{ $t("submit") }}
  div(style="width: 97%; margin: 0 auto", v-else)
    .test-title {{ $t("MontrealCognitiveAssessment") }}
    .test-tip 共30题，每项正确回答得1分，回答错误或者不知道得0分，量表总分范围为0-30分。
    div(v-for="(item, index2) in MoCAList", :key="index2")
      .title1
        span.circle
        span {{ item.title }}
      div(
        v-for="(i, index1) in item.contents",
        :key="index",
        style="position: relative; overflow: hidden"
      )
        .subTitle1(style="margin-top: 40px") {{ i.subTitle1 }}
        .subTitle {{ i.subTitle }}
        .describe {{ i.describe }}
        div(style="margin-top: 30px; margin-left: 20px")
          img(v-for="x in i.imgList", :src="x", style="margin-right: 30px")
        div(v-for="x in i.tip")
          .describe {{ x.title }}
          div(style="height: 10px")
          div(v-html="x.content", style="margin-left: 20px")

        div(style="height: 10px")
        div(style="color: red; margin-left: 20px", v-if="i.required === false") * {{ $t("selectPlease")}}
        div(
          style="width: 99%; display: flex; justify-content: end; background: pink; margin-left: 20px; position: relative"
        )
          div(
            style="width: 70px; display: flex; justify-content: space-between; position: absolute; right: 45px",
            v-if="index2 !== 2"
          )
            span {{ $t("0score")}}
            span {{ $t("1score")}}
        div(style="height: 20px")
        v-simple-table(style="width: 99%; margin-left: 20px")
          template(v-slot:default)
            tbody
              tr(v-for="(j, index) in i.question", :key="index")
                td
                  img(:src="j.img")
                  span {{ j.question }}
                td(align="right")
                  div(
                    style="width: 100px; display: flex; justify-content: space-between"
                  )
                    v-radio-group(
                      v-model="j.answer",
                      row,
                      @change="answerClick"
                    )
                      v-radio(:value="0")
                      v-radio(:value="1")

    div(style="text-align: center")
      div(v-if="isShow", style="color: red; margin-top: 50px") {{ $t("uncheckedPoints") }}
      v-btn(
        color="primary",
        style="margin-top: 20px; margin-bottom: 50px",
        @click="submit()"
      ) {{ $t("submit")}}

    v-dialog(v-model="dialog", width="400", persistent)
      v-card
        v-card-title.primary.headline.mb-12
          h4(style="color: white") {{ $t("selectSubjectEducationLevel") }}
        v-card-text
          v-form(ref="form", v-model="valid")
            v-row
              v-col(cols="12", sm="12", md="12")
                v-select(
                  :items="educations",
                  v-model="education",
                  :label='$t("educationalQualifications")',
                  solo,
                  prepend-inner-icon="mdi-gender-male-female",
                  :rules="rules.educationRules",
                  required
                )
        v-card-actions
          v-spacer
          v-btn(text, @click="dialog = false") {{ $t("drCancel") }}
          v-btn(text, @click="save()") {{ $t("daSave") }}
</template>

<script>
import { gauge } from "../../api";
import { mapState } from "vuex";
export default {
  data: (vm) => ({
    testList: [
      {
        title: "定向力",
        contents: [
          {
            subTitle:
              "现在是（星期几）（几号）（几月）（什么季节）（哪一年）？",
            required: "",
            question: [
              {
                question: "今天是星期几?",
                answer: "",
              },
              {
                question: "今天是几号?",
                answer: "",
              },
              {
                question: "现在是几月份?",
                answer: "",
              },
              {
                question: "现在是什么季节?",
                answer: "",
              },
              {
                question: "今年的年份?",
                answer: "",
              },
            ],
          },
          {
            subTitle:
              "现在我们在哪里：（省市）（区或县）（街道或乡）（什么地方）（第几层楼）？",
            required: "",
            question: [
              {
                question: "现在我们在哪里(省、市)?",
                answer: "",
              },
              {
                question: "现在我们在什么地方(区、县)?",
                answer: "",
              },
              {
                question: "现在我们在什么街道(乡、村)?",
                answer: "",
              },
              {
                question: "这里是什么地方(地址名称)?",
                answer: "",
              },
              {
                question: "现在我们在第几层楼?",
                answer: "",
              },
            ],
          },
        ],
      },
      {
        title: "记忆力",
        contents: [
          {
            subTitle:
              "现在我要说三样东西的名称，在我讲完以后，请您重复说一遍。（请仔细说清楚，每样东西一秒钟）。“皮球”“国旗”“树木”请您把这三样东西说一遍（以第一次的答案记分）",
            required: "",
            question: [
              {
                question: "复述：皮球",
                answer: "",
              },
              {
                question: "复述：国旗",
                answer: "",
              },
              {
                question: "复述：树木",
                answer: "",
              },
            ],
          },
        ],
      },
      {
        title: "注意力和计算力",
        contents: [
          {
            subTitle:
              "请您算一算100减7，然后从所得的数目再减去7，如此一直计算下去，请您将每减一个7后的答案告诉我，直到我说停为止。（若某一答案错误，但下一答案正确，只记一次错误，减到5次说“停”）",
            required: "",
            question: [
              {
                question: "100-7=93",
                answer: "",
              },
              {
                question: "93-7=86",
                answer: "",
              },
              {
                question: "86-7=79",
                answer: "",
              },
              {
                question: "79-7=72",
                answer: "",
              },
              {
                question: "72-7=65",
                answer: "",
              },
            ],
          },
        ],
      },
      {
        title: "回忆能力",
        contents: [
          {
            subTitle:
              "现在请您说出刚才我让您记住的三样东西？(“皮球”“国旗”“树木”)",
            required: "",
            question: [
              {
                question: "回忆：皮球",
                answer: "",
              },
              {
                question: "回忆：国旗",
                answer: "",
              },
              {
                question: "回忆：树木",
                answer: "",
              },
            ],
          },
        ],
      },
      {
        title: "语言能力",
        contents: [
          {
            subTitle: "（出示手表）这个东西叫什么？",
            required: "",
            subTitle1: "- 命名能力",
            question: [
              {
                question: "辨认：手表",
                answer: "",
              },
            ],
          },
          {
            subTitle: "（出示钢笔）这个东西叫什么？",
            required: "",
            question: [
              {
                question: "辨认：钢笔",
                answer: "",
              },
            ],
          },
          {
            subTitle:
              "现在我要说一句话，请您跟着我清楚的重复一遍。“四十四只石狮子”",
            required: "",
            subTitle1: "- 复述能力",
            question: [
              {
                question: "复述：四十四只石狮子",
                answer: "",
              },
            ],
          },
          {
            subTitle:
              "请您念一念这句话，并且按它的意思去做。（可将打印评估用纸给被试者，指出“闭上你的眼睛”在纸上的位置）",
            required: "",
            subTitle1: "- 阅读能力",
            question: [
              {
                question: "按纸上指令做动作：闭上你的眼睛",
                answer: "",
              },
            ],
          },
          {
            subTitle:
              "请您按我说的去做，现在开始：“用右手拿着这张纸，用两只手将它对折起来，放在您的大腿上”。（不要重复说明，也不要示范）",
            required: "",
            subTitle1: "- 执行命令能力",
            question: [
              {
                question: "按口头指令动作：用右手拿纸",
                answer: "",
              },
              {
                question: "按口头指令动作：将纸对折",
                answer: "",
              },
              {
                question: "按口头指令动作：将纸放在自己大腿上",
                answer: "",
              },
            ],
          },
          {
            subTitle: "您给我写一句完整的句子。（提供笔和打印评估用纸）。",
            required: "",
            subTitle1: "- 书写能力",
            question: [
              {
                question: "能够写一句完整句子(含主语、谓语、宾语)",
                answer: "",
              },
            ],
          },
          {
            subTitle:
              "这是一张图，请您在纸上照样画出来。（指出打印用纸上图的位置）。",
            required: "",
            subTitle1: "- 结构能力",
            question: [
              {
                question: "按样画图",
                answer: "",
                img: require("@/assets/paper.jpg"),
              },
            ],
          },
        ],
      },
    ],
    MoCAList: [
      {
        title: "视空间与执行能力",
        contents: [
          {
            subTitle1: "- 交替连线测验",
            subTitle:
              "将打印的评估用纸给被试者）我们有时会用“123……”或者汉语“甲乙丙……”来表示顺序。请您按照从数字到汉字并逐渐升高的顺序画一条连线。从这里开始（指向数字1），从1连向甲，再连向2，并一直连下去，到这里结束（指向汉字戊）。",
            describe:
              "评分标准：当患者完全按照“1-甲-2-乙-3-丙-4-丁-5-戊”的顺序进行连线且没有任何交叉线时给1分。当患者出现任何错误而没有立刻自我纠正时，给0分。",
            required: "",
            question: [
              {
                img: require("@/assets/answer1.png"),
                answer: "",
              },
            ],
          },
          {
            subTitle1: "- 视空间技能（立方体）",
            subTitle: "请您照着测试纸上的图在右面的空白处再画一遍，尽可能精确",
            describe:
              "评分标准：完全符合下列标准时，给1分：1.图形为三维结构  2.所有的线都存在  3.无多余的线  4.相对的边基本平行，长度基本一致，长方体或棱柱体也算正确上述标准中，只要违反其中任何一条，即为0分。",
            required: "",
            question: [
              {
                img: require("@/assets/answer2.png"),
                answer: "",
              },
            ],
          },
          {
            subTitle1: "- 视空间技能（钟表）",
            subTitle:
              "请您在测试纸上画一个钟表，填上所有的数字并指示出11点10分。",
            describe:
              "评分标准：符合下列三个标准时，分别给1分：1.轮廓1分：表面必须是个圆，允许有轻微的缺陷，如：圆没有闭合。  2.数字1分：所有数字必须完整且无多余的数字，数字顺序必须正确且在所述的象限内，可以是罗马数字，数字可以放在圆圈之外  3.指针1分：必须有两个指针且一起指向正确的时间，时针必须明显短于分针，指针的中心交点必须在表内且接近于钟表的中心。 上述各项目的标准中，如果违反其中任何一条，则该项目不给分。",
            required: "",
            question: [
              {
                question:
                  "轮廓：表面必须是个圆，允许有轻微的缺陷，如：圆没有闭合。",
                answer: "",
              },
              {
                question:
                  "数字：所有数字必须完整且无多余的数字，数字顺序必须正确且在所述的象限内，可以是罗马数字，数字可以放在圆圈之外",
                answer: "",
              },
              {
                question:
                  "指针：必须有两个指针且一起指向正确的时间，时针必须明显短于分针，指针的中心交点必须在表内且接近于钟表的中心。",
                answer: "",
              },
            ],
          },
        ],
      },
      {
        title: "命名",
        contents: [
          {
            subTitle:
              "（自左向右指着测试纸上的图片问患者）请您告诉我这个动物的名字。",
            describe:
              "评分标准：每答对一个给1分。正确答案：（1）狮子，（2）犀牛，（3）骆驼或单峰骆驼",
            imgList: [
              require("@/assets/answer3.png"),
              require("@/assets/answer4.png"),
              require("@/assets/answer5.png"),
            ],
            required: "",
            question: [
              {
                question: "狮子",
                answer: "",
              },
              {
                question: "犀牛",
                answer: "",
              },
              {
                question: "骆驼或单峰骆驼",
                answer: "",
              },
            ],
          },
        ],
      },
      {
        title: "记忆",
        contents: [
          {
            subTitle:
              "这是一个记忆力测验。在下面的时间里我会给您读几个词，您要注意听，一定要记住。当我读完之后，把您记住的词告诉我。回答时想到哪个就说哪个，不必按照我读的顺序。面孔 天鹅绒 教堂 菊花 红色（以每秒钟1个词的速度读出5个词。记录被测者第一次的答案。当回答出所有的词，或者再也回忆不起来时，向被试者说明）我把这些词再读一遍，努力去记并把您记住的词告诉我，包括您在第一次已经说过的词。（把这5个词再读一遍，记录第二次的答案。）在评估结束后，我会让您把这些词再回忆一次。",
            describe: "评分标准：这两次回忆不计分",
            required: true,
          },
        ],
      },
      {
        title: "注意",
        contents: [
          {
            subTitle1: "- 数字顺背",
            subTitle:
              "下面我说一些数字，您仔细听，当我说完时您就跟着照样背出来。（按照每秒钟1个数字的速度读出21854）",
            describe: "评分标准：复述准确给1分",
            required: "",
            question: [
              {
                question: "",
                answer: "",
              },
            ],
          },
          {
            subTitle1: "- 数字倒背",
            subTitle:
              "下面我再说一些数字，您仔细听，但是当我说完时您必须按照原数倒着背出来。（按照每秒钟1个数字的速度读出742）",
            describe: "评分标准：复述准确给1分",
            required: "",
            question: [
              {
                question: "",
                answer: "",
              },
            ],
          },
          {
            subTitle1: "- 警觉性",
            subTitle:
              "现在我朗读一组字母，每当我读到A时请用手敲打一下。其他的字母不要敲打。（以每秒钟1个的速度读出F B A C M N A A J K L B A F A K D E A A A J A M O F A A B）",
            describe:
              "评分标准：如果完全正确或只有一次错误则给1分，否则不给分。错误是指当读A的时候漏敲，或读其他字母时误敲。",
            required: "",
            question: [
              {
                question: "",
                answer: "",
              },
            ],
          },
          {
            subTitle1: "- 连续减7",
            subTitle:
              "现在请您做一道计算题，从100中减去一个7，而后从得数中再减去一个7，一直往下减，共计算五次。",
            describe:
              "评分标准：总分三分。全部错误记0分，一个正确给1分，两到三个正确给2分，四到五个正确给3分。从100开始计算正确的减数，每一个减数都单独评定。如果被测者减错了一次，而从这一个减数开始后续的减7都正确，则后续的正确减数要给分。例如，如果患者的回答是93-85-78-71-64，85是错的，而其他的结果都正确，因此给3分。",
            required: "",
            question: [
              {
                question: "正确1个",
                answer: "",
              },
              {
                question: "正确2～3个",
                answer: "",
              },
              {
                question: "正确4～5个",
                answer: "",
              },
            ],
          },
        ],
      },
      {
        title: "语言",
        contents: [
          {
            subTitle1: "- 句子复述",
            subTitle:
              "现在我要对您说一句话，我说完之后请您把我说的话尽可能原原本本的重复出来。（暂停一会儿）我只知道今天张亮是来帮过忙的人。（等待患者回答完毕）现在我再说另一句话，我说完之后您也把它尽可能原原本本的重复出来。（暂停一会儿）狗在房间的时候，猫总是躲在沙发下面。",
            describe:
              "评分标准：复述正确，每句话分别给1分。复述必须准确。注意复述时出现的省略。",
            required: "",
            question: [
              {
                question: "第一句复述准确",
                answer: "",
              },
              {
                question: "第二句复述准确",
                answer: "",
              },
            ],
          },
          {
            subTitle1: "- 词语流畅性",
            subTitle: "请您在一分钟之内尽量多地说出动物的名字。",
            describe: "评分标准：在一分钟内说出11个或者更多的词语则记1分。",
            required: "",
            question: [
              {
                question: "",
                answer: "",
              },
            ],
          },
        ],
      },
      {
        title: "抽象",
        contents: [
          {
            subTitle:
              "请您说说桔子和香蕉在什么方面相类似，或者说他们有什么共性。（如果被测者回答的是一种具体特征，如：都有皮，都能吃。那么再提示一次）请换一种说法，他们在什么方面类似？（如果被测者仍未给出准确答案，则说）您说的没错，也可以说他们都是水果。（不要给出其他任何解释或说明）",
            describe:
              "评分标准：只对下面两道题的回答进行评分。每组词分别给1分。只有下列的回答被视为正确：火车和自行车：运输工具，交通工具，旅行用的    手表和尺子：测量仪器，测量用的。",
            required: "",
            question: [
              {
                question: "请您再说说火车和自行车在什么方面类似？",
                answer: "",
              },
              {
                question: "请您再说说手表和尺子在什么方面类似？",
                answer: "",
              },
            ],
          },
        ],
      },
      {
        title: "延迟回忆",
        contents: [
          {
            subTitle:
              "在之前的训练中我给您提过几个词，请您在尽量回忆一下，告诉我这些词都有什么。（根据下述方式对被测者进行提示）",
            describe:
              "评分标准：在未经提示下自由回忆正确的词，每词给1分。在延迟自由回忆之后，对于未能回忆起来的词，通过语义分类线索鼓励被测者尽可能地回忆。经分类提示或多选提示回忆正确者，打钩。先进行分类提示，如果仍不能回忆起来，再进行多选提示。",
            tip: [
              {
                title: "分类提示",
                content:
                  '面孔：<span style="color: rgba(0, 0, 0, 0.6);font-size: 14px">身体的一部分&nbsp;&nbsp;&nbsp;</span>天鹅绒：<span style="color: rgba(0, 0, 0, 0.6);font-size: 14px">一种纺织品&nbsp;&nbsp;&nbsp;</span>教堂：<span style="color: rgba(0, 0, 0, 0.6);font-size: 14px">一种建筑&nbsp;&nbsp;&nbsp;</span>菊花：<span style="color: rgba(0, 0, 0, 0.6);font-size: 14px">一种花&nbsp;&nbsp;&nbsp;</span>红色：<span style="color: rgba(0, 0, 0, 0.6);font-size: 14px">一种颜色。</span>',
              },
              {
                title: "多选提示：",
                content:
                  '面孔 <span style="color: rgba(0, 0, 0, 0.6);font-size: 14px">{鼻子、</span>面孔<span style="color: rgba(0, 0, 0, 0.6);font-size: 14px">、手掌}</span>&nbsp;&nbsp;&nbsp;天鹅绒 <span style="color: rgba(0, 0, 0, 0.6);font-size: 14px">{棉布、的确良、</span>天鹅绒<span style="color: rgba(0, 0, 0, 0.6);font-size: 14px">}</span>&nbsp;&nbsp;&nbsp;教堂 <span style="color: rgba(0, 0, 0, 0.6);font-size: 14px">{</span>教堂<span style="color: rgba(0, 0, 0, 0.6);font-size: 14px">、学校、医院}</span>&nbsp;&nbsp;&nbsp;菊花 <span style="color: rgba(0, 0, 0, 0.6);font-size: 14px">{玫瑰、</span>菊花<span style="color: rgba(0, 0, 0, 0.6);font-size: 14px">、牡丹}</span>&nbsp;&nbsp;&nbsp;红色 <span style="color: rgba(0, 0, 0, 0.6);font-size: 14px">{<span style="color: rgba(0, 0, 0, 0.6);font-size: 14px">红色<span style="color: rgba(0, 0, 0, 0.6);font-size: 14px">、蓝色、绿色}</span',
              },
            ],
            required: "",
            question: [
              {
                question: "面孔",
                answer: "",
              },
              {
                question: "天鹅绒",
                answer: "",
              },
              {
                question: "教堂",
                answer: "",
              },
              {
                question: "菊花",
                answer: "",
              },
              {
                question: "红色",
                answer: "",
              },
            ],
          },
        ],
      },
      {
        title: "定向",
        contents: [
          {
            subTitle:
              "告诉我今天是什么日期。（如果患者回答不完整，则可以分别提示患者）告诉我现在是哪年、哪月、今天确切日期、星期几。告诉我这是什么地方，它在哪个城市。",
            describe: "评分标准：每正确回答一项给1分。",
            required: "",
            question: [
              {
                question: "年",
                answer: "",
              },
              {
                question: "月",
                answer: "",
              },
              {
                question: "日",
                answer: "",
              },
              {
                question: "星期几",
                answer: "",
              },
              {
                question: "地方",
                answer: "",
              },
              {
                question: "城市",
                answer: "",
              },
            ],
          },
        ],
      },
    ],
    checked: [],
    isShow: false,
    scoreResult: 0,
    resultTable: [],
    mocaResultTable: [
      "交替连线测验",
      "视空间技能——立方体",
      "视空间技能——钟表轮廓",
      "视空间技能——钟表数字",
      "视空间技能——钟表指针",
      "命名——狮子",
      "命名——犀牛",
      "命名——骆驼或单峰骆驼",
      "数字顺背",
      "数字倒背",
      "警觉性",
      "连续减7——正确1个",
      "连续减7——正确2~3个",
      "连续减7——正确4~5个",
      "句子复述——第一句复述正确",
      "句子复述——第二句复述正确",
      "词语流畅性",
      "抽象——火车和自行车在什么方面类似",
      "抽象——手表和尺子在什么方面类似",
      "延迟回忆——面孔",
      "延迟回忆——天鹅绒",
      "延迟回忆——教堂",
      "延迟回忆——菊花",
      "延迟回忆——红色",
      "定向——年",
      "定向——月",
      "定向——日",
      "定向——星期几",
      "定向——城市",
      "定向——地方",
    ],
    dialog: false,
    rules: {
      educationRules: [(v) => !!v || vm.$('pleaseSelectAcademicQualifications')],
    },
    education: "",
    valid: true,
    educations: [vm.$t('uneducated'), vm.$t('primary'), vm.$t('junior'), vm.$t('university'), vm.$t('(PhD'), vm.$t('master'), vm.$t('PhD')],
    required: false,
    patientsList: {
      name: "",
      hospitalNumber: "",
      birthday: "",
      gender: "",
      maritalStatus: "",
      education: "",
    },
    id: "",
    selectedData: [],
    index: "",
  }),

  computed: {
    ...mapState({
      patients: (state) => state.patients.items,
    }),
  },

  watch: {
    patients: function (val) {
      val.forEach((item) => {
        if (item.model.ID === this.id) {
          this.patientsList = item;
        }
      });
      if (this.patientsList.education) {
        this.education = this.patientsList.education;
      }
    },
  },

  async mounted() {
    const result = await gauge.getResult(parseInt(this.$route.query.model.ID));
    if (result.data.data != "") {
      this.selectedData = JSON.parse(result.data.data);
      if (parseInt(this.$route.query.assessmentId) === 1) {
        this.selectedData.forEach((item) => {
          if (item.answer === 0 || item.answer === 1) {
            this.testList[item.id].contents[item.content_id].question[
              item.label_id
            ].answer = item.answer;
          }
        });
      } else {
        this.selectedData.forEach((item) => {
          if (item.answer === 0 || item.answer === 1) {
            this.MoCAList[item.id].contents[item.content_id].question[
              item.label_id
            ].answer = item.answer;
          }
        });
      }
    }

    this.id = parseInt(this.$route.query.patientId);
    this.patients.forEach((item) => {
      if (item.model.ID === this.id) {
        this.patientsList = item;
      }
    });
    if (this.patientsList.education) {
      this.education =
        this.patientsList.education == "无"
          ? "未受教育"
          : this.patientsList.education;
    }
  },
  methods: {
    async answerClick() {
      this.$route.query.assessmentId = parseInt(this.$route.query.assessmentId);
      this.$route.query.patientId = parseInt(this.$route.query.patientId);
      this.$route.query.userId = parseInt(this.$route.query.userId);
      this.$route.query.model.ID = parseInt(this.$route.query.model.ID);
      if (parseInt(this.$route.query.assessmentId) === 1) {
        this.scoreResult = 0;
        this.resultTable = [];
        this.testList.forEach((item, q) => {
          item.contents.forEach((x, index) => {
            x.question.forEach((j, i) => {
              this.resultTable.push({
                name: item.title,
                question: j.question,
                answer: j.answer,
                label_id: i,
                content_id: index,
                id: q,
              });
              if (typeof j.answer !== "string") {
                this.scoreResult = this.scoreResult + j.answer;
              }
            });
          });
        });
        this.$route.query.data = JSON.stringify(this.resultTable);
        this.$route.query.score = this.scoreResult;

        this.testList.forEach((x) => {
          if (
            x.contents.findIndex(
              (target) => target.required == false || target.required === ""
            ) === -1
          ) {
            this.$route.query.complete = true;
          } else {
            this.$route.query.complete = false;
          }
        });
        await gauge.submit(this.$route.query);
      } else if (parseInt(this.$route.query.assessmentId) === 2) {
        this.scoreResult = 0;
        this.resultTable = [];
        this.MoCAList.forEach((item, q) => {
          item.contents.forEach((x, index) => {
            if (x.question != undefined) {
              x.question.forEach((j, i) => {
                this.resultTable.push({
                  question: this.mocaResultTable,
                  answer: j.answer,
                  label_id: i,
                  content_id: index,
                  id: q,
                });
                if (typeof j.answer !== "string") {
                  this.scoreResult = this.scoreResult + j.answer;
                }
              });
            }
          });
        });

        this.$route.query.data = JSON.stringify(this.resultTable);
        this.$route.query.score = this.scoreResult;

        this.MoCAList.forEach(async (x) => {
          if (
            x.contents.findIndex(
              (target) => target.required == false || target.required === ""
            ) == -1
          ) {
            this.$route.query.complete = true;
          } else {
            this.$route.query.complete = false;
          }
        });

        await gauge.submit(this.$route.query);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    async submit() {
      this.$route.query.assessmentId = parseInt(this.$route.query.assessmentId);
      this.$route.query.patientId = parseInt(this.$route.query.patientId);
      this.$route.query.userId = parseInt(this.$route.query.userId);
      this.$route.query.model.ID = parseInt(this.$route.query.model.ID);
      if (parseInt(this.$route.query.assessmentId) === 1) {
        this.scoreResult = 0;
        this.resultTable = [];
        this.testList.forEach((item) => {
          item.contents.forEach((x, index) => {
            if (x.question.some((x) => x.answer === "")) {
              item.contents[index].required = false;
            } else {
              this.scoreResult = this.scoreResult + x.answer;
              item.contents[index].required = true;
            }
            x.question.forEach((j, i) => {
              this.resultTable.push({
                name: item.title,
                question: j.question,
                answer: j.answer,
                label_id: i,
              });
            });
          });
        });
        this.$route.query.data = JSON.stringify(this.resultTable);
        this.$route.query.score = this.scoreResult;
        this.required = false;

        if (this.resultTable.some((x) => x.answer === "")) {
          this.required = false;
          this.isShow = true;
          this.$route.query.complete = false;
        } else {
          this.isShow = false;
          this.required = true;
          this.$route.query.complete = true;
        }
        if (this.required) {
          await gauge.submit(this.$route.query);
          this.$router.push({
            path: `/gaugeResult/${parseInt(this.$route.query.model.ID)}`,
            query: { assessmentId: this.$route.query.assessmentId, type: 1 },
          });
        }
      } else {
        this.scoreResult = 0;
        this.resultTable = [];
        this.MoCAList.forEach((item) => {
          item.contents.forEach((x, index) => {
            if (x.question != undefined) {
              if (x.question.some((x) => x.answer === "")) {
                item.contents[index].required = false;
              } else {
                this.scoreResult = this.scoreResult + x.answer;
                item.contents[index].required = true;
              }
              x.question.forEach((j) => {
                this.resultTable.push({
                  question: this.mocaResultTable,
                  answer: j.answer,
                });
              });
            }
          });
        });

        this.$route.query.data = JSON.stringify(this.resultTable);
        this.$route.query.score = this.scoreResult;

        if (this.resultTable.some((x) => x.answer === "")) {
          this.isShow = true;
          this.dialog = false;
          this.$route.query.complete = false;
        } else {
          this.isShow = false;
          this.$route.query.complete = true;
          this.education =
            this.patientsList.education == "无"
              ? "未受教育"
              : this.patientsList.education;
          this.dialog = true;
        }
      }
    },

    async save() {
      this.$refs.form.validate();
      if (this.valid) {
        this.$route.query.complete = true;
        this.resultTable.push(this.education);
        this.$route.query.data = JSON.stringify(this.resultTable);
        await gauge.submit(this.$route.query);
        this.$router.push({
          path: `/gaugeResult/${parseInt(this.$route.query.model.ID)}`,
          query: { assessmentId: this.$route.query.assessmentId, type: 1 },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.test-tip {
  margin-top: 50px;
}

.test-title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

.title1 {
  margin-top: 40px;
  font-weight: bold;
  font-size: 20px;
}

.subTitle1 {
  margin-top: 20px;
  font-weight: bold;
  margin-left: 20px;
}

.subTitle {
  font-size: 15px;
  color: #000000;
  font-weight: normal;
  margin-top: 20px;
  margin-left: 20px;
}

.describe {
  width: 93%;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  margin-top: 10px;
}

.circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #74bcff;
  margin-right: 5px;
  position: relative;
  top: -3px;
}

.hide {
  display: none;
}
</style>