<template lang="pug">
div
  v-dialog.dialog(v-model='printDialog', width='700', persistent)
    v-card
      v-card-title.primary.headline.mb-12
        h4(style='color: white') {{ $t("printTitle") }}
      v-card-text
        v-row
          v-col(cols='12', sm='5', md='5')
            v-menu(
              ref='startDateMenu',
              v-model='startDateMenu',
              :close-on-content-click='false',
              transistion='scale-transition',
              offset-y,
              min-width='auto'
            )
              template(v-slot:activator='{ on, attrs }')
                v-text-field(
                  solo,
                  v-model='date.startDate',
                  :label='$t("thStartDate")',
                  prepend-inner-icon='mdi-calendar',
                  readonly,
                  v-bind='attrs',
                  v-on='on'
                )
              v-date-picker(ref='birthdayPicker', v-model='date.startDate', min='1900-01-01', @change='saveStartDate', locale='zh-ch')
          v-col(cols='12', sm='1', md='1', style='text-align: center; line-height: 50px')
            span —
          v-col(cols='12', sm='5', md='5')
            v-menu(
              ref='endDateMenu',
              v-model='endDateMenu',
              :close-on-content-click='false',
              transistion='scale-transition',
              offset-y,
              min-width='auto'
            )
              template(v-slot:activator='{ on, attrs }')
                v-text-field(
                  solo,
                  v-model='date.endDate',
                  :label='$t("thEndDate")',
                  prepend-inner-icon='mdi-calendar',
                  readonly,
                  v-bind='attrs',
                  v-on='on'
                )
              //- :max="new Date().toISOString().substr(0, 10)"
              v-date-picker(ref='birthdayPicker', v-model='date.endDate', min='1900-01-01', @change='saveEndDate', locale='zh-cn')
          v-col(cols='12', sm='11', md='11')
            v-text-field(
              solo,
              v-model='diagnosis',
              :label='$t("diagnosis")',
              prepend-inner-icon='mdi-calendar',

            )
          v-col(cols='12', sm='11', md='11')
            v-text-field(
              solo,
              v-model='plan',
              :label='$t("plan")',
              prepend-inner-icon='mdi-calendar',

            )
      v-card-actions.bottom(style='background: #fff')
        v-spacer
        v-btn(text, @click='clearClick') {{ $t("daClear") }}
        v-btn(text, @click='cancelClick()') {{ $t("daCancel") }}
        v-btn(text, @click='printClick()') {{ $t("daPrintView") }}
</template>

<script>
export default {
  props: {
    printDialog: Boolean,
    printId: Number,
  },
  data: () => ({
    date: {
      startDate: '',
      endDate: ''
    },
    dates: ['', ''],
    startDateMenu: false,
    endDateMenu: false,
    diagnosis: "",
    plan: ""
  }),
  mounted() {
    const nowDate = this.dayjs(new Date()).format('YYYY-MM-DD')
    this.dates = [nowDate, nowDate]
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    }
  },
  methods: {
    saveStartDate(date) {
      this.$refs.startDateMenu.save(date)
    },
    saveEndDate(date) {
      this.$refs.endDateMenu.save(date)
    },
    cancelClick() {
      this.clearClick()
      this.$emit('cancelClick')
    },
    clearClick() {
      this.date = {
        startDate: '',
        endDate: ''
      }
    },
    printClick() {
      this.$emit('printClick', this.date, this.printId, this.diagnosis, this.plan)
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  position: relative;
  height: 800px;
  background: paleturquoise;

  .bottom {
    position: absolute;
    z-index: 10;
    bottom: 12;
  }
}
</style>

