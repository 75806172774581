<template lang="pug">
div
  TreatmentPrint(v-if='this.$route.query.type == "print"')
  TreatmentDetailCITStore(
    v-if='treatment && treatment.app.packageName == "com.xzs.citstorerelease" && this.$route.query.type == undefined'
  )
  TreatmentDetailDragonBeat(v-if='treatment && treatment.app.packageName == "com.xzs.dragonbeat" && this.$route.query.type == undefined')
  TreatmentDetailWheelMaze(v-if='treatment && treatment.app.packageName == "com.xzs.WheelMaze" && this.$route.query.type == undefined')
  TreatmentDetailShadow(v-if='treatment && treatment.app.packageName == "com.xzs.shadow" && this.$route.query.type == undefined')
  TreatmentDetailPingPong(
    v-if='treatment && treatment.app.packageName == "com.xzs.pingpongsimulator" && this.$route.query.type == undefined'
  )
  TreatmentDetailShootingGallery(
    v-if='treatment && treatment.app.packageName == "com.xzs.shootinggallery" && this.$route.query.type == undefined'
  )
  TreatmentDetailTaichi(v-if='treatment && treatment.app.packageName == "com.xzs.taichi" && this.$route.query.type == undefined')
  TreatmentDetailGrow(v-if='treatment && treatment.app.packageName == "com.xzs.grow" && this.$route.query.type == undefined')
  TreatmentDetailbbqFlip(v-if='treatment && treatment.app.packageName == "com.xzs.bbqflip" && this.$route.query.type == undefined')
  TreatmentDetailMemoryRoom(v-if='treatment && treatment.app.packageName == "com.xzs.memoryroom" && this.$route.query.type == undefined')
  TreatmentDetailSandTableRoom(
    v-if='treatment && treatment.app.packageName == "com.xzs.sandtableroom" && this.$route.query.type == undefined'
  )
  TreatmentDetailCatharsisRoom(
    v-if='treatment && treatment.app.packageName == "com.xzs.catharsisroom" && this.$route.query.type == undefined'
  )
  TreatmentDetailCognitiveAssessment(
    v-if='treatment && treatment.app.packageName == "com.xzs.cognitiveassessment" && this.$route.query.type == undefined'
  )
  TreatmentDetailMelodicPitch(
    v-if='treatment && treatment.app.packageName == "com.xzs.melodicpitch" && this.$route.query.type == undefined'
  )
  TreatmentDetailJellyfish(v-if='treatment && treatment.app.packageName == "com.xzs.jellyfishswim" && this.$route.query.type == undefined')
  TreatmentDetailSnowballfight(
    v-if='treatment && treatment.app.packageName == "com.xzs.snowballfight" && this.$route.query.type == undefined'
  )
  TreatmentDetailMathBoxing(v-if='treatment && treatment.app.packageName == "com.xzs.mathboxing" && this.$route.query.type == undefined')
  TreatmentDetailTowerOfLondon(
    v-if='treatment && treatment.app.packageName == "com.xzs.londontower" && this.$route.query.type == undefined'
  )
  TreatmentDetailAcrophobia(v-if='treatment && treatment.app.packageName == "com.xzs.acrophobia" && this.$route.query.type == undefined')
  TreatmentDeatailPainrelief(v-if='treatment && treatment.app.packageName == "com.xzs.painrelief" && this.$route.query.type == undefined')
  TreatmentDetailEyeMovement(
    v-if='treatment && treatment.app.packageName == "com.XZS.VREyeTrackingAttentionAssessmentSystem" && this.$route.query.type == undefined'
  )
  TreatmentDetailForestfriends(
    v-if='treatment && treatment.app.packageName == "com.xzs.forestfriends" && this.$route.query.type == undefined'
  )
  TreatmentDetailFruitparty(v-if='treatment && treatment.app.packageName == "com.xzs.fruitparty" && this.$route.query.type == undefined')
  TreatmentDetailGolfclub(v-if='treatment && treatment.app.packageName == "com.xzs.golfclub" && this.$route.query.type == undefined')
  TreatmentDetailPuppydollhouse(
    v-if='treatment && treatment.app.packageName == "com.xzs.puppydollhouse" && this.$route.query.type == undefined'
  )
  TreatmentDetailTennis(v-if='treatment && treatment.app.packageName == "com.xzs.vrtennis" && this.$route.query.type == undefined')
  TreatmentDetailBreathe(v-if='treatment && treatment.app.packageName == "com.xzs.breathe" && this.$route.query.type == undefined')
  TreatmentDetailRabbitStore(v-if='treatment && treatment.app.packageName == "com.xzs.rabbitstore" && this.$route.query.type == undefined')
  TreatmentDetailSailingadventure(
    v-if='treatment && treatment.app.packageName == "com.xzs.sailingadventure" && this.$route.query.type == undefined'
  )
  TreatmentDetailHideNSeek(
    v-if='treatment && treatment.app.packageName == "com.xzs.hidenseek" && this.$route.query.type == undefined'
  )
  TreatmentDetailBrushteeth(
    v-if='treatment && treatment.app.packageName == "com.xzs.brushteeth" && this.$route.query.type == undefined'
  )
  TreatmentDetailVideoPlayer(v-if='treatment && treatment.app.packageName == "com.xzs.videoplayer" && this.$route.query.type == undefined')
  TreatmentDetailSpp(v-if='treatment && treatment.app.packageName == "com.xzs.spp" && this.$route.query.type == undefined')
  TreatmentDetailScale(v-if='treatment && treatment.app.packageName == "com.xzs.mindratingscalesystem" && this.$route.query.type == undefined')
  TreatmentDetailLaundry(v-if='treatment && treatment.app.packageName == "com.xzs.laundry" && this.$route.query.type == undefined')
  TreatmentDetailCITStoreGesture(v-if='treatment && treatment.app.packageName == "com.xzs.citstoregesture" && this.$route.query.type == undefined')
  TreatmentDetailMathBoxing2024(v-if='treatment && treatment.app.packageName == "com.xzs.mathboxing2024" && this.$route.query.type == undefined')
  TreatmentDetailShootingGallery2(v-if='treatment && treatment.app.packageName == "com.xzs.shootinggallery2" && this.$route.query.type == undefined')
  TreatmentDetailADLSupermarket(v-if='treatment && treatment.app.packageName == "com.xzs.adlsupermarket" && this.$route.query.type == undefined')
  TreatmentDetailFruittrain(v-if='treatment && treatment.app.packageName == "com.xzs.fruittrain" && this.$route.query.type == undefined')
  TreatmentDetailMusicbeat(v-if='treatment && treatment.app.packageName == "com.xzs.musicbeat" && this.$route.query.type == undefined')
  TreatmentDetailComaArousal(v-if='treatment && treatment.app.packageName == "com.xzs.ComaArousal" && this.$route.query.type == undefined')
  TreatmentDetailHappypatting(v-if='treatment && treatment.app.packageName == "com.xzs.happypatting" && this.$route.query.type == undefined')
  TreatmentDetailSimulatedtown(v-if='treatment && treatment.app.packageName == "com.xzs.simulatedtown" && this.$route.query.type == undefined')
  TreatmentDetailPostalSearch(v-if='treatment && treatment.app.packageName == "com.xzs.postalsearch" && this.$route.query.type == undefined')
  TreatmentDetailPostalBlitz(v-if='treatment && treatment.app.packageName == "com.xzs.postalblitz" && this.$route.query.type == undefined')
  TreatmentDetailPostalCardMission(v-if='treatment && treatment.app.packageName == "com.xzs.postalcardmission" && this.$route.query.type == undefined')
  //- TreatmentDetailIceJourney(v-if='treatment && treatment.app.packageName == "com.xzs.icejourney" && this.$route.query.type == undefined')
</template>

<script>
import { mapGetters } from 'vuex'
import TreatmentDetailPostalSearch from './TreatmentDetailPostalSearch.vue'
import TreatmentDetailPostalBlitz from './TreatmentDetailPostalBlitz.vue'
import TreatmentDetailPostalCardMission from './TreatmentDetailPostalCardMission.vue'
import TreatmentDetailCITStore from './TreatmentDetailCITStore'
import TreatmentDetailDragonBeat from './TreatmentDetailDragonBeat'
import TreatmentDetailWheelMaze from './TreatmentDetailWheelMaze'
import TreatmentDetailShadow from './TreatmentDetailShadow'
import TreatmentDetailPingPong from './TreatmentDetailPingPong'
import TreatmentDetailShootingGallery from './TreatmentDetailShootingGallery'
import TreatmentDetailTaichi from './TreatmentDetailTaichi'
import TreatmentDetailGrow from './TreatmentDetailGrow'
import TreatmentDetailbbqFlip from './TreatmentDetailbbqFlip'
import TreatmentDetailMemoryRoom from './TreatmentDetailMemoryRoom'
import TreatmentDetailCatharsisRoom from './TreatmentDetailCatharsisRoom'
import TreatmentDetailSandTableRoom from './TreatmentDetailSandTableRomm.vue'
import TreatmentDetailCognitiveAssessment from './TreatmentDetailCognitiveAssessment.vue'
import TreatmentDetailMelodicPitch from './TreatmentDetailMelodicPitch.vue'
import TreatmentDetailJellyfish from './TreatmentDetailJellyfish.vue'
import TreatmentDetailSnowballfight from './TreatmentDetailSnowballfight.vue'
import TreatmentDetailMathBoxing from './TreatmentDetailMathBoxing.vue'
import TreatmentDetailTowerOfLondon from './TreatmentDetailTowerOfLondon.vue'
import TreatmentDetailAcrophobia from './TreatmentDetailAcrophobia.vue'
import TreatmentDeatailPainrelief from './TreatmentDeatailPainrelief.vue'
import TreatmentDetailForestfriends from './TreatmentDetailForestfriends.vue'
import TreatmentDetailVideoPlayer from './TreatmentDetailVideoPlayer.vue'
import TreatmentDetailSpp from './TreatmentDetailSpp.vue'
import TreatmentDetailEyeMovement from './TreatmentDetailEyeMovement.vue'
import TreatmentDetailFruitparty from './TreatmentDetailFruitparty.vue'
import TreatmentDetailGolfclub from './TreatmentDetailGolfclub.vue'
import TreatmentDetailPuppydollhouse from './TreatmentDetailPuppydollhouse.vue'
import TreatmentDetailTennis from './TreatmentDetailTennis.vue'
import TreatmentDetailBreathe from './TreatmentDetailBreathe.vue'
import TreatmentDetailRabbitStore from './TreatmentDetailRabbitStore.vue'
import TreatmentDetailSailingadventure from './TreatmentDetailSailingadventure.vue'
import TreatmentDetailBrushteeth from './TreatmentDetailBrushteeth.vue'
import TreatmentPrint from '../components/PrintPage/TreatmentPrint.vue'
import TreatmentDetailHideNSeek from './TreatmentDetailHideNSeek.vue'
import TreatmentDetailScale from './TreatmentDetailScale.vue'
import TreatmentDetailLaundry from './TreatmentDetailLaundry.vue'
import TreatmentDetailCITStoreGesture from './TreatmentDetailCITStoreGesture.vue'
import TreatmentDetailMathBoxing2024 from './TreatmentDetailMathBoxing2024.vue'
import TreatmentDetailShootingGallery2 from './TreatmentDetailShootingGallery2.vue'
import TreatmentDetailADLSupermarket from './TreatmentDetailADLSupermarket.vue'
import TreatmentDetailFruittrain from './TreatmentDetailFruittrain.vue'
import TreatmentDetailMusicbeat from './TreatmentDetailMusicbeat.vue'
import TreatmentDetailComaArousal from './TreatmentDetailComaArousal.vue'
import TreatmentDetailHappypatting from './TreatmentDetailHappypatting'
import TreatmentDetailSimulatedtown from './TreatmentDetailSimulatedtown.vue'

// import TreatmentDetailIceJourney from './TreatmentDetailIceJourney.vue'
export default {
  components: {
    TreatmentDetailPostalSearch,
    TreatmentDetailPostalBlitz,
    TreatmentDetailPostalCardMission,
    TreatmentDetailSimulatedtown,
    TreatmentDetailHappypatting,
    TreatmentDetailComaArousal,
    TreatmentDetailMusicbeat,
    TreatmentDetailFruittrain,
    TreatmentDetailADLSupermarket,
    TreatmentDetailShootingGallery2,
    TreatmentDetailMathBoxing2024,
    TreatmentDetailCITStore,
    TreatmentDetailDragonBeat,
    TreatmentDetailWheelMaze,
    TreatmentDetailShadow,
    TreatmentDetailPingPong,
    TreatmentDetailShootingGallery,
    TreatmentDetailTaichi,
    TreatmentDetailGrow,
    TreatmentDetailbbqFlip,
    TreatmentDetailMemoryRoom,
    TreatmentDetailBrushteeth,
    TreatmentDetailSandTableRoom,
    TreatmentDetailCatharsisRoom,
    // TreatmentDetailIceJourney,
    TreatmentDetailScale,
    TreatmentDetailVideoPlayer,
    TreatmentDetailSpp,
    TreatmentDetailHideNSeek,
    TreatmentDetailCognitiveAssessment,
    TreatmentDetailMelodicPitch,
    TreatmentDetailJellyfish,
    TreatmentDetailSnowballfight,
    TreatmentDetailMathBoxing,
    TreatmentDetailTowerOfLondon,
    TreatmentDetailAcrophobia,
    TreatmentDeatailPainrelief,
    TreatmentDetailEyeMovement,
    TreatmentDetailForestfriends,
    TreatmentDetailFruitparty,
    TreatmentDetailGolfclub,
    TreatmentDetailPuppydollhouse,
    TreatmentDetailTennis,
    TreatmentDetailBreathe,
    TreatmentDetailRabbitStore,
    TreatmentDetailSailingadventure,
    TreatmentDetailLaundry,
    TreatmentDetailCITStoreGesture,
    TreatmentPrint
  },

  computed: {
    ...mapGetters({
      treatmentById: 'treatments/treatmentById'
    }),

    treatment() {
      return this.treatmentById(this.$route.params.id)
    }
  }
}
</script>