var startTime = ''
var moment = require('moment')
export const startTreatmentTime = time => {
  window.localStorage['now'] = time
}

export const getStartTreatmentTime = () => {
  const now = window.localStorage.getItem('now')
  startTime = moment(now).format('YYYY-MM-DDTHH:mm:ss+08:00')
  return startTime
}
