<template lang="pug">
div(v-if='treatment') 
  v-toolbar.mb-6(flat)
    v-btn.ma-2(large, color='orange', dark, @click='goBack()')
      v-icon(dark, left) mdi-arrow-left
      | {{ $t("preTitle") }}
    v-divider.mx-4(inset, vertical)
    v-icon.mr-1 mdi-file-chart
    v-toolbar-title {{ $t("reportTitle") }}
    v-spacer
    DeviceMini(v-if='isActive', :deviceId='isActiveTreatment.device.model.ID')
    v-spacer
    v-tooltip(v-if='isActive', bottom)
      template(v-slot:activator='{ on, attrs }')
        v-btn(fab, dark, color='orange', @click='terminate()', v-bind='attrs', v-on='on')
          v-icon mdi-square-rounded
      span {{ $t("terminateTreatment") }}
    div(v-else)
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn.primary.mr-2(fab, v-bind='attrs', v-on='on', @click='printDetail()')
            v-icon mdi-printer
        span {{ $t("actionPrintTooltip") }}
      v-tooltip(bottom) 
        template(v-slot:activator='{ on, attrs }')
          v-btn(fab, disabled, v-bind='attrs', v-on='on')
            v-icon mdi-check
        span {{ $t("trainingCompleted") }}
  v-row
    v-col(xl='12', lg='12', md='12')
      v-card.mx-auto(hover)
        v-img(v-if='isActive', :src='streamImgSrc && streamImgSrc != "initiating" ? streamImgSrc : getCoverSrc()', height='auto')
          .countdown(v-if='enabledCountdown') {{ countdownRes }}
        .d-flex.flex-no-wrap.justify-space-between
          v-row.ml-6(align='center')
            v-img(v-if='!isActive', :src='getCoverSrc()', width='64', max-width='64', height='36', max-height='36')
            v-card-title {{ treatment.app.name }}
          v-card-subtitle
            v-chip.ma-1(v-for='(item, i) in getTags(treatment.app.tags)', :key='i') {{ item }}
          v-spacer
          v-card-subtitle
            div(style='margin-top: 5px; font-size: 16px; color: #000000de') {{ $t("trainingScore") }}: {{ this.treatment.score }}
  v-row
    v-col(v-if='isActive && enabledEEG', xl='12', lg='12', md='12')
      EEGDiagram(:sn='isActiveTreatment.device.sn')
  v-row
    v-col(xl='4', lg='4', md='12')
      v-card.mx-auto(hover, color='teal', dark)
        .d-flex.flex-no-wrap.justify-space-between
          v-card-title {{ $t("trainingTitle") }}
          v-spacer
          .grey.rounded-bl-xl(v-if='!isActive')
            v-card-subtitle {{ $t("trainingEnd") }}
          .warning.rounded-bl-xl(v-else)
            v-card-subtitle {{ $t("isTraining") }}
        v-card-text
          div(v-if='isActive')
            .d-flex.flex-no-wrap.justify-space-between.my-4
              v-icon.mr-1 mdi-timer
              span {{ `${$t("trainingDuration")}` }}
              v-spacer
              span {{ `${calculateDuration(startTreatmentTime)}` }}
          div(v-if='!isActive')
            .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.complete')
              v-icon.mr-1 mdi-check-circle-outline
              span {{ `${$t("isTrainingCompleted")}` }}
              v-spacer
              span {{ $t("trainingCompleted") }}
            .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='!treatment.complete')
              v-icon.mr-1 mdi-check-circle-outline
              span {{ `${$t("isTrainingCompleted")}` }}
              v-spacer
              span {{ $t("trainingNotCompleted") }}
            .d-flex.flex-no-wrap.justify-space-between.my-4
              v-icon.mr-1 mdi-timer-outline
              span {{ `${$t("trainingDuration")}` }}
              v-spacer
              span {{ $t("totalDuration") }} 
    v-col(xl='4', lg='4', md='12')
      v-card.mx-auto(hover, color='cyan darken-3', dark)
        v-card-title {{ $t("configTitle") }}
        v-divider
        v-card-text
          .d-flex.flex-no-wrap.justify-space-between.my-4
            v-icon.mr-1 mdi-school-outline
            span {{ $t("tutorial") }}
            v-spacer
            span(v-if='config && config.tutorial') {{ $t("on") }}
            span(v-else) {{ $t("off") }}
          .d-flex.flex-no-wrap.justify-space-between.my-4
            v-icon.mr-1 mdi-monitor-eye
            span {{ $t("monitor") }}
            v-spacer
            span(v-if='config && config.monitor') {{ $t("on") }}
            span(v-else) {{ $t("off") }}
          .d-flex.flex-no-wrap.justify-space-between.my-4
            v-icon.mr-1 mdi-timer-outline
            span {{ $t("timeLimit") }}
            v-spacer
            span(v-if='config && config.timeLimit == -1') {{ $t("infinite") }}
            span(v-if='config && config.timeLimit == 10') {{ $t("10min") }}
            span(v-if='config && config.timeLimit == 15') {{ $t("15min") }}
            span(v-if='config && config.timeLimit == 30') {{ $t("30min") }}
          .d-flex.flex-no-wrap.justify-space-between.my-4
            v-icon.iconfont.icon-shezhiguize
            span {{ $t("serveRule") }}
            v-spacer
            span(v-if='config && config.serveType == 2') {{ $t("alternate") }}
            span(v-if='config && config.serveType == 1') {{ $t("aiServe") }}
            span(v-if='config && config.serveType == 0') {{ $t("userServe") }}
          .d-flex.flex-no-wrap.justify-space-between.my-4
            img(src='@/assets/hand-icon.png', width='20', style='margin-right: 5px')
            span {{ $t("preferredHand") }}
            v-spacer
            span(v-if='config && config.hand == 1') {{ $t("rightHand") }}
            span(v-if='config && config.hand == 0') {{ $t("leftHand") }}
          .d-flex.flex-no-wrap.justify-space-between.my-4
            v-icon.mr-1 mdi-numeric-3-circle-outline
            span {{ $t("sets") }}
            v-spacer
            span(v-if='config && config.set == 2') {{ $t("threeSetsTwoWins") }}
            span(v-if='config && config.set == 3') {{ $t("fiveSetsThreeWins") }}
    v-col(xl='4', lg='4', md='12')
      v-card.mx-auto(hover, color='blue-grey', dark)
        v-card-title {{  typeUsername == '1' ? $t("userNameTitle") : $t("patientTitle")  }}
        v-divider
        v-card-text
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.name')
            v-icon.mr-1 mdi-account
            span {{ $t("patientName") }}
            v-spacer
            span {{ treatment.patient.name }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.patientNumber && bedNumberName != "false"')
            v-icon.mr-1 mdi-clipboard-account
            span {{  bedNumberName == 'true' ? (typeUsername == '1'? $t("userNameNumber") : $t("patientNumber")) : bedNumberName  }}
            v-spacer
            span {{ treatment.patient.patientNumber }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.bedNumber && partition !== "false"')
            v-icon.mr-1 mdi-bed
            span {{ partition === "true" ? $t("subregion") : partition }}
            v-spacer
            span {{ treatment.patient.bedNumber }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.birthday && enableAge')
            v-icon.mr-1 mdi-calendar
            span {{ $t("patientAge") }}
            v-spacer
            span {{ Math.floor((new Date() - new Date(treatment.patient.birthday).getTime()) / 3.15576e10) >= 0 ? Math.floor((new Date() - new Date(treatment.patient.birthday).getTime()) / 3.15576e10) : "" }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.gender')
            v-icon.mr-1 mdi-gender-male-female
            span {{ $t("patientGender") }}
            v-spacer
            span {{ treatment.patient.gender }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.maritalStatus') 
            v-icon.mr-1 mdi-human-male-female
            span {{ $t("patientMaritalStatus") }}
            v-spacer
            span {{ treatment.patient.maritalStatus }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.ethnicity')
            v-icon.mr-1 mdi-map-marker
            span {{ $t("patientEthnicity") }}
            v-spacer
            span {{ treatment.patient.ethnicity }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.education')
            v-icon.mr-1 mdi-school
            span {{ $t("patientEducation") }}
            v-spacer
            span {{ treatment.patient.education }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.remarks')
            v-icon.mr-1 mdi-pencil-box
            span {{ $t("patientRemarks") }}
            v-spacer
            span {{ treatment.patient.remarks }}
  //- 排行榜
  v-row
    v-col(xl='12', lg='12', md='12')
      v-card.mx-auto(hover)
        v-card-title {{ $t("leaderBoard") }}
        v-divider
        v-timeline(v-for='(item, index) in rankingList', :key='index', align-top, dense)
          v-timeline-item(color='grey lighten-2', fill-dot, small)
            v-row
              v-col
                v-tooltip(bottom) 
                  template(v-slot:activator='{ on, attrs }')
                    div(v-bind='attrs', v-on='on')
                      span.iconfont.icon-icon-person
                      span {{ item.patient_name }}
                  span {{ $t("name") }}
              v-col
                v-tooltip(bottom)
                  template(v-slot:activator='{ on, attrs }')
                    div(v-bind='attrs', v-on='on')
                      img(src='@/assets/score.png', width='22', style='margin-right: 5px; position: relative; top: 5px')
                      span {{ item.score }}
                  span {{ $t("scores") }}
              v-col
                v-tooltip(bottom)
                  template(v-slot:activator='{ on, attrs }') 
                    div(v-bind='attrs', v-on='on')
                      span.iconfont.icon-paiming
                      span {{ index + 1 }}
                  span {{ $t("ranking") }}
  v-row#pdfDom 
    v-col(xl='12', lg='12', md='12')
      v-card.mx-auto(hover)
        v-card-title {{ $t("recordScoreGraph") }}
        v-card-text
          apexchart#chart(ref='chart', type='line', height='400', :options='chartScoreOptions', :series='ScoreSeries')
  //-  @click:row='clicked',
  v-row
    v-col(xl='12', lg='12', md='12')
      v-card.mx-auto(hover)
        v-card-title {{ $t("thisTrainingStatus") }}
        v-card-text
          v-data-table.table(
            :headers='headers',
            :items='filteredData',
            item-key='id',
            show-expand,
            :expanded.sync='expandedRows',
            hide-default-footer
          )
            template(v-slot:expanded-item='{ item }')
              td(:colspan='headers.length + 1', style='background: #f9f7f7; border-top: none')
                v-row(v-for='(x, i) in item.details', :key='i')
                  td(width='100', align='left') {{ x.data.serveId }}
                  td(width='150', style='margin-left: -50px') {{ $t('server') }}：{{ x.data.servePlayer }}
                  td(width='100') {{ x.data.round_win ? $t('wins') : $t('losses') }}
                  td(width='200') {{ `${$t('timeConsuming')}：${Math.floor(x.data.set_duration / 60) > 0 ? `${Math.floor(x.data.set_duration / 60)}${$t('minute')}` : ""}${x.data.set_duration % 60}${$t('second')}` }}
                  td(width='100') {{ x.data.round_result }}
                  td(width='100') {{ `${x.data.display_round_score[0]} : ${x.data.display_round_score[1]}` }}
  v-row
    v-col(xl='12', lg='12', md='12')
      v-card.mx-auto(hover)
        v-card-title {{ $t("medalWall") }}
        v-card-text
          .medal-content
            .medal(v-for='(item, index) in medalsList', :key='index')
              .block
                img(:src='getSrc(item.img)', width='200', :class='item.status ? "" : "img"')
                .medal-name
                  .name {{ item.medal_title }}
                  .date {{ item.status ? treatmentDate(item.updated_at == "" ? item.created_at : item.updated_at) : $t('notAchieved') }}
              .line
              .description {{ item.description }}

  TreatmentDetailReport(
    ref='treatmentReport',
    :patientId='$route.query.patientId',
    :treatmentId='$route.query.treatmentId',
    :printDom='printDom',
    :pdfDomGraph='pdfDomGraph'
  )
</template>

<script>
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { VUE_APP_FILE_SERVER_ENDPOINT } from '@/app.config'
import { mapActions, mapGetters } from 'vuex'
import { treatment } from '../api'
import { connectStreamer } from '../lib/fmetp'
import DeviceMini from './DeviceMini'
import EEGDiagram from './EEGDiagram.vue'
import VueApexCharts from 'vue-apexcharts'
import { getStartTreatmentTime } from '../lib/start-treatment-time'
import TreatmentDetailReport from './Treatments/TreatmentDetailReport.vue'

export default {
  data: () => ({
    now: moment(),
    isActive: undefined,
    totalDuration: 0,
    socket: undefined,
    streamImgSrc: undefined,
    getScoreData: [],
    startTreatmentTime: '',
    averageData: {},
    thisUsersData: [],
    allScoreData: [],
    printDom: '',
    pdfDomGraph: '',
    recordsList: [],
    rankingList: [],
    medalsList: [],

    headers: [
      { text: '', value: 'set' },
      { text: '', value: 'score' },
      { text: '', value: 'time' },
      { text: '', value: 'isWin' },
      { text: '', value: 'expand', sortable: false }
    ],
    items: [],
    expandedRows: [],
    timer: undefined,
    nowTimer: undefined,
    startTime: '',
    countdownRes: '',
    timestamp: 0
  }),

  beforeDestroy() {
    clearInterval(this.timer)
    clearInterval(this.nowTimer)
  },

  created() {
    momentDurationFormatSetup(moment)
    this.nowTimer = this.$mySetInterval(() => (this.now = moment()))
    this.fetchRecords(this.$route.params.id)
    this.startTreatmentTime = getStartTreatmentTime()

    if (this.enabledCountdown && this.isActive) {
      this.timer = this.$mySetInterval(() => {
        setTimeout(() => {
          this.startTime = this.calculateDuration(this.treatment.applicationStartTime)
          this.getTimeGap(JSON.parse(this.treatment.settings).timeLimit, this.startTime, this.treatment?.model.ID)
        }, 0)
      })
    }
  },

  async mounted() {
    await this.$nextTick()
    this.streamTreatment()
    const appId = parseInt(this.$route.query.appId)
    const patientId = parseInt(this.$route.query.patientId)
    const treatmentId = parseInt(this.$route.query.treatmentId)

    const resRankingList = await treatment.treatmentRankingList({
      appId: appId,
      type: 'web',
      modeName: 'point',
      patientId: patientId,
      treatmentId: treatmentId
    })
    this.rankingList = resRankingList.data.All

    const res = await treatment.treatmentMedalsList({ appId: appId, patientId: patientId })
    this.medalsList = res.data

    const result = await treatment.getScore({ appId: appId, patientId: patientId })
    if (result.data.length > 0) {
      const data = result.data.filter(x => {
        return x.score !== 0
      })
      this.getScoreData = data
    }
    this.printDom = document.querySelector('#pdfDom')
  },

  destroyed() {
    this.socket.close()
  },

  methods: {
    toggleExpanded(item) {
      const index = this.expandedRows.indexOf(item)
      if (index > -1) {
        this.expandedRows.splice(index, 1)
      } else {
        this.expandedRows.push(item)
      }
    },
    isExpanded(item) {
      return this.expandedRows.includes(item)
    },

    treatmentDate(dateString) {
      const date = new Date(dateString)
      return date.toLocaleDateString('zh-CN', { hour12: false })
    },

    clicked(value) {
      const index = this.expandedRows.indexOf(value)
      if (index === -1 && this.expandedRows.length === 0) {
        this.expandedRows.push(value)
      } else if (index == 0) {
        this.expandedRows.splice(index, 1)
      } else if (this.expandedRows.length !== 0) {
        this.expandedRows.splice(index, 1)
        //打开点击的行
        this.expandedRows.push(value)
      }
    },

    ...mapActions({
      fetchRecords: 'records/indexByTreatment'
    }),

    async getTimeGap(currentTime, pastTime, treatmentId) {
      let pattern = /\d+/g
      let match = pastTime.match(pattern)
      var minutes = 0
      var seconds = 0
      if (match.length == 1) {
        seconds = parseInt(match[0])
      } else if (match.length == 2) {
        minutes = parseInt(match[0])
        seconds = parseInt(match[1])
      }
      var now = new Date()
      var pastTimestamp = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), minutes, seconds).getTime()
      var currentTimestamp = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), currentTime, 0).getTime()
      this.timestamp = currentTimestamp - pastTimestamp
      if (this.timestamp > 0) {
        let date = new Date(this.timestamp)
        this.countdownRes = date.getMinutes() + this.$t('minute') + date.getSeconds() + this.$t('second')

        if (date.getMinutes() == 0 && date.getSeconds() == 0) {
          await treatment.terminateCountdown({ complete: true, id: treatmentId.toString() })
          clearInterval(this.timer)
          clearInterval(this.nowTimer)
          this.socketIds[treatmentId].close()
        }
      } else {
        this.countdownRes = 0 + this.$t('minute') + 0 + this.$t('second')
      }
    },

    async printDetail() {
      this.$refs.treatmentReport.dialog = true
    },

    goBack() {
      this.$router.go(-1)
      clearInterval(this.timer)
      clearInterval(this.nowTimer)
    },

    async terminate() {
      const treatmentId = this.treatment?.model.ID
      await treatment.terminate(treatmentId)
      this.socket.close()
      clearInterval(this.timer)
      clearInterval(this.nowTimer)
    },

    getCoverSrc() {
      return `${VUE_APP_FILE_SERVER_ENDPOINT}/${this.treatment.app.cover}`
    },

    getSrc(img) {
      return `${VUE_APP_FILE_SERVER_ENDPOINT}/medal/${img}`
    },

    streamTreatment() {
      const treatmentId = this.treatment?.model.ID.toString()
      this.streamImgSrc = 'initiating'
      this.socket = connectStreamer(treatmentId, src => (this.streamImgSrc = src))
    },

    getTags(tags) {
      return tags.split(';')
    },

    calculateDuration(then) {
      if (this.now?.diff && moment(then).year() >= 2021) {
        return moment.duration(this.now.diff(then)).format(this.$t('hHourmmMinssSecond'))
      } else {
        return NaN
      }
    },

    calculateDurationBetween(start, end) {
      if (moment.duration(moment(end).diff(start))._milliseconds > 0) {
        if (start && end) {
          return moment.duration(moment(end).diff(start)).format(this.$t('hHourmmMinssSecond'))
        } else {
          return NaN
        }
      } else {
        return 0 + this.$t('second')
      }
    },

    readableDuration(seconds) {
      return moment.duration(parseInt(seconds), 'seconds').format(this.$t('hHourmmMinssSecond'))
    },

    achieveGoals() {
      return this.records.length > 0 ? parseFloat(this.records[this.records.length - 1].data.currentAccuracy) * 100 + '%' : 0
    }
  },

  watch: {
    treatment: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.isActive = newVal.active
        if (!this.isActive) {
          clearInterval(this.timer)
          clearInterval(this.nowTimer)
          this.socket?.close()
        }
        this.totalDuration = this.calculateDurationBetween(newVal.applicationStartTime, newVal.applicationEndTime)
      }
    }
  },

  computed: {
    ...mapGetters({
      treatmentById: 'treatments/treatmentById',
      training: 'treatments/training',
      recordsByTreatmentId: 'records/recordsByTreatmentId',
      enabledEEG: 'setting/enabledEEG',
      partition: 'setting/enabledPartition',
      typeUsername: 'setting/userName',
      bedNumberName: 'setting/bedNumberName',
      enableAge: 'setting/enableAge',
      enabledCountdown: 'setting/enabledCountdown'
    }),

    treatment() {
      return this.treatmentById(this.$route.params.id)
    },

    isActiveTreatment() {
      return this.training(this.$route.params.id)
    },

    config() {
      if (this.treatment.settings) {
        return JSON.parse(this.treatment.settings)
      } else {
        return null
      }
    },

    records() {
      return this.recordsByTreatmentId(this.$route.params.id)
    },

    filteredData() {
      const uniqueIds = [...new Set(this.records.map(item => item.data.missionId))]
      const filteredData = uniqueIds.map(id => {
        const itemsWithSameId = this.records.filter(item => item.data.missionId === id)
        const lastItemWithSameId = itemsWithSameId[itemsWithSameId.length - 1]
        return {
          id: lastItemWithSameId.data.missionId,
          set: lastItemWithSameId.data.missionId,
          score: `${this.$t('result')} ${lastItemWithSameId.data.display_set_score[0]} : ${lastItemWithSameId.data.display_set_score[1]}`,
          time: `${this.$t('timeConsuming')}：${Math.floor(lastItemWithSameId.data.set_duration / 60) > 0 ? `${Math.floor(lastItemWithSameId.data.set_duration / 60)}${this.$t('minute')}` : ''
            }${lastItemWithSameId.data.set_duration % 60}${this.$t('second')}`,
          isWin: lastItemWithSameId.data.set_win,
          details: itemsWithSameId.slice(0, itemsWithSameId.length)
        }
      })
      return filteredData
    },

    ScoreSeries() {
      if (this.getScoreData.length < 0) return []
      return [
        {
          color: '#008FFB',
          name: this.$t('trainingScore'),
          type: 'bar',
          data: this.getScoreData.reduce((arr, x) => [...arr, x.score], [])
        }
      ]
    },
    chartScoreOptions() {
      return {
        chart: {
          id: 'vuechart-example',
          stacked: false,
          toolbar: false
        },
        stroke: {
          curve: 'smooth',
          width: [4]
        },
        plotOptions: {
          bar: {
            columnWidth: '5%',
            endingShape: 'rounded'
          }
        },
        yaxis: {
          forceNiceScale: true,
          type: 'bar',
          labels: {
            style: {
              colors: '#008FFB',
              fontSize: '20px'
            }
          },
          title: {
            text: this.$t('historyScore'),
            rotate: 360,
            offsetX: 35,
            offsetY: 200,
            style: {
              color: '#008FFB',
              fontSize: '20px'
            }
          },
          tickAmount: 6,
          max: 100,
          min: 0
        },
        xaxis: {
          labels: {
            style: {
              colors: '#008FFB'
            }
          },
          categories: this.getScoreData.length === 0 ? [''] : this.getScoreData.map(x => x.model.CreatedAt.split('T')[0].replace(/-/g, '/'))
        }
      }
    }
  },

  components: {
    DeviceMini,
    EEGDiagram,
    apexchart: VueApexCharts,
    TreatmentDetailReport
  }
}
</script>

<style lang="scss" scoped>
::v-deep .apexcharts-xaxis-texts-g {
  display: none !important;
}


.icon-zhengque {
  font-size: 20px;
}

.icon-gaoerfuqiugan,
.icon-bisai,
.icon-icon-person,
.icon-paiming,
.icon-mubiao {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin-right: 5px;
  margin-left: 2px;
}

.icon-bisai {
  font-size: 18px;
}

.icon-icon-person,
.icon-paiming {
  color: #757575;
  font-size: 18px;
}

::v-deep .table .v-icon {
  position: absolute;
  right: 60px;
  margin-top: -10px;
}

::v-deep .v-data-table__expanded .row {
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: space-around;
}

.medal-content {
  display: flex;
  flex-wrap: wrap;
}

.img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}

.medal {
  width: 220px;
  // border: 1px solid black;
  border-radius: 4px;
  box-shadow: 1px 1px 8px 4px rgba(0, 0, 0, 0.2);
  margin-right: 29px;
  margin-top: 30px;
  padding-bottom: 20px;
  position: relative;

  .mask {
    width: 220px;
    border-radius: 4px;
    // box-shadow: 1px 1px 8px 4px rgba(0, 0, 0, 0.2);
    margin-right: 29px;
    margin-top: 30px;
    padding-bottom: 20px;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: -30px;
  }

  .block {
    width: 200px;
    margin: auto;

    .medal-name {
      display: flex;
      justify-content: space-between;
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.3);
    margin: 5px 0;
  }

  .description {
    margin-left: 10px;
  }

  .icon-shezhiguize {
    font-size: 20px;
    color: white !important;
    font-weight: bold;
  }
}

.countdown {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: red;
  font-weight: bold;
}
</style>
