<template lang="pug">
div
  v-row
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-monitor-eye
        | {{ $t("monitor") }}
        v-spacer
        v-switch(inset, v-model='monitor')
      v-row.setting-row(align='center', no-gutters)
        img(src='@/assets/drop.png', style='width: 25px')
        span(style='margin-left: 5px') {{ $t("fallingExperience") }}
        v-spacer
        v-switch(inset, v-model='jumpSwitch')
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-cube-outline
        | {{ $t("modeSelect") }}
        v-spacer
        v-select.outlined.mr-3(reverse, style='max-width: 100px', v-model='mode', :items='modes', item-text='text', item-value='value')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-timer-outline
        | {{ $t("boardLength") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='formworkLength',
          :items='formworkLengths',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters, v-if='enabledCountdown')
        v-icon(left) mdi-timer-outline
        | {{ $t("timeLimit") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='timeLimit',
          :items='timeLimits',
          item-text='text',
          item-value='value'
        )
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: vm => ({
    jumpSwitch: true,
    monitor: true,
    tutorial: true,
    mode: 'challenge',
    modes: [
      { text: vm.$t('stepByStepExperience'), value: 'challenge' },
      { text: vm.$t('freeMode'), value: 'free' }
    ],
    formworkLength: 1,
    formworkLengths: [
      { text: '1m', value: 1 },
      { text: '3m', value: 3 },
      { text: '5m', value: 5 }
    ],
    timeLimit: 15,
    timeLimits: [
      { text:  vm.$t('5min'), value: 5 },
      { text:  vm.$t('10min'), value: 10 },
      { text:  vm.$t('15min'), value: 15 },
      { text:  vm.$t('20min'), value: 20 },
      { text:  vm.$t('30min'), value: 30 }
    ]
  }),

  methods: {
    getSettings() {
      return JSON.stringify({
        tutorial: this.tutorial,
        monitor: this.monitor,
        jumpSwitch: this.jumpSwitch,
        mode: this.mode,
        timeLimit: this.enabledCountdown ? this.timeLimit : -1,
        blankLength: this.formworkLength
      })
    }
  },

  computed: {
    ...mapGetters({
      enabledCountdown: 'setting/enabledCountdown'
    })
  }
}
</script>
        
<style lang="sass">
.setting-row
  height: 50px
</style>
        
