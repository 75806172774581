<template lang="pug">
div
  v-row
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters, v-if='serveType !== 1')
        v-icon(left) mdi-school-outline
        | {{ $t("tutorial") }}
        v-spacer
        v-switch(inset, v-model='tutorial')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-monitor-eye
        | {{ $t("monitor") }}
        v-spacer
        v-switch(inset, v-model='monitor')
      v-row.setting-row(align='center', no-gutters, v-if='enabledCountdown')
        v-icon(left) mdi-timer-outline
        | {{ $t("timeLimit") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='timeLimit',
          :items='timeLimits',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters, v-if='!enabledCountdown')
        v-icon(left) mdi-timer-outline
        | {{ $t("timeLimit") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='noCountdownTimeLimit',
          :items='noCountdowntimeLimits',
          item-text='text',
          item-value='value'
        )
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        span.iconfont.icon-shezhiguize
        | {{ $t("serveRule") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='serveType',
          :items='serveTypes',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters)
        img(src='@/assets/hand.png', width='20', style='margin-right: 5px')
        | {{ $t("preferredHand") }}
        v-spacer
        v-select.outlined.mr-3(reverse, style='max-width: 100px', v-model='hand', :items='hands', item-text='text', item-value='value')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-numeric-3-circle-outline
        | {{ $t("sets") }}
        v-spacer
        v-select.outlined.mr-3(reverse, style='max-width: 100px', v-model='set', :items='sets', item-text='text', item-value='value')
      v-row.setting-row(align='center', no-gutters)
        span.iconfont.icon-shezhiguize
        | {{ $t("assistedForce") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='assistedForce',
          :items='assistedForceList',
          item-text='text',
          item-value='value'
        )
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: vm => ({
    tutorial: false,
    monitor: true,
    timeLimit: 15,
    timeLimits: [
      { text: vm.$t('5min'), value: 5 },
      { text: vm.$t('10min'), value: 10 },
      { text: vm.$t('15min'), value: 15 },
      { text: vm.$t('20min'), value: 20 },
      { text: vm.$t('30min'), value: 30 }
    ],
    noCountdownTimeLimit: -1,
    noCountdowntimeLimits: [
      { text: vm.$t('infinite'), value: -1 },
      { text: vm.$t('5min'), value: 5 },
      { text: vm.$t('10min'), value: 10 },
      { text: vm.$t('15min'), value: 15 },
      { text: vm.$t('20min'), value: 20 },
      { text: vm.$t('30min'), value: 30 }
    ],
    assistedForce: 0,
    assistedForceList: [
      { text: vm.$t('none'), value: 0 },
      { text: vm.$t('low'), value: 1 },
      { text: vm.$t('mid'), value: 2 },
      { text: vm.$t('high'), value: 3 }
    ],
    serveType: 2,
    serveTypes: [
      { text: vm.$t('alternate'), value: 2 },
      { text: vm.$t('aiServe'), value: 1 },
      { text: vm.$t('userServe'), value: 0 }
    ],
    hand: 1,
    hands: [
      { text: vm.$t('rightHand'), value: 1 },
      { text: vm.$t('leftHand'), value: 0 }
    ],
    set: 2,
    sets: [
      { text: vm.$t('threeSetsTwoWins'), value: 2 },
      { text: vm.$t('fiveSetsThreeWins'), value: 3 }
    ],
    patientId: undefined,
    appId: undefined
  }),

  methods: {
    getSettings() {
      return JSON.stringify({
        tutorial: this.serveType !== 1 ? this.tutorial : false,
        monitor: this.monitor,
        timeLimit: this.enabledCountdown ? this.timeLimit : this.noCountdownTimeLimit,
        serveType: this.serveType,
        hand: this.hand,
        set: this.set,
        patientId: this.patientId,
        appId: this.appId,
        assistedForce: this.assistedForce,
        modeName: 'point'
      })
    }
  },

  computed: {
    ...mapGetters({
      enabledCountdown: 'setting/enabledCountdown'
    })
  }
}
</script>

<style lang="sass" scoped>
.setting-row
  height: 50px
.icon-shezhiguize
  font-size: 24px
</style>
