<template lang="pug">
div
  v-row
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-school-outline
        | {{ $t("tutorial") }}
        v-spacer
        v-switch(inset, v-model='tutorial')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-monitor-eye
        | {{ $t("monitor") }}
        v-spacer
        v-switch(inset, v-model='monitor')
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        span.iconfont.icon-mubiao
        | {{ $t("trainingGoal") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='modeType',
          :items='modeTypes',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-cube-outline
        | {{ $t("mode") }}l
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='modeName',
          :items='modeNames',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters, v-if='modeName === "rod"')
        span.iconfont.icon-bisai
        | {{ $t("strokePlay") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='levelLimit1',
          :items='levelLimits1',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters, v-if='modeName === "hole"')
        span.iconfont.icon-bisai
        | {{ $t("matchPlay") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='levelLimit',
          :items='levelLimits',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters, v-if='modeName === "time"')
        span.iconfont.icon-bisai
        | {{ $t("timeTrial") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='levelLimit2',
          :items='levelLimits2',
          item-text='text',
          item-value='value'
        )
</template>

<script>
export default {
  data: vm => ({
    monitor: true,
    tutorial: false,
    modeName: 'time',
    modeNames: [
      { text: vm.$t('strokePlay'), value: 'rod' },
      { text: vm.$t('matchPlay'), value: 'hole' },
      { text: vm.$t('timeTrial'), value: 'time' }
    ],
    levelLimit1: 6,
    levelLimits1: [
      { text: vm.$t('threeHoles'), value: 3 },
      { text: vm.$t('sixHoles'), value: 6 },
      { text: vm.$t('nineHoles'), value: 9 }
    ],
    levelLimit: 12,
    levelLimits: [
      { text: vm.$t('twelveStrokes'), value: 12 },
      { text: vm.$t('twentyFourStrokes'), value: 24 },
      { text: vm.$t('thirtySixStrokes'), value: 36 }
    ],
    levelLimit2: 15,
    levelLimits2: [
      { text: vm.$t('5min'), value: 5 },
      { text: vm.$t('10min'), value: 10 },
      { text: vm.$t('15min'), value: 15 },
      { text: vm.$t('20min'), value: 20 }
    ],
    patientId: undefined,
    appId: undefined,
    modeType: 'normal',
    modeTypes: [
      { text: vm.$t('cognitiveRehabilitation'), value: 'patient' },
      { text: vm.$t('physicalRehabilitation'), value: 'normal' }
    ]
  }),

  methods: {
    getSettings() {
      return JSON.stringify({
        tutorial: this.tutorial,
        monitor: this.monitor,
        timeLimit: -1,
        modeName: this.modeName,
        levelLimit: this.modeName === 'rod' ? this.levelLimit1 : this.modeName === 'hole' ? this.levelLimit : this.levelLimit2,
        patientId: this.patientId,
        appId: this.appId,
        modeType: this.modeType
      })
    }
  }
}
</script>

<style lang="sass">
.setting-row
  height: 50px
.icon-bisai,.icon-mubiao
  font-weight: bold
  color: #757575
  margin-left: 3px
  margin-right: 10px
.icon-mubiao
  font-size: 20px
</style>
