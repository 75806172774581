import { device } from '../../api'

const mapDevice = (data) => ({
  model: {
    ID: data.id,
    CreatedAt: data.created_at,
    UpdatedAt: data.updated_at,
    DeletedAt: data.deleted_at,
  },
  sn: data.sn,
  name: data.name,
  state: data.state,
  deviceType: data.device_type,
  puiVersion: data.pui_version,
  volume: data.volume,
  wifiIpAddress: data.wifi_ip_address,
  wifiStrength: data.wifi_strength,
  batteryPercentage: data.battery_percentage,
  chargingState: data.charging_state,
  eegState: data.eeg_state,
  eegBattery: data.eeg_battery,
  eegContact: data.eeg_contact,
})

export default {
  namespaced: true,

  state: {
    items: [],
  },
  
  getters: {
    devicesAvail: (state, getters, rootState, rootGetters) => {
      return state.items.filter(x => !rootGetters['treatments/devicesNA'].includes(x.model.ID) && x.state == 'client_connected')
    },

    devicesWA: (state, getters, rootState, rootGetters) => {
      const result = state.items.map(x => ({ ...x }))
      return result.map(x => {
        x.avail = !rootGetters['treatments/devicesNA'].includes(x.model.ID) && x.state == 'client_connected' 
        return x
      })
    },

    deviceById: (state) => ((id) => (
      state.items[state.items.findIndex(x => x.model.ID == id)]
    )),
  },
  
  actions: {
    async index ({ commit }) {
      var userId = 0
      if(JSON.parse(window.localStorage.getItem('vuex')).users.role != undefined){
        const logToken = JSON.parse(window.localStorage.getItem('vuex'))
        const adminId = logToken.users.userId
        const role = logToken.users.role
        userId = parseInt(role == 'admin'?(window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1):adminId)
      }else{
        userId = 1
      }
      const response = await device.index(userId)
      commit('index', response.data)
    },

    async pgevent ({ commit }, payload) {
      switch (payload.action) {
        case 'INSERT':
          commit('add', mapDevice(payload.data))
          break
        case 'UPDATE':
          commit('edit', mapDevice(payload.data))
          break
        case 'DELETE':
          commit('remove', payload.data.id)
          break
        default:
          break 
      }
    }
  },
  
  mutations: {
    index (state, payload) {
      state.items = payload
    },

    add (state, payload) {
      state.items.splice(state.items.length, 0, payload)
    },

    edit (state, payload) {
      state.items.splice(state.items.findIndex(x => x.model.ID == payload.model.ID), 1, payload)
    },

    remove (state, payload) {
      state.items.splice(state.items.findIndex(x => x.model.ID == payload), 1)
    },
  }
}