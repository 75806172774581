import { about } from '../../api'

const mapSetting = data => ({
  name: data.name,
  number: data.number,
  release_message: data.release_message,
  complete_message: data.complete_message,
  warranty_date: data.warranty_date,
  company: data.company,
  address: data.address,
  telephone: data.telephone,
  year: data.Years
})

export default {
  namespaced: true,

  state: {
    name: undefined,
    number: undefined,
    release_message: undefined,
    complete_message: undefined,
    warranty_date: undefined,
    company: undefined,
    address: undefined,
    telephone: undefined,
    year: undefined
  },

  getters: {
    name: state => {
      return state.name
    },

    number: state => {
      return state.number
    },

    release_message: state => {
      return state.release_message
    },

    complete_message: state => {
      return state.complete_message
    },

    warranty_date: state => {
      return state.warranty_date
    },

    company: state => {
      return state.company
    },

    address: state => {
      return state.address
    },

    telephone: state => {
      return state.telephone
    },

    year: state => {
      return state.year
    }
  },

  actions: {
    async index({ commit }) {
      var userId = 0
      if (
        JSON.parse(window.localStorage.getItem('vuex')) != null &&
        JSON.parse(window.localStorage.getItem('vuex')).users.role != undefined
      ) {
        const logToken = JSON.parse(window.localStorage.getItem('vuex'))
        const adminId = logToken.users.userId
        const role = logToken.users.role
        userId = parseInt(role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId)
      } else {
        userId = 1
      }
      const response = await about.index(userId)
      commit('index', response.data)
    },

    userType({ commit }, data) {
      commit('userType', (window.localStorage['userName'] = data))
    },

    async pgevent({ commit }, payload) {
      switch (payload.action) {
        case 'UPDATE':
          commit('update', mapSetting(payload.data))
          break
        default:
          break
      }
    }
  },

  mutations: {
    index(state, payload) {
      state.name = payload.name
      state.number = payload.number
      state.release_message = payload.release_message
      state.complete_message = payload.complete_message
      state.warranty_date = payload.warranty_date
      state.company = payload.company
      state.address = payload.address
      state.telephone = payload.telephone
      state.year = payload.Years
    },

    update(state, payload) {
      state.name = payload.name
      state.number = payload.number
      state.release_message = payload.release_message
      state.complete_message = payload.complete_message
      state.warranty_date = payload.warranty_date
      state.company = payload.company
      state.address = payload.address
      state.telephone = payload.telephone
      state.year = payload.Years
    }
  }
}
