<template lang="pug">
div
  v-row
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters, v-if='model === "classic"')
        v-icon(left) mdi-school-outline
        | {{ $t("tutorial") }}
        v-spacer
        v-switch(inset, v-model='tutorial')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-monitor-eye
        | {{ $t("monitor") }}
        v-spacer
        v-switch(inset, v-model='monitor')
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-cube-outline
        | {{ $t("mode") }}
        v-spacer
        v-select.outlined.mr-3(reverse, style='max-width: 100px', v-model='model', :items='modes', item-text='text', item-value='value')
      v-row.setting-row(align='center', no-gutters, v-if='model === "classic"')
        v-icon(left) mdi-flash-outline
        | {{ $t("difficulty")}}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='difficulty',
          :items='difficulties',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters, v-if='enabledCountdown')
        v-icon(left) mdi-timer-outline
        | {{ $t("timeLimit") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='timeLimit',
          :items='timeLimits',
          item-text='text',
          item-value='value'
        )
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: vm => ({
    tutorial: true,
    monitor: true,
    timeLimit: 15,
    timeLimits: [
      { text:  vm.$t('5min'), value: 5 },
      { text:  vm.$t('10min'), value: 10 },
      { text:  vm.$t('15min'), value: 15 },
      { text:  vm.$t('20min'), value: 20 },
      { text:  vm.$t('30min'), value: 30 }
    ],
    model: 'classic',
    modes: [
      { text: vm.$t('classicMode'), value: 'classic' },
      { text: vm.$t('freeMode'), value: 'intelligent' }
    ],
    difficulty: 'medium',
    difficulties: [
      { text: vm.$t('easy'), value: 'simple' },
      { text: vm.$t('normal'), value: 'medium' },
      { text: vm.$t('hard'), value: 'difficulty' }
    ]
  }),

  methods: {
    getSettings() {
      return JSON.stringify({
        tutorial: this.model == 'classic' ? this.tutorial : false,
        monitor: this.monitor,
        timeLimit: this.enabledCountdown ? this.timeLimit : -1,
        model: this.model,
        difficulty: this.model == 'classic' ? this.difficulty : ''
      })
    }
  },

  computed: {
    ...mapGetters({
      enabledCountdown: 'setting/enabledCountdown'
    })
  }
}
</script>

<style lang="sass">
.setting-row
  height: 50px
</style>

