<template lang="pug">
div(v-if='appId')
  v-row(align='center')
    v-img(:src='getThumbnailSrc(appById(appId))', width='64', max-width='64', height='36', max-height='36')
    v-divider.mx-4(inset, vertical)
    pre.body-1 {{ appById(appId).name }}
</template>

<script>
import { mapGetters } from 'vuex'
import { VUE_APP_FILE_SERVER_ENDPOINT } from '@/app.config'

export default {
  props: ['appId'],

  methods: {
    getThumbnailSrc(item) {
      if (item.thumbnail !== '1') {
        return `${VUE_APP_FILE_SERVER_ENDPOINT}/${item.thumbnail}`
      }
    }
  },

  computed: {
    ...mapGetters({
      appById: 'apps/appById'
    })
  }
}
</script>