<template lang='pug'>
v-dialog(v-model='displayAppsDialog', width='550px', @click:outside='visible = false')
  v-toolbar(dense, dark, color='primary', style='height: 60px')
    v-toolbar-title {{ $t("appsTitle") }}
    el-select(v-model='value', clearable, :placeholder="$t('selectPlease')", @change='category', @clear='clearSelect')
      el-option(v-for='item in items', :key='item.value', :label='item.title', :value='item.value')
    el-input(
      :placeholder="$t('pleaseEnterContent')",
      suffix-icon='el-icon-search',
      v-model='searchApps',
      @click.stop.native='isOpen ? (visible = false) : (visible = true)',
      @focus.stop='keyboardShowClick'
    )
  v-card
    v-card-text
      v-row
        v-col(cols='12', sm='12', md='12')
          v-card(style='overflow: hidden')
            //-  
            v-checkbox(
              v-model='checkbox',
              label='',
              style='margin-top: 10px; margin-left: 10px',
              v-if='hideApps.length > 0',
              @change='checkChange'
            )
            v-card-text
              v-list.overflow-y-auto(style='height: 300px; position: relative; top: -30px')
                v-list-item-group(v-model='appIndex')
                  v-list-item(
                    v-for='(item, index) in apps(searchApps)',
                    @click='appListClick(item, index)',
                    :key='index',
                    :class='currentIndex === index && checkbox == false ? listColor : checkbox == true ? listColor : ""'
                  )
                    v-list-item-content.px-6.my-2
                      v-row(align='center')
                        //- getThumbnailSrc(appById(item.model.ID))
                        v-img(:src='getThumbnailSrc(appById(item.model.ID))', width='64', max-width='64', height='36', max-height='36')
                        v-divider.mx-4(inset, vertical)
                        pre.body-1 {{ item.name }}
      v-row
        v-col(v-show='pairingListShow', cols='12', sm='12', md='12')
          v-card.select(:style='{ height: pairList + "px" }')
            v-card-text
              v-row
                v-col(cols='12', sm='6', md='4', v-for='(item, index) in selectedList', :key='index')
                  .pairingBtn
                    span {{ item.name }}
                    span.iconfont.icon-close(@click='pairingClose(item)') 
    v-card-actions(style='padding-bottom: 30px')
      v-spacer
      v-btn(text, @click='clear(); visible = false') {{ $t("dialogCancel") }}
      v-btn(color='primary', @click='confirm(); visible = false') {{ $t("drConfirm") }}

  v-snackbar(elevation='24', v-model='snackbar', :color='snackbarColor', timeout='3000') {{ notifMsg }}
    template(v-slot:action='{ attrs }')
      v-btn(text, v-bind='attrs', @click='snackbar = false') {{ $t("notifClose") }}

  Keyboard#keyboard(layout='normal', :input='input', v-if='visible && enableTouch', :cancel='cancel')
</template>
<script>
import { VUE_APP_FILE_SERVER_ENDPOINT } from '@/app.config'
import { mapGetters } from 'vuex'
import { app } from './../../api'
import Keyboard from 'vue-keyboard-zh'

function filterByValue(arr, term) {
  return arr.filter(obj => {
    return Object.keys(obj).reduce((acc, curr) => {
      return acc || String(obj[curr]).toLowerCase().includes(term)
    }, false)
  })
}

export default {
  props: ['hideData'],

  data: () => ({
    displayAppsDialog: false,
    searchApps: undefined,
    appIndex: undefined,
    pairingListShow: true,
    checkbox: false,
    items: {},
    value: '',
    selectedList: [],
    selectId: [],
    hideApps: [],
    allSelectId: [],
    listColor: '',
    currentIndex: -1,
    pairList: 80,
    snackbarColor: '',
    notifMsg: '',
    snackbar: false,
    input: '',
    visible: false,
    isOpen: false
  }),

  watch: {
    hideData: function (newVal) {
      this.hideApps = newVal
    }
  },

  methods: {
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    keyboardShowClick(e) {
      if (!this.isOpen) {
        this.input = e.target
        if (!this.visible) this.visible = true
      } else {
        this.visible = false
        this.input = ''
      }
    },
    cancel() {
      this.visible = false
    },
    checkChange(val) {
      this.checkbox = val
      if (this.checkbox) {
        this.listColor = 'listColor'
        this.hideApps.forEach(x => {
          this.allSelectId.push(x.model.ID)
        })
      } else {
        this.allSelectId = []
      }
    },
    getLocalStorage(query) {
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      const obj1 = {
        adminId: role == 'admin' ? adminId : 1,
        userId: userId,
        role: role,
        isShow: 0,
        type: String(query),
        status: true
      }
      return obj1
    },
    getShowApps(ids) {
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      const obj2 = {
        adminId: role == 'admin' ? adminId : 1,
        userId: userId,
        role: role,
        ids: ids,
        status: true
      }
      return obj2
    },
    async category(val) {
      const result = await app.index(this.getLocalStorage(val))
      const data = result.data
      const arr = this.selectedList.map(item => item.model.ID)
      this.hideApps = data.filter(item => !arr.includes(item.model.ID))
    },
    clearSelect() {
      this.hideApps = this.hideData
      this.checkbox = false
      this.listColor = ''
    },
    apps(query) {
      if (!query) return this.hideApps
      return filterByValue(this.hideApps, query)
    },
    appById(id) {
      if (id) {
        return this.hideApps[this.hideApps.findIndex(x => x.model.ID == id)]
      }
    },
    getThumbnailSrc(item) {
      return `${VUE_APP_FILE_SERVER_ENDPOINT}/${item.thumbnail}`
    },
    clear() {
      this.displayAppsDialog = false
      this.selectedList = []
      this.value = ''
    },
    appListClick(item, index) {
      const selectIndex = this.hideApps.findIndex(y => {
        return y.model.ID === item.model.ID
      })
      this.currentIndex = index
      this.listColor = 'listColor'
      this.hideApps.splice(selectIndex, 1)
      this.selectedList.unshift(item)
      if (this.selectedList.length > 3) {
        this.pairList = 160
      } else {
        this.pairList = 80
      }
      setTimeout(() => {
        this.listColor = ''
      }, 300)
    },
    pairingClose(item) {
      const deleIndex = this.selectedList.findIndex(y => {
        return y.model.ID === item.model.ID
      })
      this.hideApps.unshift(item)
      this.selectedList.splice(deleIndex, 1)
      if (this.selectedList.length > 3) {
        this.pairList = 160
      } else {
        this.pairList = 80
      }
    },
    async confirm() {
      this.selectedList.forEach(item => {
        this.selectId.push(item.model.ID)
      })
      if (this.selectedList.length > 0) {
        //const appsId = window.localStorage.getItem('appsId') ? window.localStorage.getItem('appsId') : ''
        const ids = this.selectId.join(',')
        let newIds = []
        for (var i = 0; i < ids.split(',').length; i++) {
          if (ids.split(',').indexOf(ids.split(',')[i]) == i) {
            newIds.push(ids.split(',')[i])
          }
        }
        window.localStorage['appsId'] = newIds.join(',')
        await app.hideApps(this.getShowApps(newIds.join(',')))
      } else if (this.allSelectId.length > 0 && this.checkbox) {
        // const appsId = window.localStorage.getItem('appsId') ? window.localStorage.getItem('appsId') : ''
        const ids = this.allSelectId.join(',')
        let newIds = []
        for (var j = 0; j < ids.split(',').length; j++) {
          if (ids.split(',').indexOf(ids.split(',')[j]) == j) {
            newIds.push(ids.split(',')[j])
          }
        }
        window.localStorage['appsId'] = newIds.join(',')
        await app.hideApps(this.getShowApps(newIds.join(',')))
      } else if (this.selectedList.length === 0 && this.allSelectId.length === 0) {
        this.notifMsg = this.$t('pleaseSelectSoftwareDisplayed')
        this.snackbarColor = 'success'
        this.snackbar = true
        return
      }
      this.$emit('refresh')
      this.selectedList = []
      this.displayAppsDialog = false
      this.checkbox = false
      this.value = ''
    }
  },

  mounted() {
    this.resize()
    this.items = [
      {
        title: this.$t("vrCognition"),
        value: 0
      },
      {
        title: this.$t("vrPsychology"),
        value: 1
      },
      {
        title: this.$t("vrExposure"),
        value: 2
      },
      {
        title: this.$t("vrMindfulness"),
        value: 3
      },
      {
        title: this.$t("vrHypnosis"),
        value: 4
      },
      {
        title: this.$t("vrMusic"),
        value: 5
      },
      {
        title: this.$t("vrRelaxation"),
        value: 6
      },
      {
        title: this.$t("vrExploration"),
        value: 7
      },
      {
        title: this.$t("vrEvaluation"),
        value: 8
      }
    ]
    window.addEventListener('resize', this.resize, false)
  },

  components: {
    Keyboard
  },

  computed: {
    ...mapGetters({
      enableTouch: 'setting/enableTouch'
    })
  }
}
</script>

<style scoped lang="sass">
.select
  overflow: auto

.pairingBtn
  height: 56px
  border-radius: 2px
  line-height: 56px
  display: flex
  justify-content: center
  color: #1976D2
  font-size: 14px
  position: relative
  background: #E4EFFA
.icon-close
  position: absolute
  top: -8px
  right: -9px
  height: 20px
  width: 20px
  text-align: center
  background: #777777
  border-radius: 50%
  color: #fff
  cursor: pointer
.icon-close:before
  position: relative
  top: -18px
  top: -18px
::v-deep .el-input
  width: 120px
  position: relative
  left: 210px
  color: #fff
::v-deep .el-select
  width: 120px
  position: relative
  left: -10px
::v-deep .el-input__inner
  border-radius: 55px
  height: 35px
  background: #1976D2
  color: #fff
  border: 1px solid #3788D8

::v-deep .el-input__icon
  line-height: 35px
  color: #fff
::v-deep .el-select .el-input .el-select__caret
  color: #fff
::v-deep .v-input__slot
  min-height: 35px !important
  line-height: 35px !important
.listColor
  background: #E4EFFA
  color: #1976D2
::v-deep input::-webkit-input-placeholder
  -webkit-text-fill-color: #fff
::v-deep .v-application .primary--text
  color: red !important
#keyboard
  position: fixed
  top: auto !important
  bottom: 0
  z-index: 999
  padding: 14px 21px
</style>

