<template lang="pug">
div
  v-row
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-monitor-eye
        | {{ $t("monitor") }}
        v-spacer
        v-switch(inset, v-model='monitor')
      v-row.setting-row(align='center', no-gutters, v-if='enabledCountdown')
        v-icon(left) mdi-timer-outline
        | {{ $t("timeLimit") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='timeLimit',
          :items='timeLimits',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters, v-if='!enabledCountdown')
        v-icon(left) mdi-timer-outline
        | {{ $t("timeLimit") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='noCountdownTimeLimit',
          :items='noCountdowntimeLimits',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-numeric-3-box-multiple-outline
        | {{ $t("playMode") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='playMode',
          :items='playModeList',
          item-text='text',
          item-value='value'
        )
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-message-outline
        | {{ $t("difficulty") }}
        v-spacer
        v-select.outlined.mr-3(reverse, style='max-width: 100px', v-model='difficulty', :items='difficultyList', item-text='text', item-value='value')
      //- v-row.setting-row(align='center', no-gutters)
      //-   v-icon(left) mdi-message-outline
      //-   | {{ $t("detailSetting") }}
      //-   v-spacer
      div(v-if='difficulty == 4')
        v-row.setting-row(align='center', no-gutters)
          v-icon(left) mdi-message-outline
          | {{ $t("digit") }}
          v-spacer
          v-select.outlined.mr-3(reverse, style='max-width: 100px', v-model='digit', :items='digitList', item-text='text', item-value='value')
        v-row.setting-row(align='center', no-gutters)
          v-icon(left) mdi-message-outline
          | {{ $t("trueCount") }}
          v-spacer
          v-text-field.outlined.mr-3(reverse, style='max-width: 100px', v-model='trueCount')
        v-row.setting-row(align='center', no-gutters)
          v-icon(left) mdi-message-outline
          | {{ $t("errorCount") }}
          v-spacer
          v-text-field.outlined.mr-3(reverse, style='max-width: 100px', v-model='errorCount')
        v-row.setting-row(align='center', no-gutters)
          v-icon(left) mdi-message-outline
          | {{ $t("blood") }}
          v-spacer
          v-text-field.outlined.mr-3(reverse, style='max-width: 100px', v-model='blood')
        v-row.setting-row(align='center', no-gutters)
          v-icon(left) mdi-message-outline
          | {{ $t("round") }}
          v-spacer
          v-text-field.outlined.mr-3(reverse, style='max-width: 100px', v-model='round')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-message-outline
        | {{ $t("clarity") }}
        v-spacer
        v-select.outlined.mr-3(reverse, style='max-width: 100px', v-model='clarity', :items='clarityList', item-text='text', item-value='value')
      div(v-if='clarity == 4')
        v-row.setting-row(align='center', no-gutters)
          v-icon(left) mdi-message-outline
          | {{ $t("resolution") }}
          v-spacer
          v-select.outlined.mr-3(reverse, style='max-width: 100px', v-model='resolution', :items='resolutionList', item-text='text', item-value='value')
        v-row.setting-row(align='center', no-gutters)
          v-icon(left) mdi-numeric-3-box-multiple-outline
          | {{ $t("fps") }}
          v-spacer
          v-select.outlined.mr-3(
            reverse,
            style='max-width: 100px',
            v-model='fps',
            :items='fpsList',
            item-text='text',
            item-value='value'
          )
        v-row.setting-row(align='center', no-gutters)
          v-icon(left) mdi-numeric-6-circle-outline
          | {{ $t("quality") }}
          v-spacer
          v-select.outlined.mr-3(
            reverse,
            style='max-width: 100px',
            v-model='quality',
            :items='qualityList',
            item-text='text',
            item-value='value'
          )
        v-row.setting-row(align='center', no-gutters)
          v-icon(left) mdi-numeric-6-circle-outline
          | {{ $t("chromaSubsampling") }}
          v-spacer
          v-select.outlined.mr-3(
            reverse,
            style='max-width: 100px',
            v-model='chromaSubsampling',
            :items='chromaSubsamplingList',
            item-text='text',
            item-value='value'
          )
        v-row.setting-row(align='center', no-gutters)
          v-icon(left) mdi-numeric-6-circle-outline
          | {{ $t("resize") }}
          v-spacer
          v-select.outlined.mr-3(
            reverse,
            style='max-width: 100px',
            v-model='resize',
            :items='resizeList',
            item-text='text',
            item-value='value'
          )
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['appCodename'],
  data: vm => ({
    tutorial: true,
    monitor: true,
    timeLimit: 15,
    timeLimits: [
      { text: vm.$t('5min'), value: 5 },
      { text: vm.$t('10min'), value: 10 },
      { text: vm.$t('15min'), value: 15 },
      { text: vm.$t('20min'), value: 20 },
      { text: vm.$t('20min'), value: 30 }
    ],
    noCountdownTimeLimit: -1,
    noCountdowntimeLimits: [
      { text: vm.$t('infinite'), value: -1 },
      { text: vm.$t('5min'), value: 5 },
      { text: vm.$t('10min'), value: 10 },
      { text: vm.$t('15min'), value: 15 },
      { text: vm.$t('20min'), value: 20 },
      { text: vm.$t('20min'), value: 30 }
    ],
    playMode: 0,
    playModeList: [
      { text: vm.$t('gamepadMode'), value: 0 },
      { text: vm.$t('gestureRecognition'), value: 1 }
    ],
    symbol: "+",
    difficulty: 1,
    difficultyList: [
      { text: vm.$t('easy'), value: 1 },
      { text: vm.$t('normal'), value: 2 },
      { text: vm.$t('hard'), value: 3 },
      { text: vm.$t('custom'), value: 4 }
    ],
    digit: 1,
    digitList: [
      { text: vm.$t('1bit'), value: 1 },
      { text: vm.$t('2bits'), value: 2 },
      { text: vm.$t('3bits'), value: 3 },
      { text: vm.$t('4bits'), value: 4 }
    ],
    trueCount: 1,
    errorCount: 1,
    blood: 200,
    round: 3,
    //clarity
    clarity:1,
    clarityList:[
      { text: vm.$t('Smooth'), value: 0 },
      { text: vm.$t('SD'), value: 1 },
      { text: vm.$t('HD'), value: 2 },
      { text: vm.$t('Blu-ray'), value: 3 },
      { text: vm.$t('custom'), value: 4 }
    ],
    resolution: 512,
    resolutionList: [
      { text: vm.$t('low'), value: 512 },
      { text: vm.$t('high'), value: 1024 },
      { text: vm.$t('superHigh'), value: 2048 }
    ],
    fps:24,
    fpsList:[
      { text: vm.$t('low'), value: 24 },
      { text: vm.$t('high'), value: 30 },
      { text: vm.$t('superHigh'), value: 60 }
    ],
    quality: 20,
    qualityList:[
      { text: vm.$t('low'), value: 20 },
      { text: vm.$t('mid'), value: 40 },
      { text: vm.$t('high'), value: 60 }
    ],
    chromaSubsampling: 0,
    chromaSubsamplingList:[
      { text: vm.$t('superHigh'), value: 0 },
      { text: vm.$t('high'), value: 1 },
      { text: vm.$t('low'), value: 2 },
      { text: vm.$t('grayScale'), value: 3 }
    ],
    resize:0,
    resizeList:[
      { text: vm.$t('fullFrame'), value: 0 },
      { text: vm.$t('halfFrame'), value: 1 },
      { text: vm.$t('quarterFrame'), value: 2 },
      { text: vm.$t('quarterFrame'), value: 3 }
    ]
  }),

  methods: {
    getSettings() {
      let digit = 1
      let trueCount = 1
      let errorCount = 1
      let blood = 200
      let round = 3
      switch (this.difficulty) {
        case 1:
          break;
        case 2:
          digit = 2
          trueCount = 1
          errorCount = 2
          blood = 200
          round = 3
          break;
        case 3:
          digit = 3
          trueCount = 1
          errorCount = 3
          blood = 200
          round = 3
      }
      switch(this.appCodename.split("/")[1]){
        case "add":
          this.symbol = "+";
          break
        case "sub":
          this.symbol = "-";
          break
        case "mul":
          this.symbol = "*";
          break
        case "div":
          this.symbol = "/";
          break
        case "all":
          this.symbol = "fusion";
          break
      }
      let options = {
        resolution: this.resolution,
        fps: this.fps,
        quality: this.quality,
        chromaSubsampling: this.chromaSubsampling,
        resize: this.resize
      }
      return JSON.stringify({
        monitor: this.monitor,
        timeLimit: this.enabledCountdown ? this.timeLimit : this.noCountdownTimeLimit,
        playmode: this.playMode,
        symbol: this.symbol,
        digit: digit,
        trueCount: trueCount,
        errorCount: errorCount,
        blood: blood,
        round: round,
        streamSetting: JSON.stringify({ level: this.clarity, options: options })
      })
    }
  },

  computed: {
    ...mapGetters({
      enabledCountdown: 'setting/enabledCountdown'
    })
  }
}
</script>

<style lang="sass">
.setting-row
  height: 50px
</style>

