import { patient } from '../../api'

const mapPatient = data => ({
  model: {
    ID: data.id,
    CreatedAt: data.created_at,
    UpdatedAt: data.updated_at,
    DeletedAt: data.deleted_at
  },
  name: data.name,
  patientNumber: data.patient_number,
  bedNumber: data.bed_number,
  gender: data.gender,
  birthday: data.birthday,
  maritalStatus: data.marital_status,
  ethnicity: data.ethnicity,
  education: data.education,
  idNumber: data.id_number,
  remarks: data.remarks,
  section: data.section,
  hospitalNumber: data.hospital_number,
  diagnosis: data.diagnosis
})

export default {
  namespaced: true,

  state: {
    items: []
  },

  getters: {
    patientsAvail: (state, getters, rootState, rootGetters) => {
      return state.items == null ? [] : state.items.filter(x => !rootGetters['treatments/patientsNA'].includes(x.model.ID))
    },

    patientsWA: (state, getters, rootState, rootGetters) => {
      if (state.items !== null) {
        const result = [...state.items]
        return result.map(x => {
          x.avail = !rootGetters['treatments/patientsNA'].includes(x.model.ID)
          return x
        })
      } else {
        return []
      }
    },

    patientById: state => id => state.items[state.items.findIndex(x => x.model.ID == id)]
  },

  actions: {
    async index({ commit }) {
      var userId = 0
      if (JSON.parse(window.localStorage.getItem('vuex')).users.role != undefined) {
        const logToken = JSON.parse(window.localStorage.getItem('vuex'))
        const adminId = logToken.users.userId
        const role = logToken.users.role
        userId = parseInt(role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId)
      } else {
        userId = 1
      }
      const response = await patient.index('', '', userId)
      commit('index', response.data)
    },

    async pgevent({ commit }, payload) {
      switch (payload.action) {
        // case 'INSERT':
        //   commit('add', mapPatient(payload.data))
        //   break
        case 'UPDATE':
          commit('edit', mapPatient(payload.data))
          break
        case 'DELETE':
          commit('remove', payload.data.id)
          break
        default:
          break
      }
    }
  },

  mutations: {
    index(state, payload) {
      state.items = payload
    },

    add(state, payload) {
      state.items.splice(0, 0, payload)
    },

    edit(state, payload) {
      state.items.splice(
        state.items.findIndex(x => x.model.ID == payload.model.ID),
        1,
        payload
      )
    },

    remove(state, payload) {
      state.items.splice(
        state.items.findIndex(x => x.model.ID == payload),
        1
      )
    }
  }
}
