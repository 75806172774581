<template lang="pug">
div
  v-row
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-monitor-eye
        | {{ $t("monitor") }}
        v-spacer
        v-switch(inset, v-model='monitor')
      v-row.setting-row(v-if='enabledCountdown',align='center', no-gutters)
        v-icon(left) mdi-timer-outline
        | {{ $t("timeLimit") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='timeLimit',
          :items='timeLimits',
          item-text='text',
          item-value='value'
        )
      
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-timer-outline
        | {{ $t("gameMode") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='gameMode',
          :items='gameModeList',
          item-text='text',
          item-value='value'
        )
      StreamSettings(ref="streamSettings")
</template>

<script>
import { mapGetters } from 'vuex'
import StreamSettings from './common/StreamSettings.vue'
export default {
  data: vm => ({
    monitor: true,
    timeLimit: 15,
    timeLimits: [
    { text: vm.$t("infinite"), value: 0 },
      { text: `5 ${vm.$t("minutes")}`, value: 5 },
      { text: `10 ${vm.$t("minutes")}`, value: 10 },
      { text: `15 ${vm.$t("minutes")}`, value: 15 },
      { text: `20 ${vm.$t("minutes")}`, value: 20 },
      { text: `30 ${vm.$t("minutes")}`, value: 30 }
    ],
    gameMode:0,
    gameModeList:[
      {text:vm.$t("paper"),value:0},
      {text: vm.$t("online"),value:1}
    ]

  }),

  methods: {
    getSettings() {
      return JSON.stringify({
        monitor: this.monitor,
        timeLimit: this.enabledCountdown ? this.timeLimit : this.noCountdownTimeLimit,
        GameMode: this.gameMode,
        streamSetting: this.$refs.streamSettings.streamSetting()
      })
    }
  },

  computed: {
    ...mapGetters({
      enabledCountdown: 'setting/enabledCountdown'
    })
  },
  components:{
    StreamSettings
  }
}
</script>

<style lang="sass">
.setting-row
  height: 50px
</style>

