<template lang="pug">
div(style='height: 50vh; position: relative', @click='visible = false')
  .appTopBar(:style='{ marginLeft: isOpen ? "-256px" : "-12px", paddingBottom: isOpen ? "40px" : "30px" }')
    div(style='display: flex; flex-wrap: wrap')
      img(
        src='@/assets/title_logo.png',
        width='50',
        style='margin-right: 10px; margin-top: 25px; margin-left: 15px',
        v-if='!isOpen',
        @click='setWidth'
      )
      v-toolbar-title(v-if='isOpen', style='margin-top: 25px; margin-left: 256px') {{ $t("appsTitle") }}
      h3(v-if='!isOpen', style='margin-top: 30px; font-size: 19px') {{ $t("appsTitle") }}
      v-divider.mx-4(inset, vertical, :style='{ marginTop: isOpen ? "25px" : "20px" }')
      div(style='position: relative; top: 18px; display: flex', v-if='!isOpen')
        v-col(cols='12', sm='8', md='8')
          el-input.mInput(
            :placeholder="$t('enterSoftwareName')",
            v-model='appName',
            clearable,
            @clear='inputClear',
            @click.stop.native='visible = true',
            @focus.stop='keyboardShowClick'
          )
        v-tooltip(bottom)
          template(v-slot:activator='{ on, attrs }')
            v-btn.back(
              dark,
              color='orange',
              @click='searchClick()',
              v-bind='attrs',
              v-on='on',
              style='position: relative; top: 12px; height: 33px'
            )
              v-icon.iconfont.icon-31sousuo
          span {{ $t("search") }}
      v-spacer
      .displayBtn(:style='{ display: "flex", right: isOpen ? "2%" : "5%" }')
        v-tooltip(bottom, v-if='isAdmin')
          template(v-slot:activator='{ on, attrs }')
            v-btn(fab, dark, color='orange', @click='displayApps()', v-bind='attrs', v-on='on')
              img(src='@/assets/openEyes.png', width='24')
          span {{ $t("displayTreatment") }}
    div(style='margin-top: 20px')
      v-row.mLabel(v-if='!isOpen', style='margin-left: 8px')
        span(
          style='margin-left: 10px; margin-top: 20px; font-size: 12px; width: 70px',
          v-for='(item, index) in items',
          @click='categoryClick(item, index)',
          :class='categoryIndex == index ? "colorClick" : "titleBtn"',
          v-if='isAdmin'
        ) {{ item.title }}
        span(
          style='margin-left: 10px; margin-top: 20px; font-size: 12px; width: 70px',
          v-for='(item, index) in userItems',
          @click='categoryClick(item, index)',
          :class='categoryIndex == index ? "colorClick" : "titleBtn"',
          v-if='!isAdmin'
        ) {{ item.title }}
      v-row.search(style='margin-left: 243px', v-if='isOpen') 
        v-col(cols='12', sm='2', md='2')
          el-input(:placeholder="$t('enterSoftwareName')", v-model='appName', clearable, @clear='inputClear')
        v-tooltip(bottom)
          template(v-slot:activator='{ on, attrs }')
            v-btn.back(dark, color='orange', @click='searchClick()', v-bind='attrs', v-on='on', style='position: relative; top: 12px')
              v-icon.iconfont.icon-31sousuo
          span {{ $t("search") }}
        v-row.label(style='position: relative; top: 6px; left: 15px', v-if='isOpen')
          span(
            style='margin-left: 10px; margin-top: 20px',
            v-for='(item, index) in items',
            @click='categoryClick(item, index)',
            :class='categoryIndex == index ? "colorClick" : "titleBtn"',
            v-if='isAdmin'
          ) {{ item.title }}
          span(
            style='margin-left: 10px; margin-top: 20px',
            v-for='(item, index) in userItems',
            @click='categoryClick(item, index)',
            :class='categoryIndex == index ? "colorClick" : "titleBtn"',
            v-if='!isAdmin'
          ) {{ item.title }}

      v-chip.ma-1(v-for='(item, i) in selectedTags', :key='i') {{ item }}
      v-chip.ml-2(v-if='selectedTags.length > 0', @click='clearSelectedTags()')
  v-row(v-if='enabledCategory && selectedTags.length == 0')
    v-col(v-for='(item, i) in tags', :key='i', xl='3', lg='4', md='4')
      v-btn.tagBtn(dark, block, elevation='3', height='150', :color='randomColor()', @click='selectTag(item)') {{ item }}

  v-row(v-if='enabledCategory && selectedTags.length > 0', :style='{ marginTop: contentTop1 }', ref='appTop')
    v-col(v-for='(item, i) in appsByTag(selectedTags[0])', :key='i', xl='4', lg='6', md='12')
      v-card.mx-auto
        v-img(:src='getCoverSrc(item)')
          .durationBox(v-if='item.duration') {{ item.duration }}
        v-card-title {{ item.name }}
          v-spacer
          v-btn(color='primary', @click='start(item)')
            v-icon(left, style='font-size: 22px') mdi-play-circle
            |
            | {{ $t("startTraining") }}
        v-card-subtitle(style='position: relative')
          v-chip.ma-1(v-for='(item, i) in getTags(item)', :key='i') {{ item }}
          v-chip(dark, color='orange', v-if='item.warning') {{ item.warning }}
          v-btn(style='position: absolute; right: 15px; top: 10px; background: #e0e0e0; width: 150px', v-if='isAdmin')
            img(src='@/assets/ccloseEyes.png', width='16', style='position: relative; left: -7px')
            |
            | {{ $t("hideApps") }}
        v-divider
        v-card-text {{ item.description }}
  v-row(v-if='!enabledCategory && isOpen', :style='{ marginTop: contentTop1 }', ref='appTop')
    v-col(v-for='(item, i) in appsData', :key='i', xl='4', lg='6', md='12')
      v-card.mx-auto
        v-img(:src='getCoverSrc(item)')
          .durationBox(v-if='item.duration') {{ item.duration }}
        v-card-title {{ item.name }}
          v-spacer
          v-btn(color='primary', @click='start(item)',style="width: 150px")
            v-icon(left, style='font-size: 22px,margin-right:30px') mdi-play-circle
            |
            span {{ $t("startTraining") }}
        v-card-subtitle(style='position: relative')
          v-chip.ma-1(v-for='(item, i) in getTags(item)', :key='i') {{ item }}
          v-chip(dark, color='orange', v-if='item.warning') {{ item.warning }}
          v-btn(
            @click='hideApps(item)',
            style='position: absolute; right: 15px; top: 10px; background: #e0e0e0; width: 150px',
            v-if='isAdmin'
          )
            img(src='@/assets/ccloseEyes.png', width='16', style='position: relative; left: -7px')
            |
            | {{ $t("hideApps") }}
        v-divider
        v-card-text {{ item.description }}
  v-row(v-if='!enabledCategory && !isOpen', :style='{ marginTop: contentTop1 }', ref='appTop')
    v-col(v-for='(item, i) in appsData', :key='i', xl='4', lg='6', md='12')
      v-card.wrap-flex
        v-img.img(:src='getCoverSrc(item)', width='310', height='178.75')
          .wrapDurationBox(v-if='item.duration', style='font-size: 14px') {{ item.duration }}
          div(@click='hideApps(item)', v-if='isAdmin')
            span.iconfont.icon-biyansvg(style='color: white; font-size: 22px; margin-left: 10px')
        .right
          v-card-title(style='color: #000; padding-top: 2px; font-size: 16px') {{ item.name }}
            v-spacer
            v-btn(color='primary', @click='start(item)', style='width: 100px; height: 28px; font-size: 12px; margin-top: 13px')
              v-icon(left, style='font-size: 20px; margin-top: 1px') mdi-play-circle
              |
              | {{ $t("startTraining") }}
          v-card-subtitle(style='position: relative; padding-bottom: 6px')
            div(style='width: 300px')
              v-chip.ma-1(v-for='(item, i) in getTags(item)', :key='i', style='font-size: 10px') {{ item }}
              v-chip(dark, color='orange', v-if='item.warning', style='font-size: 10px') {{ item.warning }}
          v-card-text(style='padding-bottom: 10px')
            .app-description {{ item.description }}
  NewTreatment(ref='newTreatment')
  DisplayAppsDialog(ref='displayApps', @refresh='refreshData', :hideData='hideData')
  Keyboard#keyboard(layout='normal', :input='input', v-if='visible && enableTouch', :cancel='cancel')
  .nav
    NavigationDrawer(:class='navShow ? "navShow" : "navHide"', @selectClick='selectClick')
  .mask(v-show='navShow && !isOpen', @click='outClick')
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import NewTreatment from './NewTreatment'
import DisplayAppsDialog from './Apps/DisplayAppsDialog.vue'
import { VUE_APP_FILE_SERVER_ENDPOINT } from '@/app.config'
import { app } from '../api'
import Keyboard from 'vue-keyboard-zh'
import { NavigationDrawer } from '../components'

export default {
  data: () => ({
    colors: ['amber'],
    selectedTags: [],
    items: {},
    categoryIndex: -1,
    appsData: [],
    userItems: [],
    hideData: [],
    categoryType: '0,1,2,3,4,5,6,7,8',
    appName: '',
    isOpen: false,
    input: '',
    visible: false,
    contentTop1: '',
    navShow: false
  }),

  watch: {
    apps: function (newVal) {
      this.appsData = newVal
    }
  },

  async mounted() {
    const that = this
    window.addEventListener('resize', this.resize, false)
    this.resize()
    this.items = [
    {
        title: this.$t("vrCognition"),
        value: 0
      },
      {
        title: this.$t("vrPsychology"),
        value: 1
      },
      {
        title: this.$t("vrExposure"),
        value: 2
      },
      {
        title: this.$t("vrMindfulness"),
        value: 3
      },
      {
        title: this.$t("vrHypnosis"),
        value: 4
      },
      {
        title: this.$t("vrMusic"),
        value: 5
      },
      {
        title: this.$t("vrRelaxation"),
        value: 6
      },
      {
        title: this.$t("vrExploration"),
        value: 7
      },
      {
        title: this.$t("vrEvaluation"),
        value: 8
      }
    ]
    const appResult = await app.index(this.getLocalStorage(this.categoryType, 1))
    this.appsData = appResult.data
    const reslut = await app.categoryUserIndex(this.getLocalStorage(this.categoryType, 1))
    const obj = Object.values(reslut.data)
    this.items.forEach(x => {
      obj.forEach(item => {
        if (x.value == item) {
          this.userItems.push(x)
        }
      })
    })
    
    var elementResizeDetectorMaker = require('element-resize-detector')
    var erd = elementResizeDetectorMaker()
    elementResizeDetectorMaker({
      strategy: 'scroll',
      callOnAdd: true,
      debug: true
    })
    var height = 0
    erd.listenTo(document.getElementsByClassName('appTopBar'), function (element) {
      height = element.offsetHeight - 23
      that.contentTop1 = height + 'px'
    })
  },

  methods: {
    setWidth() {
      this.navShow = true
    },
    selectClick(val) {
      if (!this.isOpen) {
        this.navShow = val
      } else {
        this.navShow = true
      }
    },
    outClick() {
      if (this.isOpen) {
        this.navShow = true
      } else {
        this.navShow = false
      }
    },
    keyboardShowClick(e) {
      this.input = e.target
      if (!this.visible) this.visible = true
    },
    cancel() {
      this.visible = false
    },
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    inputClear() {
      this.appName = ''
      this.searchClick()
    },
    async searchClick() {
      const relust = await app.index(this.getLocalStorage(this.categoryType, 1, this.appName))
      this.appsData = relust.data
    },
    getLocalStorage(query, show, name) {
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      const obj1 = {
        adminId: role == 'admin' ? adminId : 1,
        userId: userId,
        role: role,
        isShow: show,
        type: String(query),
        name: name
      }
      return obj1
    },
    async refreshData() {
      const appsData1 = await app.index(this.getLocalStorage(this.categoryType, 1))
      this.appsData = appsData1.data
      this.categoryIndex = -1
    },
    async hideApps(item) {
      // var id = ''
      //const appsId = window.localStorage.getItem('appsId') ? window.localStorage.getItem('appsId') : ''
      // const appsArr = ''
      // id = appsArr.filter(x => {
      //   return x !== String(item.model.ID)
      // })

      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      const obj2 = {
        adminId: role == 'admin' ? adminId : 1,
        userId: userId,
        role: role,
        ids: item.model.ID + '',
        status: false,
      }
      // window.localStorage['appsId'] = id.length > 1 ? id.join(',') : ''
      const result = await app.hideApps(obj2)
      if (result.data == 'success' && this.categoryIndex === -1) {
        const appsData1 = await app.index(this.getLocalStorage(this.categoryType, 1))
        this.appsData = appsData1.data
      } else if (this.categoryIndex !== -1) {
        const result = await app.index(this.getLocalStorage(this.categoryIndex, 1))
        this.appsData = result.data
      }
    },
    async categoryClick(item, index) {
      if (this.categoryIndex !== index) {
        this.categoryIndex = index
        const result = await app.index(this.getLocalStorage(item.value, 1))
        const data = result.data
        this.appsData = data
      } else {
        this.categoryIndex = -1
        const result = await app.index(this.getLocalStorage(this.categoryType, 1))
        const data = result.data
        this.appsData = data
      }
    },
    getCoverSrc(item) {
      return `${VUE_APP_FILE_SERVER_ENDPOINT}/${item.cover}`
    },
    getTags(item) {
      return item?.tags.split(';')
    },
    toggleAppDescription(item) {
      if (!this.shows[item.name]) {
        this.shows[item.name] = true
      } else {
        if (this.shows[item.name] == true) this.shows[item.name] = false
        if (this.shows[item.name] == false) this.shows[item.name] = true
      }
    },
    start(item) {
      this.$refs.newTreatment.preAppId = item?.model.ID
      this.$refs.newTreatment.dialog = true
    },
    async displayApps() {
      this.$refs.displayApps.displayAppsDialog = true
      const result = await app.index(this.getLocalStorage(this.categoryType, 0))
      this.hideData = result.data
    },
    randomColor() {
      return this.colors[Math.floor(Math.random() * this.colors.length)]
    },
    selectTag(tag) {
      this.selectedTags = [tag]
    },
    clearSelectedTags() {
      this.selectedTags = []
    },
    appsByTag(tag) {
      return this.apps.filter(app => app.tags.split(';').includes(tag))
    }
  },

  components: {
    NewTreatment,
    DisplayAppsDialog,
    Keyboard,
    NavigationDrawer
  },

  computed: {
    ...mapState({
      apps: state => state.apps.items
    }),
    ...mapGetters({
      enabledCategory: 'setting/enabledCategory',
      isAdmin: 'users/isAdmin',
      enableTouch: 'setting/enableTouch'
    }),
    tags() {
      const arr = this.apps.flatMap(app => app.tags.split(';'))
      const set = new Set(arr)
      return set
    }
  }
}
</script>

<style lang="sass" scoped>
.topNav
  width: 80%
  display: flex
  justify-content: space-between
.tagBtn
  border-radius: 12px
  border: none
  font-size: 32px
  min-width: 10%
.tagBtn:hover
  transform: scale(1.02)
.wrapDurationBox
  position: absolute
  bottom: 8px
  right: 8px
  padding: 2px 6px
  border-radius: 8px
  background: rgba(255, 255, 255, 0.85)
.durationBox
  position: absolute
  bottom: 8px
  right: 16px
  padding: 2px 12px
  border-radius: 8px
  background: rgba(255, 255, 255, 0.85)
.titleBtn
  display: inline-block
  width: 95px
  height: 35px
  background: #E0E0E0
  border-radius: 84px
  text-align: center
  line-height: 35px
  cursor: pointer
  min-width: 9.7%
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.colorClick
  display: inline-block
  width: 90px
  height: 35px
  background: #FFA500
  border-radius: 84px
  text-align: center
  line-height: 35px
  color: #fff
  cursor: pointer
.topBar
  width: 100%
  position: fixed
  top: 0
  z-index: 2
  margin-left: -255px
  background: #fff
.appTopBar
  width: 100%
  position: fixed
  z-index: 2
  top: 0
  background: #fff

.displayBtn
  display: inline-block

.displayBtn
  position: absolute
  top: 15px
::v-deep .mInput .el-input__inner
  height: 33px !important
#keyboard
  position: fixed
  top: auto !important
  bottom: 0
  z-index: 999
  padding: 14px 21px
.navShow
  animation: expanded 0.5s ease
.navHide
  display: none
.mask
  width: 100%
  height: 100%
  background: rgba(0,0,0,0.3)
  position: fixed
  top: 0
  z-index: 998
.right
  width: 100%
.wrap-flex
  width: 100%
  display: flex
  border-radius: 15px
.img
  border-top-left-radius: 15px !important
  border-bottom-left-radius: 15px
  border-top-right-radius: 0 !important
.app-description
  width: 100%
  height: 70px
  text-overflow: -o-ellipsis-lastline
  overflow: hidden
  text-overflow: ellipsis
  display: -webkit-box
  -webkit-line-clamp: 3
  line-clamp: 3
  -webkit-box-orient: vertical
  margin-top: 10px
</style>

