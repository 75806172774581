<template lang="pug">
div
  v-row
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-monitor-eye
        | {{ $t("monitor") }}
        v-spacer
        v-switch(inset, v-model='monitor')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-timer-outline
        | {{ $t("timeLimit") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='timeLimit',
          :items='timeLimits',
          item-text='text',
          item-value='value'
        )
      StreamSettings(ref="streamSettings")
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-music-circle-outline
        |  {{ $t("musicSelection") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='music',
          :items='musics',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-sine-wave
        | {{ $t("frequencySelection") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='frequency',
          :items='frequencies',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-lightbulb-outline
        | {{ $t("brightnessSelection") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='brightness',
          :items='brightnesses',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-palette-outline
        | {{ $t("colorSelection") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='colour',
          :items='colors',
          item-text='text',
          item-value='value'
        )
</template>

<script>
import { mapGetters } from 'vuex'
import StreamSettings from './common/StreamSettings.vue'
export default {
  data: vm => ({
    monitor: true,
    timeLimit: -1,
    timeLimits: [
      { text: vm.$t('infinite'), value: -1 },
      { text: vm.$t('5min'), value: 5 },
      { text: vm.$t('10min'), value: 10 },
      { text: vm.$t('15min'), value: 15 },
      { text: vm.$t('20min'), value: 20 },
      { text: vm.$t('30min'), value: 30 }
    ],
    mode: '0',
    modes: [
      { text: vm.$t('manualMode'), value: '0' },
      { text: vm.$t('automaticMode'), value: '1' }
    ],
    music: '0',
    musics: [
      { text: vm.$t('alphaWave'), value: '0' },
      { text: vm.$t('betaWave'), value: '1' },
      { text: vm.$t('deltaWave'), value: '2' },
      { text: vm.$t('thetaWave'), value: '3' },
      { text: vm.$t('combination'), value: '4' }
    ],
    frequency: '8',
    frequencies: [
      { text: vm.$t('8hz'), value: '8' },
      { text: vm.$t('20hz'), value: '20' },
      { text: vm.$t('28hz'), value: '28' },
      { text: vm.$t('30hz'), value: '30' },
      { text: vm.$t('35hz'), value: '35' },
      { text: vm.$t('combination'), value: '39' }
    ],
    brightness: '0.1',
    brightnesses: [
      { text: vm.$t('0.1f'), value: '0.1' },
      { text: vm.$t('0.2f'), value: '0.2' },
      { text: vm.$t('0.3f'), value: '0.3' },
      { text: vm.$t('0.4f'), value: '0.4' },
      { text: vm.$t('0.5f'), value: '0.5' },
      { text: vm.$t('0.6f'), value: '0.6' },
      { text: vm.$t('0.7f'), value: '0.7' },
      { text: vm.$t('0.8f'), value: '0.8' },
      { text: vm.$t('0.9f'), value: '0.9' },
      { text: vm.$t('1f'), value: '1' },
    ],
    colour: 'red',
    colors: [
      { text: vm.$t('red'), value: 'red' },
      { text: vm.$t('orange'), value: 'orange' },
      { text: vm.$t('yellow'), value: 'yellow' },
      { text: vm.$t('green'), value: 'green' },
      { text: vm.$t('cyan'), value: 'cyan' },
      { text: vm.$t('blue'), value: 'blue' },
      { text: vm.$t('purple'), value: 'purple' },
      { text: vm.$t('white'), value: 'white' },
    ]
  }),

  methods: {
    getSettings() {
      if (this.music === '4' || this.frequency === '39') {
        this.mode = '1'
        this.music = '4'
        this.frequency = '39'
      } else {
        this.mode = '0'
      }
      return JSON.stringify({
        monitor: this.monitor,
        mode: this.mode,
        music: this.music,
        frequency: this.frequency,
        brightness: this.brightness,
        colour: this.colour,
        timeLimit: this.enabledCountdown ? this.timeLimit : -1,
        streamSetting: this.$refs.streamSettings.streamSetting()
      })
    }
  },

  computed: {
    ...mapGetters({
      enabledCountdown: 'setting/enabledCountdown'
    })
  },

  components: {
    StreamSettings
  }
}
</script>

<style lang="sass">
.setting-row
  height: 50px
</style>
