import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { global, socket, users, setting, devices, apps, patients, treatments, records, gauge, gaugeRecords, about } from './modules'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    global,
    socket,
    users,
    setting,
    devices,
    apps,
    patients,
    treatments,
    records,
    gauge,
    gaugeRecords,
    about
  },
  strict: debug,
  plugins: [createPersistedState({ paths: ['users'] })]
})
