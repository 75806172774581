<template lang="pug">
div
  v-row
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-monitor-eye
        | {{ $t("monitor") }}
        v-spacer
        v-switch(inset, v-model='monitor')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-monitor-eye
        | {{ $t("tutorial") }}
        v-spacer
        v-switch(inset, v-model='tutorial')
      v-row.setting-row(v-if='enabledCountdown',align='center', no-gutters)
        v-icon(left) mdi-timer-outline
        | {{ $t("timeLimit") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='timeLimit',
          :items='timeLimits',
          item-text='text',
          item-value='value'
        )
    v-col.px-6(cols='6')
      
      v-row.setting-row(align='center', no-gutters,v-if='appCodename!=="fun_animal"')
        v-icon(left) mdi-timer-outline
        | {{ $t("difficulty") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='difficulty',
          :items='difficultyList',
          item-text='text',
          item-value='value'
        )
      StreamSettings(ref="streamSettings")
</template>

<script>
import { mapGetters } from 'vuex'
import StreamSettings from './common/StreamSettings.vue'
export default {
  props: ['packageName', 'appCodename'],
  data: vm => ({
    monitor: true,
    tutorial: true,
    gameMode: 0,
    timeLimit: -1,
    timeLimits: [
      { text: vm.$t('infinite'), value: -1 },
      { text: vm.$t('5min'), value: 5 },
      { text: vm.$t('10min'), value: 10 },
      { text: vm.$t('15min'), value: 15 },
      { text: vm.$t('20min'), value: 20 },
      { text: vm.$t('20min'), value: 30 }
    ],
    difficulty: 0,
    difficultyList: [
      { text: vm.$t('easy'), value: 0 },
      { text: vm.$t('normal'), value: 1 },
      { text: vm.$t('hard'), value: 2 }
    ]
  }),

  methods: {
    getSettings() {
      switch (this.appCodename){
        case "fun_animal":
          this.gameMode = 2
          break
        case "color_selection":
          this.gameMode = 1
          break
        case "arithmetic_shot":
          this.gameMode = 0
          break
      }
      return JSON.stringify({
        codename: this.appCodename,
        tutorial:this.tutorial,
        monitor: this.monitor,
        Difficulty: this.difficulty,
        GameMode: this.gameMode,
        timeLimit: this.enabledCountdown ? this.timeLimit : -1,
        streamSetting: this.$refs.streamSettings.streamSetting()
      })
    }
  },

  computed: {
    ...mapGetters({
      enabledCountdown: 'setting/enabledCountdown'
    })
  },
  components:{
    StreamSettings
  }
}
</script>

<style lang="sass">
.setting-row
  height: 50px
</style>

