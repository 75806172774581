import { gauge } from '../../api'

export default {
  namespaced: true,

  state: {
    items: []
  },

  actions: {
    async index({ commit }) {
      const response = await gauge.index()
      commit('index', response.data)
    }
  },

  mutations: {
    index(state, payload) {
      state.items = payload
    }
  }
}
