<template lang="pug">
div
  v-row(align='center')
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-monitor-eye
        | {{ $t("monitor") }}
        v-spacer
        v-switch(inset, v-model='monitor')
      v-col.px-6(cols='6') 
      v-row.setting-row(align='center', no-gutters)
        img(src='@/assets/icon-breathe.png', width='24', style='margin-right: 5px')
        | {{ $t("breathMonitor") }}
        v-spacer
        v-switch(inset, v-model='breathMonitor')
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters, v-if='enabledCountdown')
        v-icon(left) mdi-timer-outline
        | {{ $t("timeLimit") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='timeLimit',
          :items='timeLimits',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters, v-if='!enabledCountdown')
        v-icon(left) mdi-timer-outline
        | {{ $t("timeLimit") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='noCountdownTimeLimit',
          :items='noCountdowntimeLimits',
          item-text='text',
          item-value='value'
        )
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-numeric-3-box-multiple-outline
        | {{ $t("numOfSets") }}
        v-spacer
        v-select.outlined.mr-3(reverse, style='max-width: 100px', v-model='numOfSets', :items='Sets', item-text='text', item-value='value')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-numeric-6-circle-outline
        | {{ $t("timesPerSet") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='timesPerSet',
          :items='PerSet',
          item-text='text',
          item-value='value'
        )
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: vm => ({
    tutorial: true,
    monitor: true,
    breathMonitor: false,
    timeLimit: 15,
    timeLimits: [
      { text: vm.$t('5min'), value: 5 },
      { text: vm.$t('10min'), value: 10 },
      { text: vm.$t('15min'), value: 15 },
      { text: vm.$t('20min'), value: 20 },
      { text: vm.$t('30min'), value: 30 }
    ],
    noCountdownTimeLimit: -1,
    noCountdowntimeLimits: [
      { text: vm.$t('infinite'), value: -1 },
      { text: vm.$t('5min'), value: 5 },
      { text: vm.$t('10min'), value: 10 },
      { text: vm.$t('15min'), value: 15 },
      { text: vm.$t('20min'), value: 20 },
      { text: vm.$t('30min'), value: 30 }
    ],
    numOfSets: 3,
    Sets: [3, 4, 5],
    timesPerSet: 5,
    PerSet: [5, 6, 7, 8, 9, 10]
  }),

  methods: {
    getSettings() {
      return JSON.stringify({
        tutorial: this.tutorial,
        monitor: this.monitor,
        breath: this.breathMonitor,
        timeLimit: this.enabledCountdown ? this.timeLimit : this.noCountdownTimeLimit,
        numOfSets: this.numOfSets,
        timesPerSet: this.timesPerSet
      })
    }
  },

  computed: {
    ...mapGetters({
      enabledCountdown: 'setting/enabledCountdown'
    })
  }
}
</script>

<style lang="sass">
.setting-row
  height: 50px
</style>

